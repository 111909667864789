import React, { useState } from "react";
import { useSelector } from "react-redux";

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import GridContainer from "components/Grid/GridContainer.js";

import GoogleMapReact from 'google-map-react';
import GridItem from "components/Grid/GridItem.js";
import { Link } from '@material-ui/core';

// core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Button from "components/CustomButtons/Button.js";
import CloseIcon from '@material-ui/icons/Close';

import Tooltip from '@material-ui/core/Tooltip';

import ReactTable from "react-table-6";
import Utils from "../../services/utils";
import axios from 'axios'
import loginStore from "redux/stores/LoginStore.js";

import utils from "../../services/utils";

let _user = loginStore.getLoginUser();

const customStyles = {
  paper: {
    backgroundColor: 'white',
    padding: 30,
    webkitBoxShadow: '5px 6px 10px 0px rgba(0,0,0,0.27)',
    mozBoxShadow: '5px 6px 10px 0px rgba(0,0,0,0.27)',
    boxShadow: '5px 6px 10px 0px rgba(0,0,0,0.27)',
  },
  modal: {
    display: 'block',
    maxWidth: '70%',
    height: '100%',
    overflowWrap: "break-word",
    overflowY: "auto;",
    overflowX: "none;",
    paddingBottom: "20px"
  },
  spanLabel: {
    fontWeight: 500,
  },
  button: {
    position: 'absolute',
    padding: 0,
    margin: 0,
    top: '40px',
    right: '40px',
    scrollbars: "none"
  }
};

const useStyles = makeStyles(customStyles);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const AnyReactComponent = ({ text }) => (<div>
  <HtmlTooltip title={text} interactive>
    <AccountCircleIcon style={{ fontSize: "18px", color: "red" }} />
  </HtmlTooltip></div>);


export default function NonSupplyChainCaseModal(props) {
  const classes = useStyles();

  const defaultProps = {
    center: {
      lng: 100.5018,
      lat: 13.7563
    },
    zoom: 7
  };

  const nonSupplyChainCases = useSelector(state => state.nonSupplyChainCasesReducer.items)


  const industries = useSelector(state => state.industriesReducer.items);

  const subindustries = useSelector(state => state.subIndustriesReducer.items);

  const countries = useSelector(state => state.countriesReducer.items);

  const provinces = useSelector(state => state.provincesReducer.items);

  const districts = useSelector(state => state.districtsReducer.items);



  const getNonSupplyChainCase = (id) => {
    return nonSupplyChainCases.find(supplier => {
      return supplier.id === parseInt(id)
    })
  }

  const getCountryName = (id) => {
    const tobject = countries.find((ttobject) => {
      return ttobject.id === id
    });
    return tobject ? tobject.name : '-'
  }

  const getDistrictName = (id) => {
    const tobject = districts.find((ttobject) => {
      return ttobject.id === id
    });
    return tobject ? tobject.name : '-'
  }


  const getProvinceName = (id) => {
    const tobject = provinces.find((ttobject) => {
      return ttobject.id === id
    });
    return tobject ? tobject.name : '-'
  }

  const nonSupplyChainCase = getNonSupplyChainCase(props.value)
  const [totalWorkers, setTotalWorkers] = useState(0)

  var config = []
  React.useEffect(() => {
    if (localStorage.getItem("token") != undefined) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      }
    }
  }, [])


  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      className={classes.modal + ' modal'}
    >
      <div className={classes.paper}>
        <h3 style={{ marginTop: 0 }}> {`${nonSupplyChainCase && nonSupplyChainCase.name || '-'}`} </h3>
        <Button
          simple
          color="danger"
          className={classes.button}
          onClick={() => props.onClose()}
        >
          <CloseIcon />
        </Button>
        <GridContainer>
          <GridItem>
            <br />
          </GridItem>

          <GridContainer>

            <GridItem xs={12} sm={12} md={6} lg={6}>
              <h4> General: </h4>
              <p> <span className={classes.spanLabel}> Description: </span> {nonSupplyChainCase && nonSupplyChainCase.description || '-'} </p>
            </GridItem>

          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <h4> Location: </h4>
              <p> <span className={classes.spanLabel}> Created: </span> {nonSupplyChainCase && nonSupplyChainCase.created || '-'} </p>
              <p> <span className={classes.spanLabel}> Address: </span> {nonSupplyChainCase && nonSupplyChainCase.address || '-'} </p>
              <p> <span className={classes.spanLabel}> Zipcode: </span> {nonSupplyChainCase && nonSupplyChainCase.zipcode || '-'} </p>
              <p> <span className={classes.spanLabel}> Country: </span> {nonSupplyChainCase && getCountryName(nonSupplyChainCase.country)} </p>
              <p> <span className={classes.spanLabel}> Province: </span> {nonSupplyChainCase && getProvinceName(nonSupplyChainCase.province)} </p>
              <p> <span className={classes.spanLabel}> District: </span> {nonSupplyChainCase && getDistrictName(nonSupplyChainCase.district)} </p>
              <p> <span className={classes.spanLabel}> Industry: </span> {nonSupplyChainCase && utils.getIndustryName(industries, nonSupplyChainCase.industries)} </p>
              <p> <span className={classes.spanLabel}> Sub Industry: </span> {nonSupplyChainCase && utils.getSubIndustryName(subindustries, nonSupplyChainCase.subindustries)} </p>
              <p> <span className={classes.spanLabel}> Zipcode: </span> {nonSupplyChainCase && nonSupplyChainCase.zipcode || '-'} </p>

            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <h4> Contact: </h4>
              <p> <span className={classes.spanLabel}> Offender Name: </span> {nonSupplyChainCase && nonSupplyChainCase.offender_name || '-'} </p>
              <p> <span className={classes.spanLabel}> contact_phone: </span> {nonSupplyChainCase && nonSupplyChainCase.contact_phone || '-'} </p>
              <p> <span className={classes.spanLabel}> contact_email: </span> {nonSupplyChainCase && nonSupplyChainCase.contact_email || '-'} </p>
            </GridItem>

          </GridContainer>
        </GridContainer>

      </div>
    </Modal >
  );
}
