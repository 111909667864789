import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Utils from "../../services/utils";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Select,
    FormControl,
    MenuItem } from '@material-ui/core';
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import StrategicPartnersDropdown from "components/ilmdb/StrategicPartnersDropdown.js"
import SuppliersDropdown from "components/ilmdb/SuppliersDropdown.js"

import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { makeStyles } from "@material-ui/core/styles";
import { createSupplyChain, updateSupplyChain, fetchSupplyChains } from "../../redux/actions/StrategicPartnerActions";

import loginStore from "../../redux/stores/LoginStore";

import { fetchSuppliers } from "../../redux/actions/SupplierActions";

const customStyle = {
    ...styles,
    ...sweetAlertStyles,
    label: {
        color: 'black',
        fontSize: '14px',
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 400,
        lineHeight: 1.42857,
    },
}
const statuses = [
    {status:'Pre Production', value: 'pre_production'},
    {status:'Active', value:'active'},
    {status:'Inactive', value:'inactive'},
]

const useStyles = makeStyles(customStyle);


export default function SupplyChainForm(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedSuppliers, setSelectedSuppliers] = useState(null)
    const supplyChains = useSelector(state => state.supplyChainReducer.items);
    const [selectedStatus, setSelectedStatus] = useState(null)
    const suppliers = useSelector(state => state.suppliersReducer.items)

    const [payload, setPayload] = useState({
        name: '',
        strategic_partner: null,
        suppliers: [],
        supplier_ids_with_status: null,
        selectedSuppliers: null
    })

    const [alert, setAlert] = useState(null)

    const id = new URLSearchParams(props.location.search).get('id') // id from query string of edited strategic partner

    useEffect(() => {
        dispatch(fetchSupplyChains());
    }, []);

    useEffect(() => {
        if (!suppliers || suppliers.length < 1) {
          dispatch(fetchSuppliers())
        }
    }, []);

    useEffect(() => {

        if (supplyChains && supplyChains.length > 0 && id) {
            const supplyChain = supplyChains.filter(item => { return item.id == id })[0]

            if (supplyChain) {
                setSelectedStatus(supplyChain.suppliers)
                let supplierIds = null

                if(supplyChain.suppliers.length > 0){
                    // take the suppliers ids from suppliers object
                    supplierIds = supplyChain.suppliers.map(sp => { return sp.supplier})

                    if(supplierIds){

                        let selectedSuppliers = []

                        const selectedSupplier = supplierIds.map(id => {
                            selectedSuppliers.push(suppliers.filter(supplier => {return supplier.id == id})[0])
                        })

                        if(selectedSupplier){
                            setSelectedSuppliers(selectedSuppliers)
                        }
                    }
                }
                setPayload({...payload,
                    name: supplyChain.name || '',
                    strategic_partner: supplyChain.strategic_partner,
                    suppliers: supplyChain.suppliers,
                    supplier_ids_with_status: supplierIds
                })
            }
        }
    }, [supplyChains]);

    const handleConfirmSuccessAlert = () => {
        props.history.push('/admin/supplychains')
    }

    const handleSelectStatus = (e) => {
    
        const supplierIndex = payload.suppliers.findIndex(item => {
            return item.supplier == e.target.name;
        });

        let copiedSuppliers = [...payload.suppliers];
        copiedSuppliers[supplierIndex] = {
            ...copiedSuppliers[supplierIndex],
            status: e.target.value
        };

        setPayload({
            ...payload,
            suppliers: copiedSuppliers
        });
    }
    
    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                onConfirm={handleConfirmSuccessAlert}
                confirmBtnCssClass={classes.button + " " + classes.success}
                title="Well done!"
            >
                {id ? 'Supply Chain was updated' : 'Supply Chain has been successfully created'}
            </SweetAlert>
        );
    };

    const errorAlert = (error) => {
        let errorMessage = error.message;

        console.log('ERRROR', error)
        setAlert(
            <SweetAlert
                danger
                onConfirm={() => setAlert(null)}
                confirmBtnCssClass={classes.button + " " + classes.success}
                title="Error"
            >
                {errorMessage}
            </SweetAlert>
        );
    };

    const onSubmit = () => {
        console.log('On submit')
        console.log(payload)
        const errors = [];

        if (!payload.name || payload.name === '') {
            errors.push("Name must contain a value.");
        }

        if (!payload.strategic_partner) {
            errors.push("strategic_partner must contain a value.");
        }


        if (!payload.suppliers || !payload.suppliers.length || payload.suppliers.length < 1) {
            errors.push("At least one supplier must be selected.");
        }

        if (errors.length > 0) {
            errorAlert({
                message: (<div>{errors.map((error) => {
                    return (<div>{error}<br /><br /></div>);
                })}</div>)
            })
            return;
        }

        setPayload({
            ...payload,
            suppliers:payload.supplier_ids_with_status
        })

        if (id) {
            dispatch(updateSupplyChain(id, payload, successAlert, errorAlert))
        }
        else {
            dispatch(createSupplyChain(payload, successAlert, errorAlert))
        }
    }

    const handleSupplierDropDownSelect = (tsuppliers, selectedSuppliersData) => {
    
        // Filter the payload.suppliers array based on tsuppliers
        let filtered = payload.suppliers.filter(obj => tsuppliers.includes(obj.supplier));
    
        // Find new suppliers
        let newSuppliers = selectedSuppliersData.filter(sup => !payload.suppliers.some(obj => obj.supplier == sup.id));
    
        // Create a new payload object with updated suppliers
        const updatedPayload = {
            ...payload,
            selectedSuppliers: selectedSuppliersData,
            supplier_ids_with_status: tsuppliers,
            suppliers: [...filtered, ...newSuppliers.map(sup => ({ supplier: sup.id, supplier_name: sup.name, status: findSelected(sup.id) }))]
        };
    
        // Update the payload state
        setPayload(updatedPayload);
    };
    function findSelected(supplierId){
        let supplier = null;
        if(payload.suppliers != null){
            supplier = payload.suppliers.find(item => {
            return item.supplier == supplierId
         })
        }

        return supplier ? supplier.status: "active"
    }

    return (
        <GridContainer>
            {alert}
            <GridItem xs={12}>
                <Card style={{ marginTop: 0 }}>
                    <CardHeader color="rose" icon>
                        <h4 className={classes.cardIconTitle}>Add/Edit Supply Chain</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} >
                                <CustomInput
                                    id="name"
                                    labelText="Name"
                                    formControlProps={{ fullWidth: true }}
                                    inputProps={{
                                        type: "text",
                                        onChange: e => setPayload({
                                            ...payload,
                                            name: e.target.value
                                        })
                                    }}
                                    value={payload.name}
                                />
                            </GridItem>
                            <GridItem xs={12}>
                                <Card style={{ marginTop: 0 }}>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                <StrategicPartnersDropdown
                                                    value={payload.strategic_partner}
                                                    onSelect={partnerId => setPayload({
                                                        ...payload,
                                                        strategic_partner: partnerId
                                                    })}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem xs={12}>
                                <Card style={{ marginTop: 0 }}>
                                    <CardBody>
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                <SuppliersDropdown
                                                    dataInclude={true}
                                                    value={payload.supplier_ids_with_status}
                                                    onSelect={(tsuppliers, selectedSuppliersData) => handleSupplierDropDownSelect(tsuppliers, selectedSuppliersData)}
                                                    multipleselect={true}
                                                />
                                            </GridItem>
                                        </GridContainer>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            {Array.isArray(payload.suppliers) && payload.suppliers.length > 0 &&
                                   payload.suppliers.map(supplier => {
                                       return (
                                        <GridItem xs={6} key={supplier.supplier}>
                                           <FormControl key={supplier.supplier+supplier.supplier_name} fullWidth>
                                                <h4>{supplier.supplier_name}</h4>
                                                <Select 
                                                        key={supplier.supplier_name+supplier.supplier}
                                                        defaultValue={findSelected(supplier.supplier)}
                                                        name={String(supplier.supplier)}
                                                        onChange={handleSelectStatus.bind(this)}
                                                        >
                                                        {statuses.map(option => {
                                                            return  <MenuItem 
                                                                      key={Utils.giveMeGuid()}
                                                                      supplier-id={supplier.supplier}
                                                                      value={option.value} 
                                                                      primarytext={option.status}>
                                                                        {option.status}
                                                                      </MenuItem> 
                                                        })}
                                                </Select>
                                                <hr/>
                                            </FormControl>
                                        </GridItem>)
                                        })
                                }

                        </GridContainer>


                        <GridContainer justifyContent='flex-end'>

                            {(() => {
                               return (<div>
                                    {loginStore.isDirectors() === false && <h4>*Please Note: To Add or Edit supply chains you must have 'Directors' Group added to your account .</h4>}
                                    <Button disabled={loginStore.isDirectors() === false} color='success' onClick={onSubmit}>Save</Button>
                                </div>)
                            })()}

                        </GridContainer>

                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer >);
}
