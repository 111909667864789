import React, { useState } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import utils from "../../services/utils.js";

const customStyles = {
  ...styles,
};

const useStyles = makeStyles(customStyles);

export default function CaseStatusesDropdown(props, context) {
  const valueEmpty = 1;

  const propsOnSelect = props.onSelect ? props.onSelect : (e) => {};
  const propsShowLabel = props.showLabel !== undefined ? props.showLabel : true;

  const caseStatuses = [
    { id: 1, name: context.t("open") },
    { id: 2, name: context.t("close") },
  ];
  const [caseStatusId, setCaseStatusId] = useState(props?.value ?? valueEmpty);

  const classes = useStyles();

  const handleChangeCaseStatusId = (e) => {
    setCaseStatusId(e.target.value);
    propsOnSelect({
      ...e,
      list: caseStatuses,
    });
  };

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      {propsShowLabel === true ? (
        <InputLabel
          htmlFor="casestatuses-select"
          className={classes.selectLabel}
        >
          {context.t("Case Status")}
        </InputLabel>
      ) : null}

      <Select
        key={utils.giveMeGuid()}
        value={caseStatusId}
        onChange={handleChangeCaseStatusId}
        inputProps={{
          name: "casestatusesSelect",
          id: "casestatuses-select",
        }}
      >
        {caseStatuses &&
          Object.keys(caseStatuses).map((obj, i) => {
            return (
              <MenuItem value={caseStatuses[obj].id} key={utils.giveMeGuid()}>
                {caseStatuses[obj].name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}

CaseStatusesDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};
