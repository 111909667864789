import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import { useDispatch, useSelector } from "react-redux";

import { fetchReasons } from "../../redux/actions/CallActions";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import utils from "../../services/utils.js";
import loginStore from "redux/stores/LoginStore";

const customStyles = {
  ...styles,
};

let _user = loginStore.getLoginUser();

const useStyles = makeStyles(customStyles);

let fetch = false;

export default function ReasonsDropdown(props, context) {
  const propsOnSelect = props.onSelect ? props.onSelect : (e) => {};
  const valueEmpty = " ";

  const reasons = useSelector((state) => state.callReasons.items);
  const lang = useSelector((state) => state.i18nState.lang);
  const dispatch = useDispatch();

  const [reasonId, setReasonId] = useState(
    props.values ? props.values : valueEmpty
  );

  useEffect(() => {
    if (fetch === false) {
      fetch = true;
      dispatch(fetchReasons(lang));
    }
  }, [reasonId]);

  const classes = useStyles();

  const handleReasonChannelId = (e) => {
    setReasonId(e.target.value);
    propsOnSelect({
      ...e,
      list: reasons,
    });
  };

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="channel-select" className={classes.selectLabel}>
        {context.t("Purpose of Call/Message")}
      </InputLabel>
      <Select
        key={utils.giveMeGuid()}
        value={reasonId}
        onChange={handleReasonChannelId}
        inputProps={{
          name: "reasonSelect",
          id: "reason-select",
        }}
      >
        <MenuItem value={" "} key={utils.giveMeGuid()} disabled={true}>
          {context.t("Purpose")}
        </MenuItem>
        {_user && _user.groups.includes("CSO")
          ? reasons &&
            Object.keys(reasons).map((obj, i) => {
              if (reasons[obj].name !== "Coordination with Issara") {
                return (
                  <MenuItem value={reasons[obj].id} key={utils.giveMeGuid()}>
                    {reasons[obj].name}
                  </MenuItem>
                );
              }
            })
          : reasons &&
            Object.keys(reasons).map((obj, i) => {
              if (reasons[obj].name !== "Coordination call") {
                return (
                  <MenuItem value={reasons[obj].id} key={utils.giveMeGuid()}>
                    {reasons[obj].name}
                  </MenuItem>
                );
              }
            })}
      </Select>
    </FormControl>
  );
}

ReasonsDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};
