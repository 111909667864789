import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from '@material-ui/core/Tooltip';

import ReactTable from "react-table-6";

import Pagination from "components/Pagination/Pagination2.js";

// react component used to create sweet alerts
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardText from "components/Card/CardText.js";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox"
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";
import VisibilityIcon from '@material-ui/icons/Visibility';

// import SuppliersDropdown from "components/ilmdb/SuppliersDropdown.js"
import { withStyles, makeStyles } from "@material-ui/core/styles";

import NonSupplyChainCaseModal from "./NonSupplyChainCaseModal";

import { fetchNonSupplyChainsCases } from "../../redux/actions/SupplierActions";


import { fetchCountries } from "../../redux/actions/LocaleActions";

import { fetchIndustries, fetchSubIndustries } from "../../redux/actions/LocaleActions.js";
import { fetchProvinces } from "../../redux/actions/LocaleActions";
import { fetchDistricts } from "../../redux/actions/LocaleActions";

import Utils from "services/utils.js";

import loginStore from "../../redux/stores/LoginStore"
import LinearProgress from '@material-ui/core/LinearProgress';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import { MenuProps, options } from "./utils";
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';


let _user = loginStore.getLoginUser();
if (_user) {
  var _group = _user.groups.includes("CSO")
}

const customStyle = {
  ...customCheckboxRadioSwitch,
  ...sweetAlertStyles,
  customButton: {
    padding: 0,
    margin: 0
  },
  checkRoot: {
    padding: 0
  },
  labelRoot: {
    margin: 0
  },

}

const useStyles = makeStyles(customStyle);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);



export default function NonSupplyChainCaseList(props) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const industries = useSelector(state => state.industriesReducer.items)
  const fetchingIndustries = useSelector(state => state.industriesReducer.fetchingIndustries)

  const subindustries = useSelector(state => state.subIndustriesReducer.items)
  const fetchingSubIndustries = useSelector(state => state.subIndustriesReducer.fetchingSubIndustries)

  const countries = useSelector(state => state.countriesReducer.items)
  const fetchingCountries = useSelector(state => state.countriesReducer.fetchingCountries)

  const provinces = useSelector(state => state.provincesReducer.items)
  const fetchingProvinces = useSelector(state => state.provincesReducer.fetchingProvinces)

  const districts = useSelector(state => state.districtsReducer.items)
  const fetchingDistricts = useSelector(state => state.districtsReducer.fetchingDistricts)

  const all_non_supply_chain_cases = useSelector(state => state.nonSupplyChainCasesReducer.items)
  const nonSupplyChainCasesMap = useSelector(state => state.nonSupplyChainCasesReducer.itemsMap);
  const fetchingNonSupplyChains = useSelector(state => state.nonSupplyChainCasesReducer.fetchingNonSupplyChains)

  const [nonSupplyChainCasesArray, setNonSupplyChainCasesArray] = useState([]);

  const [checked, setChecked] = React.useState(false);

  const [checkedCountry, setCheckedCountry] = React.useState(false);
  const [nonSupplyChainCases, setNonSupplyChainCases] = useState([]);


  const handleSelectNonSupplyChainCase = (event, newValue) => {
    props.history.push(`/admin/edit-non-supply-chain-case?id=${newValue.id}`);
  }

  const handleChangeCountry = (event) => {
    setCheckedCountry(event.target.checked);
  };

  

  // FILTERS
  const [selectedNonSupplyChainCases, setSelectedNonSupplyChainCases] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("")

  const [selectedNonSupplyChainCaseName, setSelectedNonSupplyChainCaseName] = useState("");
  
  const [selected, setSelected] = useState([]);

  const [modal, setModal] = useState({
    open: false,
    id: null
  });

  useEffect(() => {
    dispatch(fetchNonSupplyChainsCases)
  }, [selectedCountry, selectedNonSupplyChainCaseName == null])

  useEffect(() => {
    // Fetch countries
    dispatch(fetchCountries())
    dispatch(fetchProvinces())
    dispatch(fetchDistricts())
    dispatch(fetchIndustries())
    dispatch(fetchSubIndustries())
    dispatch(fetchNonSupplyChainsCases());

  }, []);


  useEffect(() => {
    if (all_non_supply_chain_cases.length > 0) {
      onSearchNonSupplyChainCases()
    }
  }, [all_non_supply_chain_cases, selectedNonSupplyChainCases, industries, subindustries, countries]);



  const redirectToEditNonSupplyChainCase = (id) => {
    props.history.push(`/admin/edit-non-supply-chain-case?id=${id}`);
  }

  const handleViewButtonClick = (id) => {
    setModal({
      open: true,
      id: id
    })
  }

  const editButton = (id) => {
    return (
      <Button
        title={"Edit: " + id}
        simple
        color="success"
        value={id}
        className={classes.customButton}
        onClick={(e) => redirectToEditNonSupplyChainCase(e.currentTarget.value)}
      >
        <Edit />
      </Button>
    )
  }

  const viewButton = (id) => {
    return (
      <Button
        title={"View: " + id}
        simple
        color="info"
        value={id}
        className={classes.customButton}
        onClick={(e) => handleViewButtonClick(e.currentTarget.value)}
      >
        <VisibilityIcon />
      </Button>
    )
  }


  const onSearchNonSupplyChainCases = () => {
    const filteredNonSupplyChainCasesArray = []

    all_non_supply_chain_cases.map(itemIter => {
      let newItem = { ...itemIter };

      // Filter by strategic partner
      if (selectedNonSupplyChainCases.length > 0) {
        if (!selectedNonSupplyChainCases.includes(newItem.id)) {
          return // skip
        }
      }

      newItem['industry_name'] = (industries) ? (props => {
        const industry = industries.find((item) => {
          return item.id === props;
        });
        return (industry ? industry.name : '-');

      })((newItem.industries && newItem.industries.length > 0) ? newItem.industries[0] : -1) : "-";

      newItem['subindustry_name'] = (subindustries) ? (props => {
        const subindustry = subindustries.find((item) => {
          return item.id === props;
        });
        return (subindustry ? subindustry.name : '-');

      })((newItem.subindustries && newItem.subindustries.length > 0) ? newItem.subindustries[0] : -1) : "-";

      newItem['country_name'] = (countries) ? (props => {
        const country = countries.find((item) => {
          return item.id === props;
        });
        return (country ? country.name : '-');

      })(newItem.country) : "-";

      newItem['province_name'] = (provinces) ? (props => {
        if (provinces && Array.isArray(provinces) && provinces.length > 0) {
          const province = provinces.find((item) => {
            return item.id === props;
          });
          return (province ? province.name : '-');
        } else {
          return '-'
        }

      })(newItem.province) : "-";

      newItem['district_name'] = (districts) ? (props => {
        const district = districts.find((item) => {
          return item.id === props;
        });
        return (district ? district.name : '-');

      })(newItem.district) : "-";



      newItem['edit'] = editButton(newItem.id)
      newItem['view'] = viewButton(newItem.id)

      filteredNonSupplyChainCasesArray.push(newItem)
    });
    setNonSupplyChainCasesArray(filteredNonSupplyChainCasesArray)
  }

  if (fetchNonSupplyChainsCases === true ||
    Utils.isEmpty(industries) === true ||
    Utils.isEmpty(subindustries) === true ||
    Utils.isEmpty(countries) === true) {
    return <LinearProgress />
  }


  const handleTypeSupplier = (event) => {
    setSelectedNonSupplyChainCaseName(event.target.value)
  }

 
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 100);
  };


  const selectMultipleCountry = (event) => {
    setSelected(event.target.value);
    setSelectedCountry(event.target.value[0])
  };

  return (
    <GridContainer>

      <NonSupplyChainCaseModal open={modal.open} value={modal.id} onClose={() => setModal({ ...modal, open: false })} />
      <GridItem xs={12}>
        <FormGroup row>
          <FormControlLabel style={{ color: "black", marginTop: "-20px" }}
            control={
              <Checkbox
                checked={checkedCountry}
                onChange={handleChangeCountry}
                name="checkedB"
                color="primary"
              />
            }
            label="Search by Worker Nationality"
          />
        </FormGroup>
      </GridItem>




      <GridItem xs={12}>
        <Card style={{ marginTop: 0 }}>
          <CardText style={{ color: 'white' }}>
            Filter by Non Supply Chain Case Names
          </CardText>
          <CardBody>
            <GridContainer>
              <GridItem xs={12}>
                {/* <SuppliersDropdown
                      value={selectedSuppliers}
                      onSelect={suppliers => setSelectedSuppliers(suppliers)}
                      supplierFilterParams={[setSuppliersArray, selectedStrategicPartners, suppliers, suppliersArray2]}
                      multipleselect={true}
                    /> */}

                <Autocomplete
                  id="combo-box-demo"
                  options={nonSupplyChainCasesArray ? nonSupplyChainCasesArray : null}
                  getOptionLabel={(option) => option.name}
                  style={{ width: "100%", backgroundColor: "#fff" }}
                  onChange={handleSelectNonSupplyChainCase}
                  filterOptions={filterOptions}
                  renderInput={
                    (params) => {
                      return (<TextField {...params} label="Non Supply Chain Case Names" variant="outlined" limit={100} />)
                    }
                  }
                  onKeyDown={handleTypeSupplier}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>


      {
        checkedCountry ?
          <GridItem xs={12}>
            <Card style={{ marginTop: 0 }}>
              <CardText style={{ color: 'white' }}>
                Search by worker Nationality
              </CardText>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    <FormControl variant="outlined" className={classes.formControl} style={{ width: "100%", paddingRight: "12px" }}>
                      <InputLabel id="mutiple-select-label">Worker Nationality</InputLabel>
                      <Select
                        labelId="mutiple-select-label"
                        multiple
                        value={selected}
                        onChange={selectMultipleCountry}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                      >
                        {
                          options.map((option) => (
                            <MenuItem key={option.ContryName} value={option.Value} id="">
                              <ListItemText primary={option.ContryName} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          : null
      }
   
      <GridItem xs={12}>
        <CardHeader style={{ padding: 0 }}>
          <GridContainer justifyContent='space-between'>
            <GridItem>
              <h4>Search Results
                (Found: {(nonSupplyChainCasesArray !== undefined &&
                  nonSupplyChainCasesArray.length !== undefined) ? nonSupplyChainCasesArray.length : 0})
              </h4>
            </GridItem>

          </GridContainer>
        </CardHeader>


        {(nonSupplyChainCasesArray && nonSupplyChainCasesArray.length > 0) &&
        (<Card style={{ marginTop: 0 }}>
          <CardBody>
            <ReactTable PaginationComponent={Pagination}
              defaultFilterMethod={(filter, row) => Utils.findStringInObjectFields([row._original], filter.value, [filter.id]).length > 0}
              filterable={true}

              data={nonSupplyChainCasesArray}
              // defaultSorted={[{
              //   id: 'name',
              // }]}
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                  filterable: true,
                  width: 200,
                  Cell: props => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 120)}
                        </div>
                      </HtmlTooltip>
                    )
                  }
                },
                {
                  Header: "Description",
                  accessor: "description",
                  filterable: true,
                  width: 200,
                  Cell: props => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 120)}
                        </div>
                      </HtmlTooltip>
                    )
                  }
                },
                {
                  Header: "Country",
                  accessor: "country_name",
                  width: 200,
                  filterable: true,
                  Cell: props => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 80)}
                        </div>
                      </HtmlTooltip>
                    )
                  }
                },
                {
                  Header: "Province",
                  accessor: "province_name",
                  width: 200,
                  filterable: true,
                  Cell: props => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 80)}
                        </div>
                      </HtmlTooltip>
                    )
                  }
                },
                {
                  Header: "District",
                  accessor: "district_name",
                  width: 200,
                  filterable: true,
                  Cell: props => {
                    return (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 80)}
                        </div>
                      </HtmlTooltip>
                    )
                  }
                },

                {
                  Header: "",
                  sortable: false,
                  filterable: false,
                  accessor: "view",
                  width: 40
                },
                {
                  Header: "",
                  show: (_group === true) ? false : true,
                  sortable: false,
                  filterable: false,
                  accessor: "edit",
                  width: 40,
                },
                {
                  Header: "",
                  width: 20,
                  sortable: false,
                  filterable: false
                }

              ]}
              defaultPageSize={5}
              showPaginationTop={false}
              showPaginationBottom={true}
              loading={fetchingNonSupplyChains || fetchingCountries || fetchingIndustries || fetchingSubIndustries || fetchingProvinces || fetchingDistricts}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>)
        }
      </GridItem>


    </GridContainer>
  );
}
