import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Progressbar from "./Progressbar.js";
import PiCharts from "./PiCharts.js";
import DonutChart from "./DonutChart.js";
import GridContainer from "components/Grid/GridContainer.js";
import { makeStyles } from "@material-ui/core/styles";
import SpeedometerStat from "./SpeedometerStat";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import "./css/style1.css";
import HttpService from "services/HttpService.js";
import loginStore from "redux/stores/LoginStore.js";
import { Container, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "white",
    color: "black",
    borderBottom: "2px solid black",
    borderTop: "2px solid black",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(0)": {
      backgroundColor: theme.palette.action.hover,
    },
    border: "2px solid white",
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 400,
  },
  topHeading: {
    borderRadius: "0",
    backgroundColor: "#999999",
    color: "#fff",
    fontWeight: "500",
    padding: "18px",
    width: "100%",
  },
  theadtext: {
    color: "black",
    fontWeight: "600",
    fontSize: "16px",
  },
  gaugeContainer: {
    textAlign: "center",
    justifyContent: "center",
    height: "180px",
    display: "flex",
  },
  gaugeBox: {
    minHeight: "10rem",
    justifyContent: "center",
    margin: "10px",
    boxShadow: "0px 1px 5px #d1d1d1",
  },
  gaugeTableText: {
    fontSize: "13px",
    fontWeight: 500,
    marginTop: "-30px",
  },
  container: {
    flex: 1,
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    margin: 10,
  },
});

export default function SPProgressDashboard(props, context) {
  let _user = loginStore.getLoginUser();
  const [chartData, setChartData] = useState([]);
  const [actualChartData, setActualChartData] = useState([]);
  const [activitiesdata, setActivitiesData] = useState([]);

  useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/activity_progress/?sp=${
        _user.partner != null ? _user.partner : props.partner
      }`,
      (response) => {
        setActivitiesData(response.results);
      },
      (err) => {
        console.log(err.message);
      }
    );
    setChartData([]);
    setActualChartData([]);
  }, [props.partner]);

  let newupdatearray = [];
  if (activitiesdata.length !== 0) {
    activitiesdata.map((item, index) => {
      if (item.value === true) {
        newupdatearray.push(item);
      }
    });
  }

  newupdatearray = newupdatearray.sort((a, b) => {
    if (a.priority_activity.id < b.priority_activity.id) {
      return -1;
    }
  });

  const classes = useStyles();
  const [internationalStandards, setInternationalStandards] = useState(false);
  const [communicatedToSupp, setCommunicatedToSupp] = useState(false);
  const [commercialProcess, setCommercialProcess] = useState(false);
  const [wcInternationalSnD, setWcInternationalSnD] = useState(false);
  const [wcCommunicatedToS, setWcCommunicatedToS] = useState(false);
  const [wcCommercialProcesses, setWcCommercialProcesses] = useState(false);
  const [supplierCRC, setSupplierCRC] = useState();
  const [strategicPartner, setStrategicPartner] = useState(null);

  useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/?sp=${
        _user.partner != null ? _user.partner : props.partner
      }`,
      (response) => {
        if (response.hasOwnProperty("Result")) {
        } else {
          setStrategicPartner(response.strategic_partner);
          setChartData(response);
          for (let i = 0; i < response.key_activities.length; i++) {
            if (
              response.key_activities[i].area === "labour_recruitment" &&
              response.key_activities[i].str_value ===
                "In line with international standards and definitions"
            ) {
              setInternationalStandards(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "labour_recruitment" &&
              response.key_activities[i].str_value ===
                "Clearly communicated to suppliers, including effective date"
            ) {
              setCommunicatedToSupp(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "labour_recruitment" &&
              response.key_activities[i].str_value ===
                "Integrated into commercial processes"
            ) {
              setCommercialProcess(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "working_conditions" &&
              response.key_activities[i].str_value ===
                "In line with international standards and definitions"
            ) {
              setWcInternationalSnD(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "working_conditions" &&
              response.key_activities[i].str_value ===
                "Clearly communicated to suppliers"
            ) {
              setWcCommunicatedToS(response.key_activities[i].value);
            }
            if (
              response.key_activities[i].area === "working_conditions" &&
              response.key_activities[i].str_value ===
                "Integrated into commercial processes"
            ) {
              setWcCommercialProcesses(response.key_activities[i].value);
            }
          }
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/all_cases/?sp=${
        _user.partner != null ? _user.partner : props.partner
      }`,
      (response) => {
        setActualChartData(response);
      },
      (err) => {
        console.log(err.message);
      }
    );
  }, [props.partner]);

  useEffect(() => {
    if (strategicPartner !== null) {
      HttpService.get(
        `${process.env.REACT_APP_API_URL}/strategicpartners/${
          _user.partner != null ? _user.partner : props.partner
        }/supplier_crc_score/`,
        (response) => {
          setSupplierCRC(response);
        },
        (err) => {
          console.log(err.message);
        }
      );
    }
  }, [strategicPartner]);

  const [progressOne, setProgressOne] = useState();
  const [progressTwo, setProgressTwo] = useState();

  useEffect(() => {
    // ========== AREA 2. LABOUR RECRUITMENT ER INDICATOR 1. dynamic bottom strings =================
    if (
      (internationalStandards && communicatedToSupp && !commercialProcess) ||
      (internationalStandards && !communicatedToSupp && !commercialProcess) ||
      (!internationalStandards && !communicatedToSupp && !commercialProcess) ||
      (internationalStandards && communicatedToSupp && commercialProcess)
    ) {
      setProgressOne(
        <>
          <Grid xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
          <Grid xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
        </>
      );
    }
    if (
      (!internationalStandards && communicatedToSupp && commercialProcess) ||
      (!internationalStandards && communicatedToSupp && !commercialProcess)
    ) {
      setProgressOne(
        <>
          <Grid xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
          <Grid xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
        </>
      );
    }
    if (!internationalStandards && !communicatedToSupp && commercialProcess) {
      setProgressOne(
        <>
          <Grid xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
        </>
      );
    }
    if (internationalStandards && !communicatedToSupp && commercialProcess) {
      setProgressOne(
        <>
          <Grid xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
        </>
      );
    }
    if (!internationalStandards && communicatedToSupp && !commercialProcess) {
      setProgressOne(
        <>
          <Grid xs={4}>
            {context.t(
              "Clearly communicated to suppliers, including effective date"
            )}
          </Grid>
          <Grid xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
        </>
      );
    }

    // ============ AREA 3. WORKING CONDITIONS WORK INDICATOR 1 dynamic bottom strings ============
    if (
      (wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses) ||
      (wcInternationalSnD && !wcCommunicatedToS && !wcCommercialProcesses) ||
      (!wcInternationalSnD && !wcCommunicatedToS && !wcCommercialProcesses) ||
      (wcInternationalSnD && wcCommunicatedToS && wcCommercialProcesses)
    ) {
      setProgressTwo(
        <>
          <Grid xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid xs={4}>{context.t("Clearly communicated to suppliers")}</Grid>
          <Grid xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
        </>
      );
    }
    if (
      (!wcInternationalSnD && wcCommunicatedToS && wcCommercialProcesses) ||
      (!wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses)
    ) {
      setProgressTwo(
        <>
          <Grid xs={4}>{context.t("Clearly communicated to suppliers")}</Grid>
          <Grid xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
        </>
      );
    }
    if (!wcInternationalSnD && !wcCommunicatedToS && wcCommercialProcesses) {
      setProgressTwo(
        <>
          <Grid xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid xs={4}>{context.t("Clearly communicated to suppliers")}</Grid>
        </>
      );
    }
    if (wcInternationalSnD && !wcCommunicatedToS && wcCommercialProcesses) {
      setProgressTwo(
        <>
          <Grid xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
          <Grid xs={4}>{context.t("Clearly communicated to suppliers")}</Grid>
        </>
      );
    }
    if (!wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses) {
      setProgressTwo(
        <>
          <Grid xs={4}>{context.t("Clearly communicated to suppliers")}</Grid>
          <Grid xs={4}>
            {context.t("In line with international standards and definitions")}
          </Grid>
          <Grid xs={4}>
            {context.t("Integrated into commercial processes")}
          </Grid>
        </>
      );
    }
  }, [
    internationalStandards,
    communicatedToSupp,
    commercialProcess,
    wcInternationalSnD,
    wcCommunicatedToS,
    wcCommercialProcesses,
  ]);

  function getTitle(title_text) {
    let title = "";
    let area_title = title_text.split("_");
    for (let i = 0; i < area_title.length; i++) {
      title =
        title + area_title[i][0].toUpperCase() + area_title[i].slice(1) + " ";
    }
    return title;
  }

  return (
    <Container>
      {actualChartData.length === 0 ? (
        <LinearProgress />
      ) : (
        <Box sx={{ display: "flex" }}>
          <Grid container spacing={2}>
            <Grid container>
              <div className="workplan-box">
                <h3>{context.t("Workplan Progress Dashboard")}</h3>
                <Grid>
                  <h5>{context.t("AREA 1. WORKER VOICE")}</h5>
                </Grid>

                <GridContainer
                  style={{
                    marginTop: "50px",
                    borderBottom: "1px solid #c4c4c4",
                  }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Grid container>
                      <p style={{ height: "42px" }}>
                        {context.t(
                          "WORK INDICATOR 1. Percentage of tier-1 suppliers (export level) in supply chain with worker voice"
                        )}
                      </p>
                      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ px: 2 }}>
                        <PiCharts
                          name="w1-target"
                          data={chartData}
                          actualData={actualChartData}
                        />
                        <br />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ px: 2 }}>
                        <PiCharts
                          name="w1-actual"
                          data={chartData}
                          actualData={actualChartData}
                        />
                        <br />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Grid container>
                      <p style={{ height: "42px" }}>
                        {context.t(
                          "WORK INDICATOR 2. Percentage of upstream suppliers in supply chain with worker voice"
                        )}
                      </p>

                      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ px: 2 }}>
                        <PiCharts
                          name="w1-target upstream"
                          data={chartData}
                          actualData={actualChartData}
                        />
                        <br />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} sx={{ px: 2 }}>
                        <PiCharts
                          name="w1-actual upstream"
                          data={chartData}
                          actualData={actualChartData}
                        />
                        <br />
                      </Grid>
                    </Grid>
                  </Grid>
                </GridContainer>

                <GridContainer
                  style={{
                    padding: "20px 0",
                    borderBottom: "1px solid #c4c4c4",
                  }}
                >
                  <Grid>
                    <h5>{context.t("AREA 2. LABOUR RECRUITMENT")}</h5>
                  </Grid>
                  <Grid container style={{ marginBottom: "10px" }}>
                    <Grid item xs={5} sx={{ px: 3 }}>
                      <p>
                        {context.t(
                          "ER INDICATOR 1. Ethical recruitment policy in force is:"
                        )}
                      </p>
                    </Grid>
                    <Grid xs={7} sx={{ px: 3 }}>
                      <Progressbar
                        name="Ethical recruitment policy"
                        data={chartData}
                      />
                      <Grid
                        container
                        style={{
                          padding: "0 30px",
                          color: "#a6a6a6",
                          fontSize: "13px",
                        }}
                      >
                        {progressOne ? (
                          progressOne
                        ) : (
                          <Grid xs={12} style={{ textAlign: "center" }}>
                            {context.t("Wait...")}
                            <br />
                            <br />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <p>{context.t("ER INDICATOR 2.")}</p>
                  <Grid container>
                    <Grid
                      item
                      xs={5}
                      sx={{ px: 3 }}
                      style={{ paddingLeft: "10px" }}
                    >
                      <p>
                        {context.t(
                          "Indicator 2A: Number of recruitment agencies mapped across supplier base."
                        )}
                      </p>
                    </Grid>
                    <Grid item xs={7} sx={{ px: 3 }}>
                      <Progressbar
                        name="number of ra mapped"
                        data={chartData}
                      />
                    </Grid>
                  </Grid>

                  <br />
                  <Grid container>
                    <Grid
                      item
                      xs={5}
                      sx={{ px: 3 }}
                      style={{ paddingLeft: "10px" }}
                    >
                      <p>
                        {context.t(
                          "Indicator 2B: Number of suppliers collaborating with Issara and their recruitment agencies on pre-departure orientations and strengthened recruitment practices"
                        )}
                      </p>
                    </Grid>
                    <Grid item xs={7} sx={{ px: 3 }}>
                      <Progressbar
                        name="number of collaborating suppliers with Issara"
                        data={chartData}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid
                      item
                      xs={5}
                      sx={{ px: 3 }}
                      style={{ paddingLeft: "10px" }}
                    >
                      <p>
                        {context.t(
                          "Indicator 2C: Number of suppliers utilizing Golden Dreams Marketplace to reduce risk of worker indebtedness and workers paying fees to brokers and middlemen"
                        )}
                      </p>
                    </Grid>

                    <Grid item xs={7} sx={{ px: 3 }}>
                      <Progressbar
                        name="number suppliers utilizing GD marketplace"
                        data={chartData}
                      />
                    </Grid>
                  </Grid>
                  <br />

                  <Grid container>
                    <Grid item xs={5} sx={{ px: 3 }}>
                      <p>
                        {context.t(
                          "ER INDICATOR 3. Number of suppliers that have undertaken recruitment surveys to assess recruitment process-related risks, and recruitment-related fees paid by workers"
                        )}
                      </p>
                    </Grid>
                    <Grid item xs={7} sx={{ px: 3 }}>
                      <Progressbar
                        name="suppliers that have undertaken recruitment surveys"
                        data={chartData}
                      />
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container>
                    <Grid item xs={5} sx={{ px: 3 }}>
                      <p>
                        {context.t(
                          "ER INDICATOR 4. Percentage of workers in supply chain reimbursed for recruitment-related fees they have paid"
                        )}
                      </p>
                    </Grid>
                    <Grid item xs={7} sx={{ px: 3 }}>
                      <Progressbar
                        name="workers in supply chain"
                        data={chartData}
                      />
                    </Grid>
                  </Grid>
                </GridContainer>
                <br />
                <GridContainer>
                  <Grid>
                    <h5>{context.t("AREA 3. WORKING CONDITIONS")}</h5>
                  </Grid>
                  <Grid container>
                    <Grid item xs={5} sx={{ px: 3 }}>
                      <p>
                        {context.t(
                          "WORK INDICATOR 1. Ethical sourcing and migrant worker policies put into force that are:"
                        )}
                      </p>
                    </Grid>
                    <Grid item xs={7} sx={{ px: 3 }}>
                      <Progressbar
                        name="Ethical sourcing and migrant worker policies"
                        data={chartData}
                      />
                      <Grid
                        container
                        style={{
                          padding: "0 30px",
                          color: "#a6a6a6",
                          fontSize: "13px",
                        }}
                      >
                        {progressTwo ? (
                          progressTwo
                        ) : (
                          <Grid xs={12} style={{ textAlign: "center" }}>
                            {context.t("Wait...")}
                            <br />
                            <br />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <br />
                  <hr />

                  <Grid style={{ marginTop: "50px" }}>
                    <p>
                      {context.t(
                        "WORK INDICATOR 2. Average supplier performance scores:"
                      )}
                    </p>
                  </Grid>

                  <GridContainer style={{ justifyContent: "center" }}>
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={4}
                      className={`${classes.gaugeContainer} speedo-container`}
                    >
                      <Grid className={classes.gaugeBox}>
                        <SpeedometerStat
                          name="Quality of Response"
                          data={chartData}
                        />
                        <p className={classes.gaugeTableText}>
                          {context.t("Quality of Response")}
                        </p>
                        <p style={{ backgroundColor: "grey", color: "white" }}>
                          {context.t("Target")}
                        </p>
                      </Grid>

                      <Grid className={classes.gaugeBox}>
                        <SpeedometerStat
                          name="Quality of Response actual"
                          data={supplierCRC}
                        />
                        <p className={classes.gaugeTableText}>
                          {context.t("Quality of Response")}
                        </p>
                        <p
                          style={{
                            backgroundColor: "grey",
                            color: "white",
                            borderRight: "1px solid white",
                          }}
                        >
                          {context.t("Actual")}
                        </p>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={4}
                      className={`${classes.gaugeContainer} speedo-container`}
                    >
                      <Grid className={classes.gaugeBox}>
                        <SpeedometerStat
                          name="Timeliness of Response"
                          data={chartData}
                        />
                        <p className={classes.gaugeTableText}>
                          {context.t("Timeliness of Response")}
                        </p>
                        <p style={{ backgroundColor: "grey", color: "white" }}>
                          {context.t("Target")}
                        </p>
                      </Grid>

                      <Grid className={classes.gaugeBox}>
                        <SpeedometerStat
                          name="Timeliness of Response actual"
                          data={supplierCRC}
                        />
                        <p className={classes.gaugeTableText}>
                          {context.t("Timeliness of Response")}
                        </p>
                        <p
                          style={{
                            backgroundColor: "grey",
                            color: "white",
                            borderRight: "1px solid white",
                          }}
                        >
                          {context.t("Actual")}
                        </p>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={4}
                      className={`${classes.gaugeContainer} speedo-container`}
                    >
                      <Grid className={classes.gaugeBox}>
                        <SpeedometerStat
                          name="Openness to Reform"
                          data={chartData}
                        />
                        <p className={classes.gaugeTableText}>
                          {context.t("Openness to Reform")}
                        </p>
                        <p style={{ backgroundColor: "grey", color: "white" }}>
                          {context.t("Target")}
                        </p>
                      </Grid>

                      <Grid className={classes.gaugeBox}>
                        <SpeedometerStat
                          name="Openness to Reform actual"
                          data={supplierCRC}
                        />
                        <p className={classes.gaugeTableText}>
                          {context.t("Openness to Reform")}
                        </p>
                        <p
                          style={{
                            backgroundColor: "grey",
                            color: "white",
                            borderRight: "1px solid white",
                          }}
                        >
                          {context.t("Actual")}
                        </p>
                      </Grid>
                    </Grid>
                  </GridContainer>

                  <GridContainer
                    style={{
                      marginTop: "50px",
                      paddingTop: "20px",
                      borderTop: "1px solid #c4c4c4",
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Grid container>
                        <p>
                          {context.t(
                            "WORK INDICATOR 3. Percentage of worker-reported issues in the supply chain resolved in under 60 days"
                          )}
                        </p>

                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ px: 2 }}>
                          <PiCharts
                            name="Average supplier performance scores-target"
                            data={chartData}
                            actualData={actualChartData}
                          />
                          <br />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ px: 2 }}>
                          <PiCharts
                            name="Average supplier performance scores-actual"
                            data={chartData}
                            actualData={actualChartData}
                          />
                          <br />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={6}>
                      <Grid
                        container
                        style={{
                          padding: "0 20px",
                          marginBottom: "130px",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          {context.t(
                            "WORK INDICATOR 4. Percentage of remediation efforts of specific issues resolved at a Fair, Good or Excellent level"
                          )}
                        </p>

                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ px: 3 }}>
                          <DonutChart
                            name="remediation efforts actual"
                            data={chartData}
                            actualData={actualChartData}
                          />
                          <div>
                            <p className="remidation-outcome">
                              {context.t("REMEDIATION")} <br /> &nbsp;
                              {context.t("OUTCOMES")}
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </GridContainer>
                  {newupdatearray.length === 0 ? (
                    <GridContainer>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h3>
                          {context.t(
                            "PROGRESS ON PRIORITY WORKPLAN ACTIVITIES"
                          )}
                        </h3>
                      </Grid>
                      <Grid item xs={12} className={classes.topHeading}>
                        <Typography
                          variant="body1"
                          style={{ margin: "auto 0" }}
                        >
                          {context.t("Priority Activities Not Found")}
                        </Typography>
                      </Grid>
                    </GridContainer>
                  ) : (
                    <GridContainer>
                      <GridContainer>
                        <Grid item xs={12} sm={12} md={10} lg={10}>
                          <h3>
                            {context.t(
                              "PROGRESS ON PRIORITY WORKPLAN ACTIVITIES"
                            )}
                          </h3>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <p>{context.t("Red = not started")}</p>
                          <p>{context.t("Yellow = in progress")}</p>
                          <p>{context.t("Green = complete")}</p>
                        </Grid>
                      </GridContainer>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell className={classes.theadtext}>
                                {context.t("ID")}
                              </StyledTableCell>
                              <StyledTableCell className={classes.theadtext}>
                                {context.t("AREA")}
                              </StyledTableCell>
                              <StyledTableCell
                                className={classes.theadtext}
                                align="left"
                              >
                                {context.t("ACTIVITY")}
                              </StyledTableCell>
                              <StyledTableCell
                                className={classes.theadtext}
                                align="left"
                              >
                                {context.t("STATUS")}
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {newupdatearray.map((item, index) => {
                              if (item.value === true) {
                                return (
                                  <StyledTableRow key={index}>
                                    <StyledTableCell>
                                      {index + 1}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {getTitle(
                                        item.priority_activity.category
                                      )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {item.priority_activity.description}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                      {item.progress === "in_progress" ? (
                                        <div
                                          className="status-circle"
                                          style={{ backgroundColor: "yellow" }}
                                        ></div>
                                      ) : null}
                                      {item.progress === "not_started" ? (
                                        <div
                                          className="status-circle"
                                          style={{ backgroundColor: "red" }}
                                        ></div>
                                      ) : null}
                                      {item.progress === "complete" ? (
                                        <div
                                          className="status-circle"
                                          style={{ backgroundColor: "green" }}
                                        ></div>
                                      ) : null}
                                    </StyledTableCell>
                                  </StyledTableRow>
                                );
                              }
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </GridContainer>
                  )}
                </GridContainer>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
}

SPProgressDashboard.contextTypes = {
  t: PropTypes.func.isRequired,
};
