import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 18,
    borderRadius: 8,
    marginTop: 8,
    marginBottom: 5,
    marginLeft: 30,
    marginRight: 30,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    backgroundColor: "#148696",
    border: "1px solid #148696"
  },
}))(LinearProgress);

export default function Progressbar(props) {
  const [progress, setProgress] = React.useState(100);
  const [internationalStandards, setInternationalStandards] = React.useState(false);
  const [communicatedToSupp, setCommunicatedToSupp] = React.useState(false);
  const [commercialProcess, setCommercialProcess] = React.useState(false);
  const [wcInternationalSnD, setWcInternationalSnD] = React.useState(false);
  const [wcCommunicatedToS, setWcCommunicatedToS] = React.useState(false);
  const [wcCommercialProcesses, setWcCommercialProcesses] = React.useState(false);
  // const [suppCollaborating, setSuppCollaborating] = React.useState(null);
  const [numberofRA, setNumberofRA] = React.useState(null);
  const [numberofCollaboratingSupp, setNumberofCollaboratingSupp] = React.useState(null);
  const [numberSuppUtilizingGD, setNumberSuppUtilizingGD] = React.useState(null);
  const [undertakenRecruitmentSupp, setUndertakenRecruitmentSupp] = React.useState(null);
  const [paidWorkersInSC, setPaidWorkersInSC] = React.useState(null);
  const [bottomData, setBbottomData] = React.useState(null);
  const [keyActivities, setKeyActivities] = React.useState([]);
  const [progressIndicators, setProgressIndicators] = React.useState([]);
  React.useEffect(() => {
    if (props.data !== undefined) {
      setKeyActivities(props.data.key_activities)
      setProgressIndicators(props.data.progress_indicator)
    }
    if (keyActivities) {
      for (let i = 0; i < keyActivities.length; i++) {
        if (keyActivities[i].area === "labour_recruitment" && keyActivities[i].str_value === "In line with international standards and definitions") {
          setInternationalStandards(keyActivities[i].value)
        }
        if (keyActivities[i].area === "labour_recruitment" && keyActivities[i].str_value === "Clearly communicated to suppliers, including effective date") {
          setCommunicatedToSupp(keyActivities[i].value)
        }
        if (keyActivities[i].area === "labour_recruitment" && keyActivities[i].str_value === "Integrated into commercial processes") {
          setCommercialProcess(keyActivities[i].value)
        }
        // * //
        if (keyActivities[i].area === "working_conditions" && keyActivities[i].str_value === "In line with international standards and definitions") {
          setWcInternationalSnD(keyActivities[i].value)
        }
        if (keyActivities[i].area === "working_conditions" && keyActivities[i].str_value === "Clearly communicated to suppliers") {
          setWcCommunicatedToS(keyActivities[i].value)
        }
        if (keyActivities[i].area === "working_conditions" && keyActivities[i].str_value === "Integrated into commercial processes") {
          setWcCommercialProcesses(keyActivities[i].value)
        }
      }
    }
    if (progressIndicators) {
      for (let xl = 0; xl < progressIndicators.length; xl++) {
        if (progressIndicators[xl].area === "labour_recruitment" && progressIndicators[xl].str_value === "Workers in supply chain") {
          setPaidWorkersInSC(progressIndicators[xl].numeric_value)
        }
        if (progressIndicators[xl].area === "labour_recruitment" && progressIndicators[xl].str_value === "Undertaken recruitment suppliers") {
          setUndertakenRecruitmentSupp(progressIndicators[xl].numeric_value)
        }
        // for 2A, 2B and 2C
        if (progressIndicators[xl].area === "labour_recruitment" && progressIndicators[xl].str_value === "number of ra mapped") {
          setNumberofRA(progressIndicators[xl].numeric_value)
        }
        if (progressIndicators[xl].area === "labour_recruitment" && progressIndicators[xl].str_value === "number of collaborating suppliers with Issara") {
          setNumberofCollaboratingSupp(progressIndicators[xl].numeric_value)
        }
        if (progressIndicators[xl].area === "labour_recruitment" && progressIndicators[xl].str_value === "number suppliers utilizing GD marketplace") {
          setNumberSuppUtilizingGD(progressIndicators[xl].numeric_value)
        }
      }
    }
  })

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress(oldProgress => {
        // ============= first and last progress bar =================
        if (internationalStandards && communicatedToSupp && commercialProcess && props.name === "Ethical recruitment policy") {
          return Math.min(100 / 1, 100);
        }
        if ((internationalStandards && communicatedToSupp && !commercialProcess && props.name === "Ethical recruitment policy")
          || (internationalStandards && commercialProcess && !communicatedToSupp && props.name === "Ethical recruitment policy")
          || (communicatedToSupp && commercialProcess && !internationalStandards && props.name === "Ethical recruitment policy")) {
          return Math.min(100 / 1.5, 100);
        }
        if ((communicatedToSupp && !internationalStandards && !commercialProcess && props.name === "Ethical recruitment policy")
          || (commercialProcess && !communicatedToSupp && !internationalStandards && props.name === "Ethical recruitment policy")
          || (internationalStandards && !commercialProcess && !communicatedToSupp && props.name === "Ethical recruitment policy")) {
          return Math.min(100 / 3, 100);
        }
        if (!internationalStandards && !communicatedToSupp && !commercialProcess && props.name === "Ethical recruitment policy") {
          return Math.min(0, 100);
        }
        // ===========================================================================
        if (wcInternationalSnD && wcCommunicatedToS && wcCommercialProcesses && props.name === "Ethical sourcing and migrant worker policies") {
          return Math.min(100 / 1, 100);
        }
        if ((wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses && props.name === "Ethical sourcing and migrant worker policies")
          || (wcInternationalSnD && !wcCommunicatedToS && wcCommercialProcesses && props.name === "Ethical sourcing and migrant worker policies")
          || (!wcInternationalSnD && wcCommunicatedToS && wcCommercialProcesses && props.name === "Ethical sourcing and migrant worker policies")) {
          return Math.min(100 / 1.5, 100);
        }
        if ((wcInternationalSnD && !wcCommunicatedToS && !wcCommercialProcesses && props.name === "Ethical sourcing and migrant worker policies")
          || (!wcInternationalSnD && !wcCommunicatedToS && wcCommercialProcesses && props.name === "Ethical sourcing and migrant worker policies")
          || (!wcInternationalSnD && wcCommunicatedToS && !wcCommercialProcesses && props.name === "Ethical sourcing and migrant worker policies")) {
          return Math.min(100 / 3, 100);
        }
        if (!wcInternationalSnD && !wcCommunicatedToS && !wcCommercialProcesses && props.name === "Ethical sourcing and migrant worker policies") {
          return Math.min(0, 100);
        }
        // ============= first and last progress bar =================

        // ============= ER INDICATOR 4. progress bar =================
        if (props.name === "workers in supply chain") {
          if (paidWorkersInSC !== null) {
            setBbottomData(paidWorkersInSC)
            return Math.min(paidWorkersInSC);
          }
        }
        // ============= ER INDICATOR 4. progress bar =================

        // ============= ER INDICATOR 2. progress bar 2A, 2B and 2C =================
        if (props.name === "number of ra mapped") {
          if (numberofRA !== null) {
            setBbottomData(numberofRA)
            if (numberofRA > 100) {
              return Math.min(85);
            }
            else {
              return Math.min(numberofRA, 100);
            }
          }
        }
        if (props.name === "number of collaborating suppliers with Issara") {
          if (numberofCollaboratingSupp !== null) {
            setBbottomData(numberofCollaboratingSupp)
            if (numberofCollaboratingSupp > 100) {
              return Math.min(85);
            }
            else {
              return Math.min(numberofCollaboratingSupp, 100);
            }
          }
        }
        if (props.name === "number suppliers utilizing GD marketplace") {
          if (numberSuppUtilizingGD !== null) {
            setBbottomData(numberSuppUtilizingGD)
            if (numberSuppUtilizingGD > 100) {
              return Math.min(85);
            }
            else {
              return Math.min(numberSuppUtilizingGD, 100);
            }
          }
        }
        // ============= ER INDICATOR 2. progress bar =================

        // ============= ER INDICATOR 3. progress bar =================
        if (props.name === "suppliers that have undertaken recruitment surveys") {
          if (undertakenRecruitmentSupp !== null) {
            setBbottomData(undertakenRecruitmentSupp)
            if (undertakenRecruitmentSupp > 100) {
              return Math.min(85);
            }
            else {
              return Math.min(undertakenRecruitmentSupp, 100);
            }
          }
        }
        // ============= ER INDICATOR 3. progress bar =================

        else {
          return Math.min(0, 100);
        }
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [internationalStandards, communicatedToSupp, commercialProcess,
    paidWorkersInSC, numberofRA, numberofCollaboratingSupp, numberSuppUtilizingGD, undertakenRecruitmentSupp]);

  return (
    <div >
      <BorderLinearProgress variant="determinate" value={progress} />
      <div style={{ padding: "0 5%", textAlign: "end" }}>
        <b>
          {
            bottomData ? bottomData : null
          }
        </b>
        <b>
          {
            (props.name === "workers in supply chain" && bottomData) ? "%" : null
          }
        </b>
      </div>
    </div>
  );
}
