import axios from 'axios';

export const GET_SUPPLIER_PROGRESS_START = 'GET_SUPPLIER_PROGRESS_START';
export const GET_SUPPLIER_PROGRESS_SUCCESS = 'GET_SUPPLIER_PROGRESS_SUCCESS';
export const GET_SUPPLIER_PROGRESS_FAIL = 'GET_SUPPLIER_PROGRESS_FAIL';

export const UPDATE_SUPPLIER_PROGRESS_START = 'UPDATE_SUPPLIER_PROGRESS_START';
export const UPDATE_SUPPLIER_PROGRESS_SUCCESS = 'UPDATE_SUPPLIER_PROGRESS_SUCCESS';
export const UPDATE_SUPPLIER_PROGRESS_FAIL = 'UPDATE_SUPPLIER_PROGRESS_FAIL';

// get progress status
export const getSupplierProgressStart = () => ({
  type: GET_SUPPLIER_PROGRESS_START
})

export const getSupplierProgressSuccess = (progresses) => ({
  type: GET_SUPPLIER_PROGRESS_SUCCESS,
  progresses: progresses
})

export const getSupplierProgressFail = (error) => ({
  type: GET_SUPPLIER_PROGRESS_FAIL,
  error: error
})

// update progress status
export const updateSupplierProgressStart = () => ({
  type: UPDATE_SUPPLIER_PROGRESS_START
})

export const updateSupplierProgressSuccess = (progresses) => ({
  type: UPDATE_SUPPLIER_PROGRESS_SUCCESS,
  progresses: progresses
})

export const updateSupplierProgressFail = (error) => ({
  type: UPDATE_SUPPLIER_PROGRESS_FAIL,
  error: error
})


export const getSupplierProgress = (supplierId) => {
  return (dispatch, getState) => {
    dispatch(getSupplierProgressStart());

    axios.get(`${process.env.REACT_APP_API_URL}/supplier-progress/${supplierId}`)
      .then(res => {
        dispatch(getSupplierProgressSuccess(res.data));
      })
      .catch(err => {
        dispatch(getSupplierProgressFail(err))
        throw err
      })
  }
}


export const updateSupplierProgress = (supplierId, key, value) => {
  return (dispatch, getState) => {
    const form = new FormData()
    form.append(key, value)
    dispatch(getSupplierProgressStart());
    axios.post(`${process.env.REACT_APP_API_URL}/supplier-progress/${supplierId}`, form)
      .then(res => {
        dispatch(getSupplierProgressSuccess(res.data));
      })
      .catch(err => {
        dispatch(getSupplierProgressFail(err))
        throw err
      })
  }
}