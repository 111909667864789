import HttpService from '../../services/HttpService';
import moment from 'moment';



export const CREATE_SUPPLIER_REPORT_REQUEST = 'CREATE_SUPPLIER_REPORT_REQUEST';
export const CREATE_SUPPLIER_REPORT_SUCCESS = 'CREATE_SUPPLIER_REPORT_SUCCESS';

export const DELETE_SUPPLIER_REPORT_REQUEST = 'DELETE_SUPPLIER_REPORT_REQUEST';
export const DELETE_SUPPLIER_REPORT_SUCCESS = 'DELETE_SUPPLIER_REPORT_SUCCESS';

export const DELETE_SUPPLIER_REPORT_ERROR = 'DELETE_SUPPLIER_REPORT_ERROR';

export const REPORT_ERROR = 'REPORT_ERROR';

export const createSupplierReportRequest = (report) => ({
  type: CREATE_SUPPLIER_REPORT_REQUEST,
  report: report
})


export function createSupplierReport(id, report, success, error) {
  return function (dispatch) {
    dispatch(createSupplierReportRequest(report))
    return HttpService.post(`${process.env.REACT_APP_API_URL}/suppliers/${id}/report/`, report,
      (res) => {
        dispatch({
          type: CREATE_SUPPLIER_REPORT_SUCCESS,
          reports: res.reports,
          receivedAt: moment().format('MMM Do YYYY, h:mm:ss a')
        })
      },
      (err) => {
        dispatch({
          type: REPORT_ERROR,
          message: err.message,
          receivedAt: moment().format('MMM Do YYYY, h:mm:ss a')
        })
      });
  }
}


export const deleteSupplierReportRequest = (report) => ({
  type: DELETE_SUPPLIER_REPORT_REQUEST,
  report_id: report.report_id
})

export function deleteSupplierReport(id, report, success, error) {
  return function (dispatch) {
    dispatch(deleteSupplierReportRequest(report))
    return HttpService.deleteByBody(`${process.env.REACT_APP_API_URL}/suppliers/${id}/report/`, report,
      (res) => {
        dispatch({
          type: DELETE_SUPPLIER_REPORT_SUCCESS,
          report_id: report.report_id,
          receivedAt: moment().format('MMM Do YYYY, h:mm:ss a')
        })
      },
      (err) => {
        console.log(err)
        dispatch({
          type: DELETE_SUPPLIER_REPORT_ERROR,
          message: err.message,
          report_id: report.report_id,
          receivedAt: moment().format('MMM Do YYYY, h:mm:ss a')
        })
      });
  }
}

