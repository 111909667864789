import HttpService from '../../services/HttpService';

export const REQUEST_GRIEVANCE_MECHANISMS = 'REQUEST_GRIEVANCE_MECHANISMS'
export const RECEIVE_GRIEVANCE_MECHANISMS = 'RECEIVE_GRIEVANCE_MECHANISMS'

export const CREATE_GRIEVANCE_MECHANISMS_REQUEST = 'CREATE_GRIEVANCE_MECHANISMS_REQUEST'
export const CREATE_GRIEVANCE_MECHANISMS_SUCCESS = 'CREATE_GRIEVANCE_MECHANISMS_SUCCESS'
export const CREATE_GRIEVANCE_MECHANISMS_FAILURE = 'CREATE_GRIEVANCE_MECHANISMS_FAILURE'

export const UPDATE_GRIEVANCE_MECHANISMS_REQUEST = 'UPDATE_GRIEVANCE_MECHANISMS_REQUEST'
export const UPDATE_GRIEVANCE_MECHANISMS_SUCCESS = 'UPDATE_GRIEVANCE_MECHANISMS_SUCCESS'
export const UPDATE_GRIEVANCE_MECHANISMS_FAILURE = 'UPDATE_GRIEVANCE_MECHANISMS_FAILURE'


/***************************************************/
/************ GET ALL Grievance Mechanisms RESPONSES ***********/
/***************************************************/

export const requestGrievanceMechanisms = () => ({
  type: REQUEST_GRIEVANCE_MECHANISMS,
})

export const receiveGrievanceMechanisms = (responses) => ({
  type: RECEIVE_GRIEVANCE_MECHANISMS,
  responses: responses,
})

export const fetchGrievanceMechanisms = () => dispatch => {
  dispatch(requestGrievanceMechanisms())
  return HttpService.get(`${process.env.REACT_APP_API_URL}/grievance-mechanism-reviews/`,
    (resp) => {
      dispatch(receiveGrievanceMechanisms(resp.results));
    },
    (err) => { console.log(err.message) });
}


/***************************************************/
/************ POST NEW Grievance Mechanisms RESPONSE ***********/
/***************************************************/

export const createGrievanceMechanismsRequest = () => ({
  type: CREATE_GRIEVANCE_MECHANISMS_REQUEST,
})

export const createGrievanceMechanismsSuccess = (response) => ({
  type: CREATE_GRIEVANCE_MECHANISMS_SUCCESS,
  response: response,
})

export const createGrievanceMechanismsFailure = (error) => ({
  type: CREATE_GRIEVANCE_MECHANISMS_FAILURE,
  error: error
})

export const createGrievanceMechanisms = (payload, success, error) => dispatch => {
  dispatch(createGrievanceMechanismsRequest())
  return HttpService.post(`${process.env.REACT_APP_API_URL}/grievance-mechanism-reviews/`, payload,
    (resp) => {
      dispatch(createGrievanceMechanismsSuccess(resp));
      success(resp)
    },
    (err) => {
      dispatch(createGrievanceMechanismsFailure(err))
      error(err)
    });
}


/***************************************************/
/********* UPDATE EXISTING GRIEVANCE MECHANISMS RESPONSE *******/
/***************************************************/

export const updateGrievanceMechanismRequest = () => ({
  type: UPDATE_GRIEVANCE_MECHANISMS_REQUEST,
})

export const updateGrievanceMechanismsuccess = (response) => ({
  type: UPDATE_GRIEVANCE_MECHANISMS_SUCCESS,
  response: response,
})

export const updateGrievanceMechanismFailure = (error) => ({
  type: UPDATE_GRIEVANCE_MECHANISMS_FAILURE,
  error: error
})

export const updateGrievanceMechanism = (id, payload, success, error) => dispatch => {
  dispatch(updateGrievanceMechanismRequest())
  return HttpService.put(`${process.env.REACT_APP_API_URL}/grievance-mechanism-reviews/${id}/`, payload,
    (resp) => {
      dispatch(updateGrievanceMechanismsuccess(resp));
      success(resp)
    },
    (err) => {
      dispatch(updateGrievanceMechanismFailure(err))
      error(err)
    });
}
