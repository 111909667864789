import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
// import moment
import moment from 'moment'

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import Datetime from "react-datetime";

import SuppliersDropdown from "components/ilmdb/SuppliersDropdown.js";

import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { fetchGrievanceMechanisms, createGrievanceMechanisms, updateGrievanceMechanism } from "redux/actions/GrievanceMechanismsActions";


const customStyles = {
  ...sweetAlertStyles,
  header: {
    textAlign: 'center'
  },
  subheader: {
    fontWeight: '400',
  },
  datetime: {
    marginTop: '24px'
  },
};

const useStyles = makeStyles(customStyles);


export default function GrievanceMechanismsForm(props) {

  const dispatch = useDispatch()
  const classes = useStyles();

  /*********************************************/
  /***************** START STATE ***************/
  /*********************************************/

  // Participants
  const [suppliers, setSuppliers] = useState([])
  const [description, setDescription] = useState('')
  const [suppliersNotes, setSuppliersNotes] = useState('')
  // Actions
  const [suppliersNextStepsDeadline, setSuppliersNextStepsDeadline] = useState(null)


  const [createdBy, setCreatedBy] = useState(null)

  // Not related to payload
  const businessResponses = useSelector(state => state.businessResponsesReducer.items)
  const grievanceMechanisms = useSelector(state => state.grievanceMechanismsReducer.items)
  const savingBusinessResponse = useSelector(state => state.businessResponsesReducer.savingBusinessResponse)
  const [alert, setAlert] = useState(null)

  const id = new URLSearchParams(props.location.search).get('id') // id from query string of edited business response

  useEffect(() => {
    // fetch Grievance Mechanisms
    dispatch(fetchGrievanceMechanisms())
  }, []);

  useEffect(() => {
    if (grievanceMechanisms && Object.keys(grievanceMechanisms).length > 0 && id) {
      const item = grievanceMechanisms[id]

      if (item) {
        setSuppliers(item.supplier);
        item.date_of_review && setSuppliersNextStepsDeadline(new Date(item.date_of_review));
        item.description && setDescription(item.description);
        item.assessment && setSuppliersNotes(item.assessment);
        item.created_by && setCreatedBy(item.created_by);
      }
    }
  }, [grievanceMechanisms]);

  /*********************************************/
  /****************** END STATE ****************/
  /*********************************************/

  const handleConfirmSuccessAlert = () => {
    props.history.push('/admin/grievancemechanismslist')
  }

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        onConfirm={handleConfirmSuccessAlert}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title="Well done!"
      >
        {id ? 'Grievance mechanism was updated' : 'New grievance mechanism has been successfully created'}
      </SweetAlert>
    );
  };

  const errorAlert = (error) => {
    console.log('ERRROR', error)
    setAlert(
      <SweetAlert
        danger
        onConfirm={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title="Error"
      >
        {error.message}
      </SweetAlert>
    );
  };

  const onSubmit = () => {
    const payload = {
      supplier: suppliers,
      date_of_review: suppliersNextStepsDeadline instanceof Date ? moment(suppliersNextStepsDeadline.toLocaleDateString()).format('YYYY-MM-DD') : null,
      description: description,
      assessment: suppliersNotes,
    }

    if (createdBy) {
      payload['created_by'] = createdBy
    }
    // edit update of Create 
    if (id) {
      dispatch(updateGrievanceMechanism(id, payload, successAlert, errorAlert))
    }
    else {
      dispatch(createGrievanceMechanisms(payload, successAlert, errorAlert))
    }
  }

  if (id && (grievanceMechanisms === undefined || grievanceMechanisms === null || Object.keys(grievanceMechanisms).length < 1)) {
    return <CircularProgress />
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} lg={12}>
        <Card style={{ marginTop: 0 }}>
          {alert}
          <CardBody>
            <h4 className={classes.header}> Grievance Mechanisms </h4>
            <GridItem>
              <h5 className={classes.subheader}> Select Supplier </h5>
              <SuppliersDropdown
                multipleselect={false}
                value={suppliers}
                onSelect={suppliers => setSuppliers(suppliers)}
              />
              <GridItem>
                <FormControl>
                  <Datetime
                    id="suppliers_next_steps_deadline"
                    timeFormat={false}
                    value={suppliersNextStepsDeadline}
                    onChange={date => setSuppliersNextStepsDeadline(date._d)}
                    inputProps={{ placeholder: "Select Date of review" }}
                    className={classes.datetime}
                    closeOnSelect={true}
                    closeOnTab={true}
                  />
                </FormControl>
              </GridItem>
              <CustomInput
                name="description"
                value={description}
                labelText="Description"
                isTextArea={true}
                formControlProps={{ fullWidth: true }}
                inputProps={{ onChange: e => setDescription(e.target.value) }}
              />
              <CustomInput
                name="assessment"
                value={suppliersNotes}
                labelText="Assessment"
                isTextArea={true}
                formControlProps={{ fullWidth: true }}
                inputProps={{ onChange: e => setSuppliersNotes(e.target.value) }}
              />
            </GridItem>

            <GridContainer justify='flex-end'>
              <GridItem>
                <Button color='success' onClick={onSubmit} disabled={savingBusinessResponse}>Save</Button>
              </GridItem>
            </GridContainer>

          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
