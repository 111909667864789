import React from "react";

//import { GoogleLogin } from 'react-google-login';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import PasswordResetView from "../users/PasswordResetView";
import ForgotPasswordView from "../users/ForgotPasswordView";

import loginStore from "../../redux/stores/LoginStore";

import { withRouter } from "react-router";

const useStyles = makeStyles(styles);

function PasswordResetPage(props) {
  const clientID = process.env.REACT_APP_ILM_CLIENT_ID;
  const token = new URLSearchParams(window.location.search).get("token");

  const [code, setCode] = React.useState("");
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  const handleSend2FactorCode = () => {
    loginStore.do2FactorSendCode(code);
  };

  // const googleAuthResponse = (resp) => {
  //   LoginActions.loginGoogle(resp);
  // }

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12}>
          {token !== undefined && token !== null && token !== "" ? (
            <PasswordResetView token={token} />
          ) : (
            <ForgotPasswordView />
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
}

export default withRouter(PasswordResetPage);
