import React from "react";
import PropTypes from "prop-types";
// core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Danger from "components/Typography/Danger";

const customStyles = {
  label: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.42857,
  },
  customGridItem: {
    marginTop: "-20px",
  },
};

export default function OutreachFields(props, context) {
  const classes = makeStyles(customStyles)();

  const sumOfNationalities =
    props.state.myanmar_workers_reached +
    props.state.cambodian_workers_reached +
    props.state.thai_workers_reached +
    props.state.lao_workers_reached +
    props.state.nepalese_workers_reached +
    props.state.bangladeshi_workers_reached +
    props.state.indonesian_workers_reached +
    props.state.malaysian_workers_reached +
    props.state.indian_workers_reached +
    props.state.other_workers_reached;

  return (
    <div className="mt-1">
      <p className={classes.label} style={{ marginBottom: -10 }}>
        {context.t("Estimated number of workers reached")}
      </p>
      <GridContainer>
        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Total")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  total_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                  female_workers_reached: Math.floor(e.target.value / 2),
                  male_workers_reached:
                    e.target.value - Math.floor(e.target.value / 2),
                }),
            }}
            value={`${parseInt(props.state.total_workers_reached)}`}
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Female")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  female_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                  male_workers_reached:
                    props.state.total_workers_reached - e.target.value,
                }),
            }}
            value={`${parseInt(props.state.female_workers_reached)}`}
            helperText={
              props.state.total_workers_reached <
                props.state.female_workers_reached &&
              context.t("This value can not be greater than the total")
            }
            error={
              props.state.total_workers_reached <
              props.state.female_workers_reached
            }
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Male")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  male_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                  female_workers_reached:
                    props.state.total_workers_reached - e.target.value,
                }),
            }}
            value={`${parseInt(props.state.male_workers_reached)}`}
            helperText={
              props.state.total_workers_reached <
                props.state.male_workers_reached &&
              context.t("This value can not be greater than the total")
            }
            error={
              props.state.total_workers_reached <
              props.state.male_workers_reached
            }
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Burmese")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  myanmar_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                }),
            }}
            value={`${parseInt(props.state.myanmar_workers_reached)}`}
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Cambodian")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  cambodian_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                }),
            }}
            value={`${parseInt(props.state.cambodian_workers_reached)}`}
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Thai")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  thai_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                }),
            }}
            value={`${parseInt(props.state.thai_workers_reached)}`}
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Lao")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  lao_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                }),
            }}
            value={`${parseInt(props.state.lao_workers_reached)}`}
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Nepalese")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  nepalese_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                }),
            }}
            value={`${parseInt(props.state.nepalese_workers_reached)}`}
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Bangladeshi")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  bangladeshi_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                }),
            }}
            value={`${parseInt(props.state.bangladeshi_workers_reached)}`}
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Indonesian")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  indonesian_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                }),
            }}
            value={`${parseInt(props.state.indonesian_workers_reached)}`}
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Malaysian")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  malaysian_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                }),
            }}
            value={`${parseInt(props.state.malaysian_workers_reached)}`}
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Indian")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  indian_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                }),
            }}
            value={`${parseInt(props.state.indian_workers_reached)}`}
          />
        </GridItem>

        <GridItem lg={1}>
          <CustomInput
            labelText={context.t("Other")}
            formControlProps={{ fullWidth: true }}
            inputProps={{
              type: "number",
              onChange: (e) =>
                props.onChange({
                  other_workers_reached:
                    e.target.value > 0 ? parseInt(e.target.value) : 0,
                }),
            }}
            value={`${parseInt(props.state.other_workers_reached)}`}
          />
        </GridItem>
      </GridContainer>
      {props.state.total_workers_reached != sumOfNationalities && (
        <Danger>
          {/* <p>
            {`Total (${props.state.total_workers_reached}) ${
              props.state.total_workers_reached > sumOfNationalities ? ">" : "<"
            }
              Sum of nationalities (${sumOfNationalities})`} // this is original logic
          </p> */}
          <p>
            {context.t("Total ({total_workers_reached})", {
              total_workers_reached: props.state.total_workers_reached,
            })}
            {props.state.total_workers_reached > sumOfNationalities ? ">" : "<"}
            {context.t("Sum of nationalities ({sum_of_nationalities})", {
              sum_of_nationalities: sumOfNationalities,
            })}
          </p>
        </Danger>
      )}
    </div>
  );
}

OutreachFields.contextTypes = {
  t: PropTypes.func.isRequired,
};
