import React from "react";
import Chart from "react-apexcharts";

function DonutChart(props) {
  const [actualDonutData, setActualDonutData] = React.useState({
    poorStatus: undefined,
    fairStatus: undefined,
    goodStatus: undefined,
    excellentStatus: undefined,
  });

  if (props.actualData.length != 0) {
    if (
      actualDonutData.poorStatus === undefined &&
      actualDonutData.fairStatus === undefined &&
      actualDonutData.goodStatus === undefined &&
      actualDonutData.excellentStatus === undefined
    ) {
      setActualDonutData({
        poorStatus: props.actualData.Poor,
        fairStatus: props.actualData.Fair,
        goodStatus: props.actualData.Good,
        excellentStatus: props.actualData.Excellent,
      });
    }
  }

  const options = {
    labels: ["Poor", "Fair", "Good", "Excellent"],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      width: 0,
    },
    legend: {
      show: true,
      position: "bottom",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
        },
      },
    },
    colors: ["#a3199c", "#fdda61", "#a6dfdd", "#148696"],
    stroke: {
      colors: ["none"],
    },
  };

  const series = [0, 0, 0, 0];
  if (props.name == "remediation efforts actual") {
    if (
      actualDonutData.poorStatus &&
      actualDonutData.fairStatus &&
      actualDonutData.goodStatus &&
      actualDonutData.excellentStatus
    ) {
      while (series.length > 0) {
        series.pop();
      }
      series.push(actualDonutData.poorStatus);
      series.push(actualDonutData.fairStatus);
      series.push(actualDonutData.goodStatus);
      series.push(actualDonutData.excellentStatus);
    }
  }
  // if(props.name === "remediation efforts target"){

  // }

  return (
    <>
      <Chart options={options} series={series} type="donut" />
    </>
  );
}

export default DonutChart;
