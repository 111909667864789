import React from 'react'
import Chart from 'react-apexcharts'
// import axios from 'axios'

function PieChart(props) {
    const [actualData, setActualData] = React.useState({
        actual_closed_under60: undefined,
        actual_Case_Quality: undefined,
    })
    React.useEffect(() => {
        if (props.chartData !== undefined && props.chartData !== null && props.chartData.length !== 0) {
            setActualData({
                actual_closed_under60: props.chartData['closed_cases percentage under 60 days'],
            })
        }

        if (props.chartData2 !== undefined && props.chartData2 !== null && props.chartData2.length !== 0) {
            setActualData({
                actual_Case_Quality: props.chartData2.closed_cases_quality_level,
            })
        }
    }, [props.chartData, props.chartData2])

    const options = {
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 0
        },
        legend: {
            show: true,
            position: 'bottom',
            fontSize: '10px',
            markers: {
                width: 12,
                height: 12,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 12,
                customHTML: undefined,
                onClick: undefined,
                offsetX: 0,
                offsetY: 0
            },
        },
        colors: [],
        labels: []
    }
    var series = []
    // var  colors = []
    // var labels= []   
    if (props.name == "worker-reported issues") {
        if (actualData.actual_closed_under60) {
            while (series.length > 0) {
                series.pop();
            }

            // series.push(actualData.actual_closed_under60.toFixed(2))
            series.push(parseFloat(actualData.actual_closed_under60.toFixed(2)))
            options.colors.push("#148696")
            options.labels.push("Resolved")

            // series.push(100.00 - actualData.actual_closed_under60.toFixed(2))
            series.push(parseFloat(100.00 - actualData.actual_closed_under60.toFixed(2)))
            options.colors.push("gray")
            options.labels.push("Unresolved")

        }
    }


    if (props.name == "remediation efforts") {
        var _total = 0
        var _poor = 0
        var _fair = 0
        var _good = 0
        var _excellent = 0

        if (actualData.actual_Case_Quality && actualData.actual_Case_Quality !== undefined && actualData.actual_Case_Quality.length !== 0) {
            _total = actualData.actual_Case_Quality.poor_quality + actualData.actual_Case_Quality.fair_quality
                + actualData.actual_Case_Quality.good_quality + actualData.actual_Case_Quality.excellent_quality
            _poor = parseFloat(((actualData.actual_Case_Quality.poor_quality / _total) * 100).toFixed(2))
            _fair = parseFloat(((actualData.actual_Case_Quality.fair_quality / _total) * 100).toFixed(2))
            _good = parseFloat(((actualData.actual_Case_Quality.good_quality / _total) * 100).toFixed(2))
            _excellent = parseFloat(((actualData.actual_Case_Quality.excellent_quality / _total) * 100).toFixed(2))
        }


        if (actualData.actual_Case_Quality && actualData.actual_Case_Quality !== undefined && actualData.actual_Case_Quality.length !== 0) {
            while (series.length > 0) {
                series.pop();
            }
            series.push(_poor)
            options.colors.push("#a3199c")
            options.labels.push("Poor")

            series.push(_fair)
            options.colors.push("#fdc161")
            options.labels.push("Fair")

            series.push(_good)
            options.colors.push("#a6dfdd")
            options.labels.push("Good")

            series.push(_excellent)
            options.colors.push("#148696")
            options.labels.push("Excellent")



        }

    }

    return (
        <>
            <Chart options={options} series={series} type="pie" />

        </>
    )
}

export default PieChart;
