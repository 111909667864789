import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";

// core components
import { useDispatch, useSelector } from "react-redux";

import { fetchDistricts } from "../../redux/actions/LocaleActions.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import utils from "../../services/utils.js";

const customStyles = {
  ...styles,
};

const useStyles = makeStyles(customStyles);

let fetch = false;

export default function DistrictsDropdown(props, context) {
  const propsOnSelect = props.onSelect ? props.onSelect : (e) => {};
  const valueEmpty = " ";

  const districts = useSelector((state) => state.districtsReducer.items);

  const dispatch = useDispatch();

  const [districtId, setDistrictId] = useState(
    props.values ? props.values : valueEmpty
  );

  useEffect(() => {
    if (fetch === false) {
      fetch = true;
      dispatch(fetchDistricts());
    }
  }, [districtId]);

  useEffect(() => {
    if (props.supplier_data) {
      setDistrictId(props.supplier_data.district);
    }
  }, [props.supplier_data]);

  const classes = useStyles();

  const handleChangeDistrictId = (e) => {
    setDistrictId(e.target.value);
    propsOnSelect({
      ...e,
      list: districts,
    });
  };

  let filteredList = {};

  if (
    districts !== undefined &&
    props.provinceId !== undefined &&
    props.provinceId !== valueEmpty
  ) {
    let provinceIdNumber = Number.parseInt(props.provinceId);

    Object.keys(districts).map((district, i) => {
      if (districts[district].province === provinceIdNumber) {
        filteredList[district] = districts[district];
      }
    });
  }

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="district-select" className={classes.selectLabel}>
        {context.t("District")}
      </InputLabel>
      <Select
        key={utils.giveMeGuid()}
        value={districtId}
        onChange={handleChangeDistrictId}
        inputProps={{
          name: "districtSelect",
          id: "district-select",
        }}
      >
        <MenuItem value={valueEmpty} key={utils.giveMeGuid()}>
          {context.t("District")}
        </MenuItem>
        {Object.keys(filteredList).map((key, i) => {
          return (
            <MenuItem value={filteredList[key].id} key={utils.giveMeGuid()}>
              {" "}
              {filteredList[key].name}{" "}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

DistrictsDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};
