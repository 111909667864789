/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

import ReactDOM from "react-dom";

// Always redirect to https module (since GAE custom env doesn't allow to do that)
import HttpsRedirect from "react-https-redirect";

import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
// import { createLogger } from 'redux-logger';
import { composeWithDevTools } from "redux-devtools-extension";
import I18n from "redux-i18n"; // Ensure this is the default import

import history from "./history";
import { rootILMReducer } from "./redux/reducers";
import loginStore from "./redux/stores/LoginStore";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL";
import AdminLayout from "layouts/Admin.js";
import { translations } from "languages/i18n/translations";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "./styles.css";

const middleware = [thunk];
const fallbackLang = "en";

if (process.env.NODE_ENV !== "production") {
  // disable redux logging
  // middleware.push(createLogger())
}

const store = createStore(
  rootILMReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

const initialLang =
  (loginStore.isLoggedIn() &&
    ((loginStore.isCSO() && localStorage.getItem("cso_lang")) ||
      (loginStore.isSupplier() && localStorage.getItem("supplier_lang")) ||
      (loginStore.isGlobalPartner() &&
        localStorage.getItem("global_partner_lang")))) ||
  fallbackLang;

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loginStore.isLoggedIn() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/auth/login-page?path=",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

ReactDOM.render(
  <HttpsRedirect disabled={process.env.REACT_APP_HTTPS === "false"}>
    <Provider store={store}>
      <I18n
        initialLang={initialLang}
        fallbackLang={fallbackLang}
        translations={
          process.env.REACT_APP_DISABLE_TRANSLATIONS === "true"
            ? {}
            : translations
        }
      >
        <Router history={history}>
          <Switch>
            <Route path="/rtl" component={RtlLayout} />
            <Route path="/auth" component={AuthLayout} />
            <AuthenticatedRoute path="/admin" component={AdminLayout} />
            <Redirect from="/" to="/admin/team-tasks" />
          </Switch>
        </Router>
      </I18n>
    </Provider>
  </HttpsRedirect>,
  document.getElementById("root")
);
