import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { useDispatch, useSelector } from "react-redux";

import { fetchReferToOrg } from "../../redux/actions/LocaleActions.js";

import PropTypes from "prop-types";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import utils from "../../services/utils.js";
import "./style.css"

const customStyles = {
  ...styles
};

const useStyles = makeStyles(customStyles);

let fetch = false;


export default function ReferralOptionDropdown(props, context) {
  const propsOnSelect = (props.onSelect) ? props.onSelect : (e) => { };


 const refertoorg = useSelector(state => state.referralActionsReducer.fetchReferToOrg)
  const dispatch = useDispatch();

  const valueEmpty = " ";
  const [referralOptionId, setReferralOptionId] = useState((props.value) ? props.value : valueEmpty);

  useEffect(() => {
    if (fetch === false) {
      fetch = true;
      dispatch(fetchReferToOrg())
    }
  }, []);



  const classes = useStyles();

  const handleChangeReferralOptionId = (e) => {
    setReferralOptionId(e.target.value);
    console.log(e.target.value)
    propsOnSelect({
      ...e,
      list: referralOptionId
    });
  }

  console.log(referralOptionId)

  return (
    <FormControl
      fullWidth
      className={classes.selectFormControl}
    >
      <InputLabel
        htmlFor="refertoorg-select"
        className={classes.selectLabel}
        required
      >
        Referral Option
      </InputLabel>
       <Select
        key={utils.giveMeGuid()}
        value={referralOptionId}
        onChange={handleChangeReferralOptionId}
        inputProps={{
          name: "referralOptionSelect",
          id: "referralOptionSelect"
        }}
      >
        <MenuItem value={valueEmpty} key={utils.giveMeGuid()}>{context.t("Organization Name")}</MenuItem>
        {refertoorg && Object.keys(refertoorg).map((obj, i) => {
          return <MenuItem value={refertoorg[obj].id} key={utils.giveMeGuid()}> {context.t(refertoorg[obj].name)} </MenuItem>
        })}
      </Select>
    </FormControl>
  );
}

ReferralOptionDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};
