import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { fetchNationalities } from "redux/actions/LocaleActions";

const fuzzysort = require("fuzzysort");

export default function NationalitiesDropdown(props, context) {
  const dispatch = useDispatch();
  const fetchingNationalities = useSelector(
    (state) => state.nationalitiesReducer.fetchingNationalities
  );


  const defaultValue = props.multipleselect ? [] : null;
  const filterNationalities = props.filterNationalities ? props.filterNationalities : null;

  const [selectedNationalities, setSelectedNationalities] = useState(props.value);

  const nationalities = useSelector((state) => state.nationalitiesReducer.items);

  useEffect(() => {
    if (!nationalities || nationalities.length < 1) {
      dispatch(fetchNationalities());
    }
  }, []);

  useEffect(() => {
    setSelectedNationalities(props.value);
  }, [props]);

  const handleSelectNationality = (e, value, reason) => {
    var newSelectedNationalities = [];

    if (props.dataInclude && props.dataInclude == true) {
      let selectedNationalitiesData = [];
      if (props.multipleselect) {
        // Convert array of Objects to array of IDs
        value.map((item) => {
          newSelectedNationalities.push(item.id);
          selectedNationalitiesData.push(item);
        });
      } else {
        newSelectedNationalities = value ? value.id : null;
        selectedNationalitiesData = value ? value : null;
      }
      setSelectedNationalities(newSelectedNationalities);
      // Send selected nationalities to parent component
      props.onSelect(newSelectedNationalities, selectedNationalitiesData);
    } else {
      if (props.multipleselect) {
        // Convert array of Objects to array of IDs
        value.map((item) => {
          newSelectedNationalities.push(item.id);
        });
      } else {
        newSelectedNationalities = value ? value.id : null;
      }
      setSelectedNationalities(newSelectedNationalities);
      // Send selected nationalities to parent component
      props.onSelect(newSelectedNationalities);
    }
  };

  var value = defaultValue;
  if (!fetchingNationalities && nationalities && selectedNationalities) {
    nationalities.map((item) => {
      if (props.multipleselect) {
        if (selectedNationalities.includes(item.id)) {
          value.push(item);
        }
      } else {
        if (selectedNationalities == item.id) {
          value = item;
        }
      }
    });
  }

  return fetchingNationalities ? (
    <p> {context.t("Fetching nationalities..")} </p>
  ) : (
    <FormControl fullWidth>
      <Autocomplete
        id="combo-box-demo"
        options={(() => {
          let returnNationalities = [];
          if (
            filterNationalities && 
            Array.isArray(filterNationalities) &&
            filterNationalities.length > 0
          ) {
            nationalities.forEach((item) => {
              filterNationalities.forEach((fItem) => {
                if (fItem.nationality == item.id) {
                  returnNationalities.push(item);
                }
              });
            });
          } else {
            returnNationalities = nationalities;
          }
          return returnNationalities;
        })()}
        multiple={props.multipleselect}
        onChange={handleSelectNationality}
        value={value}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label={props.label}
            variant="outlined"
            fullWidth
          />
        )}
        filterOptions={(options, state) => {
          if (state.inputValue) {
            const results = fuzzysort.go(state.inputValue, options, {
              key: "name",
              allowTypo: true,
              limit: 100, // don't return more than 100 results
              threshold: -10000, // don't return really bad results
            });
            return results.map((result) => result.obj);
          }
          // show only 100 nationalities by default to speed up rendering
          return options.slice(0, 100);
        }}
      />
    </FormControl>
  );
}

// Default values for props:
NationalitiesDropdown.defaultProps = {
  onSelect: (selectedNationalities) =>
    console.log("Selected Nationalities: ", selectedNationalities),
};

NationalitiesDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};
