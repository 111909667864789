import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Button from "components/CustomButtons/Button.js";
import ButtonBar from "components/ButtonBar/ButtonBar.js";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.js";

import IssaraStaffDropdown from "components/ilmdb/IssaraStaffDropdown.js";
import ReferralActionsDropdown from "components/ilmdb/ReferralActionsDropdown.js";
import ReferralOptionDropdown from "components/ilmdb/ReferralOptionDropdown.js";
import CaseStatusesDropdown from "components/ilmdb/CaseStatusesDropdown";

// material-ui icons
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import moment from "moment";
import loginStore from "redux/stores/LoginStore";
import FormLabel from "@material-ui/core/FormLabel";

let _user = loginStore.getLoginUser();

const style = {
  infoText: {
    fontWeight: "300",
    marginBottom: "-20px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class CallActionFields extends React.Component {
  constructor(props) {
    super(props);
    if (props.allDataWizardSaveState !== undefined) {
      this.state = Object.assign({}, props.allDataWizardSaveState);
    } else {
      const { globalEditData } = this.props;
      this.state = {
        vot_needs: globalEditData ? globalEditData.vot_needs : undefined,
        client_share_info_consent: globalEditData
          ? globalEditData.client_share_info_consent
          : undefined,
        referral_notes: globalEditData
          ? globalEditData.referral_notes
          : undefined,
        next_steps: globalEditData ? globalEditData.next_steps : undefined,
        next_steps_issara_staff:
          globalEditData && globalEditData.next_steps_issara_staff
            ? globalEditData.next_steps_issara_staff.id
            : undefined,
        referral_action:
          globalEditData && globalEditData.referral_action
            ? globalEditData.referral_action.id
            : undefined,
        refer_to_org:
          globalEditData && globalEditData.refer_to_org
            ? globalEditData.refer_to_org
            : undefined,
        dead_line_date: globalEditData
          ? globalEditData.dead_line_date
          : moment().format("YYYY-MM-DD"),
        final_remarks: globalEditData
          ? globalEditData.final_remarks
          : undefined,
        risk_assessment: globalEditData
          ? globalEditData.risk_assessment
          : undefined,
        case_status: globalEditData ? globalEditData.case_status : 1,
        currentText:
          globalEditData && globalEditData.referral_action
            ? globalEditData.referral_action.name
            : "",
      };
    }
    console.log(this.state.referral_action);
  }
  sendState = () => {
    let returnObj = Object.assign({}, this.state);
    returnObj.was_validated = this.isValidated();
    return returnObj;
  };
  isValidated = () => {
    let isValid = true;
    let errorsInState = {};
    if (this.state.referral_action == 10) {
      if (this.state.refer_to_org == undefined) {
        errorsInState["issara_staff_id_error"] = (
          <FormLabel error>
            {this.context.t("Organization must be selected")}
          </FormLabel>
        );
      }
      if (Object.keys(errorsInState).length > 0) {
        isValid = false;
        this.setState(errorsInState);
      }
      return isValid;
    } else {
      return true;
    }
  };

  render() {
    const valueEmpty = " ";

    const { classes, globalEditData } = this.props;

    const simpleButtons = [
      { color: "success", icon: Edit },
      { color: "danger", icon: Close },
    ].map((prop, key) => {
      return (
        <Button
          color={prop.color}
          simple
          className={classes.actionButton}
          key={key}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });

    return (
      <div>
        <form>
          <GridItem>
            {_user && _user.groups.includes("CSO") ? (
              <h4 className={classes.infoText}>
                {this.context.t("Client Support (Organization action)")}
              </h4>
            ) : (
              <h4 className={classes.infoText}>
                {this.context.t("Client Support (Issara Actions)")}
              </h4>
            )}
          </GridItem>

          <GridItem xs={12}>
            <CustomInput
              id={"case_interactions_summary"}
              labelText={this.context.t(
                "What are the most urgent needs of the client?"
              )}
              isTextArea={true}
              value={this.state.vot_needs}
              formControlProps={{
                fullWidth: true,
              }}
              closeOnSelect={true}
              inputProps={{
                onChange: (e) => {
                  this.setState({ vot_needs: e.target.value });
                },
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            <ButtonBar
              value={this.state.client_share_info_consent}
              name={this.context.t(
                "Consent to pass on details to employer, agency, referral partner?"
              )}
              buttons={[
                { name: this.context.t("Yes"), value: "Yes" },
                { name: this.context.t("No"), value: "No" },
              ]}
              onClick={(name, value) => {
                this.setState({ client_share_info_consent: value });
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            <CaseStatusesDropdown
              value={this.state.case_status}
              onSelect={(e) => {
                this.setState({
                  case_status:
                    e.target.value === undefined ||
                    e.target.value === valueEmpty
                      ? undefined
                      : Number.parseInt(e.target.value),
                });
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            <ReferralActionsDropdown
              value={this.state.referral_action}
              onSelect={(e) => {
                this.setState({
                  currentText: e.currentTarget.textContent,
                  referral_action:
                    e.target.value === undefined ||
                    e.target.value === valueEmpty
                      ? undefined
                      : Number.parseInt(e.target.value),
                });
              }}
            />
          </GridItem>
          <GridItem xs={12}></GridItem>
          {this.state.currentText ==
            " Referral information provided to the partner organization " ||
          this.state.currentText ==
            "Referral information provided to the partner organization" ? (
            <div style={{ padding: "12px" }}>
              {this.state.issara_staff_id_error}
              <ReferralOptionDropdown
                value={this.state.refer_to_org}
                onSelect={(e) => {
                  this.setState({
                    refer_to_org:
                      e.target.value === undefined ||
                      e.target.value === valueEmpty
                        ? undefined
                        : Number.parseInt(e.target.value),
                  });
                }}
              />
            </div>
          ) : null}

          <GridItem xs={12}>
            <CustomInput
              id={"case_action_details_summary"}
              value={this.state.referral_notes}
              labelText={
                _user && _user.groups.includes("CSO")
                  ? this.context.t(
                      "Details on organization action (Referral Notes)"
                    )
                  : this.context.t("Details on Issara action (Referral Notes)")
              }
              isTextArea={true}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (e) => {
                  this.setState({ referral_notes: e.target.value });
                },
              }}
            />
          </GridItem>

          <GridItem xs={12}>
            <CustomInput
              id={"next-steps"}
              labelText={
                _user && _user.groups.includes("CSO")
                  ? this.context.t(
                      "Next steps organization follow-up and action"
                    )
                  : this.context.t("Next steps for Issara follow-up and action")
              }
              value={this.state.next_steps}
              isTextArea={true}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: (e) => {
                  this.setState({ next_steps: e.target.value });
                },
              }}
            />
          </GridItem>
          {
            <GridItem xs={12}>
              <IssaraStaffDropdown
                value={this.state.next_steps_issara_staff}
                onSelect={(issaraStaffId) => {
                  this.setState({ next_steps_issara_staff: issaraStaffId });
                }}
                name={this.context.t("Responsible Issara focal point")}
              />
            </GridItem>
          }

          <GridContainer>
            <GridItem xs={6}>
              <InputLabel className={classes.label}>
                {this.context.t("Deadline")}
              </InputLabel>
              <FormControl justify="space-around">
                <Datetime
                  value={this.state.dead_line_date}
                  id="case_interactions_interaciton_date"
                  timeFormat={false}
                  inputProps={{
                    placeholder: this.context.t("Deadline Date"),
                  }}
                  onChange={(newDate) => {
                    this.setState({
                      dead_line_date:
                        newDate && newDate.format !== undefined
                          ? newDate.format("YYYY-MM-DD")
                          : newDate,
                    });
                  }}
                />
              </FormControl>
            </GridItem>

            <GridItem xs={12}>
              <CustomInput
                id={"case_interactions_summary"}
                labelText={this.context.t("Final Remarks")}
                value={this.state.final_remarks}
                isTextArea={true}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    this.setState({ final_remarks: e.target.value });
                  },
                }}
              />
            </GridItem>

            <GridItem xs={12}>
              <CustomInput
                id={"case_risk_assessment"}
                labelText={this.context.t("Risk Assessment")}
                value={this.state.risk_assessment}
                isTextArea={true}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: (e) => {
                    this.setState({ risk_assessment: e.target.value });
                  },
                }}
              />
            </GridItem>
          </GridContainer>
        </form>
      </div>
    );
  }
}

CallActionFields.propTypes = {
  classes: PropTypes.object,
};

CallActionFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withStyles(style)(CallActionFields);
