/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";

import styles from "assets/jss/material-dashboard-pro-react/components/footerStyle.js";

const useStyles = makeStyles(styles);

export default function Footer(props, context) {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const { fluid, white, rtlActive } = props;
  // var container = cx({
  //   [classes.container]: !fluid,
  //   [classes.containerFluid]: fluid,
  //   [classes.whiteColor]: white
  // });
  // var anchor =
  //   classes.a +
  //   cx({
  //     [" " + classes.whiteColor]: white
  //   });
  // var block = cx({
  //   [classes.block]: true,
  //   [classes.whiteColor]: white
  // });
  return (
    <footer className={classes.footer}>
      {/* <div className={container}> */}
      {/* <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="/admin/teamstasks" className={block}>
                {rtlActive ? "الصفحة الرئيسية" : "Home"}
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="https://www.issarainstitute.org/" target="__blank" className={block}>
                {rtlActive ? "شركة" : "Company"}
              </a>
            </ListItem>

          </List>
        </div> */}
      <center>
        &copy; {currentYear} &nbsp;
        <a
          href="https://www.issarainstitute.org/"
          target="__blank"
          style={{ color: "#148696" }}
        >
          {rtlActive
            ? context.t("Issara Institute")
            : context.t("Issara Institute")}
          .
        </a>{" "}
        {context.t("All rights reserved. V3.0.0")}
      </center>
      {/* <div>
          <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
              (c) {currentYear} Issara Institute.  All rights reserved.
              </ListItem>
          </List>
        </div> */}
      {/* </div> */}
    </footer>
  );
}

Footer.propTypes = {
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};

Footer.contextTypes = {
  t: PropTypes.func.isRequired,
};
