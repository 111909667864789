import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import SpeedometerStat from "../Dashboard/SpeedometerStat.js";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import loginStore from "redux/stores/LoginStore";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSupplier,
  fetchSupplierProgress,
} from "redux/actions/SupplierActions";
import Utils from "services/utils";
import HttpService from "../../services/HttpService";
import Grid from "@material-ui/core/Grid";
import GridContainer from "components/Grid/GridContainer.js";
import PiCharts from "./PiCharts.js";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../Dashboard/SPWorkPlanning/css/style1.css";
import C3Chart from "react-c3js";
import "c3/c3.css";
import SuppliersPaginateDropdown from "components/Async/SuppliersPaginateDropdown.js";

const customStyle = {
  green: {
    backgroundColor: "#148696",
    borderRadius: 0,
    color: "white",
  },
  gray: {
    backgroundColor: "#b7b7b7",
    borderRadius: 0,
  },
  yellow: {
    backgroundColor: "#ffe599",
    borderRadius: 0,
  },
  paper: {
    padding: "1rem",
    margin: "1rem 0",
  },

  gaugeContainer: {
    textAlign: "center",
    height: "170px",
    display: "flex",
    "@media (max-width: 547px)": {
      flexDirection: "column",
      height: "auto",
    },
  },
  gaugeTableText: {
    fontSize: "13px",
    fontWeight: 500,
    marginTop: "-30px",
  },
  chartBox: {
    padding: "16px 10px",
    height: "335px",
    boxShadow: "0.005rem 1px 2px #a4a0a0",
    borderRadius: "6px",
    "@media (max-width: 547px)": {
      marginBottom: "20px",
    },
    "@media (max-width: 1272px)": {
      marginBottom: "20px",
    },
  },
  TypographyStyle: {
    fontSize: "0.9rem",
    height: "90px",
    padding: "10px",
    "@media (max-width: 767px)": {
      fontSize: "1rem",
    },
  },
  SecondGrid: {
    padding: "0px",
  },

  gaugeContainer: {
    textAlign: "center",
    justifyContent: "center",
    height: "180px",
    display: "flex",
  },

  gaugeBox: {
    minHeight: "10rem",
    justifyContent: "center",
    margin: "10px",
    boxShadow: "0px 1px 5px #d1d1d1",
  },
};

const useStyles = makeStyles(customStyle);

function buttonColorSetter(colorString, classes) {
  switch (colorString) {
    case "Gray":
      return classes.gray;
    case "Yellow":
      return classes.yellow;
    case "Green":
      return classes.green;
    default:
      return classes.gray;
  }
}

/**
 * <CRCScoreSummaryPanels key={Utils.giveMeGuid()} workerInteractions={workerInteractions} />
 * @param {workerInteractions} props
 * @returns
 */
function CRCScoreSummaryPanels(props, context) {
  const classes = useStyles();
  const [chartData, setChartData] = useState({});
  const [showCRCChart, setShowCRCChart] = useState(false);
  const supplier = props.supplier;
  const workerInteractions = props.workerInteractions;

  // useEffect(() => {
  //   if (workerInteractions.crcRawScored) {
  //     const result = Utils.generateCrCAveragesMap(
  //       workerInteractions.crcRawScored
  //     );
  //     if (result) {
  //       setChartData(result);
  //       setShowCRCChart(true);
  //     }
  //   }
  // }, [workerInteractions]);

  // const crcDataSummaryMap = Utils.generateCrCAveragesMap(
  //   workerInteractions.crcRawScored,
  //   false
  // );
  // let crcDataSummary = crcDataSummaryMap.get(supplier);

  const [qualityCRC, setQualityCRC] = useState(null);
  const [timelinessCRC, setTimelinessCRC] = useState(null);
  const [opennessCRC, setOpennessCRC] = useState(null);

  useEffect(() => {
    if (workerInteractions.crcRawScored) {
      if (loginStore.isGlobalPartner() === true) {
        const result = Utils.generateCrCAveragesMap(
          workerInteractions.crcRawScored,
          false
        );
        const crcDataSummary = result.get(supplier);
        if (crcDataSummary) {
          const total_length =
            crcDataSummary.supplier_crc_scores.length > 0
              ? crcDataSummary.supplier_crc_scores.length
              : 1;
          setQualityCRC(
            parseFloat((crcDataSummary.quality_avg / total_length).toFixed(1))
          );
          setTimelinessCRC(
            parseFloat(
              (crcDataSummary.timeliness_avg / total_length).toFixed(1)
            )
          );
          setOpennessCRC(
            parseFloat((crcDataSummary.openness_avg / total_length).toFixed(1))
          );
        }
      } else {
        const result = Utils.generateCrCAveragesMap(
          workerInteractions.crcRawScored
        );
        if (result) {
          setChartData(result);
          setShowCRCChart(true);
        }
      }
    }
  }, [workerInteractions]);

  const [indicator2Data, setIndicator2Data] = useState();
  const [indicator3Data, setIndicator3Data] = useState();

  const chartDataHandler1 = () => {
    let config;
    if (localStorage.getItem("token") != undefined) {
      config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/suppliers/${supplier}/closed_cases/`,
        config
      )
      .then((response) => {
        setIndicator2Data(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const chartDataHandler2 = (supplierId) => {
    let config;
    if (localStorage.getItem("token") != undefined) {
      config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/suppliers/${supplier}/cases_quality_level/`,
        config
      )
      .then((response) => {
        setIndicator3Data(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    chartDataHandler1();
    chartDataHandler2();
  }, [supplier]);

  return (
    <div>
      <Grid container justifyContent="space-around" spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography className={classes.TypographyStyle}>
            {context.t(
              "WORK INDICATOR 1. Supplier performance in relation to responding to worker-reported grievances and collaborating with Issara Institute staff"
            )}
          </Typography>
          {(loginStore.isIssaraManagement() || loginStore.isIssaraStaff()) &&
          showCRCChart ? (
            <C3Chart
              data={chartData.data}
              axis={chartData.axis}
              bar={chartData.bar ? chartData.bar : {}}
            />
          ) : null}
        </Grid>
        {loginStore.isGlobalPartner() === true ? (
          <GridContainer style={{ justifyContent: "center" }}>
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              className={`${classes.gaugeContainer} speedo-container`}
            >
              <Grid className={classes.gaugeBox}>
                <SpeedometerStat
                  name="Quality of Response actual"
                  value={qualityCRC}
                />
                <p className={classes.gaugeTableText}>Quality of Response</p>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              className={`${classes.gaugeContainer} speedo-container`}
            >
              <Grid className={classes.gaugeBox}>
                <SpeedometerStat
                  name="Timeliness of Response actual"
                  value={timelinessCRC}
                />
                <p className={classes.gaugeTableText}>Timeliness of Response</p>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              className={`${classes.gaugeContainer} speedo-container`}
            >
              <Grid className={classes.gaugeBox}>
                <SpeedometerStat
                  name="Openness to Reform actual"
                  value={opennessCRC}
                />
                <p className={classes.gaugeTableText}>Openness to Reform</p>
              </Grid>
            </Grid>
          </GridContainer>
        ) : (
          <Grid></Grid>
        )}

        <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: "20px" }}>
          <Typography className={classes.TypographyStyle}>
            {context.t(
              "WORK INDICATOR 2. Percentage of worker-reported issues in the supply chain resolved in under 60 days"
            )}
          </Typography>
          <PiCharts
            name="worker-reported issues"
            chartData={indicator2Data}
            showLegend={true}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Typography className={classes.TypographyStyle}>
            {context.t(
              "WORK INDICATOR 3. Percentage of remediation efforts of specific issues resolved at Poor, Fair, Good and Excellent level"
            )}
          </Typography>
          <PiCharts
            name="remediation efforts"
            chartData2={indicator3Data}
            showLegend={true}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default function SPSupplierProgress(props, context) {
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [selectedSupplierName, setSelectedSupplierName] = useState("");
  const [suppliers, setSuppliers] = useState([]);

  const user = loginStore.getLoginUser();

  const isRunned = useRef(false);
  useEffect(() => {
    if (isRunned.current) return;
    isRunned.current = true;

    if (user.groups.includes("global_partner")) {
      HttpService.get(
        `${process.env.REACT_APP_API_URL}/strategicpartners/${user.partner}/suppliers/`,
        (response) => {
          setSuppliers(response.suppliers);
        },
        (err) => {
          console.log(err.message);
        }
      );
    } else {
      HttpService.get(
        `${process.env.REACT_APP_API_URL}/all_suppliers/?name=${selectedSupplierName}`,
        (response) => {
          setSuppliers(response.results);
        },
        (err) => {
          console.log(err.message);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (isRunned.current) return;
    isRunned.current = true;

    HttpService.get(
      `${process.env.REACT_APP_API_URL}/all_suppliers/`,
      (response) => {
        setSuppliers(response.results);
      },
      (err) => {
        console.log(err.message);
      }
    );
  }, [selectedSupplierName]);

  const handleSelectSupplier = (event, value) => {
    if (value !== null) {
      setSelectedSupplier(value.id);
    }
  };

  const handleTypeSupplier = (event) => {
    setSelectedSupplierName(event.target.value);
  };

  let styles = {
    upperbox: {
      backgroundColor: "#fff",
      padding: "10px 30px",
      borderRadius: "8px",
    },
  };

  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 100);
  };

  if (user.groups.includes("global_partner")) {
    return (
      <main>
        <h5>{context.t("Select Supplier")}</h5>
        <section>
          <Grid container style={{ ...styles.upperbox, padding: "20px" }}>
            <FormControl
              variant="outlined"
              style={{ width: "100%", backgroundColor: "#fff" }}
            >
              <SuppliersPaginateDropdown
                label={context.t("Suppliers")}
                isMulti={false}
                placeholder={context.t("Select a supplier")}
                value={selectedSupplier}
                onSelect={(supplierId) => {
                  setSelectedSupplier(supplierId);
                }}
              />
            </FormControl>
          </Grid>
          {selectedSupplier && (
            <SSPSupplierProgress supplierId={selectedSupplier} />
          )}
        </section>
      </main>
    );
  } else {
    if (suppliers.length == 0) {
      return <CircularProgress />;
    } else {
      return (
        <>
          <Grid container style={{ ...styles.upperbox, padding: "20px" }}>
            <Grid container>
              <Autocomplete
                id="combo-box-demo"
                options={suppliers ? suppliers : null}
                getOptionLabel={(option) => option.name}
                style={{ width: "100%", backgroundColor: "#fff" }}
                onChange={handleSelectSupplier}
                filterOptions={filterOptions}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Suppliers"
                      variant="outlined"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>

          <br />
          {selectedSupplier ? (
            <Grid container style={{ ...styles.upperbox, padding: "20px" }}>
              <Grid container>
                {selectedSupplier && (
                  <SSPSupplierProgress supplierId={selectedSupplier} />
                )}
              </Grid>
            </Grid>
          ) : null}
          {/* <Grid container style={{ ...styles.upperbox, padding: "20px" }}>
            <Grid container>
              {selectedSupplier && <SSPSupplierProgress supplierId={selectedSupplier} />}
            </Grid>
          </Grid> */}
        </>
      );
    }
  }
}

// sp's supplier progress dashboard
function SSPSupplierProgress(props, context) {
  let supplierId = props.supplierId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [workerInteractions, setWorkerInteractions] = useState({});

  const fetchMetricsFilters = (supplierId) => {
    Promise.all([
      new Promise((resolve) => {
        HttpService.get(
          `${process.env.REACT_APP_API_URL}/metrics/interactions?supplierIds=${supplierId}`,
          (interactions) => {
            setWorkerInteractions(interactions);

            const totalProvinceCallsNew = [];
            const totalNationalityCallsNew = [];

            interactions.nationalityByCallCount.forEach((item) => {
              totalNationalityCallsNew.push([
                item.client_nationality__name,
                item.client_nationality__count,
                item.client_nationality__id,
              ]);
            });

            interactions.districtsByCallCount.forEach((item) => {
              totalProvinceCallsNew.push({
                name: item.district__name,
                call_count: item.district__count,
                lng: item.lng,
                lat: item.lat,
              });
            });

            const totalWorkerCallsNew = {
              x: ["x"],
              y: ["Worker Calls and Messages By Month"],
            };

            interactions.caseCountByYearMonth.forEach((item) => {
              totalWorkerCallsNew.x.push(item.month_year + "-01");
              totalWorkerCallsNew.y.push(item.call_count);
            });

            resolve(interactions);
          },
          (err) => {
            console.log(err.message);
            resolve(err.message);
          }
        );
      }),
    ]).then((resolve) => {});
  };

  const supplier = useSelector((state) => state.suppliersReducer.item);
  const supplierProgress = useSelector(
    (state) => state.suppliersReducer.supplierProgress
  );

  useEffect(() => {
    dispatch(fetchSupplier(supplierId));
    dispatch(fetchSupplierProgress(supplierId));
    fetchMetricsFilters(supplierId);
  }, [supplierId]);
  let commaRegex = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g;
  return (
    <main>
      <section>
        <Paper className={classes.paper}>
          <h4>{context.t("WORKER VOICE")}</h4>
          <div>
            {context.t(
              "Grey = Not yet initiated; Yellow = Partial progress; Green = Completed/ongoing"
            )}
          </div>
          <div>
            {supplierProgress["WORKER VOICE"]?.map((status) => (
              <Button
                key={status.key}
                variant="contained"
                className={buttonColorSetter(status?.value, classes)}
              >
                {status.label}
              </Button>
            ))}
          </div>
        </Paper>
      </section>

      <section>
        <Paper className={classes.paper}>
          <h4>{context.t("LABOUR RECRUITMENT")}</h4>
          <div>
            {context.t(
              "Grey = Not yet initiated; Yellow = Partial progress; Green = Completed/ongoing"
            )}
          </div>
          <div>
            {supplierProgress["LABOUR RECRUITMENT"]?.map((status) => (
              <Button
                key={status.key}
                variant="contained"
                className={buttonColorSetter(status?.value, classes)}
              >
                {status.label}
              </Button>
            ))}
          </div>
        </Paper>
      </section>

      <section>
        <Paper className={classes.paper}>
          <h4>{context.t("WORKING CONDITIONS")}</h4>
          <CRCScoreSummaryPanels
            workerInteractions={workerInteractions}
            supplier={supplierId}
          />
        </Paper>
      </section>

      <section>
        <Paper className={classes.paper}>
          <h4>{context.t("ACTIVITIES & ACHIEVEMENTS SUMMARY")}</h4>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow key="num_of_workers_access_to_issara_wv_channels">
                  <TableCell component="td" scope="row">
                    {context.t(
                      "Number of workers with access to Issara worker voice channels"
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier &&
                    supplier.num_of_workers_access_to_issara_wv_channels
                      ? supplier.num_of_workers_access_to_issara_wv_channels
                          .toString()
                          .replace(commaRegex, ",")
                      : context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="date_of_most_recent_worker_satisfaction_survey">
                  <TableCell component="td" scope="row">
                    {context.t(
                      "Date of most recent worker satisfaction survey"
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier &&
                    supplier.date_of_most_recent_worker_satisfaction_survey
                      ? supplier.date_of_most_recent_worker_satisfaction_survey
                      : context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="date_of_most_recent_recruitment_survey">
                  <TableCell component="td" scope="row">
                    {context.t("Date of most recent recruitment survey")}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier && supplier.date_of_most_recent_recruitment_survey
                      ? supplier.date_of_most_recent_recruitment_survey
                      : context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="num_workers_reimbursed_recruitment_fees">
                  <TableCell component="td" scope="row">
                    {context.t("Number of workers reimbursed recruitment fees")}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier &&
                    supplier.num_workers_reimbursed_recruitment_fees
                      ? supplier.num_workers_reimbursed_recruitment_fees
                          .toString()
                          .replace(commaRegex, ",")
                      : context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="remain_num_workers_owed_recruitment_fee_reimbursement">
                  <TableCell component="td" scope="row">
                    {context.t(
                      "Remaining number of workers owed recruitment fee reimbursement"
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier &&
                    supplier.remain_num_workers_owed_recruitment_fee_reimbursement
                      ? supplier.remain_num_workers_owed_recruitment_fee_reimbursement
                          .toString()
                          .replace(commaRegex, ",")
                      : context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="total_amt_recruitment_fee_reimbursed_to_workers">
                  <TableCell component="td" scope="row">
                    {context.t(
                      "Total amount of recruitment fees reimbursed to workers (US dollars)"
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier
                      ? supplier.total_amt_recruitment_fee_reimbursed_to_workers !==
                        null
                        ? `$ ${supplier.total_amt_recruitment_fee_reimbursed_to_workers
                            .toString()
                            .split(".")[0]
                            .replace(commaRegex, ",")}`
                        : context.t("Not available")
                      : context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="remain_amt_owed_recruitment_fee_reimbursement_to_workers">
                  <TableCell component="td" scope="row">
                    {context.t(
                      "Remaining amount owed, recruitment fee reimbursement to workers (US dollars)"
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier
                      ? supplier.remain_amt_owed_recruitment_fee_reimbursement_to_workers !==
                        null
                        ? `$ ${supplier.remain_amt_owed_recruitment_fee_reimbursement_to_workers
                            .toString()
                            .split(".")[0]
                            .replace(commaRegex, ",")}`
                        : context.t("Not available")
                      : context.t("Not available")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </section>
    </main>
  );
}

SPSupplierProgress.contextTypes = {
  t: PropTypes.func.isRequired,
};

CRCScoreSummaryPanels.contextTypes = {
  t: PropTypes.func.isRequired,
};

SSPSupplierProgress.contextTypes = {
  t: PropTypes.func.isRequired,
};
