import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import SuppliersPaginateDropdown from "components/Async/SuppliersPaginateDropdown.js";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  dialogContent: {
    height: "390px",
  },
}));

export default function SelectSupplierDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open ?? false);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={props.closeClick}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          <h4>
            <strong>Select a Supplier To Update</strong>
          </h4>
        </DialogTitle>
        <DialogContent className={`${classes.root} ${classes.dialogContent}`}>
          <SuppliersPaginateDropdown
            label="Please select a supplier to update"
            value={selectedSuppliers}
            onSelect={(suppliers) => setSelectedSuppliers(suppliers)}
            isMulti={false}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={props.closeClick}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={selectedSuppliers ? false : true}
            onClick={props.selectedSupplier.bind(this, selectedSuppliers)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
