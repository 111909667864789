import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import utils from "../../services/utils";

import Datetime from "react-datetime";
// core components
import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import SupplyChainDropdown from "./SupplyChainDropdown";
import IndustriesSubIndustriesDropdown from "./IndustriesSubIndustriesDropdown";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import SearchIcon from "@material-ui/icons/Search";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import moment from "moment";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import CountriesDropdown from "./CountriesDropdown";
import SupplierStatusDropDownLight from "../SupplierStatusDropDown/SupplierStatusDropDownLight";

const customStyles = {
  ...styles,
  ...customCheckboxRadioSwitch,
};

const useStyles = makeStyles(customStyles);

export default function SupplyChainFilter(props, context) {
  const classes = useStyles();

  const propOnUpdateSummary = props.onUpdateSummary;
  const minStartTime = props.minStartTime
    ? props.minStartTime
    : moment("2019-01-01");
  const maxEndTime = props.maxEndTime;

  const countries = useSelector((state) => state.countriesReducer.items);
  const strategicPartners = useSelector(
    (state) => state.strategicPartnerReducer.items
  );

  const supplyChains = useSelector((state) => state.supplyChainReducer.items);
  const suppliersMap = useSelector(
    (state) => state.suppliersReducer.suppliers_items_map
  );
  const [showList, setShowList] = useState(false);
  const [unselect, setUnselect] = useState(false);
  const [checkAll, setCheckAll] = useState(null);

  const [selectedData, setSelectedData] = useState({
    supplierStatus: props.supplierStatus ? props.supplierStatus : null,
    country: props.country ? props.country : null,
    supplyChain: props.supplyChainValue ? props.supplyChainValue.id : null,
    industry: props.industry ? props.industry : null,
    subindustry: props.subindustry ? props.subindustry : null,
    endTime: props.endTime ? props.endTime : moment(),
    specialilzedEndTimeLabel: props.specialilzedEndTimeLabel
      ? props.specialilzedEndTimeLabel
      : null,
    startTime: props.startTime ? props.startTime : minStartTime,
    suppliersMap:
      props.suppliers && props.suppliers.forEach !== undefined
        ? (() => {
            let newSuppliersMap = {};
            props.suppliers.forEach((supplierId) => {
              newSuppliersMap["" + supplierId] = suppliersMap[supplierId];
            });
            return newSuppliersMap;
          })()
        : {},
  });

  const getStrategicPartner = (id) => {
    return strategicPartners.find((strategicPartner) => {
      return strategicPartner.id === parseInt(id);
    });
  };

  const currenctSelectedSupplyChainSuppliers = (
    tSupplyChain,
    industry,
    subindustry
  ) => {
    let newSuppliersMap = {};

    let supplyChain = supplyChains.find((supplyChain) => {
      return supplyChain.id === parseInt(tSupplyChain);
    });

    if (
      supplyChain !== undefined &&
      supplyChain !== null &&
      supplyChain.suppliers &&
      supplyChain.suppliers.length > 0
    ) {
      supplyChain.suppliers.map((supplier) => {
        if (
          (industry === null ||
            industry === undefined ||
            suppliersMap[supplier.supplier].industry === industry) &&
          (subindustry === null ||
            subindustry === undefined ||
            suppliersMap[supplier.supplier].subindustry === subindustry)
        ) {
          newSuppliersMap[supplier.supplier] = suppliersMap[supplier.supplier];
        }
      });
    }
    return newSuppliersMap;
  };

  const currenctSelectedSupplyChainSuppliersByCountry = (
    tSupplyChain,
    country
  ) => {
    let newSuppliersMap = {};

    let supplyChain = supplyChains.find((supplyChain) => {
      return supplyChain.id === parseInt(tSupplyChain);
    });

    if (
      supplyChain !== undefined &&
      supplyChain !== null &&
      supplyChain.suppliers &&
      supplyChain.suppliers.length > 0
    ) {
      supplyChain.suppliers.map((supplier) => {
        if (suppliersMap[supplier.supplier].country === country) {
          newSuppliersMap[supplier.supplier] = suppliersMap[supplier.supplier];
        }
      });
    }
    return newSuppliersMap;
  };

  const currenctSelectedSupplyChainSuppliersByStatus = (
    tSupplyChain,
    status
  ) => {
    setCheckAll(null);
    let newSuppliersMap = {};

    let supplyChain = supplyChains.find((supplyChain) => {
      return supplyChain.id === parseInt(tSupplyChain);
    });

    if (
      supplyChain !== undefined &&
      supplyChain !== null &&
      supplyChain.suppliers &&
      supplyChain.suppliers.length > 0
    ) {
      supplyChain.suppliers.map((supplier) => {
        if (supplier.status === status) {
          newSuppliersMap[supplier.supplier] = suppliersMap[supplier.supplier];
        }
      });
    }
    return newSuppliersMap;
  };

  const getSupplyChain = (id) => {
    return supplyChains.find((supplyChain) => {
      return supplyChain.id === parseInt(id);
    });
  };

  const getCountryName = (id) => {
    const country = countries.find((country) => {
      return country.id === id;
    });
    return country ? country.name : "-";
  };

  const selectAll = () => {
    setSelectedData({
      ...selectedData,
      supplierStatus: "active",
      suppliersMap: currenctSelectedSupplyChainSuppliers(
        selectedData.supplyChain
      ),
    });

    setUnselect(false);
    setCheckAll(true);
  };

  const deselectAll = () => {
    setSelectedData({
      ...selectedData,
      suppliersMap: {},
    });
    setUnselect(true);
    setCheckAll(false);
  };

  useEffect(() => {
    if (!props.suppliers && selectedData.supplyChain) {
      let newSelectedData = {
        ...selectedData,
        suppliersMap: currenctSelectedSupplyChainSuppliers(
          selectedData.supplyChain
        ),
      };

      setSelectedData(newSelectedData);
    } else {
      // selectAll();
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showList]);

  let supplyChain = getSupplyChain(selectedData.supplyChain);
  let strategicPartner = getStrategicPartner(
    supplyChain ? supplyChain.strategic_partner : 0
  );
  let supplyChainSuppliersCount = (() => {
    let found = 0;
    if (
      supplyChain &&
      supplyChain.suppliers !== null &&
      supplyChain.suppliers.length > 0
    ) {
      supplyChain.suppliers.map((supplier) => {
        if (
          (selectedData.industry === null ||
            suppliersMap[supplier.supplier].industry ===
              selectedData.industry) &&
          (selectedData.subindustry === null ||
            suppliersMap[supplier.supplier].subindustry ===
              selectedData.subindustry)
        ) {
          found += 1;
        }
      });
    }

    return found;
  })();

  if (supplyChain === undefined || supplyChain === null) {
    supplyChain = {};
  }

  if (strategicPartner === undefined || strategicPartner === null) {
    strategicPartner = {};
  }

  return (
    <Card>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridItem>
              <SupplyChainDropdown
                disableSupplyChainSelect={props.disableSupplyChainSelect}
                value={selectedData.supplyChain}
                onSelect={(tSupplyChain) => {
                  let newSelectedData = {
                    ...selectedData,
                    supplierStatus: null,
                    industry: null,
                    subindustry: null,
                    supplyChain: tSupplyChain,
                    suppliersMap: currenctSelectedSupplyChainSuppliers(
                      tSupplyChain
                    ),
                  };

                  if (
                    propOnUpdateSummary !== null &&
                    propOnUpdateSummary !== undefined
                  ) {
                    propOnUpdateSummary(newSelectedData);
                  }
                }}
              />
            </GridItem>
          </GridItem>

          <GridItem xs={12} sm={12} md={6} lg={6}>
            <GridItem>
              <InputLabel className={classes.selectLabel}>Start</InputLabel>
              <FormControl fullWidth>
                <Datetime
                  value={selectedData.startTime}
                  timeFormat={false}
                  inputProps={{ placeholder: "Start" }}
                  closeOnSelect={true}
                  onChange={(date) => {
                    if (minStartTime && minStartTime > date) {
                      date = minStartTime;
                    }
                    return typeof date === "object"
                      ? setSelectedData({ ...selectedData, startTime: date })
                      : setSelectedData({ ...selectedData, startTime: null });
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <InputLabel className={classes.selectLabel}>
                {context.t("End")}
                {selectedData.specialilzedEndTimeLabel
                  ? selectedData.specialilzedEndTimeLabel
                  : null}
              </InputLabel>
              <FormControl fullWidth>
                <Datetime
                  value={selectedData.endTime}
                  timeFormat={false}
                  inputProps={{ placeholder: "End" }}
                  closeOnSelect={true}
                  onChange={(date) => {
                    if (maxEndTime && maxEndTime < date) {
                      date = maxEndTime;
                    }
                    return typeof date === "object"
                      ? setSelectedData({ ...selectedData, endTime: date })
                      : setSelectedData({ ...selectedData, endTime: null });
                  }}
                />
              </FormControl>
            </GridItem>
          </GridItem>

          <GridItem xs={12} sm={12} md={6} lg={6}>
            <IndustriesSubIndustriesDropdown
              key={utils.giveMeGuid()}
              industry_values={selectedData.industry}
              subindustry_values={selectedData.subindustry}
              onSelect={(e) => {
                setSelectedData({
                  ...selectedData,
                  industry: e.industry_id,
                  subindustry: e.subindustry_id,
                  suppliersMap: currenctSelectedSupplyChainSuppliers(
                    selectedData.supplyChain,
                    e.industry_id,
                    e.subindustry_id
                  ),
                });
              }}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridItem>
              <Button
                color={"info"}
                onClick={() => {
                  if (
                    propOnUpdateSummary !== null &&
                    propOnUpdateSummary !== undefined
                  ) {
                    propOnUpdateSummary({ ...selectedData });
                  }
                }}
              >
                {context.t("Search")}&nbsp;
                <SearchIcon />
              </Button>
            </GridItem>
          </GridItem>

          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader>
                {selectedData.supplyChain ? (
                  <GridItem>
                    <GridContainer>
                      {showList === false ? (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowList(true);
                          }}
                        >
                          <ExpandMoreIcon style={{ paddingTop: "4px" }} />
                          &nbsp;{context.t("Suppliers")}&nbsp;(
                          {supplyChainSuppliersCount})
                        </a>
                      ) : (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setShowList(false);
                          }}
                        >
                          <ExpandLessIcon />
                          &nbsp;{context.t("Suppliers")}&nbsp;(
                          {supplyChainSuppliersCount})
                        </a>
                      )}
                    </GridContainer>
                  </GridItem>
                ) : (
                  <GridContainer></GridContainer>
                )}
              </CardHeader>

              {selectedData.supplyChain === null ? (
                <GridContainer>
                  <CardBody>
                    <h4>{context.t("No Supply Chain Selected")}</h4>
                  </CardBody>
                </GridContainer>
              ) : null}

              {showList === false ||
              (selectedData.supplyChain === null &&
                selectedData.supplyChain === undefined)
                ? null
                : suppliersMap &&
                  supplyChain &&
                  supplyChain.suppliers &&
                  supplyChain.suppliers.length && (
                    <div>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={2} md={2} lg={2}>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                selectAll();
                              }}
                            >
                              {context.t("Select All")}
                            </a>
                          </GridItem>
                          <GridItem xs={2} md={2} lg={2}>
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                deselectAll();
                              }}
                            >
                              {context.t("Un-select All")}
                            </a>
                          </GridItem>
                          <GridItem xs={2} md={4} lg={4}>
                            <a style={{ cursor: "pointer" }}>
                              {context.t("Select Suppliers by Country")}
                            </a>
                            <CountriesDropdown
                              values={selectedData.country}
                              onSelect={(e) => {
                                setSelectedData({
                                  ...selectedData,
                                  country: e.target.value,
                                  suppliersMap: currenctSelectedSupplyChainSuppliersByCountry(
                                    selectedData.supplyChain,
                                    e.target.value
                                  ),
                                });
                              }}
                            />
                          </GridItem>
                          <GridItem xs={2} md={4} lg={4}>
                            <a style={{ cursor: "pointer" }}>
                              {context.t("Select Suppliers by Status")}
                            </a>
                            <SupplierStatusDropDownLight
                              onSelect={(status) => {
                                setSelectedData({
                                  ...selectedData,
                                  supplierStatus: status,
                                  suppliersMap: currenctSelectedSupplyChainSuppliersByStatus(
                                    selectedData.supplyChain,
                                    status
                                  ),
                                });
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <hr />
                        <GridContainer>
                          {supplyChainSuppliersCount > 0 &&
                          supplyChain.suppliers !== null &&
                          supplyChain.suppliers.length > 0 ? (
                            (() => {
                              return supplyChain.suppliers.sort((a, b) => {
                                return suppliersMap[
                                  a.supplier
                                ].name.localeCompare(
                                  suppliersMap[b.supplier].name
                                );
                              });
                            })().map((supplier) => {
                              return (
                                <GridItem
                                  key={utils.giveMeGuid()}
                                  className={classes.gridItem}
                                  xs={12}
                                  sm={4}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        style={{ paddingRight: "20px" }}
                                        checked={(function() {
                                          if (
                                            selectedData.suppliersMap[
                                              supplier.supplier
                                            ] !== undefined &&
                                            selectedData.supplierStatus != null
                                          ) {
                                            return true;
                                          }
                                          return false;
                                        })()}
                                        onClick={() => {
                                          let newSelectedData = {
                                            ...selectedData,
                                          };

                                          if (
                                            newSelectedData.suppliersMap[
                                              supplier.supplier
                                            ] === undefined ||
                                            newSelectedData.suppliersMap[
                                              supplier.supplier
                                            ] === null
                                          ) {
                                            newSelectedData.suppliersMap[
                                              supplier.supplier
                                            ] = suppliersMap[supplier.supplier];
                                          } else {
                                            delete newSelectedData.suppliersMap[
                                              supplier.supplier
                                            ];
                                          }
                                          setSelectedData(newSelectedData);
                                        }}
                                        checkedIcon={
                                          <Check
                                            className={classes.checkedIcon}
                                          />
                                        }
                                        icon={
                                          <Check
                                            className={classes.uncheckedIcon}
                                          />
                                        }
                                        classes={{
                                          checked: classes.checked,
                                          root: classes.checkRoot,
                                        }}
                                      />
                                    }
                                    classes={{
                                      label:
                                        classes.label +
                                        " " +
                                        classes.customLabel,
                                      root: classes.labelRoot,
                                    }}
                                    label={suppliersMap[supplier.supplier].name}
                                  />
                                </GridItem>
                              );
                            })
                          ) : (
                            <Card>
                              <CardHeader>
                                <h4>
                                  {context.t(
                                    "No Suppliers Exist Under these Search Parameters"
                                  )}
                                </h4>
                              </CardHeader>
                            </Card>
                          )}
                        </GridContainer>

                        <Button
                          style={{ float: "right", marginBottom: "10px" }}
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          onClick={() => {
                            setShowList(false);
                          }}
                        >
                          {context.t("Close Checkbox Panel")}
                        </Button>
                      </CardBody>
                    </div>
                  )}
            </Card>
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
}

SupplyChainFilter.contextTypes = {
  t: PropTypes.func.isRequired,
};
