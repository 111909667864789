import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Utils from "../../services/utils";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import "c3/c3.css";
import Card from "components/Card/Card.js";
const customStyles = {
  ...styles,
  largeStatContainer: {
    paddingTop: "6.3rem",
    paddingBottom: "6.7rem",
    margin: "10px 0 0 0",
  },
  largeStatContainer2: {
    paddingTop: "6.3rem",
    paddingBottom: "7.3rem",
    margin: "10px 0 0 0",
  },
  largeStatNumber: {
    textAlign: "center",
    fontSize: "3.5rem",
    fontWeight: 500,
    minHeight: "2.5rem",
  },
  largeStatName: {
    textAlign: "center",
    fontSize: "1.3rem",
    fontWeight: 400,
  },
  smallStatContainer: {
    paddingTop: "1.8rem",
    paddingBottom: "1rem",
    margin: "0 0 1rem 0",
  },
  smallStatNumber: {
    textAlign: "center",
    fontSize: "2rem",
    fontWeight: 600,
    minHeight: "1.65rem",
  },
  smallStatName: {
    textAlign: "center",
    fontSize: "1.1rem",
    fontWeight: 400,
  },
  outreachTable: {
    padding: "0.7rem 1rem 0.7rem 1rem",
    margin: "10px 0 1rem 0",
  },
  marginTopZero: {
    marginTop: 0,
  },
  marginBottom: {
    marginBottom: "1rem",
  },
  center: {
    textAlign: "center",
  },
  marginAuto: {
    margin: "auto",
  },
  gaugeBox: {
    minHeight: "10rem",
    margin: "auto",
  },

  gaugeTableText: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  gaugeContainer: {
    textAlign: "center",
    width: "200px",
    height: "200px",
  },
  button: {
    padding: 0,
    margin: 0,
  },
  chartcontainerholder: {
    minWidth: "850px",
  },
  galleryImg: {
    width: "30px",
    height: "18px",
    maxWidth: "30px",
    maxHeight: "20px",
    paddingRight: "5px",
    paddingBottom: "4px",
  },
  galleryContainer: {},
  galleryContainerBigger: {
    width: "100px",
    height: "100px",
  },
  cardCategory: {
    padding: "20px",
  },
};

const useStyles = makeStyles(customStyles);

export default function TotalWorkerCasesSummaryPanels(props, context) {
  const classes = useStyles();
  const { totalWorkerInteractions } = props;
  const sharedFiles = props.sharedFiles;
  const nationalities = props.nationalities;

  if (
    !totalWorkerInteractions ||
    !totalWorkerInteractions.caseDataSummary ||
    !totalWorkerInteractions.nationalityByCallCount ||
    !sharedFiles ||
    !nationalities ||
    nationalities.length === 0
  ) {
    return <div />;
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} lg={3}>
          <Card className={classes.largeStatContainer2}>
            <span>
              <div
                className={`${classes.cardTitle} ${classes.largeStatNumber}`}
              >
                {Utils.formatNumber(
                  totalWorkerInteractions.caseDataSummary.total
                )}
              </div>
              <p className={`${classes.cardCategory} ${classes.largeStatName}`}>
                {context.t("Total Hotline Calls")}
              </p>
            </span>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={9}>
          <GridContainer>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <span>
                  <div
                    className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                  >
                    {Utils.formatNumber(
                      totalWorkerInteractions.caseDataSummary.men
                    )}
                  </div>
                  <p
                    className={`${classes.cardCategory} ${classes.smallStatName}`}
                  >
                    {context.t("Men")}
                  </p>
                </span>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <span>
                  <div
                    className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                  >
                    {Utils.formatNumber(
                      totalWorkerInteractions.caseDataSummary.women
                    )}
                  </div>
                  <p
                    className={`${classes.cardCategory} ${classes.smallStatName}`}
                  >
                    {context.t("Women")}
                  </p>
                </span>
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <span>
                  <div
                    className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                  >
                    {Utils.formatNumber(
                      totalWorkerInteractions.caseDataSummary.total -
                        (totalWorkerInteractions.caseDataSummary.women +
                          totalWorkerInteractions.caseDataSummary.men)
                    )}
                  </div>
                  <p
                    className={`${classes.cardCategory} ${classes.smallStatName}`}
                  >
                    {context.t("Unknown")}
                  </p>
                </span>
              </Card>
            </GridItem>

            {totalWorkerInteractions.nationalityByCallCount.length > 0 ? (
              totalWorkerInteractions.nationalityByCallCount.map((item) => {
                let nationality = nationalities.find((nationalityItem) => {
                  return nationalityItem.id === item.client_nationality;
                });

                let sharedFileFlag =
                  nationality !== null && nationality !== undefined
                    ? sharedFiles.find((sharedFileItem) => {
                        return sharedFileItem.id === nationality.imageicon;
                      })
                    : null;

                return (
                  <GridItem
                    key={Utils.giveMeGuid()}
                    xs={6}
                    sm={4}
                    md={4}
                    lg={4}
                  >
                    <Card className={classes.smallStatContainer}>
                      <div
                        className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                      >
                        {Utils.formatNumber(item.client_nationality__count)}
                      </div>
                      <span
                        className={`${classes.cardCategory} ${classes.smallStatName}`}
                      >
                        {sharedFileFlag && (
                          <img
                            className={classes.galleryImg}
                            src={sharedFileFlag.file_path}
                          />
                        )}
                        {Utils.formatNumber(item.client_nationality__name)}
                      </span>
                    </Card>
                  </GridItem>
                );
              })
            ) : (
              <div />
            )}
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

TotalWorkerCasesSummaryPanels.contextTypes = {
  t: PropTypes.func.isRequired,
};
