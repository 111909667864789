import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import GridContainer from "components/Grid/GridContainer.js";

import GoogleMapReact from "google-map-react";
import GridItem from "components/Grid/GridItem.js";
import { Link } from "@material-ui/core";

// core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "components/CustomButtons/Button.js";
import CloseIcon from "@material-ui/icons/Close";

import Tooltip from "@material-ui/core/Tooltip";

import ReactTable from "react-table-6";
import Utils from "../../services/utils";
import axios from "axios";
import loginStore from "redux/stores/LoginStore.js";

import utils from "../../services/utils";

let _user = loginStore.getLoginUser();

const customStyles = {
  paper: {
    backgroundColor: "white",
    padding: 30,
    webkitBoxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
    mozBoxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
    boxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
  },
  modal: {
    display: "block",
    maxWidth: "70%",
    height: "100%",
    overflowWrap: "break-word",
    overflowY: "auto;",
    overflowX: "none;",
    paddingBottom: "20px",
  },
  spanLabel: {
    fontWeight: 500,
  },
  button: {
    position: "absolute",
    padding: 0,
    margin: 0,
    top: "40px",
    right: "40px",
    scrollbars: "none",
  },
};

const useStyles = makeStyles(customStyles);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const AnyReactComponent = ({ text }) => (
  <div>
    <HtmlTooltip title={text} interactive>
      <AccountCircleIcon style={{ fontSize: "18px", color: "red" }} />
    </HtmlTooltip>
  </div>
);

export default function SupplierModal(props, context) {
  const classes = useStyles();

  const defaultProps = {
    center: {
      lng: 100.5018,
      lat: 13.7563,
    },
    zoom: 7,
  };

  const suppliers = useSelector((state) => state.suppliersReducer.items);

  const industries = useSelector((state) => state.industriesReducer.items);

  const subindustries = useSelector(
    (state) => state.subIndustriesReducer.items
  );

  const countries = useSelector((state) => state.countriesReducer.items);

  const provinces = useSelector((state) => state.provincesReducer.items);

  const districts = useSelector((state) => state.districtsReducer.items);

  const supplyChains = useSelector((state) => state.supplyChainReducer.items);

  const getSupplyChains = (supplier) => {
    if (!supplier) {
      return [];
    }
    let supplyChainFound = [];
    let foundMap = new Map();
    supplyChains.forEach((supplyChain) => {
      foundMap.has(supplyChain.id) === false &&
        supplyChain.suppliers &&
        supplyChain.suppliers.forEach((supplierId) => {
          if (supplier.id === supplierId) {
            foundMap.set(supplyChain.id, supplyChain);
            supplyChainFound.push(supplyChain);
          }
        });
    });

    return supplyChainFound;
  };

  const getSupplier = (id) => {
    return suppliers.find((supplier) => {
      return supplier.id === parseInt(id);
    });
  };

  const getCountryName = (id) => {
    const tobject = countries.find((ttobject) => {
      return ttobject.id === id;
    });
    return tobject ? tobject.name : "-";
  };

  const getDistrictName = (id) => {
    const tobject = districts.find((ttobject) => {
      return ttobject.id === id;
    });
    return tobject ? tobject.name : "-";
  };

  const getProvinceName = (id) => {
    const tobject = provinces.find((ttobject) => {
      return ttobject.id === id;
    });
    return tobject ? tobject.name : "-";
  };

  const supplier = getSupplier(props.value);
  const [totalWorkers, setTotalWorkers] = useState(0);
  var config = [];
  React.useEffect(() => {
    if (localStorage.getItem("token") != undefined) {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
    }
  }, []);

  React.useEffect(() => {
    if (supplier) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/suppliers/${supplier.id}/total_workers/`,
          config
        )
        .then(function(response) {
          setTotalWorkers(response.data.Total_workers);
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }, [supplier]);

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      className={classes.modal + " modal"}
    >
      <div className={classes.paper}>
        <h3 style={{ marginTop: 0 }}>
          {`${(supplier && supplier.name) || "-"}`}
        </h3>
        <Button
          simple
          color="danger"
          className={classes.button}
          onClick={() => props.onClose()}
        >
          <CloseIcon />
        </Button>
        <GridContainer>
          <GridItem>
            <br />
          </GridItem>

          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <h4> {context.t("General:")} </h4>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("website:")}
                </span>
                {(supplier && (
                  <a target="_blank" href={supplier.website}>
                    {supplier.website}
                  </a>
                )) ||
                  "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Company Description:")}
                </span>
                {(supplier && supplier.description) || "-"}
              </p>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <h4> {context.t("Location:")} </h4>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Created:")}
                </span>
                {(supplier && supplier.created) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Address:")}
                </span>
                {(supplier && supplier.address) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Zipcode:")}
                </span>
                {(supplier && supplier.zipcode) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Country:")}
                </span>
                {supplier && getCountryName(supplier.country)}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Province:")}
                </span>
                {supplier && getProvinceName(supplier.province)}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("District:")}
                </span>
                {supplier && getDistrictName(supplier.district)}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Industry:")}
                </span>
                {supplier &&
                  utils.getIndustryName(industries, supplier.industries)}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Sub Industry:")}
                </span>
                {supplier &&
                  utils.getSubIndustryName(
                    subindustries,
                    supplier.subindustries
                  )}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Zipcode:")}
                </span>
                {(supplier && supplier.zipcode) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}> {context.t("Lat:")} </span>
                {(supplier && supplier.lat) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}> {context.t("Lng:")} </span>
                {(supplier && supplier.lng) || "-"}
              </p>
            </GridItem>
            {_user && _user.groups.includes("CSO") ? null : (
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <h4> {context.t("Contact:")} </h4>
                <p>
                  <span className={classes.spanLabel}>
                    {context.t("contact_name:")}
                  </span>
                  {(supplier && supplier.contact_name) || "-"}
                </p>
                <p>
                  <span className={classes.spanLabel}>
                    {context.t("contact_phone:")}
                  </span>
                  {(supplier && supplier.contact_phone) || "-"}
                </p>
                <p>
                  <span className={classes.spanLabel}>
                    {context.t("contact_email:")}
                  </span>
                  {(supplier && supplier.contact_email) || "-"}
                </p>
                <p>
                  <span className={classes.spanLabel}>
                    {context.t("vessel_number:")}
                  </span>
                  {(supplier && supplier.vessel_number) || "-"}
                </p>
                {
                  //<p> <span className={classes.spanLabel}> fishing_gear_liscense_number: </span> {supplier && supplier.fishing_gear_liscense_number || '-'} </p>
                }
              </GridItem>
            )}
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <h4> {context.t("Work Environment:")} </h4>

              {
                //  <p> <span className={classes.spanLabel}> Tier_Id: </span> {supplier && supplier.tier_id || '-'} </p>
              }
              <p>
                <span className={classes.spanLabel}>
                  {context.t("name_harvesting_business:")}
                </span>
                {(supplier && supplier.name_harvesting_business) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("num_vessels_sourced_from:")}
                </span>
                {(supplier && supplier.num_vessels_sourced_from) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_workers:")}
                </span>
                {(supplier && totalWorkers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_men_workers:")}
                </span>
                {(supplier && supplier.total_num_men_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_women_workers:")}
                </span>
                {(supplier && supplier.total_num_women_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_thai_workers:")}
                </span>
                {(supplier && supplier.total_num_thai_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_cambodian_workers:")}
                </span>
                {(supplier && supplier.total_num_cambodian_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_myanmar_workers:")}
                </span>
                {(supplier && supplier.total_num_myanmar_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_lao_workers:")}
                </span>
                {(supplier && supplier.total_num_lao_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_vietnamese_workers:")}
                </span>
                {(supplier && supplier.total_num_vietnamese_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_nepalese_workers:")}
                </span>
                {(supplier && supplier.total_num_nepalese_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_bangladeshi_workers:")}
                </span>
                {(supplier && supplier.total_num_bangladeshi_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_indonesian_workers:")}
                </span>
                {(supplier && supplier.total_num_indonesian_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_malaysian_workers:")}
                </span>
                {(supplier && supplier.total_num_malaysian_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_indian_workers:")}
                </span>
                {(supplier && supplier.total_num_indian_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_pakistani_workers:")}
                </span>
                {(supplier && supplier.total_num_pakistani_workers) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("total_num_sri_lankan_workers:")}
                </span>
                {(supplier && supplier.total_num_sri_lankan_workers) || "-"}
              </p>
            </GridItem>

            <GridItem xs={12} sm={12} md={6} lg={6}>
              <h4> {context.t("Informative:")} </h4>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("hiring_practice:")}
                </span>
                {(supplier && supplier.hiring_practice) || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Recruiter:")}
                </span>
                {/* {supplier && supplier.hiring_practice || '-'}  */}
                {supplier &&
                Array.isArray(supplier.recruiter) &&
                supplier.recruiter.length !== 0
                  ? supplier.recruiter.map((recruiters, i) => {
                      return (
                        <>
                          <p>
                            {context.t("recruiter:")}
                            <span>{recruiters.recruiter_name}</span>
                          </p>
                          <p>
                            {context.t("Status:")}
                            <span>{recruiters.status}</span>
                          </p>
                        </>
                      );
                    })
                  : null}
              </p>
              <p>
                <span className={classes.spanLabel}>{context.t("other:")}</span>
                {(supplier && supplier.other) || "-"}
              </p>
            </GridItem>
            {_user && _user.groups.includes("CSO") ? null : (
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <h4> {context.t("Supply Chains")}</h4>
                <div>
                  <ReactTable
                    key={Utils.giveMeGuid()}
                    data={getSupplyChains(supplier)}
                    columns={[
                      {
                        Header: context.t("id"),
                        accessor: "id",
                        sortable: false,
                        resizable: false,
                        width: 80,
                      },
                      {
                        Header: context.t("name"),
                        accessor: "name",
                        sortable: false,
                        resizable: false,
                        width: 160,
                      },
                      {
                        Header: "",
                        sortable: false,
                        accessor: "edit",
                        width: 30,
                      },
                    ]}
                    defaultPageSize={getSupplyChains(supplier).length}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </div>
              </GridItem>
            )}
            {_user && _user.groups.includes("CSO")
              ? null
              : supplier && (
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <h4>{context.t("Reports")}</h4>
                    {supplier.reports.map((report, index) => {
                      return (
                        <Link
                          target="_blank"
                          href={report.file_path}
                          variant="body2"
                        >
                          {report.name}
                        </Link>
                      );
                    })}
                  </GridItem>
                )}
          </GridContainer>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <h4>{context.t("Worker Voice")}</h4>
            <span>
              {supplier && supplier.worker_voice != null
                ? supplier.worker_voice
                  ? context.t("There is worker voice in this workplace")
                  : context.t("There is no worker voice in this workplace")
                : ""}
            </span>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <h4>{context.t("Worker Voice Rolled Out")}</h4>
            <span>
              {supplier && supplier.is_worker_voice_rolled_out != null
                ? supplier.is_worker_voice_rolled_out
                  ? context.t(
                      "Engaged with supplier and worker voice rolled out"
                    )
                  : context.t(
                      "Is NOT Engaged with supplier and worker voice NOT rolled out"
                    )
                : ""}
            </span>
          </GridItem>
        </GridContainer>
        <GridItem>
          <br />
        </GridItem>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {supplier && supplier.lat && supplier.lng && (
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Google Maps:")}
                </span>
                {
                  <div style={{ height: "400px", width: "100%" }}>
                    <GoogleMapReact
                      key={Utils.giveMeGuid()}
                      bootstrapURLKeys={{
                        key: process.env.REACT_APP_GOOGLE_GEOCODE_API_KEY,
                      }}
                      defaultCenter={{
                        lat: parseFloat(supplier.lat),
                        lng: parseFloat(supplier.lng),
                      }}
                      defaultZoom={defaultProps.zoom}
                    >
                      <AnyReactComponent
                        key={Utils.giveMeGuid()}
                        lat={parseFloat(supplier.lat)}
                        lng={parseFloat(supplier.lng)}
                        text={
                          <div>
                            {supplier.name}
                            <br />
                            {supplier.total_num_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_workers +
                                  context.t("total workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_men_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_men_workers +
                                  context.t("total men workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_women_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_women_workers +
                                  context.t("total women workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_thai_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_thai_workers +
                                  context.t("Thai workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_cambodian_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_cambodian_workers +
                                  context.t("Cambodian workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_myanmar_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_myanmar_workers +
                                  context.t("Myanmar workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_lao_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_lao_workers +
                                  context.t("Lao workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_vietnamese_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_vietnamese_workers +
                                  context.t("Vietanmese workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_nepalese_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_nepalese_workers +
                                  context.t("Nepalese workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_bangladeshi_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_bangladeshi_workers +
                                  context.t("Bangladeshi workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_indonesian_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_indonesian_workers +
                                  context.t("Indonesian workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_malaysian_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_malaysian_workers +
                                  context.t("Malaysian workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_indian_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_indian_workers +
                                  context.t("Indian workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_pakistani_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_pakistani_workers +
                                  context.t("Pakistani workers")}
                              </div>
                            ) : null}
                            {supplier.total_num_sri_lankan_workers ? (
                              <div>
                                <br />
                                {"" +
                                  supplier.total_num_sri_lankan_workers +
                                  context.t("Sri Lankan workers")}
                              </div>
                            ) : null}
                          </div>
                        }
                      />
                    </GoogleMapReact>
                  </div>
                }
              </p>
            )}
          </GridItem>
        </GridContainer>
      </div>
    </Modal>
  );
}

SupplierModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
