import React from "react";
import ReactSpeedometer from "react-d3-speedometer"
import { withParentSize } from '@vx/responsive';
function SpeedometerStat(props) {
  const [progressIndicator, setProgressIndicator] = React.useState()
  React.useEffect(() => {
    if (props.data !== undefined) {
      setProgressIndicator(props.data.progress_indicator)
    }
  })
  let value = 0
  if (props.name === 'Quality of Response') {
    if (progressIndicator != undefined) {
      for (let i = 0; i < progressIndicator.length; i++) {
        if (progressIndicator[i].str_value === "Quality remediation") {
          value = progressIndicator[i].numeric_value
        }
      }
    }
  }
  if (props.name === 'Timeliness of Response') {
    if (progressIndicator != undefined) {
      for (let i = 0; i < progressIndicator.length; i++) {
        if (progressIndicator[i].str_value === "Timeliness remediation") {
          value = progressIndicator[i].numeric_value
        }
      }
    }
  }
  if (props.name === 'Openness to Reform') {
    if (progressIndicator != undefined) {
      for (let i = 0; i < progressIndicator.length; i++) {
        if (progressIndicator[i].str_value === "Opennes store form") {
          value = progressIndicator[i].numeric_value
        }
      }
    }
  }
  if (props.name === 'Quality of Response actual') {
    if (props.data && props.data._quality_score != undefined) {
      value = parseFloat(props.data._quality_score.toFixed(1))
    }
  }
  if (props.name === 'Timeliness of Response actual') {
    if (props.data && props.data._timeliness_score != undefined) {
      value = parseFloat(props.data._timeliness_score.toFixed(1))
    }
  }
  if (props.name === 'Openness to Reform actual') {
    if (props.data && props.data._openness_score != undefined) {
      value = parseFloat(props.data._openness_score.toFixed(1))
    }
  }

  return (
    <div>
      <ReactSpeedometer
        value={value}
        valueFormat=".3"
        maxValue={5}
        minValue={1}
        segments={3}
        maxSegmentLabels={0}
        customSegmentStops={[1, 2, 3, 4, 5]}
        height={180}
        width={145}
        forceRender={true}
        paddingVertical={14}
        valueTextFontSize="1.7rem"
        segmentColors={['red', 'orange', 'yellow', 'green', '#005b4c']}
        needleColor="3C4858"
        textColor="3C4858"
        needleHeightRatio={0.5}
        ringWidth={25}
      />
    </div>
  )
};


export default withParentSize(SpeedometerStat);
