import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import { useSelector } from "react-redux";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const customStyles = {
  ...styles,
};

const useStyles = makeStyles(customStyles);

export default function DocumentTypeDropdown(props, context) {
  const documentTypes = useSelector((state) => [
    { value: "1", name: context.t("Undocumented") },
    { value: "2", name: context.t("Pink card") },
    { value: "3", name: context.t("CI/TD") },
    { value: "4", name: context.t("Passport") },
    { value: "5", name: context.t("Border pass") },
    { value: "6", name: context.t("NA") },
  ]);

  const classes = useStyles();

  const handleChangeDocumentTypeId = (e) => {
    props.onSelect(e.target.value);
  };

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="documentType-select" className={classes.selectLabel}>
        {context.t("Document Type")}
      </InputLabel>
      <Select
        value={props.value ? props.value : ""}
        onChange={handleChangeDocumentTypeId}
        inputProps={{
          name: "documentTypeSelect",
          id: "documentType-select",
        }}
      >
        {documentTypes.map((item, i) => {
          return (
            <MenuItem value={item.value} key={i}>
              {" "}
              {item.name}{" "}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

DocumentTypeDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

// Default values for props:
DocumentTypeDropdown.defaultProps = {
  onSelect: (selectedType) =>
    console.log("Selected documentType: ", selectedType),
};
