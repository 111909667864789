import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import SpeedometerStat from "../Dashboard/SpeedometerStat.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import LinearProgress from "@material-ui/core/LinearProgress";
import loginStore from "redux/stores/LoginStore";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSupplier,
  fetchSupplierProgress,
} from "redux/actions/SupplierActions";
import Utils from "services/utils";
import HttpService from "../../services/HttpService";
import Grid from "@material-ui/core/Grid";
import PiCharts from "./PiCharts.js";
import Typography from "@material-ui/core/Typography";
import Axios from "axios";

const customStyle = {
  green: {
    backgroundColor: "#148696",
    borderRadius: 0,
    color: "white",
  },
  gray: {
    backgroundColor: "#b7b7b7",
    borderRadius: 0,
  },
  yellow: {
    backgroundColor: "#ffe599",
    borderRadius: 0,
  },
  paper: {
    padding: "1rem",
    margin: "1rem 0",
  },

  gaugeContainer: {
    textAlign: "center",
    // justifyContent: "center",
    height: "170px",
    display: "flex",
    "@media (max-width: 547px)": {
      flexDirection: "column",
      height: "auto",
    },
  },

  gaugeBox: {
    // minHeight: '10rem',
    // justifyContent: "center",
    // margin: '10px',
    // boxShadow: '0px 1px 5px #d1d1d1',
  },

  gaugeTableText: {
    fontSize: "13px",
    fontWeight: 500,
    marginTop: "-30px",
  },
  chartBox: {
    height: "auto",
    padding: "16px 10px",
    boxShadow: "0.005rem 1px 2px #a4a0a0",
    borderRadius: "6px",
    "@media (max-width: 547px)": {
      marginBottom: "20px",
    },
    "@media (max-width: 1272px)": {
      marginBottom: "20px",
    },
    "@media (min-width: 568px)": {
      height: "auto",
    },
  },
  TypographyStyle: {
    fontSize: "0.9rem",
    height: "90px",
    padding: "10px",
    "@media (max-width: 767px)": {
      fontSize: "1rem",
    },
  },
  SecondGrid: {
    padding: "0px",
  },

  gaugeContainer: {
    textAlign: "center",
    justifyContent: "center",
    height: "180px",
    display: "flex",
  },

  gaugeBox: {
    minHeight: "10rem",
    justifyContent: "center",
    margin: "10px",
    boxShadow: "0px 1px 5px #d1d1d1",
  },
};

const useStyles = makeStyles(customStyle);

function buttonColorSetter(colorString, classes) {
  switch (colorString) {
    case "Gray":
      return classes.gray;
    case "Yellow":
      return classes.yellow;
    case "Green":
      return classes.green;
    default:
      return classes.gray;
  }
}

/**
 * <CRCScoreSummaryPanels key={Utils.giveMeGuid()} workerInteractions={workerInteractions} />
 * @param {workerInteractions} props
 * @returns
 */
function CRCScoreSummaryPanels(props, context) {
  const classes = useStyles();

  const supplier = props.supplier;
  const workerInteractions = props.workerInteractions;
  const crcDataSummaryMap = Utils.generateCrCAveragesMap(
    workerInteractions.crcRawScored,
    false
  );
  let crcDataSummary = crcDataSummaryMap.get(supplier);

  const [qualityCRC, setQualityCRC] = useState(null);
  const [timelinessCRC, setTimelinessCRC] = useState(null);
  const [opennessCRC, setOpennessCRC] = useState(null);

  let setCRC = true;
  useEffect(() => {
    if (setCRC) {
      if (crcDataSummary) {
        let totalCrcCount =
          crcDataSummary.supplier_crc_scores.length > 0
            ? crcDataSummary.supplier_crc_scores.length
            : 1;
        setQualityCRC(
          parseFloat((crcDataSummary.quality_avg / totalCrcCount).toFixed(2))
        );
        setTimelinessCRC(
          parseFloat((crcDataSummary.timeliness_avg / totalCrcCount).toFixed(2))
        );
        setOpennessCRC(
          parseFloat((crcDataSummary.openness_avg / totalCrcCount).toFixed(2))
        );
      }
      setCRC = true;
    } else return;
  }, [crcDataSummaryMap]);

  const [indicator2Data, setIndicator2Data] = React.useState(null);
  const [indicator3Data, setIndicator3Data] = React.useState([]);

  const chartDataHandler1 = () => {
    let config;
    if (localStorage.getItem("token") != undefined) {
      config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
    }
    if (supplier !== undefined) {
      Axios.get(
        `${process.env.REACT_APP_API_URL}/suppliers/${supplier}/closed_cases/`,
        config
      )
        .then((response) => {
          setIndicator2Data(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const chartDataHandler2 = () => {
    let config;
    if (localStorage.getItem("token") != undefined) {
      config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
    }
    if (supplier !== undefined) {
      Axios.get(
        `${process.env.REACT_APP_API_URL}/suppliers/${supplier}/cases_quality_level/`,
        config
      )
        .then((response) => {
          setIndicator3Data(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  React.useEffect(() => {
    if (supplier) {
      chartDataHandler1();
      chartDataHandler2();
    }
  }, [supplier]);

  if (!crcDataSummary) {
    return null;
  }

  return (
    <div>
      <Grid container justify="space-around" spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Grid className={classes.chartBox}>
            <Grid item xs={12}>
              <Typography className={classes.TypographyStyle}>
                {context.t(
                  "WORK INDICATOR 1. Supplier performance in relation to responding to worker-reported grievances and collaborating with Issara Institute staff"
                )}
              </Typography>
            </Grid>

            <GridContainer style={{ justifyContent: "center" }}>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                className={`${classes.gaugeContainer}`}
              >
                <Grid className={classes.gaugeBox}>
                  <SpeedometerStat
                    name={context.t("Quality of Response actual")}
                    value={qualityCRC}
                  />
                  <p className={classes.gaugeTableText}>
                    {context.t("Quality of Response")}
                  </p>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                className={`${classes.gaugeContainer}`}
              >
                <Grid className={classes.gaugeBox}>
                  <SpeedometerStat
                    name={context.t("Timeliness of Response actual")}
                    value={timelinessCRC}
                  />
                  <p className={classes.gaugeTableText}>
                    {context.t("Timeliness of Response")}
                  </p>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                className={`${classes.gaugeContainer}`}
              >
                <Grid className={classes.gaugeBox}>
                  <SpeedometerStat
                    name={context.t("Openness to Reform actual")}
                    value={opennessCRC}
                  />
                  <p className={classes.gaugeTableText}>
                    {context.t("Openness to Reform")}
                  </p>
                </Grid>
              </Grid>
            </GridContainer>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          className={classes.SecondGrid}
        >
          <Grid container className={classes.chartBox}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{
                justifyContent: "end",
                display: "inline-block",
                height: "100%",
                verticalAlign: "bottom",
              }}
            >
              <Typography className={classes.TypographyStyle}>
                {context.t(
                  "WORK INDICATOR 2. % of worker-reported issues in the supply chain resolved in under 60 days"
                )}
              </Typography>

              <Grid>
                <PiCharts
                  name="worker-reported issues"
                  chartData={indicator2Data}
                />
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              style={{ justifyContent: "center" }}
            >
              <Typography className={classes.TypographyStyle}>
                {context.t(
                  "WORK INDICATOR 3. % of remediation efforts of specific issues resolved at Poor, Fair, Good and Excellent level"
                )}
              </Typography>

              <Grid>
                <PiCharts
                  name="remediation efforts"
                  chartData2={indicator3Data}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default function SupplierProgress(props, context) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [workerInteractions, setWorkerInteractions] = useState({});
  const [supplierId, setSupplierId] = useState();
  const [totalWorkerCalls, setTotalWorkerCalls] = useState({
    x: [context.t("x")],
    y: [context.t("Worker Calls and Messages By Month")],
  });

  const fetchMetricsFilters = (supplierId) => {
    Promise.all([
      new Promise((resolve) => {
        HttpService.get(
          `${process.env.REACT_APP_API_URL}/metrics/interactions?supplierIds=${supplierId}`,
          (interactions) => {
            setWorkerInteractions(interactions);

            const totalProvinceCallsNew = [];
            const totalNationalityCallsNew = [];

            interactions.nationalityByCallCount.forEach((item) => {
              totalNationalityCallsNew.push([
                item.client_nationality__name,
                item.client_nationality__count,
                item.client_nationality__id,
              ]);
            });

            interactions.districtsByCallCount.forEach((item) => {
              totalProvinceCallsNew.push({
                name: item.district__name,
                call_count: item.district__count,
                lng: item.lng,
                lat: item.lat,
              });
            });

            const totalWorkerCallsNew = {
              x: ["x"],
              y: [context.t("Worker Calls and Messages By Month")],
            };

            interactions.caseCountByYearMonth.forEach((item) => {
              totalWorkerCallsNew.x.push(item.month_year + "-01");
              totalWorkerCallsNew.y.push(item.call_count);
            });

            setTotalWorkerCalls(totalWorkerCallsNew);

            resolve(interactions);
          },
          (err) => {
            console.log(err.message);
            resolve(err.message);
          }
        );
      }),
    ]).then((resolve) => {});
  };

  const supplier = useSelector((state) => state.suppliersReducer.item);
  const supplierProgress = useSelector(
    (state) => state.suppliersReducer.supplierProgress
  );

  const fetchingSupplier = useSelector(
    (state) => state.suppliersReducer.fetchingSupplier
  );
  const fetchingSupplierProgress = useSelector(
    (state) => state.suppliersReducer.fetchingSupplierProgress
  );

  useEffect(() => {
    const user = loginStore.getLoginUser();
    setSupplierId(user.supplier);
    dispatch(fetchSupplier(user.supplier));
    dispatch(fetchSupplierProgress(user.supplier));
    fetchMetricsFilters(user.supplier);
  }, []);
  if (fetchingSupplier || fetchingSupplierProgress) {
    return <LinearProgress />;
  }

  return (
    <main>
      <section>
        <Paper className={classes.paper}>
          <section>
            <Paper className={classes.paper}>
              <h4>{context.t("WORKER VOICE")}</h4>
              <div>
                {context.t(
                  "Grey = Not yet initiated; Yellow = Partial progress; Green = Completed/ongoing"
                )}
              </div>
            </Paper>
          </section>
          <div>
            {supplierProgress["WORKER VOICE"]?.map((status) => (
              <Button
                key={status.key}
                variant="contained"
                className={buttonColorSetter(status?.value, classes)}
              >
                {status.label}
              </Button>
            ))}
          </div>
        </Paper>
      </section>
      <section>
        <Paper className={classes.paper}>
          <section>
            <Paper className={classes.paper}>
              <h4>{context.t("LABOUR RECRUITMENT")}</h4>
              <div>
                {context.t(
                  "Grey = Not yet initiated; Yellow = Partial progress; Green = Completed/ongoing"
                )}
              </div>
            </Paper>
          </section>
          <div>
            {supplierProgress["LABOUR RECRUITMENT"]?.map((status) => (
              <Button
                key={status.key}
                variant="contained"
                className={buttonColorSetter(status?.value, classes)}
              >
                {status.label}
              </Button>
            ))}
          </div>
        </Paper>
      </section>
      <section>
        <Paper className={classes.paper}>
          <h4>{context.t("WORKING CONDITIONS")}</h4>

          <CRCScoreSummaryPanels
            workerInteractions={workerInteractions}
            supplier={supplierId}
          />
        </Paper>
      </section>
      <section>
        <Paper className={classes.paper}>
          <h4>{context.t("ACTIVITIES & ACHIEVEMENTS SUMMARY")}</h4>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow key="num_of_workers_access_to_issara_wv_channels">
                  <TableCell component="td" scope="row">
                    {context.t(
                      "Number of workers with access to Issara worker voice channels"
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier?.num_of_workers_access_to_issara_wv_channels ||
                      context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="date_of_most_recent_worker_satisfaction_survey">
                  <TableCell component="td" scope="row">
                    {context.t(
                      "Date of most recent worker satisfaction survey"
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier?.date_of_most_recent_worker_satisfaction_survey ||
                      context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="date_of_most_recent_recruitment_survey">
                  <TableCell component="td" scope="row">
                    {context.t("Date of most recent recruitment survey")}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier?.date_of_most_recent_recruitment_survey ||
                      context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="num_workers_reimbursed_recruitment_fees">
                  <TableCell component="td" scope="row">
                    {context.t("Number of workers reimbursed recruitment fees")}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier?.num_workers_reimbursed_recruitment_fees ||
                      context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="remain_num_workers_owed_recruitment_fee_reimbursement">
                  <TableCell component="td" scope="row">
                    {context.t(
                      "Remaining number of workers owed recruitment fee reimbursement"
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier?.remain_num_workers_owed_recruitment_fee_reimbursement ||
                      context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="total_amt_recruitment_fee_reimbursed_to_workers">
                  <TableCell component="td" scope="row">
                    {context.t(
                      "Total amount of recruitment fees reimbursed to workers (US dollars)"
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier?.total_amt_recruitment_fee_reimbursed_to_workers ||
                      context.t("Not available")}
                  </TableCell>
                </TableRow>
                <TableRow key="remain_amt_owed_recruitment_fee_reimbursement_to_workers">
                  <TableCell component="td" scope="row">
                    {context.t(
                      "Remaining amount owed, recruitment fee reimbursement to workers (US dollars)"
                    )}
                  </TableCell>
                  <TableCell component="td" scope="row">
                    {supplier?.remain_amt_owed_recruitment_fee_reimbursement_to_workers ||
                      context.t("Not available")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </section>
    </main>
  );
}

SupplierProgress.contextTypes = {
  t: PropTypes.func.isRequired,
};

CRCScoreSummaryPanels.contextTypes = {
  t: PropTypes.func.isRequired,
};
