
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};



const options = [
  { ContryName: "Thailand", Value: "Thailand" },
  { ContryName: "Cambodia", Value: "Cambodia" },
  { ContryName: "Myanmar", Value: "Myanmar" },
  { ContryName: "Laos", Value: "Laos" },
  { ContryName: "Vietnam", Value: "Vietnam" },
  { ContryName: "Nepal", Value: "Nepal" },
  { ContryName: "Bangladesh", Value: "Bangladesh" },
  { ContryName: "Indonesia", Value: "Indonesia" },
  { ContryName: "Malaysia", Value: "Malaysia" },
  { ContryName: "India", Value: "India" },
  { ContryName: "Pakistan", Value: "Pakistan" },
  { ContryName: "Sri Lanka", Value: "Sri Lanka" },
];


const CountryName = (country_name) => {
  if(country_name === "Thailand"){
    return "total_num_thai_workers"
  }

  if(country_name === "Cambodia"){
    return "total_num_cambodian_workers"
  }

  if(country_name === "Myanmar"){
    return "total_num_thai_workers"
  }

  if(country_name === "Laos"){
    return "total_num_lao_workers"
  }

  if(country_name === "Vietnam"){
    return "total_num_vietnamese_workers"
  }

  if(country_name === "Nepal"){
    return "total_num_nepalese_workers"
  }

  if(country_name === "Bangladesh"){
    return "total_num_bangladeshi_workers"
  }

  if(country_name === "Indonesia"){
    return "total_num_indonesian_workers"
  }

  if(country_name === "India"){
    return "total_num_indian_workers"
  }

  if(country_name === "Pakistan"){
    return "total_num_pakistani_workers"
  }

  if(country_name === "Sri Lanka"){
    return "total_num_sri_lankan_workers"
  }

  else{
    return ""
  }
}

export { MenuProps, options, CountryName };

