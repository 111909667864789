import React, { useState, useEffect, useCallback } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import axios from "axios";
import "./SuppliersPaginateDropdown.css";
import PropTypes from "prop-types";

async function loadOptions(search, loadedOptions, { cursor }) {
  try {
    const queryParams = new URLSearchParams();

    if (search) queryParams.set("name", search);
    if (cursor) queryParams.set("cursor", cursor);

    const url = `${
      process.env.REACT_APP_API_URL
    }/supplier_list_dropdown/?${queryParams.toString()}`;

    const response = await axios.get(url);
    const responseJSON = response.data;

    return {
      options: responseJSON.results.map((result) => ({
        value: result.id,
        label: result.name,
      })),
      hasMore: Boolean(responseJSON.next),
      additional: {
        cursor: extractCursorFromNextUrl(responseJSON.next),
      },
    };
  } catch (error) {
    console.error("Error loading options:", error);
    return {
      options: [],
      hasMore: false,
    };
  }
}

const extractCursorFromNextUrl = (nextUrl) => {
  if (!nextUrl) return null;
  const url = new URL(nextUrl);
  return url.searchParams.get("cursor");
};

const SuppliersPaginateDropdown = (props, context) => {
  const [selectedOptionsFromServer, setSelectedOptionsFromServer] = useState(
    []
  );
  const [isFocused, setIsFocused] = useState(false);

  const handleSelectionChange = useCallback(
    (selectedOptions) => {
      let selectedIDs = [];

      if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
        selectedIDs = selectedOptions.map((option) => option.value);
      } else if (
        !Array.isArray(selectedOptions) &&
        selectedOptions &&
        !props.isMulti
      ) {
        selectedIDs = [selectedOptions.value];
      }
      setSelectedOptionsFromServer(selectedOptions || []);
      if (props.isMulti) {
        props.onSelect(selectedIDs);
      } else {
        props.onSelect(selectedIDs[0]);
      }
    },
    [props.onSelect, props.isMulti]
  );

  const selectStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 100,
    }),
    control: (base) => ({
      ...base,
      lineHeight: "36px",
    }),
  };

  const fetchDataForPreselectedIDs = useCallback(async () => {
    if (props.value) {
      const normalizedIDs = Array.isArray(props.value)
        ? props.value
        : [props.value];

      if (normalizedIDs.length > 0) {
        try {
          const response = await fetchSupplierData(normalizedIDs);
          setSelectedOptionsFromServer(
            response.results.map((result) => ({
              value: result.id,
              label: result.name,
            }))
          );
        } catch (error) {
          console.error("Error fetching data:", error);
          setSelectedOptionsFromServer([]);
        }
      }
    } else {
      setSelectedOptionsFromServer([]);
    }
  }, [props.value]);

  useEffect(() => {
    fetchDataForPreselectedIDs();
  }, [fetchDataForPreselectedIDs]);

  // construct url by ids of array
  function constructURLWithIDs(baseURL, idArray) {
    const queryParams = idArray.map((id) => `id=${id}`).join("&");
    return `${baseURL}/?${queryParams}`;
  }

  async function fetchSupplierData(normalizedIDs) {
    const url = constructURLWithIDs(
      `${process.env.REACT_APP_API_URL}/supplier_list_dropdown`,
      normalizedIDs
    );

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  }

  return (
    <div className="select-container">
      <label
        className={`floating-label ${
          isFocused || selectedOptionsFromServer.length
            ? "focused"
            : "unfocused"
        }`}
      >
        {selectedOptionsFromServer?.length > 0
          ? context.t("Selected")
          : props.label}
      </label>
      <AsyncPaginate
        styles={selectStyles}
        isSearchable={props.isSearchable}
        isClearable={true}
        placeholder={props.placeholder}
        value={selectedOptionsFromServer}
        loadOptions={loadOptions}
        onChange={handleSelectionChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        isMulti={props.isMulti}
        additional={{
          cursor: null,
        }}
      />
    </div>
  );
};

export default SuppliersPaginateDropdown;

SuppliersPaginateDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};
