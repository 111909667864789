import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// date picker required modules
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Utils from "services/utils";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import CountryProvincesDistrictsDropdown from "components/ilmdb/CountryProvincesDistrictsDropdown.js";
import TextField from '@material-ui/core/TextField';

import IndustriesSubIndustriesDropdown from "components/ilmdb/IndustriesSubIndustriesDropdown.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { makeStyles } from "@material-ui/core/styles";

import loginStore from "../../redux/stores/LoginStore"
import { createNonSupplyChainCase, updateNonSupplyChainCase, fetchNonSupplyChainsCases } from "../../redux/actions/SupplierActions";

const dateFormat = "MM/dd/yyyy"

const customStyle = {
  ...styles,
  ...sweetAlertStyles,
  label: {
    color: 'black',
    fontSize: '14px',
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.42857,
  },
  activitiesContainer: {
    border: '1px solid rgba(0,0,0,0.3)',
    borderRadius: '4px',
    marginTop: '30px',
    paddingBottom: '15px',
    fontSize: '16px',
    boxShadow: '1px 1px rgba(0,0,0,0.3)'
  },
  input: {
    padding: "5px",
  },
}

const useStyles = makeStyles(customStyle);


export default function NonSupplyChainForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const nonSupplyChainCases = useSelector(state => state.nonSupplyChainCasesReducer.items);
  const savingNonSupplyChain = useSelector(state => state.nonSupplyChainCasesReducer.savingNonSupplyChain);
  const [nonSupplyChainCase, setNonSupplyChainCase] = useState({});
  const initialPayload = {
    name: '',
    offender_name: '',
    country: undefined,
    address: '',
    zipcode: '',
    contact_phone: '',
    contact_email: '',
    description: '',
    vessel_type: undefined,
    industries: [],
    subindustries: [],
    country: undefined,
    province: undefined,
    district: undefined,
  };

  const [payload, setPayload] = useState(initialPayload);
  const [alert, setAlert] = useState(null)
  const [nonSupplyChainCaseId, setNonSupplyChainCaseId] = useState(null)


  const id = (props.location) ? new URLSearchParams(props.location.search).get('id') : ((props.supplierId) ? props.supplierId : nonSupplyChainCaseId); // id from query string of edited strategic partner

  useEffect(() => {
    if (nonSupplyChainCases === null || nonSupplyChainCases === undefined || nonSupplyChainCases.length < 1) {
      dispatch(fetchNonSupplyChainsCases());
    }
  }, []);


  useEffect(() => {
    if (nonSupplyChainCases && nonSupplyChainCases.length > 0) {
      let found = nonSupplyChainCases.find(nonSupplyChainCase => {
        return nonSupplyChainCase.id === parseInt(id)
      });
      if (found) {
        setNonSupplyChainCase(found);
      }
    }
  }, [nonSupplyChainCases])

  useEffect(() => {
    if (nonSupplyChainCase) {
      setPayload({
        ...payload,
        name: nonSupplyChainCase.name || '',
        address: nonSupplyChainCase.address || '',
        zipcode: nonSupplyChainCase.zipcode,
        offender_name: nonSupplyChainCase.contact_name,
        contact_phone: nonSupplyChainCase.contact_phone,
        contact_email: nonSupplyChainCase.contact_email,
        industries: nonSupplyChainCase.industries,
        subindustries: nonSupplyChainCase.subindustries,
        country: nonSupplyChainCase.country || null,
        province: nonSupplyChainCase.province || null,
        district: nonSupplyChainCase.district || null,
        description: nonSupplyChainCase.description || null
      });
    }
  }, [nonSupplyChainCase])


  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 100);
  };

  const handleConfirmSuccessAlert = () => {

    if (props.handleConfirmSuccessAlert) {
      props.handleConfirmSuccessAlert();
    } else {
      props.history.push('/admin/nonsupplychaincases')
    }
  }

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        onConfirm={handleConfirmSuccessAlert}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title="Well done!"
      >
        {id ? 'Non Supply Chain Case Name was updated' : 'Non Supply Chain Case Name has been successfully created'}
      </SweetAlert>
    );

    dispatch(fetchNonSupplyChainsCases())
  };

  const errorAlert = (error) => {
    console.log('ERRROR', error)
    setAlert(
      <SweetAlert
        danger
        onConfirm={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title="Error"
      >
        {error.message}
      </SweetAlert>
    );
  };

  const onSubmit = () => {
    console.log("Here is payload", payload);
    console.log("Here is industry and subindustry,", payload.industries, payload.subindustries);
    // Create a copy of the payload object
    let modifiedPayload = { ...payload };


    if (!modifiedPayload || !modifiedPayload.name || modifiedPayload.name.length < 0) {
      setAlert(
        <SweetAlert
          danger
          onConfirm={() => setAlert(null)}
          confirmBtnCssClass={classes.button + " " + classes.success}
          title="Error"
        >
          {"Please make sure to fill out the required Name Field."}
        </SweetAlert>);
    } else if (id) {
      dispatch(updateNonSupplyChainCase(id, modifiedPayload, successAlert, errorAlert))
    }
    else {
      dispatch(createNonSupplyChainCase(modifiedPayload, successAlert, errorAlert))
    }
  }

  const handleSelectedNonSupplyChainCase = (event, newValue) => {

    if (newValue && newValue.id) {
      setPayload(initialPayload);
      setNonSupplyChainCaseId(newValue.id);

      let found = nonSupplyChainCases.find(nonSupplyChainCase => {
        return nonSupplyChainCase.id === parseInt(newValue.id)
      });
      if (found) {
        setNonSupplyChainCase(found);
      }

      props.history.push(`/admin/edit-non-supply-chain-case?id=${newValue.id}`);
    }


  }

  let spacer = (<GridContainer>
    <GridItem>
      <br />
    </GridItem>
  </GridContainer>);



  const statuses = [
    { status: 'Active', value: 'active' },
    { status: 'Inactive', value: 'inactive' },
  ]


  if (savingNonSupplyChain) {
    return <CircularProgress />
  }

  return (id && (nonSupplyChainCases === undefined || nonSupplyChainCases === null ||
    nonSupplyChainCases.length === undefined || nonSupplyChainCases.length < 1)) ? (<div><CircularProgress /></div>) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>

      <GridContainer>
        {alert}
        <GridItem xs={12}>
          <Card style={{ marginTop: 0 }}>
            <CardHeader color="rose" icon>

              {id ?
                <h4 className={classes.cardIconTitle}>Edit Non Supply Chain Case Name</h4>
                :
                <h4 className={classes.cardIconTitle}>Add Non Supply Chain Case Name</h4>}
            </CardHeader>
            <CardBody>


              <GridContainer>
                <GridItem xs={12} >
                  <CustomInput
                    id="Name"
                    labelText="Industry and/or country of destination, location, number of workers impacted and date (MM/YY)"
                    formControlProps={{ fullWidth: true }}
                    helperText={<div style={{color: "red"}}>Required</div>}

                    inputProps={{
                      type: "text",
                      onChange: e => setPayload({
                        ...payload,
                        name: e.target.value
                      }),
                      disabled: !loginStore.isIssaraManagement()
                    }}
                    value={payload.name}
                  />
                </GridItem>



                <GridItem xs={12} >
                  <CustomInput
                    id="description"
                    labelText="Description"
                    formControlProps={{ fullWidth: true }}
                    isTextArea={true}
                    inputProps={{
                      type: "text",
                      onChange: e => setPayload({
                        ...payload,
                        description: e.target.value
                      })
                    }}
                    value={payload.description}
                  />
                </GridItem>



                <GridItem xs={12}>
                  <IndustriesSubIndustriesDropdown
                    multiple={true}
                    key={Utils.giveMeGuid()}
                    industry_values={payload.industries}
                    subindustry_values={payload.subindustries}
                    onSelect={(e) => {
                      setPayload({
                        ...payload,
                        industries: e.industry_id,
                        subindustries: e.subindustry_id
                      });
                    }} />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={9} >
                  <CustomInput
                    id="adress"
                    labelText="Detailed address of where the issue occurred"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: e => setPayload({
                        ...payload,
                        address: e.target.value
                      })
                    }}
                    value={payload.address}
                  />
                </GridItem>
                <GridItem xs={3} >
                  <CustomInput
                    id="zipcode"
                    labelText="Zipcode"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: e => setPayload({
                        ...payload,
                        zipcode: e.target.value
                      })
                    }}
                    value={payload.zipcode}
                  />
                </GridItem>

                <GridItem xs={12}>

                  <CountryProvincesDistrictsDropdown key={Utils.giveMeGuid()} country_values={(payload.country) ? { id: payload.country } : undefined}
                    province_values={(payload.province) ? { id: payload.province } : undefined}
                    district_values={(payload.district) ? { id: payload.district } : undefined}
                    onSelect={(e) => {
                      setPayload({
                        ...payload,
                        country: e.country_id,
                        province: e.province_id,
                        district: e.district_id
                      });
                    }} />
                </GridItem>
              </GridContainer>

              <GridContainer>

                <GridItem xs={12} >
                  <CustomInput
                    id="offender_name"
                    labelText="Offender Name"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: e => setPayload({
                        ...payload,
                        offender_name: e.target.value
                      })
                    }}
                    value={payload.offender_name}
                  />
                </GridItem>


                <GridItem xs={12} >
                  <CustomInput
                    id="contact_phone"
                    labelText="Contact Phone"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: e => setPayload({
                        ...payload,
                        contact_phone: e.target.value
                      })
                    }}
                    value={payload.contact_phone}
                  />
                </GridItem>

                <GridItem xs={12} >
                  <CustomInput
                    id="contact_email"
                    labelText="Contact Email"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: e => setPayload({
                        ...payload,
                        contact_email: e.target.value
                      })
                    }}
                    value={payload.contact_email}
                  />
                </GridItem>

              </GridContainer>


              {spacer}


              <GridContainer justifyContent='flex-end'>
                <GridItem>
                  <Button
                    color='success'
                    onClick={onSubmit}
                    disabled={savingNonSupplyChain}
                  >
                    Save
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody >
          </Card >
        </GridItem >
      </GridContainer >
    </MuiPickersUtilsProvider >);
}
