import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import { useSelector } from "react-redux";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const customStyles = {
  ...styles,
};

const useStyles = makeStyles(customStyles);

export default function ContractTypesDropdown(props, context) {
  const contractTypes = useSelector((state) => [
    { value: 1, name: context.t("Daily worker") },
    { value: 2, name: context.t("Piece work") },
    { value: 3, name: context.t("Seasonal worker") },
    { value: 4, name: context.t("Full-time position") },
    { value: 5, name: context.t("Other") },
  ]);

  const classes = useStyles();

  const handleChangeContractTypeId = (e) => {
    props.onSelect(e.target.value);
  };

  return (
    <FormControl fullWidth className={classes.selectFormControl}>
      <InputLabel htmlFor="contractType-select" className={classes.selectLabel}>
        {context.t("Contract Type")}
      </InputLabel>
      <Select
        value={props.value ? props.value : ""}
        onChange={handleChangeContractTypeId}
        inputProps={{
          name: "contractTypeSelect",
          id: "contractType-select",
        }}
      >
        {contractTypes.map((item, i) => {
          return (
            <MenuItem value={item.value} key={i}>
              {" "}
              {item.name}{" "}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

ContractTypesDropdown.contextTypes = {
  t: PropTypes.func.isRequired,
};

// Default values for props:
ContractTypesDropdown.defaultProps = {
  onSelect: (selectedType) =>
    console.log("Selected contractType: ", selectedType),
};
