import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import { fetchCallHHI } from "../../redux/actions/CallActions.js";
import utils from "../../services/utils.js";
import loginStore from "redux/stores/LoginStore.js";

let _user = loginStore.getLoginUser();

const customStyles = {
  ...customCheckboxRadioSwitch,
  title: {
    fontSize: "1rem",
    fontWeight: 400,
    lineHeight: 1,
    color: "rgba(0, 0, 0, 0.87)",
  },
  GridContainer: {
    marginTop: "1em !important",
    paddingLeft: "6px !important",
  },
  gridItem: {
    padding: "0 !important",
  },
  customLabel: {
    color: "rgba(0, 0, 0, 0.87)",
  },
};

const useStyles = makeStyles(customStyles);

let fetch = false;

export default function HowHearIssara(props, context) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const howHearIssara = useSelector((state) => state.callHHI.items);
  const lang = useSelector((state) => state.i18nState.lang);
  // const howHearIssaraLoading = useSelector(state => state.callHHI.fetchingCallHHI)

  const [checked, setChecked] = useState(props.values ? props.values : []);

  useEffect(() => {
    if (fetch === false) {
      fetch = true;
      dispatch(fetchCallHHI(lang));
    }
  }, []);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    // send updated list of checked items to parent component
    props.onChange(newChecked);
  };

  return (
    <div>
      {_user && _user.groups.includes("CSO") ? (
        <p className={classes.title}>
          {" "}
          {context.t(
            "How did the client hear about the organization? (check all that apply)"
          )}{" "}
        </p>
      ) : (
        <p className={classes.title}>
          {" "}
          {context.t(
            "How did the client hear about Issara? (check all that apply)"
          )}{" "}
        </p>
      )}
      {howHearIssara && howHearIssara.length > 0 ? (
        <GridContainer className={classes.GridContainer}>
          {howHearIssara.map((item) => (
            <GridItem
              key={utils.giveMeGuid()}
              className={classes.gridItem}
              xs={12}
              sm={6}
              lg={6}
              xl={4}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    key={utils.giveMeGuid()}
                    checked={checked.includes(item.id)}
                    tabIndex={-1}
                    onClick={() => handleToggle(item.id)}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                classes={{
                  label: classes.label + " " + classes.customLabel,
                  root: classes.labelRoot,
                }}
                label={item.name}
              />
            </GridItem>
          ))}
        </GridContainer>
      ) : (
        <p> {context.t("Loading...")} </p>
      )}
    </div>
  );
}

HowHearIssara.contextTypes = {
  t: PropTypes.func.isRequired,
};
