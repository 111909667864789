import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import Utils from "../../services/utils";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import C3Chart from "react-c3js";
import "c3/c3.css";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
const customStyles = {
  ...styles,
  largeStatContainer: {
    paddingTop: "6.3rem",
    paddingBottom: "6.7rem",
    margin: "10px 0 0 0",
  },
  largeStatContainer2: {
    paddingTop: "6.3rem",
    paddingBottom: "7.3rem",
    margin: "10px 0 0 0",
  },
  largeStatNumber: {
    textAlign: "center",
    fontSize: "3.5rem",
    fontWeight: 500,
    minHeight: "2.5rem",
  },
  largeStatName: {
    textAlign: "center",
    fontSize: "1.3rem",
    fontWeight: 400,
  },
  smallStatContainer: {
    paddingTop: "1.8rem",
    paddingBottom: "1rem",
    margin: "0 0 1rem 0",
  },
  smallStatNumber: {
    textAlign: "center",
    fontSize: "2rem",
    fontWeight: 600,
    minHeight: "1.65rem",
  },
  smallStatName: {
    textAlign: "center",
    fontSize: "1.1rem",
    fontWeight: 400,
  },
  outreachTable: {
    padding: "0.7rem 1rem 0.7rem 1rem",
    margin: "10px 0 1rem 0",
  },
  marginTopZero: {
    marginTop: 0,
  },
  marginBottom: {
    marginBottom: "1rem",
  },
  center: {
    textAlign: "center",
  },
  marginAuto: {
    margin: "auto",
  },
  gaugeBox: {
    minHeight: "10rem",
    margin: "auto",
  },

  gaugeTableText: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  gaugeContainer: {
    textAlign: "center",
    width: "200px",
    height: "200px",
  },
  button: {
    padding: 0,
    margin: 0,
  },
  chartcontainerholder: {
    minWidth: "850px",
  },
  galleryImg: {
    width: "30px",
    height: "18px",
    maxWidth: "30px",
    maxHeight: "20px",
    paddingRight: "5px",
    paddingBottom: "4px",
  },
  galleryContainer: {},
  galleryContainerBigger: {
    width: "100px",
    height: "100px",
  },
  cardCategory: {
    padding: "20px",
  },
};

const useStyles = makeStyles(customStyles);
export default function CallsAndMessagesFromWorkersChart(props, context) {
  const classes = useStyles();
  const totalWorkerCalls = props.totalWorkerCalls;

  return (
    <div>
      <h3 className={classes.center}>
        {context.t("Calls and Messages from Workers")}
      </h3>

      <GridContainer>
        <GridItem xs={12}>
          <Card className={classes.chartcontainerholder}>
            <CardBody>
              <C3Chart
                key={Utils.giveMeGuid()}
                data={{
                  x: "x",
                  columns: [totalWorkerCalls.x, totalWorkerCalls.y],
                  types: {
                    "Worker Calls and Messages By Month": "area-spline",
                  },
                }}
                grid={{
                  x: {
                    show: true,
                    tick: {
                      fit: true,
                      show: false,
                      rotate: -75,
                      multiline: false,
                    },
                  },
                  y: {
                    show: true,
                  },
                }}
                legend={{
                  position: "bottom",
                }}
                point={{
                  show: false,
                }}
                axis={{
                  x: {
                    type: "timeseries",
                    tick: {
                      format: "%Y-%m",
                    },
                  },
                }}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

CallsAndMessagesFromWorkersChart.contextTypes = {
  t: PropTypes.func.isRequired,
};
