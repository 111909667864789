import React from 'react'
import Chart from 'react-apexcharts'

function PieChart(props) {
    const [progressIndicator, setProgressIndicator] = React.useState()
    const [actualData, setActualData] = React.useState({
        actual_tier1: undefined,
        actual_closed_under60: undefined
    })
    React.useEffect(() => {

        if (props.data !== undefined) {
            setProgressIndicator(props.data.progress_indicator)
        }
        if (props.actualData !== undefined) {
            setActualData({
                actual_tier1: props.actualData.actual_tier1,
                actual_closed_under60: props.actualData.actual_closed_under60
            })
        }
    }, [props.data, props.actualData])

    const options = {
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false
        },
        colors: ['#148696'],
        labels: ["Loading..."],

    }
    var series = [0]

    // set values on chart
    if (props.name == "w1-target") {
        if (props.data.progress_indicator != undefined) {
            for (let i = 0; i < props.data.progress_indicator.length; i++) {
                if (props.data.progress_indicator[i].str_value === "Tier 1 suppliers") {
                    while (series.length > 0) {
                        series.pop();
                    }
                    while (options.labels.length > 0) {
                        options.labels.pop();
                    }
                    series.push(props.data.progress_indicator[i].numeric_value)
                    options.labels.push("Target")
                }
            }
        }
    }
    if (props.name == "w1-actual" && props.actualData.actual_tier1 !== undefined) {
        while (series.length > 0) {
            series.pop();
        }
        while (options.labels.length > 0) {
            options.labels.pop();
        }
        options.labels.push("Actual")
        series.push(props.actualData.actual_tier1)
    }

    ////////////////////////////////////////////////////////////////////////////

    if (props.name == "Average supplier performance scores-actual" && actualData.actual_closed_under60 !== undefined) {
        while (series.length > 0) {
            series.pop();
        }
        while (options.labels.length > 0) {
            options.labels.pop();
        }
        options.labels.push("Actual")
        series.push(actualData.actual_closed_under60)
    }
    if (props.name == "Average supplier performance scores-target") {
        if (props.data.progress_indicator != undefined) {
            for (let i = 0; i < props.data.progress_indicator.length; i++) {
                if (props.data.progress_indicator[i].str_value === "Worker reported issues") {
                    while (series.length > 0) {
                        series.pop();
                    }
                    while (options.labels.length > 0) {
                        options.labels.pop();
                    }
                    series.push(props.data.progress_indicator[i].numeric_value)
                    options.labels.push("Target")
                }
            }
        }
    }
    /////////////////////////////////////////////////////////////////////////////////
    if (props.name == "w1-actual upstream") {
        while (series.length > 0) {
            series.pop();
        }
        while (options.labels.length > 0) {
            options.labels.pop();
        }
        options.labels.push("Actual")
        // series.push(50)
        series.push(props.actualData.actual_tier2)
    }
    if (props.name == "w1-target upstream") {
        if (progressIndicator != undefined) {
            for (let i = 0; i < progressIndicator.length; i++) {
                if (progressIndicator[i].str_value === "Upstream suppliers") {
                    while (series.length > 0) {
                        series.pop();
                    }
                    while (options.labels.length > 0) {
                        options.labels.pop();
                    }
                    series.push(progressIndicator[i].numeric_value)
                    options.labels.push("Target")
                }
            }
        }
    }
    return (
        <>
            <Chart options={options} series={series} type="radialBar" />
        </>
    )
}

export default PieChart;
