import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// date picker required modules
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Utils from "services/utils";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import SupplierReportDialog from "./SupplierReportDialog";
import SelectSupplierDialog from "./SelectSupplierDialog";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import ButtonBar from "components/ButtonBarTwo/ButtonBar.js";
import { Select, FormControl, MenuItem } from "@material-ui/core";
import CountryProvincesDistrictsDropdown from "components/ilmdb/CountryProvincesDistrictsDropdown.js";

import IndustriesSubIndustriesDropdown from "components/ilmdb/IndustriesSubIndustriesDropdown.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { makeStyles } from "@material-ui/core/styles";

import loginStore from "../../redux/stores/LoginStore";
import {
  fetchSuppliers,
  createSupplier,
  updateSupplier,
} from "../../redux/actions/SupplierActions";
import {
  createSupplierReport,
  deleteSupplierReport,
} from "../../redux/actions/SupplierReportActions";
import {
  getSupplierProgress,
  updateSupplierProgress,
} from "../../redux/actions/ProgressDashboardActions";
import { fetchRecruiters } from "../../redux/actions/RecruiterActions";

import moment from "moment";
import RecruitersDropdown from "components/ilmdb/RecruitersDropdown.js";

const dateFormat = "MM/dd/yyyy";

const customStyle = {
  ...styles,
  ...sweetAlertStyles,
  label: {
    color: "black",
    fontSize: "14px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.42857,
  },
  activitiesContainer: {
    border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: "4px",
    marginTop: "30px",
    paddingBottom: "15px",
    fontSize: "16px",
    boxShadow: "1px 1px rgba(0,0,0,0.3)",
  },
  input: {
    padding: "5px",
  },
};

const useStyles = makeStyles(customStyle);

export default function SupplierForm(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [rps, setRps] = useState([]);
  const recruiters = useSelector((state) => state.recruitersReducer.items);
  const suppliers = useSelector((state) => state.suppliersReducer.items);
  const savingSupplier = useSelector(
    (state) => state.suppliersReducer.savingSupplier
  );
  const reports = useSelector(
    (state) => state.supplierReportUploadReducer.items
  );
  const progress = useSelector((state) => state.progressDashboardReducer);
  const [progressArray, setProgressArray] = useState([]);
  const [supplier, setSupplier] = useState({});
  const initialPayload = {
    name: "",
    country: undefined,
    address: "",
    zipcode: "",
    gps: "",
    tier_id: undefined,
    vessel_number: "",
    fishing_gear_liscense_number: "",
    is_worker_voice_rolled_out: false,
    contact_name: "",
    contact_phone: "",
    contact_email: "",
    name_harvesting_business: "",
    num_vessels_sourced_from: 0,
    total_num_workers: 0,
    total_num_men_workers: 0,
    total_num_women_workers: 0,
    total_num_thai_workers: 0,
    total_num_cambodian_workers: 0,
    total_num_myanmar_workers: 0,
    total_num_lao_workers: 0,
    total_num_vietnamese_workers: 0,
    total_num_nepalese_workers: 0,
    total_num_bangladeshi_workers: 0,
    total_num_indonesian_workers: 0,
    total_num_malaysian_workers: 0,
    total_num_indian_workers: 0,
    total_num_sri_lankan_workers: 0,
    total_num_pakistani_workers: 0,
    hiring_practice: "",
    other: "",
    vessel_type: undefined,
    industries: [],
    subindustries: [],
    lng: undefined,
    lat: undefined,
    description: null,
    worker_voice: false,
    is_worker_voice_rolled_out: false,
    country: undefined,
    province: undefined,
    district: undefined,
    num_of_workers_access_to_issara_wv_channels: 0,
    date_of_most_recent_recruitment_survey: null,
    date_of_most_recent_worker_satisfaction_survey: null,
    num_workers_reimbursed_recruitment_fees: 0,
    remain_num_workers_owed_recruitment_fee_reimbursement: 0,
    total_amt_recruitment_fee_reimbursed_to_workers: 0,
    remain_amt_owed_recruitment_fee_reimbursement_to_workers: 0,
    reports: [],
    recruiters_with_status: null,
    recruiter: [],
  };

  const [payload, setPayload] = useState(initialPayload);

  let fetch = false;

  useEffect(() => {
    if (Array.isArray(reports)) {
      setRps([...reports]);
    }
  }, [reports]);

  useEffect(() => {
    if (fetch === false) {
      fetch = true;
      dispatch(fetchRecruiters());
    }
  }, []);

  useEffect(() => {
    if (progress.fetched) {
      let progressArr = [];
      Object.keys(progress.items).map(function(key) {
        progressArr.push(progress.items[key]);
      });
      setProgressArray(progressArr);
    }
  }, [progress]);

  const handleEnableEditClick = (e) => {
    setSupplierDialog(true);
  };

  const handleCancelEditModeClick = (e) => {
    setSupplierId(null);
    setPayload(initialPayload);
    setSelectRecruiters(null);
    setRps([]);
    props.history.push("/admin/edit-supplier");
  };

  const [alert, setAlert] = useState(null);
  const [dialog, setDialog] = useState(false);
  const [supplierDialog, setSupplierDialog] = useState(false);
  const [supplierId, setSupplierId] = useState(null);
  const [selectedRecruiter, setSelectRecruiters] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const id = props.location
    ? new URLSearchParams(props.location.search).get("id")
    : props.supplierId
    ? props.supplierId
    : supplierId; // id from query string of edited strategic partner
  useEffect(() => {
    if (suppliers === null || suppliers === undefined || suppliers.length < 1) {
      dispatch(fetchSuppliers());
    }
  }, []);

  const deleteReportSuccess = (report_id) => {
    let copiedReports = [...rps];
    let removedReport = copiedReports.filter(
      (report) => report.id != report_id
    );
    setRps([...removedReport]);
  };

  const handleDeleteReportClick = (report_id) => {
    if (id && report_id) {
      dispatch(
        deleteSupplierReport(id, { report_id: report_id }),
        deleteReportSuccess(report_id),
        errorAlert
      );
    }
  };

  useEffect(() => {
    if (suppliers.length > 0 && id) {
      dispatch(getSupplierProgress(id));
      const supplier = suppliers.filter((item) => {
        return item.id == id;
      })[0];
      if (supplier) {
        setSupplier(supplier);
        let recruiterIds = null;
        if (
          Array.isArray(supplier.recruiter) &&
          supplier.recruiter.length > 0
        ) {
          recruiterIds = supplier.recruiter.map((rc) => {
            return rc.recruiter;
          });
          if (recruiterIds) {
            let selectedRecruiters = [];
            const selectedRecruiter = recruiterIds.map((id) => {
              selectedRecruiters.push(
                recruiters.filter((recruiter) => {
                  return recruiter.id == id;
                })[0]
              );
            });
            if (selectedRecruiter) {
              setSelectRecruiters(selectedRecruiters);
            }
          }
        }
        setSelectedStatus(supplier.recruiter);
        setPayload({
          ...payload,
          recruiters_with_status: recruiterIds,
        });
        setRps(supplier.reports);
      }
    }
  }, [suppliers, supplierId]);

  useEffect(() => {
    if (supplier) {
      setPayload({
        ...payload,
        name: supplier.name || "",
        address: supplier.address || "",
        zipcode: supplier.zipcode,
        gps: supplier.gps,
        tier_id: supplier.tier_id,
        vessel_number: supplier.vessel_number,
        fishing_gear_liscense_number: supplier.fishing_gear_liscense_number,
        contact_name: supplier.contact_name,
        contact_phone: supplier.contact_phone,
        contact_email: supplier.contact_email,
        name_harvesting_business: supplier.name_harvesting_business,
        num_vessels_sourced_from: supplier.num_vessels_sourced_from,
        total_num_workers: supplier.total_num_workers,
        total_num_men_workers: supplier.total_num_men_workers,
        total_num_women_workers: supplier.total_num_women_workers,
        total_num_thai_workers: supplier.total_num_thai_workers,
        total_num_cambodian_workers: supplier.total_num_cambodian_workers,
        total_num_myanmar_workers: supplier.total_num_myanmar_workers,
        total_num_lao_workers: supplier.total_num_lao_workers,
        total_num_vietnamese_workers: supplier.total_num_vietnamese_workers,
        total_num_nepalese_workers: supplier.total_num_nepalese_workers,
        total_num_bangladeshi_workers: supplier.total_num_bangladeshi_workers,
        total_num_indonesian_workers: supplier.total_num_indonesian_workers,
        total_num_malaysian_workers: supplier.total_num_malaysian_workers,
        total_num_indian_workers: supplier.total_num_indian_workers,
        total_num_sri_lankan_workers: supplier.total_num_sri_lankan_workers,
        total_num_pakistani_workers: supplier.total_num_pakistani_workers,
        golden_dreams_employer_id: supplier.golden_dreams_employer_id,
        hiring_practice: supplier.hiring_practice,
        other: supplier.other,
        vessel_type: supplier.vessel_type,
        worker_voice: supplier.worker_voice,
        is_worker_voice_rolled_out: supplier.is_worker_voice_rolled_out,
        industries: supplier.industries,
        subindustries: supplier.subindustries,
        country: supplier.country || null,
        province: supplier.province || null,
        district: supplier.district || null,
        website: supplier.website || null,
        description: supplier.description || null,
        lng: supplier.lng,
        lat: supplier.lat,
        num_of_workers_access_to_issara_wv_channels:
          supplier.num_of_workers_access_to_issara_wv_channels,
        date_of_most_recent_recruitment_survey:
          supplier.date_of_most_recent_recruitment_survey,
        date_of_most_recent_worker_satisfaction_survey:
          supplier.date_of_most_recent_worker_satisfaction_survey,
        num_workers_reimbursed_recruitment_fees:
          supplier.num_workers_reimbursed_recruitment_fees,
        remain_num_workers_owed_recruitment_fee_reimbursement:
          supplier.remain_num_workers_owed_recruitment_fee_reimbursement,
        total_amt_recruitment_fee_reimbursed_to_workers:
          supplier.total_amt_recruitment_fee_reimbursed_to_workers,
        remain_amt_owed_recruitment_fee_reimbursement_to_workers:
          supplier.remain_amt_owed_recruitment_fee_reimbursement_to_workers,
        reports: supplier.reports,
        recruiter: supplier.recruiter,
      });
    }
  }, [supplier]);

  const handleAddReportClick = (e) => {
    setDialog(true);
  };

  const handleCloseReportClick = (e) => {
    setDialog(false);
    setSupplierDialog(false);
  };

  const successUpload = (dialog) => {
    setDialog(dialog);
  };

  const handleUploadClick = (report) => {
    if (id && report) {
      const formData = new FormData();

      Object.keys(report).forEach((key) => {
        formData.append(key, report[key]);
      });

      dispatch(
        createSupplierReport(id, formData, successUpload(false), errorAlert)
      );
    }
  };

  const handleConfirmSuccessAlert = () => {
    if (props.handleConfirmSuccessAlert) {
      props.handleConfirmSuccessAlert();
    } else {
      props.history.push("/admin/suppliers-list");
    }
  };

  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        onConfirm={handleConfirmSuccessAlert}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title="Well done!"
      >
        {id ? "Supplier was updated" : "Supplier has been successfully created"}
      </SweetAlert>
    );

    dispatch(fetchSuppliers());
  };

  const errorAlert = (error) => {
    console.log("ERRROR", error);
    setAlert(
      <SweetAlert
        danger
        onConfirm={() => setAlert(null)}
        confirmBtnCssClass={classes.button + " " + classes.success}
        title="Error"
      >
        {error.message}
      </SweetAlert>
    );
  };

  const onSubmit = () => {
    console.log("Here is payload", payload);
    console.log(
      "Here is industry and subindustry,",
      payload.industries,
      payload.subindustries
    );
    // Create a copy of the payload object
    let modifiedPayload = { ...payload };

    if (modifiedPayload.num_vessels_sourced_from === "") {
      modifiedPayload.num_vessels_sourced_from = 0;
    }

    if (
      modifiedPayload.remain_num_workers_owed_recruitment_fee_reimbursement ===
      ""
    ) {
      modifiedPayload.remain_num_workers_owed_recruitment_fee_reimbursement = 0;
    }

    if (modifiedPayload.num_of_workers_access_to_issara_wv_channels === "") {
      modifiedPayload.num_of_workers_access_to_issara_wv_channels = 0;
    }

    if (modifiedPayload.num_workers_reimbursed_recruitment_fees === "") {
      modifiedPayload.num_workers_reimbursed_recruitment_fees = 0;
    }

    if (modifiedPayload.golden_dreams_employer_id === "") {
      modifiedPayload.golden_dreams_employer_id = 0;
    }

    if (modifiedPayload.total_num_workers === "") {
      modifiedPayload.total_num_workers = 0;
    }

    if (modifiedPayload.total_num_men_workers === "") {
      modifiedPayload.total_num_men_workers = 0;
    }

    if (modifiedPayload.total_num_women_workers === "") {
      modifiedPayload.total_num_women_workers = 0;
    }

    if (modifiedPayload.total_num_thai_workers === "") {
      modifiedPayload.total_num_thai_workers = 0;
    }

    if (modifiedPayload.total_num_cambodian_workers === "") {
      modifiedPayload.total_num_cambodian_workers = 0;
    }

    if (modifiedPayload.total_num_myanmar_workers === "") {
      modifiedPayload.total_num_myanmar_workers = 0;
    }

    if (modifiedPayload.total_num_lao_workers === "") {
      modifiedPayload.total_num_lao_workers = 0;
    }

    if (modifiedPayload.total_num_vietnamese_workers === "") {
      modifiedPayload.total_num_vietnamese_workers = 0;
    }

    if (modifiedPayload.total_num_nepalese_workers === "") {
      modifiedPayload.total_num_nepalese_workers = 0;
    }

    if (modifiedPayload.total_num_bangladeshi_workers === "") {
      modifiedPayload.total_num_bangladeshi_workers = 0;
    }

    if (modifiedPayload.total_num_indonesian_workers === "") {
      modifiedPayload.total_num_indonesian_workers = 0;
    }

    if (modifiedPayload.total_num_malaysian_workers === "") {
      modifiedPayload.total_num_malaysian_workers = 0;
    }

    if (modifiedPayload.total_num_indian_workers === "") {
      modifiedPayload.total_num_indian_workers = 0;
    }

    if (modifiedPayload.total_num_sri_lankan_workers === "") {
      modifiedPayload.total_num_sri_lankan_workers = 0;
    }

    if (modifiedPayload.total_num_pakistani_workers === "") {
      modifiedPayload.total_num_pakistani_workers = 0;
    }

    if (modifiedPayload.num_vessels_sourced_from === "") {
      modifiedPayload.num_vessels_sourced_from = 0;
    }

    if (id) {
      dispatch(updateSupplier(id, modifiedPayload, successAlert, errorAlert));
    } else {
      dispatch(createSupplier(modifiedPayload, successAlert, errorAlert));
    }
  };

  const handleSelectedSupplier = (selected) => {
    setPayload(initialPayload);
    setSelectRecruiters(null);
    setRps([]);
    setSupplierDialog(false);
    setSupplierId(selected);

    props.history.push(`/admin/edit-supplier?id=${selected}`);
  };

  let spacer = (
    <GridContainer>
      <GridItem>
        <br />
      </GridItem>
    </GridContainer>
  );

  function findSelected(recruiterId, selectedStatus) {
    let recruiter = null;
    if (selectedStatus != null) {
      recruiter = selectedStatus.find((item) => {
        return item.recruiter == recruiterId;
      });
    }

    return recruiter ? recruiter.status : "active";
  }

  const handleRecruiterDropDownSelect = (trecruiters) => {
    let selectedRecruiters = [];
    trecruiters.map((id) => {
      selectedRecruiters.push(
        recruiters.filter((item) => {
          return item.id == id;
        })[0]
      );
    });
    setSelectRecruiters(selectedRecruiters);

    let recruiterStatus = [];
    trecruiters.map((recruiter) => {
      recruiterStatus.push({
        recruiter: recruiter,
        status: "active",
      });
    });
    setPayload({
      ...payload,
      recruiter: recruiterStatus,
      recruiters_with_status: trecruiters,
    });
  };

  function findSelected(recruiterId, selectedStatus) {
    let recruiter = null;
    if (selectedStatus != null) {
      recruiter = selectedStatus.find((item) => {
        return item.recruiter == recruiterId;
      });
    }

    return recruiter ? recruiter.status : "active";
  }

  const handleSelectStatus = (e) => {
    // updating array immutably
    var recruiter_with_status = payload.recruiter;
    console.log(recruiter_with_status);
    const recruiterIndex = recruiter_with_status.findIndex((item) => {
      return item.recruiter == e.target.name;
    });

    recruiter_with_status[recruiterIndex].status = e.target.value;

    setPayload({
      ...payload,
      recruiter: recruiter_with_status,
    });
  };

  const statuses = [
    { status: "Active", value: "active" },
    { status: "Inactive", value: "inactive" },
  ];

  if (savingSupplier === true) {
    return <CircularProgress />;
  }

  return id &&
    (suppliers === undefined ||
      suppliers === null ||
      suppliers.length === undefined ||
      suppliers.length < 1) ? (
    <CircularProgress />
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <GridContainer>
        <SupplierReportDialog
          open={dialog}
          closeClick={handleCloseReportClick}
          uploadClick={handleUploadClick}
        />
        <SelectSupplierDialog
          open={supplierDialog}
          closeClick={handleCloseReportClick}
          suppliers={suppliers}
          selectedSupplier={(selected) => handleSelectedSupplier(selected)}
        />
        {alert}
        <GridItem xs={12}>
          <Card style={{ marginTop: 0 }}>
            <CardHeader color="rose" icon>
              <Button
                size="sm"
                variant="outlined"
                color="primary"
                style={{ float: "right", backgroundColor: "#00ACC1" }}
                startIcon={<EditIcon />}
                onClick={(e) => handleEnableEditClick(e)}
              >
                {id ? "Edit Another Supplier" : "Enable Edit Mode"}
              </Button>
              {id ? (
                <Button
                  size="sm"
                  variant="outlined"
                  color="secondary"
                  style={{
                    float: "right",
                    backgroundColor: "rgb(244, 14, 72)",
                  }}
                  startIcon={<CancelIcon />}
                  onClick={(e) => handleCancelEditModeClick(e)}
                >
                  Cancel Edit Mode
                </Button>
              ) : (
                ""
              )}
              {id ? (
                <h4 className={classes.cardIconTitle}>Edit Supplier</h4>
              ) : (
                <h4 className={classes.cardIconTitle}>Add Supplier</h4>
              )}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    id="Name"
                    labelText="Name of Supplier"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          name: e.target.value,
                        }),
                      disabled: !loginStore.isIssaraManagement(),
                    }}
                    value={payload.name}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    id="website"
                    labelText="Website of Supplier"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          website: e.target.value,
                        }),
                    }}
                    value={payload.website}
                  />
                </GridItem>
                <GridItem xs={9}>
                  <CustomInput
                    id="adress"
                    labelText="Address"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          address: e.target.value,
                        }),
                    }}
                    value={payload.address}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <CustomInput
                    id="zipcode"
                    labelText="Zipcode"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          zipcode: e.target.value,
                        }),
                    }}
                    value={payload.zipcode}
                  />
                </GridItem>
                <GridItem xs={3}>
                  <CustomInput
                    id="lat"
                    labelText="lat"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          lat: e.target.value,
                        }),
                    }}
                    value={payload.lat}
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="lng"
                    labelText="lng"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          lng: e.target.value,
                        }),
                    }}
                    value={payload.lng}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <CustomInput
                    id="description"
                    labelText="Description"
                    formControlProps={{ fullWidth: true }}
                    isTextArea={true}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          description: e.target.value,
                        }),
                    }}
                    value={payload.description}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <IndustriesSubIndustriesDropdown
                    multiple={true}
                    key={Utils.giveMeGuid()}
                    industry_values={payload.industries}
                    subindustry_values={payload.subindustries}
                    onSelect={(e) => {
                      console.log("Here are industries", e.industry_id);
                      console.log("Here is subindustries", e.subindustry_id);
                      setPayload({
                        ...payload,
                        industries: e.industry_id,
                        subindustries: e.subindustry_id,
                      });
                    }}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <CountryProvincesDistrictsDropdown
                    key={Utils.giveMeGuid()}
                    country_values={
                      payload.country ? { id: payload.country } : undefined
                    }
                    province_values={
                      payload.province ? { id: payload.province } : undefined
                    }
                    district_values={
                      payload.district ? { id: payload.district } : undefined
                    }
                    onSelect={(e) => {
                      console.log("Supplier Selected:", e);
                      setPayload({
                        ...payload,
                        country: e.country_id,
                        province: e.province_id,
                        district: e.district_id,
                      });
                    }}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    id="contact_name"
                    labelText="Contact Name"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          contact_name: e.target.value,
                        }),
                    }}
                    value={payload.contact_name}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <CustomInput
                    id="contact_phone"
                    labelText="Contact Phone"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          contact_phone: e.target.value,
                        }),
                    }}
                    value={payload.contact_phone}
                  />
                </GridItem>

                <GridItem xs={12}>
                  <CustomInput
                    id="contact_email"
                    labelText="Contact Email"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          contact_email: e.target.value,
                        }),
                    }}
                    value={payload.contact_email}
                  />
                </GridItem>
              </GridContainer>

              <GridContainer>
                {/*
 <GridItem xs={6}>
                  <CustomInput
                    id="tier_id"
                    labelText="Tier Id"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      className: classes.input,
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          tier_id: value
                        })
                      }
                    }}
                    value={`${parseInt(payload.tier_id)}` || undefined}
                  />
                </GridItem>

                <GridItem xs={6} >
                  <CustomInput
                    id="gps"
                    labelText="GPS"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: e => setPayload({
                        ...payload,
                        gps: e.target.value
                      })
                    }}
                    value={payload.gps}
                  />
                </GridItem>

                 <GridItem xs={6} >
                  <CustomInput
                    id="fishing_gear_liscense_number"
                    labelText="Fishing Gear Liscense Number"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: e => setPayload({
                        ...payload,
                        fishing_gear_liscense_number: e.target.value
                      })
                    }}
                    value={payload.fishing_gear_liscense_number}
                  />
                </GridItem>

                  */}
              </GridContainer>

              {spacer}

              <GridContainer>
                <GridItem xs={6}>
                  <CustomInput
                    id="vessel_number"
                    labelText="Vessel Number"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          vessel_number: e.target.value,
                        }),
                    }}
                    value={payload.vessel_number}
                  />
                </GridItem>
              </GridContainer>

              {spacer}

              <GridItem xs={12}>
                {payload.worker_voice ? (
                  <div style={{ color: "green" }}>Yes</div>
                ) : (
                  <div style={{ color: "red" }}>No</div>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      id="worker_voice"
                      checked={payload.worker_voice}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          worker_voice: e.target.checked,
                        })
                      }
                      name="worker_voice"
                      color="primary"
                    />
                  }
                  label="Is there worker voice in this workplace?"
                />
              </GridItem>

              <GridItem xs={12}>
                {payload.is_worker_voice_rolled_out ? (
                  <div style={{ color: "green" }}>Yes</div>
                ) : (
                  <div style={{ color: "red" }}>No</div>
                )}
                <FormControlLabel
                  control={
                    <Switch
                      id="is_worker_voice_rolled_out"
                      checked={payload.is_worker_voice_rolled_out}
                      onChange={(e) =>
                        setPayload({
                          ...payload,
                          is_worker_voice_rolled_out: e.target.checked,
                        })
                      }
                      name="is_worker_voice_rolled_out"
                      color="primary"
                    />
                  }
                  label="Has engagement with the supplier been initiated and have the worker voice channels been introduced?"
                />
              </GridItem>

              <GridContainer>
                <GridItem xs={12}>
                  <CustomInput
                    id="hiring_practice"
                    labelText="Hiring Practice"
                    isTextArea={true}
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          hiring_practice: e.target.value,
                        }),
                    }}
                    value={payload.hiring_practice}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Card style={{ marginTop: 0 }}>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12}>
                          <RecruitersDropdown
                            value={payload.recruiters_with_status}
                            onSelect={(trecruiters) =>
                              handleRecruiterDropDownSelect(trecruiters)
                            }
                            multipleselect={true}
                          />
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                  {Array.isArray(selectedRecruiter) &&
                    selectedRecruiter.length > 0 &&
                    selectedRecruiter.map((recruiter) => {
                      return (
                        <FormControl fullWidth>
                          <h4>{recruiter.name}</h4>
                          <Select
                            defaultValue={findSelected(
                              recruiter.id,
                              selectedStatus
                            )}
                            name={recruiter.id}
                            onChange={handleSelectStatus}
                          >
                            {statuses.map((option) => {
                              return (
                                <MenuItem
                                  recruiter-id={recruiter.id}
                                  value={option.value}
                                  primaryText={option.status}
                                >
                                  {option.status}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <hr />
                        </FormControl>
                      );
                    })}
                </GridItem>
                <GridItem xs={12}>
                  <CustomInput
                    id="other"
                    labelText="Other"
                    formControlProps={{ fullWidth: true }}
                    isTextArea={true}
                    inputProps={{
                      type: "text",
                      onChange: (e) =>
                        setPayload({
                          ...payload,
                          other: e.target.value,
                        }),
                    }}
                    value={payload.other}
                  />
                </GridItem>
              </GridContainer>

              {spacer}

              <GridContainer>
                <GridItem xs={3}>
                  <CustomInput
                    id="num_vessels_sourced_from"
                    labelText="Source vesseled Numbers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          num_vessels_sourced_from: value,
                        });
                      },
                    }}
                    value={
                      `${parseInt(payload.num_vessels_sourced_from)}` ||
                      undefined
                    }
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_workers"
                    labelText="Total Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_workers: value,
                        });
                      },
                    }}
                    value={`${parseInt(payload.total_num_workers)}` || 0}
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_men_workers"
                    labelText="Total Men Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_men_workers: value,
                        });
                      },
                    }}
                    value={`${parseInt(payload.total_num_men_workers)}` || 0}
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_women_workers"
                    labelText="Total Women Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_women_workers: value,
                        });
                      },
                    }}
                    value={`${parseInt(payload.total_num_women_workers)}` || 0}
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_thai_workers"
                    labelText="Total Thai Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_thai_workers: value,
                        });
                      },
                    }}
                    value={`${parseInt(payload.total_num_thai_workers)}` || 0}
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_cambodian_workers"
                    labelText="Total Cambodian Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_cambodian_workers: value,
                        });
                      },
                    }}
                    value={
                      `${parseInt(payload.total_num_cambodian_workers)}` || 0
                    }
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_myanmar_workers"
                    labelText="Total Myanmar Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_myanmar_workers: value,
                        });
                      },
                    }}
                    value={
                      `${parseInt(payload.total_num_myanmar_workers)}` || 0
                    }
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_nepalese_workers"
                    labelText="Total Nepalese Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_nepalese_workers: value,
                        });
                      },
                    }}
                    value={
                      `${parseInt(payload.total_num_nepalese_workers)}` || 0
                    }
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_bangladeshi_workers"
                    labelText="Total Bangladeshi Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_bangladeshi_workers: value,
                        });
                      },
                    }}
                    value={
                      `${parseInt(payload.total_num_bangladeshi_workers)}` || 0
                    }
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_indonesian_workers"
                    labelText="Total Indonesian Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_indonesian_workers: value,
                        });
                      },
                    }}
                    value={
                      `${parseInt(payload.total_num_indonesian_workers)}` || 0
                    }
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_malaysian_workers"
                    labelText="Total Malaysian Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_malaysian_workers: value,
                        });
                      },
                    }}
                    value={
                      `${parseInt(payload.total_num_malaysian_workers)}` || 0
                    }
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_indian_workers"
                    labelText="Total Indian Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_indian_workers: value,
                        });
                      },
                    }}
                    value={`${parseInt(payload.total_num_indian_workers)}` || 0}
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_pakistani_workers"
                    labelText="Total Pakistani Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_pakistani_workers: value,
                        });
                      },
                    }}
                    value={
                      `${parseInt(payload.total_num_pakistani_workers)}` || 0
                    }
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_sri_lankan_workers"
                    labelText="Total Sri Lankan Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_sri_lankan_workers: value,
                        });
                      },
                    }}
                    value={
                      `${parseInt(payload.total_num_sri_lankan_workers)}` || 0
                    }
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_lao_workers"
                    labelText="Total Lao Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_lao_workers: value,
                        });
                      },
                    }}
                    value={`${parseInt(payload.total_num_lao_workers)}` || 0}
                  />
                </GridItem>

                <GridItem xs={3}>
                  <CustomInput
                    id="total_num_vietnamese_workers"
                    labelText="Total Vietnamese Workers"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                      onChange: (e) => {
                        let value = e.target.value;
                        if (value < 0) {
                          value = 0;
                        }
                        setPayload({
                          ...payload,
                          total_num_vietnamese_workers: value,
                        });
                      },
                    }}
                    value={
                      `${parseInt(payload.total_num_vietnamese_workers)}` || 0
                    }
                  />
                </GridItem>

                <GridItem xs={3}>
                  <div title="You get these values from the golden dreams tab">
                    <CustomInput
                      id="golden_dreams_employer_id"
                      labelText="Golden Dreams Employer Id"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        className: classes.input,
                        type: "number",
                        onChange: (e) => {
                          let value = e.target.value;
                          if (value < 0) {
                            value = 0;
                          }
                          setPayload({
                            ...payload,
                            golden_dreams_employer_id: value,
                          });
                        },
                      }}
                      value={
                        `${parseInt(payload.golden_dreams_employer_id)}` ||
                        undefined
                      }
                    />
                  </div>
                </GridItem>
                {id && (
                  <>
                    <hr />
                    {!progress.fetched && progress.fetching ? (
                      <GridContainer>
                        <GridItem xs={12}>Fetching....</GridItem>
                      </GridContainer>
                    ) : (
                      <GridContainer>
                        {Object.keys(progress.items).map(function(key) {
                          return (
                            <GridItem xs={12}>
                              <div value={key}>
                                <h4>{key}</h4>
                                <ol>
                                  {Array.isArray(progress.items[key]) &&
                                    progress.items[key].length > 0 &&
                                    progress.items[key].map((item) => {
                                      return (
                                        <li style={{ marginBottom: "7px" }}>
                                          {/* {item.label} */}
                                          <ButtonBar
                                            name={item.label}
                                            value={item.value}
                                            buttons={[
                                              {
                                                key: item.key,
                                                name: "Gray",
                                                value: "Gray",
                                              },
                                              {
                                                key: item.key,
                                                name: "Yellow",
                                                value: "Yellow",
                                              },
                                              {
                                                key: item.key,
                                                name: "Green",
                                                value: "Green",
                                              },
                                            ]}
                                            onClick={(key, name, value) => {
                                              dispatch(
                                                updateSupplierProgress(
                                                  id,
                                                  key,
                                                  value
                                                )
                                              );
                                            }}
                                          />
                                        </li>
                                      );
                                    })}
                                </ol>
                              </div>
                            </GridItem>
                          );
                        })}
                        <GridItem xs={12}>
                          <h4>
                            Gray = Not yet initiated;{" "}
                            <span
                              style={{
                                color: "yellow",
                                textShadow: "1px 1px black",
                              }}
                            >
                              Yellow
                            </span>{" "}
                            = Partial Progress;{" "}
                            <span style={{ color: "green" }}>Green</span> =
                            Completed/ongoing
                          </h4>
                        </GridItem>
                      </GridContainer>
                    )}
                  </>
                )}
                <GridContainer className={classes.activitiesContainer}>
                  <GridItem xs={12}>
                    <h4>ACTIVITIES & ACHIEVEMENTS SUMMARY</h4>
                  </GridItem>
                  <GridItem xs={7}>
                    1. Number of workers with access to Issara worker voice
                    channels
                  </GridItem>
                  <GridItem xs={5}>
                    <CustomInput
                      id="num_of_workers_access_to_issara_wv_channels"
                      labelText="Total"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        onChange: (e) => {
                          let value = e.target.value;
                          if (value < 0) {
                            value = 0;
                          }
                          setPayload({
                            ...payload,
                            num_of_workers_access_to_issara_wv_channels: value,
                          });
                        },
                      }}
                      value={
                        `${parseInt(
                          payload.num_of_workers_access_to_issara_wv_channels
                        )}` || undefined
                      }
                    />
                  </GridItem>
                  <GridItem xs={7}>
                    2. Date of most recent worker satisfaction survey
                  </GridItem>
                  <GridItem xs={5}>
                    <KeyboardDatePicker
                      autoOk={true}
                      disableToolbar
                      variant="inline"
                      format={dateFormat}
                      inputFormat={dateFormat}
                      margin="normal"
                      id="date-picker-inline"
                      label="Date of most recent worker satisfaction survey"
                      value={
                        payload.date_of_most_recent_worker_satisfaction_survey
                      }
                      onChange={(date) => {
                        date !== null
                          ? setPayload({
                              ...payload,
                              date_of_most_recent_worker_satisfaction_survey: moment(
                                date
                              ).format("MM/DD/YYYY"),
                            })
                          : setPayload({
                              ...payload,
                              date_of_most_recent_worker_satisfaction_survey: date,
                            });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={7}>
                    3. Date of most recent recruitment survey
                  </GridItem>
                  <GridItem xs={5}>
                    <KeyboardDatePicker
                      autoOk={true}
                      disableToolbar
                      variant="inline"
                      format={dateFormat}
                      inputFormat={dateFormat}
                      margin="normal"
                      id="date-picker-inline"
                      label="Date of most recent recruitment survey"
                      value={payload.date_of_most_recent_recruitment_survey}
                      onChange={(date) => {
                        date !== null
                          ? setPayload({
                              ...payload,
                              date_of_most_recent_recruitment_survey: moment(
                                date
                              ).format("MM/DD/YYYY"),
                            })
                          : setPayload({
                              ...payload,
                              date_of_most_recent_recruitment_survey: date,
                            });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </GridItem>
                  <GridItem xs={7}>
                    4. Number of workers reimbursed recruitment fees
                  </GridItem>
                  <GridItem xs={5}>
                    <CustomInput
                      id="num_workers_reimbursed_recruitment_fees"
                      labelText="Total"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        onChange: (e) => {
                          let value = e.target.value;
                          if (value < 0) {
                            value = 0;
                          }
                          setPayload({
                            ...payload,
                            num_workers_reimbursed_recruitment_fees: value,
                          });
                        },
                      }}
                      value={
                        `${parseInt(
                          payload.num_workers_reimbursed_recruitment_fees
                        )}` || undefined
                      }
                    />
                  </GridItem>
                  <GridItem xs={7}>
                    5. Remaining number of workers owed recruitment fee
                    reimbursement
                  </GridItem>
                  <GridItem xs={5}>
                    <CustomInput
                      id="remain_num_workers_owed_recruitment_fee_reimbursement"
                      labelText="Total"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        onChange: (e) => {
                          let value = e.target.value;
                          if (value < 0) {
                            value = 0;
                          }
                          setPayload({
                            ...payload,
                            remain_num_workers_owed_recruitment_fee_reimbursement: value,
                          });
                        },
                      }}
                      value={
                        `${parseInt(
                          payload.remain_num_workers_owed_recruitment_fee_reimbursement
                        )}` || undefined
                      }
                    />
                  </GridItem>
                  <GridItem xs={7}>
                    6. Total amount of recruitment fees reimbursed to workers
                    (US dollars)
                  </GridItem>
                  <GridItem xs={5}>
                    <CustomInput
                      id="total_amt_recruitment_fee_reimbursed_to_workers"
                      labelText="USD"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        onChange: (e) => {
                          let value = e.target.value;
                          if (value < 0) {
                            value = 0;
                          }
                          setPayload({
                            ...payload,
                            total_amt_recruitment_fee_reimbursed_to_workers: value,
                          });
                        },
                      }}
                      value={
                        `${parseInt(
                          payload.total_amt_recruitment_fee_reimbursed_to_workers
                        )}` || undefined
                      }
                    />
                  </GridItem>
                  <GridItem xs={7}>
                    7. Remaining amount owed, recruitment fee reimbursement to
                    workers (US dollars)
                  </GridItem>
                  <GridItem xs={5}>
                    <CustomInput
                      id="remain_amt_owed_recruitment_fee_reimbursement_to_workers"
                      labelText="USD"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number",
                        onChange: (e) => {
                          let value = e.target.value;
                          if (value < 0) {
                            value = 0;
                          }
                          setPayload({
                            ...payload,
                            remain_amt_owed_recruitment_fee_reimbursement_to_workers: value,
                          });
                        },
                      }}
                      value={
                        `${parseInt(
                          payload.remain_amt_owed_recruitment_fee_reimbursement_to_workers
                        )}` || undefined
                      }
                    />
                  </GridItem>
                </GridContainer>

                <GridItem xs={12}>
                  {rps.map((report, index) => {
                    return (
                      <div key={report.id + index} title={report.name}>
                        <h4>Report . ({index + 1})</h4>
                        <p>{report.name}</p>
                        <Tooltip
                          title="Click to delete the file"
                          placement="top-start"
                        >
                          <Button
                            onClick={handleDeleteReportClick.bind(
                              this,
                              report.id
                            )}
                            variant="contained"
                            style={{ backgroundColor: "brown" }}
                            className={classes.button}
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title="Click to see the file"
                          placement="top-start"
                        >
                          <Button
                            href={report.file_path}
                            target="_blank"
                            variant="contained"
                            style={{ backgroundColor: "#00acc1" }}
                            className={classes.button}
                            startIcon={<VisibilityIcon />}
                          >
                            View
                          </Button>
                        </Tooltip>
                      </div>
                    );
                  })}
                  {id ? (
                    <>
                      <hr />
                      <Tooltip title="Add Report" placement="top-start">
                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ backgroundColor: "#00ACC1" }}
                          className={classes.button}
                          startIcon={<AddCircleIcon />}
                          onClick={(e) => handleAddReportClick(e)}
                        >
                          Add Report
                        </Button>
                      </Tooltip>
                    </>
                  ) : (
                    ""
                  )}
                </GridItem>
              </GridContainer>
              <GridContainer justifyContent="flex-end">
                <GridItem>
                  <Button
                    color="success"
                    onClick={onSubmit}
                    disabled={savingSupplier}
                  >
                    Save
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </MuiPickersUtilsProvider>
  );
}
