import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function SupplierReportDialog(props) {
    const classes = useStyles();
    const [open, setOpen] = useState(props.open ?? false)

    const [payload, setPayload] = useState({
        file_name: '',
        file: '',
        description:''
    })

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const handleChangeFileName = e => {
        setPayload({
            ...payload,
            file_name: e.target.value
        })
    }

    const handleFileDescription = e => {
        setPayload({
            ...payload,
            description: e.target.value
        })
    }

    const handleChangeFile = e => {
        setPayload({
            ...payload,
            file: e.target.files[0]
        })
    }


    return (
        <div>
            <Dialog open={open} onClose={props.closeClick} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Report File Uploader</DialogTitle>
                <DialogContent className={classes.root}>
                    <DialogContentText>
                        Please enter the name of the report and select the file
                    </DialogContentText>
                    <TextField id="filled-basic"
                        name="file_name"
                        label="Enter File Name"
                        variant="outlined"
                        fullWidth
                        onChange={e => handleChangeFileName(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField id="filled-basic"
                        name="file_description"
                        label="Enter Description"
                        variant="outlined"
                        fullWidth
                        onChange={e => handleFileDescription(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        id="filled-basic-two"
                        label="Select File"
                        name="file"
                        type="file"
                        variant="outlined"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={e => handleChangeFile(e)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={props.closeClick} color="secondary">
                        Cancel
                    </Button>
                    <Button variant="contained" style={{ backgroundColor: '#00ACC1', color: "white" }}
                        disabled={payload.file && payload.file_name ? false : true}
                        onClick={props.uploadClick.bind(this, payload)}
                    >
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}