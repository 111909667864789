
import Tooltip from '@material-ui/core/Tooltip'; import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import ReactTable from "react-table-6";

import Pagination from "components/Pagination/Pagination2.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Datetime from "react-datetime";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import CustomInput from "components/CustomInput/CustomInput.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";
import VisibilityIcon from '@material-ui/icons/Visibility';

import GrievanceMechanismsModal from './GrievanceMechanismsModal';

import SuppliersDropdown from "components/ilmdb/SuppliersDropdown.js";

import { makeStyles, withStyles } from "@material-ui/core/styles";

import { fetchUsers } from "redux/actions/UsersActions.js";
import { fetchSuppliers } from "redux/actions/SupplierActions.js";

import ReactExport from "react-export-excel";
import Utils from "../../services/utils.js";


import loginStore from "../../redux/stores/LoginStore";
import { fetchGrievanceMechanisms } from 'redux/actions/GrievanceMechanismsActions';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const customStyle = {
  button: {
    padding: 0,
    margin: 0,
  }
}

const useStyles = makeStyles(customStyle);


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);


class DownloadGrievancemechanismList extends React.Component {
  constructor(props) {
    super();
    this.state = {
      dataset: props.dataset
    }
  }

  render() {
    return (
      <GridContainer>
        <GridItem>
          <ExcelFile filename={"Grievance_Mechanisms"} element={<Button>Download As XLS File</Button>}>
            <ExcelSheet data={this.state.dataset} name="business_responses">
              <ExcelColumn label="id" value="id" />
              <ExcelColumn label="supplier_names" value="supplier_names" />
              <ExcelColumn label="date_of_review" value="date_of_review" />
              <ExcelColumn label="assessment" value="assessment" />
              {/* <ExcelColumn label="created_at" value="created_at" /> */}
              <ExcelColumn label="created_by_name" value="created_by_name" />
            </ExcelSheet>
          </ExcelFile>
        </GridItem>

      </GridContainer>
    );
  }
}



export default function GrievanceMechanismsList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const grievanceMechanisms = useSelector(state => state.grievanceMechanismsReducer.items)
  const grievanceMechanismsFetching = useSelector(state => state.grievanceMechanismsReducer.fetchingGrievanceMechanisms)

  const users = useSelector(state => state.usersReducer.items)
  const fetchingUsers = useSelector(state => state.usersReducer.fetchingUsers)
  const suppliers = useSelector(state => state.suppliersReducer.items)
  const fetchingReducerSuppliers = useSelector(state => state.suppliersReducer.fetchingSuppliers)
  const currentUser = loginStore.getLoginUser();

  const strategicPartners = useSelector(state => state.strategicPartnerReducer.items)

  const [grievancemechanismArray, setGrievancemechanismArray] = useState([]);

  const [modal, setModal] = useState({
    open: false,
    id: null
  });

  // FILTERS
  const [filters, setFilters] = useState({
    suppliers: [],
    startTime: null,
    endTime: null,
    search: ""
  });

  const filterGrievancemechanisms = () => {

    const filteredGrievancemechanismsArray = []

    Object.keys(grievanceMechanisms).forEach(key => {
      const item = grievanceMechanisms[key]

      // Filter by supplier
      if (filters.suppliers.length > 0) {
        const suppliers_intersection = filters.suppliers.filter(x => item.supplier == x)
        if (suppliers_intersection.length === 0) {
          return // skip this business response as no suppliers matched
        }
      }

      // Filter by startTime
      if (filters.startTime) {
        if (new Date(item.date_of_review).getTime() < filters.startTime._d.getTime()) {
          return // skip this business response
        }
      }

      // Filter by endTime
      if (filters.endTime) {
        if (filters.endTime._d.getTime() < new Date(item.date_of_review).getTime()) {
          return // skip this business response
        }
      }

      item["supplier_names"] = (() => {
        let names = []
        if (suppliers.length !== 0) {
          const filteredSupplier = suppliers.find(element => {
            return element.id === item.supplier
          })
          names = filteredSupplier.name
        }

        return "" + names;
      })();

      item["created_by_name"] = ((value) => {
        const createdBy = users.find((element) => {
          return element.id === value
        });
        return createdBy ? createdBy.first_name + " " + createdBy.last_name + " (" + createdBy.email + ")" : '-';
      })(item["created_by"]);


      item["created_by_short_name"] = ((value) => {
        const createdBy = users.find((element) => {
          return element.id === value
        });
        return createdBy ? createdBy.first_name + " " + createdBy.last_name : '-';
      })(item["created_by"]);

      item['edit'] = editButton(item.id)
      item['view'] = viewButton(item.id)

      filteredGrievancemechanismsArray.push(item)
    });

    if (filters.search !== undefined && filters.search.length > 1) {
      let filteredSearchData = Utils.findStringInObjectFields(filteredGrievancemechanismsArray, filters.search, ["id",
        "supplier_names",
        "date_of_review",
        "description",
        "assessment",
        "created_by_name"
      ]);
      setGrievancemechanismArray(filteredSearchData);
    } else {
      setGrievancemechanismArray(filteredGrievancemechanismsArray)
    }
  }

  const redirectToEditGrievanceMechanisms = (id) => {
    props.history.push(`/admin/grievancemechanismsadding?id=${id}`)
  }

  const handleViewButtonClick = (id) => {
    setModal({
      open: true,
      id: id
    })
  }

  const editButton = (response_id) => {
    return (
      <Button
        title={"Edit: " + response_id}
        simple
        color="success"
        value={response_id}
        className={classes.button}
        onClick={(e) => redirectToEditGrievanceMechanisms(e.currentTarget.value)}
      >
        <Edit />
      </Button>
    )
  }

  const viewButton = (response_id) => {
    return (
      <Button
        title={"View: " + response_id}
        simple
        color="info"
        value={response_id}
        className={classes.button}
        onClick={(e) => handleViewButtonClick(e.currentTarget.value)}
      >
        <VisibilityIcon />
      </Button>
    )
  }


  useEffect(() => {
    if (suppliers === null || suppliers === undefined || suppliers.length < 1) {
      dispatch(fetchSuppliers());
    }
    dispatch(fetchUsers());
    dispatch(fetchGrievanceMechanisms())
  }, []);

  useEffect(() => {
    if (grievanceMechanisms && suppliers) {
      filterGrievancemechanisms()
    }
  }, [filters, suppliers, grievanceMechanisms])


  return ((suppliers === undefined || suppliers === null || suppliers.length === undefined || suppliers.length < 1)) ? (<div>Loading...</div>) : (
    <GridContainer>
      <GrievanceMechanismsModal open={modal.open} value={modal.id} onClose={() => setModal({ ...modal, open: false })} />

      <GridItem xs={12}>
        <Card style={{ marginTop: 0 }}>
          <CardBody>
            <GridContainer justifyContent="center">
              <GridItem xs={12} sm={4} lg={6} xl={4}>
                <SuppliersDropdown multipleselect={true} onSelect={suppliers => setFilters({ ...filters, suppliers: suppliers })} value={filters.suppliers} />
              </GridItem>
              <GridItem xs={6} sm={2} lg={2} xl={1}>
                <InputLabel className={classes.label}>Between</InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    value={filters.startTime}
                    timeFormat={false}
                    inputProps={{ placeholder: "Start" }}
                    onChange={date => typeof date === 'object' && setFilters({ ...filters, startTime: date })}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect={true}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={2} lg={2} xl={1}>
                <InputLabel className={classes.label}>And</InputLabel>
                <FormControl fullWidth>
                  <Datetime
                    value={filters.endTime}
                    timeFormat={false}
                    inputProps={{ placeholder: "End" }}
                    onChange={date => typeof date === 'object' && setFilters({ ...filters, endTime: date })}
                    dateFormat='YYYY-MM-DD'
                    closeOnSelect={true}
                  />
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={2} lg={2} xl={2}>
                <FormControl justify="center">
                  <Button
                    color="rose"
                    onClick={
                      () => setFilters({
                        suppliers: [],
                        startTime: null,
                        endTime: null
                      })
                    }
                  > Reset all </Button>
                </FormControl>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
      {currentUser && currentUser.groups && currentUser.groups.includes('Issara Management') &&
        <GridItem>
          {(grievancemechanismArray !== undefined && grievancemechanismArray.length !== undefined && grievancemechanismArray.length > 0) ? (<DownloadGrievancemechanismList dataset={grievancemechanismArray} />) : (null)}
        </GridItem>
      }
      <GridItem xs={12}>
        <Card style={{ marginTop: 0 }}>
          <CardHeader>
            <h4>Search Results (Found: {(grievancemechanismArray !== undefined &&
              grievancemechanismArray.length !== undefined) ? grievancemechanismArray.length : 0})</h4>
          </CardHeader>
          <CardBody>
            <GridItem xs={12} sm={12} lg={12}>
              <FormControl fullWidth>
                <CustomInput
                  id={"search_text"}
                  labelText={"Search (Fuzzy Search Across all fields)"}
                  isTextArea={false}
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={filters.search}
                  inputProps={{
                    onChange: (e) => {
                      setFilters({ ...filters, search: (e.target.value === undefined) ? "" : e.target.value })
                    }
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <br />
            </GridItem>
            <GridItem>
              <ReactTable PaginationComponent={Pagination}
                defaultFilterMethod={(filter, row) => Utils.findStringInObjectFields([row._original], filter.value, [filter.id]).length > 0}
                filterable={true}

                data={grievancemechanismArray}
                defaultSorted={[{
                  id: 'id',
                  desc: true,
                }]}
                columns={[
                  {
                    Header: "Id",
                    accessor: "id",
                    width: 80,
                    filterable: true,
                    Cell: props => {
                      return (
                        <HtmlTooltip title={props.value} interactive>
                          <div className="cell-overflow">
                            {props.value}
                          </div>
                        </HtmlTooltip>
                      )
                    },
                  },
                  {
                    Header: "Suppliers",
                    accessor: "supplier_names",
                    width: 200,
                    filterable: true,
                    Cell: props => {
                      return (
                        <HtmlTooltip title={props.value} interactive>
                          <div className="cell-overflow">
                            {props.value}
                          </div>
                        </HtmlTooltip>
                      )
                    },
                  },
                  {
                    Header: "Date of Review",
                    accessor: "date_of_review",
                    filterable: true,
                    width: 100
                  },
                  {
                    Header: "Description",
                    accessor: "description",
                    filterable: true,
                    Cell: props => (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {props.value ?
                            Utils.shortenString(props.value, 120) :
                            '-'}
                        </div>
                      </HtmlTooltip>),
                  },
                  {
                    Header: "Assessment",
                    accessor: "assessment",
                    filterable: true,
                    Cell: props => (
                      <HtmlTooltip title={props.value} interactive>
                        <div className="cell-overflow">
                          {Utils.shortenString(props.value, 120)}
                        </div>
                      </HtmlTooltip>),
                  },
                  {
                    Header: "Created By",
                    accessor: "created_by_name",
                    filterable: true,
                    Cell: props => (
                      <HtmlTooltip title={props.value || '-'} interactive>
                        <div className="cell-overflow">
                          {props.original.created_by_short_name || '-'}
                        </div>
                      </HtmlTooltip>
                    ),
                    width: 110
                  }, {
                    Header: "",
                    sortable: false,
                    accessor: "view",
                    width: 30,
                  }, {
                    Header: "",
                    sortable: false,
                    accessor: "edit",
                    width: 30,
                  }
                ]}
                defaultPageSize={5}
                showPaginationTop={false}
                showPaginationBottom={true}
                loading={grievanceMechanismsFetching || fetchingUsers || fetchingReducerSuppliers}
                className="-striped -highlight"
              />
            </GridItem>

          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
