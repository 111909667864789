import React, { useState, useEffect } from 'react'
import HttpService from "../../../services/HttpService";
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { Switch } from '@material-ui/core';
import swal from '@sweetalert/with-react'

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

let theme = createTheme();
theme = responsiveFontSizes(theme);

let styles = {
  upperbox: {
    backgroundColor: "#fff",
    padding: '10px 30px',
    borderRadius: '0 0 6px 6px',
  },
  activity_list: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: "5px 0"
  },
  BottomBtn: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "20px",
    borderRadius: '0px'
  },
  Btn: {
    backgroundColor: "#5dc719",
    color: "#fff",
    cursor: "pointer"
  },
  selectHeight: {
    padding: "2px 14px",
  },
  topHeading: {
    borderRadius: "6px 6px 0 0",
    backgroundColor: "#999999",
    color: "#fff",
    fontWeight: "500",
    padding: "18px"
  }
}

const ActivitiesProgress = (props) => {
  const classes = useStyles();
  const [allPartners, setAllPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(0);
  const [partnersWorkplan, setPartnersWorkplan] = useState([]);
  const [activities, setActivities] = useState([]);
  const [workplanID, setWorkplanID] = React.useState()

  var wp_id = []
  const defaultFilterOptions = createFilterOptions();
  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, 20);
  };

  useEffect(() => {
    HttpService.get(`${process.env.REACT_APP_API_URL}/strategicpartners/`,
      (response) => {
        setAllPartners(response.results)
      },
      (err) => {
        console.log(err.message)
      }
    )
  }, [])

  const getActivitiesList = () => {
    HttpService.get(`${process.env.REACT_APP_API_URL}/activity_progress/?sp=${selectedPartner}`,
      (response) => {
        setActivities(response.results)
      },
      (err) => {
        console.log(err.message)
      }
    )
  }

  useEffect(() => {
    HttpService.get(`${process.env.REACT_APP_API_URL}/sp-workplans/${selectedPartner}/workplan_strategicpartner/`,
      (response) => {
        setPartnersWorkplan(response)
        setWorkplanID(response[0].id)
        wp_id = response[0].id
      },
      (err) => {
        console.log(err.message)
      }
    )
    getActivitiesList()
  }, [selectedPartner])

  const handleSelectPartner = (event, value) => {
    if (value !== null) {
      setSelectedPartner(value.id)
    }
  }

  let priority_activity = 0
  let progressData = "not_started"
  let priorityData = false

  const progressApiCall = (e) => {
    HttpService.post(`${process.env.REACT_APP_API_URL}/update_activity_progress/`,
      {
        "priority_activity": priority_activity,
        "progress": progressData,
        "workplan": workplanID ? workplanID : wp_id
      },
      (response) => {
        getActivitiesList()
      },
      (err) => {
        console.log(err.message)
      }
    )
  }

  const priorityApiCall = (e) => {
    HttpService.post(`${process.env.REACT_APP_API_URL}/update_activity_progress/`,
      {
        "priority_activity": priority_activity,
        "priority": priorityData,
        "workplan": workplanID ? workplanID : wp_id
      },
      (response) => {
        getActivitiesList()
      },
      (err) => {
        console.log(err.message)
      }
    )
  }

  var activitiesData = activities.sort((a, b) => {
    if (a.priority_activity.id > b.priority_activity.id) {
      return 1;
    }
    if (a.priority_activity.id < b.priority_activity.id) {
      return -1;
    }
    return 0;
  })

  const submitHandler = (e) => {
    swal({
      text: "Data save sucsessfully",
      buttons: false,
      timer: 2000,
    });
  }
  return (
    <>
      <Container>
        <Grid container style={{ ...styles.upperbox, padding: "20px", borderRadius: "6px" }}>
          <Autocomplete
            filterOptions={filterOptions}
            id="combo-box-demo"
            options={allPartners ? allPartners : null}
            getOptionLabel={(option) => option.name}
            onChange={handleSelectPartner}
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Strategic Partner" variant="outlined" />}
          />
        </Grid>
        <br />
        {
          (partnersWorkplan && partnersWorkplan.length > 0)
            ?
            <>
              {
                (activities && activities.length > 0)
                  ?
                  <>
                    <Grid style={{ ...styles.topHeading }}>
                      <ThemeProvider theme={theme}>
                        <Typography variant="p" style={{ margin: "auto 0", width: "400px" }}>Priority Workplan Activities</Typography>
                      </ThemeProvider>
                    </Grid>

                    <Grid container style={{ ...styles.upperbox }}>
                      {
                        activitiesData.map((item, index) => {
                          return (
                            <>
                              <Grid item xs={12} sm={12} md={12} lg={12} style={{ ...styles.activity_list }} key={index}>
                                <ThemeProvider theme={theme}>
                                  <Typography variant="p" style={{ margin: "auto 0", width: "400px" }}>
                                    {item.priority_activity.description}
                                  </Typography>
                                </ThemeProvider>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={item.priority}
                                        color="primary"
                                        name="priority_activity"
                                        id={item.id}
                                        onChange={(event) => {
                                          priority_activity = item.id
                                          priorityData = event.target.checked
                                          priorityApiCall()
                                        }}
                                      />
                                    }
                                    label="Priority"
                                  />
                                </FormGroup>
                                <FormControl variant="outlined" size="small" className={classes.formControl} style={{ margin: "auto 0" }}>
                                  <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id={item.id}
                                    value={item.progress}
                                    onChange={(event) => {
                                      priority_activity = item.id
                                      progressData = event.target.value
                                      progressApiCall()
                                    }}
                                  >
                                    <MenuItem value="not_started">Not Started</MenuItem>
                                    <MenuItem value="in_progress">In Progress</MenuItem>
                                    <MenuItem value="complete">Complete</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                            </>
                          )
                        })
                      }
                    </Grid>
                    <Grid component="span" style={{ ...styles.BottomBtn, ...styles.upperbox }} >
                      <Button variant="contained" style={styles.Btn} onClick={submitHandler}> Save </Button>
                    </Grid>
                    <br />
                  </>
                  :
                  <Grid style={{ ...styles.topHeading, borderRadius: "0px" }}>
                    <ThemeProvider theme={theme}>
                      <Typography variant="p" style={{ margin: "auto 0", width: "400px" }}>Activities not created for this Workplan</Typography>
                    </ThemeProvider>
                  </Grid>
              }
            </>
            : null
        }

        {
          (selectedPartner > 0 && partnersWorkplan.length === 0)
            ?
            <Grid style={{ ...styles.topHeading, borderRadius: "0px" }}>
              <ThemeProvider theme={theme}>
                <Typography variant="p" style={{ margin: "auto 0", width: "400px" }}>Strategic Partner has no Workplan</Typography>
              </ThemeProvider>
            </Grid>
            : null
        }
      </Container>
    </>
  )
}

export default ActivitiesProgress;
