import React, { useState } from "react";
import PropTypes from "prop-types";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// @material-ui/core components
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.js";
import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import loginStore from "../../redux/stores/LoginStore";
import * as LoginActions from "../../redux/actions/LoginActions";

const customStyles = {
    ...sweetAlertStyles,
    header: {
        textAlign: 'center'
    },
    subheader: {
        fontWeight: '400',
    },
    datetime: {
        marginTop: '24px'
    },

    buttonCloser: {
        margin: 0,
        scrollbars: "none",
        paddingRight: "10px"
    },
    cardTitle: {
        paddingTop: "20px"
    },
    container: {
        ...sweetAlertStyles.container,
        textAlign: "center"
    }

};

const useStyles = makeStyles(customStyles);

export default function ChangePasswordForm(props, context) {
    const classes = useStyles();
    const [alert, setAlert] = React.useState(null);

    const handleChangePassword = (e) => {
        e.preventDefault();
        LoginActions.changePassword(loginStore.getUserID(), payload.old_password, payload.password, payload.password2,
            (resp) => {
                console.log("changePassword-----------------Resp", resp);
                successAlert();
            }, (error) => {
                errorAlert({ message: (error.response && error.response.data) ? error.message + ":" + JSON.stringify(error.response.data) : error.message });
            });
    }

    // const googleAuthResponse = (resp) => {
    //   LoginActions.loginGoogle(resp);
    // }

    const [payload, setPayload] = useState({
        old_password: "",
        password: "",
        password2: ""
    })


    const successAlert = () => {
        setAlert(
            <SweetAlert
                success
                onConfirm={() => {
                    setAlert(null);
                    if (props.lostFocus) {
                        props.lostFocus();
                    }
                }}
                confirmBtnCssClass={classes.button + " " + classes.success}
                title={context.t("Well done!")}
            >
                {context.t('Password has been successfully changed.')}
            </SweetAlert>
        );
    };

    const errorAlert = (error) => {
        console.log('ERRROR', error)
        setAlert(
            <SweetAlert
                danger
                onConfirm={() => setAlert(null)}
                confirmBtnCssClass={classes.button + " " + classes.success}
                title={context.t("Error")}
            >
                {error.message}
            </SweetAlert>
        );
    };



    return (
        <div className={classes.container} >
            {alert}
            <GridContainer justify="center">

                <GridItem xs={12} sm={8} md={8}>
                    <form>
                        <Card>
                            <CardHeader>
                                <h3>{context.t("Change Password")}</h3>
                            </CardHeader>
                            <CardBody>
                                <CustomInput
                                    labelText={context.t("Old Password")}
                                    id="password"
                                    value={payload.old_password}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}>
                                                    {context.t("lock_outline")}
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        type: "password",
                                        autoComplete: "off",
                                        onChange: (e) => {
                                            setPayload({
                                                ...payload,
                                                old_password: e.target.value
                                            })
                                        }
                                    }}
                                />
                                <CustomInput
                                    labelText={context.t("New Password")}
                                    id="password"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    value={payload.password}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}>
                                                    {context.t("lock_outline")}
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        type: "password",
                                        autoComplete: "off",
                                        onChange: (e) => {
                                            setPayload({
                                                ...payload,
                                                password: e.target.value
                                            })
                                        }
                                    }}
                                />
                                <CustomInput
                                    labelText={context.t("Re-Enter Password to Verify")}
                                    id="password2"
                                    value={payload.password2}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}>
                                                    {context.t("lock_outline")}
                                                </Icon>
                                            </InputAdornment>
                                        ),
                                        type: "password",
                                        autoComplete: "off",
                                        onChange: (e) => {
                                            setPayload({
                                                ...payload,
                                                password2: e.target.value
                                            })
                                        }
                                    }}
                                />
                                <GridItem>
                                    <br />
                                </GridItem>

                                <GridItem xs={12} md={12} lg={12}>
                                    <Button style={{ width: "100%", padding: "20px" }} className={classes.center} color="primary" onClick={handleChangePassword.bind(this)}>
                                        {context.t("Update")}
                                    </Button>
                                </GridItem>

                            </CardBody>
                        </Card>
                    </form>
                </GridItem>
            </GridContainer>
        </div>
    );
}

ChangePasswordForm.contextTypes = {
    t: PropTypes.func.isRequired,
};