import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import "./css/style1.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import HttpService from "../../../services/HttpService";
import loginStore from "redux/stores/LoginStore";

export default function FinalWorkplan(props, context) {
  let _user = loginStore.getLoginUser();
  const [data, setdata] = useState();
  const [activitiesdata, setactivitiesData] = useState([]);
  useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/`,
      (response) => {
        setdata([response]);
      },
      (err) => {
        console.log(err.message);
      }
    );
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/activity_progress/?sp=${_user.partner}`,

      (response) => {
        setactivitiesData(response.results);
      },
      (err) => {
        console.log(err.message);
      }
    );
  }, []);
  return (
    <>
      <Grid>
        <Grid>
          <h5
            style={{
              textAlign: "center",
              background: "#e0e0e0",
              color: "black",
              padding: "20px",
              fontWeight: "bold",
            }}
          >
            {context.t("Workplan Reviews")}
          </h5>
        </Grid>
        {data ? (
          data.map((item, index) => {
            return (
              <>
                <Grid className="finalworkplan" key={index}>
                  {item &&
                  item.documents !== undefined &&
                  Array.isArray(item.documents) &&
                  item.documents.length > 0 &&
                  item.documents[0].workplan &&
                  item.documents[0].workplan.strategic_partners &&
                  item.documents[0].workplan.strategic_partners.name ? (
                    <h3
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        padding: "0px 10px",
                      }}
                    >
                      {item.documents[0].workplan.strategic_partners.name} Work
                      Plan
                    </h3>
                  ) : (
                    ""
                  )}
                  <hr style={{ padding: "1px", background: "black" }}></hr>

                  <Grid style={{ padding: "0px 10px" }}>
                    <h3 style={{ fontWeight: "bold", padding: "10px 0px" }}>
                      {context.t("Introduction")}
                    </h3>
                    <Grid container>
                      <Grid item xs={6}>
                        <h5>{context.t("Name of the focal point:")}</h5>
                      </Grid>
                      <Grid item xs={6}>
                        <p>{item.fp_name}</p>
                      </Grid>
                      <Grid item xs={6}>
                        <h5>{context.t("Created at:")}</h5>
                      </Grid>
                      <Grid item xs={6}>
                        <p>{item.created_at}</p>
                      </Grid>
                      <Grid item xs={6}>
                        <h5>
                          {context.t("Suggested date for mid-year check-in:")}
                        </h5>
                      </Grid>
                      <Grid item xs={6}>
                        <p>{item.suggested_date}</p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <hr
                    style={{
                      padding: "1px",
                      background: "black",
                      marginTop: "30px",
                    }}
                  ></hr>

                  {/* Documents sections */}
                  <Grid style={{ padding: "0px 10px" }}>
                    <h3 style={{ fontWeight: "bold", padding: "10px 0px" }}>
                      {context.t("Documents")}
                    </h3>
                    <p>
                      {context.t(
                        "Please check that all foundational documents and materials that have been shared with Issara. If not, you can upload them here:"
                      )}
                    </p>
                    {item &&
                    item.documents !== undefined &&
                    Array.isArray(item.documents) &&
                    item.documents.length > 0 &&
                    (!item.documents ||
                      item.documents === undefined ||
                      item.documents[0] === undefined)
                      ? ""
                      : Array.isArray(item.documents) &&
                        item.documents.length > 0 &&
                        item.documents.map((docs, index) => {
                          return (
                            <>
                              <Grid item xs={6}>
                                <h5>Document Type: {docs.document_type}</h5>
                              </Grid>
                              <Grid item xs={6}>
                                <p>
                                  File url:
                                  <span style={{ cursor: "pointer" }}>
                                    <a
                                      href={docs.document.file_path}
                                      target="_new"
                                    >
                                      {docs.document.name}
                                    </a>
                                  </span>
                                </p>
                              </Grid>
                            </>
                          );
                        })}
                  </Grid>
                  <hr
                    style={{
                      padding: "1px",
                      background: "black",
                      marginTop: "30px",
                    }}
                  ></hr>

                  {/* Main goals and priority section */}
                  {item &&
                    item.main_goals !== undefined &&
                    Array.isArray(item.main_goals) &&
                    item.main_goals.length > 0 && (
                      <Grid style={{ padding: "0px 10px" }}>
                        <h3 style={{ fontWeight: "bold", padding: "10px 0px" }}>
                          {context.t(
                            "Main Goals and Priority Partnership Areas"
                          )}
                        </h3>
                        <h5
                          style={{
                            padding: "5px 0px",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {context.t(
                            "What are the company's main goals in responsible sourcing and ethical recruitment over the next 3 years?"
                          )}
                        </h5>
                        <p>
                          {item.main_goals &&
                          item.main_goals[0] &&
                          item.main_goals[0].ethical_recruitment
                            ? item.main_goals[0].ethical_recruitment
                            : ""}
                        </p>
                        <h5
                          style={{
                            padding: "5px 0px",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {context.t(
                            "What are the company's main priority areas of partnership with Issara Institute?"
                          )}
                        </h5>
                        <p>
                          {item.main_goals &&
                          item.main_goals[0] &&
                          item.main_goals[0].partnership_areas
                            ? item.main_goals[0].partnership_areas
                            : ""}
                        </p>
                        <h5
                          style={{
                            padding: "5px 0px",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {context.t(
                            "How worker voice via Inclusive Labor Monitoring (ILM) will be actioned and escalated at Partner:"
                          )}
                        </h5>
                        <p>
                          {item.main_goals &&
                          item.main_goals[0] &&
                          item.main_goals[0].worker_voice_escalation
                            ? item.main_goals[0].worker_voice_escalation
                            : ""}
                        </p>
                        <h5
                          style={{
                            padding: "5px 0px",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {context.t(
                            "Priority suppliers, countries, and/or regions for continued focus or expansion:"
                          )}
                        </h5>
                        <p>
                          {item.main_goals &&
                          item.main_goals[0] &&
                          item.main_goals[0]
                            .priority_suppliers_countries_regions
                            ? item.main_goals[0]
                                .priority_suppliers_countries_regions
                            : ""}
                        </p>
                      </Grid>
                    )}

                  <hr
                    style={{
                      padding: "1px",
                      background: "black",
                      marginTop: "30px",
                    }}
                  ></hr>

                  <Grid style={{ padding: "0px 10px" }}>
                    <h3 style={{ fontWeight: "bold", padding: "10px 0px" }}>
                      {context.t("Progress Indicators")}
                    </h3>

                    <Grid>
                      <h4
                        style={{
                          fontWeight: "bold",
                          padding: "30px 0px 20px 0px",
                        }}
                      >
                        {context.t("AREA 1. WORKER VOICE: ANNUAL TARGETS")}
                      </h4>
                      <Grid container>
                        {item.progress_indicator &&
                        Array.isArray(item.progress_indicator) &&
                        item.progress_indicator.length > 0
                          ? item.progress_indicator.map((pi, i) => {
                              if (pi.area == "worker_voice") {
                                return (
                                  <>
                                    <Grid xs={6} key={i}>
                                      <Grid
                                        container
                                        style={{
                                          textAlign: "center",
                                          border: "1px solid black",
                                        }}
                                      >
                                        <Grid item xs={10}>
                                          <p style={{ margin: "auto 0" }}>
                                            {pi.str_value}
                                          </p>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <p style={{ margin: "auto 0" }}>
                                            {pi.numeric_value}
                                            {pi.sub_area === "percentage"
                                              ? "%"
                                              : null}
                                          </p>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </>
                                );
                              }
                            })
                          : null}
                      </Grid>
                    </Grid>
                    <Grid>
                      <h4
                        style={{
                          fontWeight: "bold",
                          padding: "30px 0px 20px 0px",
                        }}
                      >
                        {context.t(
                          "AREA 2. LABOUR RECRUITMENT: ANNUAL TARGETS"
                        )}
                      </h4>
                      <p>
                        {context.t(
                          "WV INDICATOR - Ethical recruitment policy in force is (tick all that are within your goal for this year) :"
                        )}
                      </p>

                      {item.key_activities &&
                      Array.isArray(item.key_activities) &&
                      item.key_activities.length > 0
                        ? item.key_activities.map((ka, i) => {
                            let hasNumber = /\d/;
                            if (ka.area === "labour_recruitment") {
                              return (
                                <>
                                  {ka.value === true ? (
                                    <span>[&#x2713;]</span>
                                  ) : (
                                    <span> [&#10006;]</span>
                                  )}
                                  <span style={{ paddingLeft: "10px" }}>
                                    {ka.str_value}
                                  </span>
                                  <br />
                                </>
                              );
                            }
                          })
                        : null}
                    </Grid>

                    <br />
                    <p>{context.t("ER INDICATOR 2.")}</p>
                    <Grid container>
                      {item.progress_indicator &&
                      Array.isArray(item.progress_indicator) &&
                      item.progress_indicator.length > 0
                        ? item.progress_indicator.map((pi, i) => {
                            if (
                              pi.area == "labour_recruitment" &&
                              pi.sub_area == "number" &&
                              pi.str_value !==
                                "Undertaken recruitment suppliers"
                            ) {
                              if (pi.str_value === "number of ra mapped")
                                return (
                                  <>
                                    <Grid xs={4} key={i}>
                                      <Grid
                                        container
                                        style={{
                                          textAlign: "center",
                                          border: "1px solid black",
                                          minHeight: "45px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={10}
                                          style={{ margin: "auto 0" }}
                                        >
                                          <p style={{ margin: "auto 0" }}>
                                            {context.t(
                                              "Number of recruitment agencies mapped"
                                            )}
                                          </p>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          style={{ margin: "auto 0" }}
                                        >
                                          <p style={{ margin: "auto 0" }}>
                                            {pi.numeric_value}
                                          </p>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </>
                                );
                            }
                          })
                        : null}
                      {item.progress_indicator &&
                      Array.isArray(item.progress_indicator) &&
                      item.progress_indicator.length > 0
                        ? item.progress_indicator.map((pi, i) => {
                            if (
                              pi.area == "labour_recruitment" &&
                              pi.sub_area == "number" &&
                              pi.str_value !==
                                "Undertaken recruitment suppliers"
                            ) {
                              if (
                                pi.str_value ===
                                "number of collaborating suppliers with Issara"
                              ) {
                                return (
                                  <>
                                    <Grid xs={4} key={i}>
                                      <Grid
                                        container
                                        style={{
                                          textAlign: "center",
                                          border: "1px solid black",
                                          minHeight: "45px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={10}
                                          style={{ margin: "auto 0" }}
                                        >
                                          <p style={{ margin: "auto 0" }}>
                                            {context.t(
                                              "Number of suppliers collaborating with Issara"
                                            )}
                                          </p>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          style={{ margin: "auto 0" }}
                                        >
                                          <p style={{ margin: "auto 0" }}>
                                            {pi.numeric_value}
                                          </p>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </>
                                );
                              }
                            }
                          })
                        : null}
                      {item.progress_indicator &&
                      Array.isArray(item.progress_indicator) &&
                      item.progress_indicator.length > 0
                        ? item.progress_indicator.map((pi, i) => {
                            if (
                              pi.area == "labour_recruitment" &&
                              pi.sub_area == "number" &&
                              pi.str_value !==
                                "Undertaken recruitment suppliers"
                            ) {
                              if (
                                pi.str_value ===
                                "number suppliers utilizing GD marketplace"
                              ) {
                                return (
                                  <>
                                    <Grid xs={4} key={i}>
                                      <Grid
                                        container
                                        style={{
                                          textAlign: "center",
                                          border: "1px solid black",
                                          minHeight: "45px",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={10}
                                          style={{ margin: "auto 0" }}
                                        >
                                          <p style={{ margin: "auto 0" }}>
                                            {context.t(
                                              "Number of suppliers utilizing GD Marketplace"
                                            )}
                                          </p>
                                        </Grid>
                                        <Grid
                                          item
                                          xs={2}
                                          style={{ margin: "auto 0" }}
                                        >
                                          <p style={{ margin: "auto 0" }}>
                                            {pi.numeric_value}
                                          </p>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </>
                                );
                              }
                            }
                          })
                        : null}
                    </Grid>

                    <Grid>
                      <h4
                        style={{
                          fontWeight: "bold",
                          padding: "30px 0px 20px 0px",
                        }}
                      >
                        {context.t(
                          "AREA 3. WORKING CONDITIONS: ANNUAL TARGETS"
                        )}
                      </h4>
                      <p>
                        {context.t(
                          "WV INDICATOR - Ethical sourcing and migrant worker policies put into force that are ( tick all that are within your goal for this year ) :"
                        )}
                      </p>
                      {item.key_activities
                        ? item.key_activities.map((ka, i) => {
                            let hasNumber = /\d/;

                            if (
                              ka.area === "working_conditions" &&
                              !hasNumber.test(ka.str_value)
                            ) {
                              return (
                                <>
                                  {ka.value === true ? (
                                    <span>[&#x2713;]</span>
                                  ) : (
                                    <span> [&#10006;]</span>
                                  )}
                                  <span style={{ paddingLeft: "10px" }}>
                                    {ka.str_value}
                                  </span>
                                  <br />
                                </>
                              );
                            }
                          })
                        : null}

                      <br />
                      <Grid container>
                        {item.progress_indicator &&
                        Array.isArray(item.progress_indicator) &&
                        item.progress_indicator.length > 0
                          ? item.progress_indicator.map((pi, i) => {
                              if (
                                pi.area == "working_conditions" &&
                                pi.sub_area == "number"
                              ) {
                                if (pi.str_value == "Quality remediation") {
                                  return (
                                    <>
                                      <Grid xs={4} key={i}>
                                        <Grid
                                          container
                                          style={{
                                            textAlign: "center",
                                            border: "1px solid black",
                                          }}
                                        >
                                          <Grid item xs={10}>
                                            <p style={{ margin: "auto 0" }}>
                                              {context.t("Quality of response")}
                                            </p>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <p style={{ margin: "auto 0" }}>
                                              {pi.numeric_value}
                                              {pi.sub_area === "percentage"
                                                ? "%"
                                                : null}
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </>
                                  );
                                }
                              }
                            })
                          : null}
                        {item.progress_indicator &&
                        Array.isArray(item.progress_indicator) &&
                        item.progress_indicator.length > 0
                          ? item.progress_indicator.map((pi, i) => {
                              if (
                                pi.area == "working_conditions" &&
                                pi.sub_area == "number"
                              ) {
                                if (pi.str_value == "Timeliness remediation") {
                                  return (
                                    <>
                                      <Grid xs={4} key={i}>
                                        <Grid
                                          container
                                          style={{
                                            textAlign: "center",
                                            border: "1px solid black",
                                          }}
                                        >
                                          <Grid item xs={10}>
                                            <p style={{ margin: "auto 0" }}>
                                              {context.t(
                                                "Timeliness of response"
                                              )}
                                            </p>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <p style={{ margin: "auto 0" }}>
                                              {pi.numeric_value}
                                              {pi.sub_area === "percentage"
                                                ? "%"
                                                : null}
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </>
                                  );
                                }
                              }
                            })
                          : null}
                        {item.progress_indicator &&
                        Array.isArray(item.progress_indicator) &&
                        item.progress_indicator.length > 0
                          ? item.progress_indicator.map((pi, i) => {
                              if (
                                pi.area == "working_conditions" &&
                                pi.sub_area == "number"
                              ) {
                                if (pi.str_value == "Opennes store form") {
                                  return (
                                    <>
                                      <Grid xs={4} key={i}>
                                        <Grid
                                          container
                                          style={{
                                            textAlign: "center",
                                            border: "1px solid black",
                                          }}
                                        >
                                          <Grid item xs={10}>
                                            <p style={{ margin: "auto 0" }}>
                                              {context.t("Openness to reform")}
                                            </p>
                                          </Grid>
                                          <Grid item xs={2}>
                                            <p style={{ margin: "auto 0" }}>
                                              {pi.numeric_value}
                                              {pi.sub_area === "percentage"
                                                ? "%"
                                                : null}
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </>
                                  );
                                }
                              }
                            })
                          : null}
                      </Grid>
                      <br />
                      <Grid
                        container
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {item.progress_indicator &&
                        Array.isArray(item.progress_indicator) &&
                        item.progress_indicator.length > 0
                          ? item.progress_indicator.map((pi, i) => {
                              if (
                                pi.area == "working_conditions" &&
                                pi.sub_area == "percentage"
                              ) {
                                return (
                                  <>
                                    <Grid xs={6} key={i}>
                                      <Grid
                                        container
                                        style={{
                                          textAlign: "center",
                                          border: "1px solid black",
                                        }}
                                      >
                                        <Grid item xs={10}>
                                          <p style={{ margin: "auto 0" }}>
                                            {pi.str_value}
                                          </p>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <p style={{ margin: "auto 0" }}>
                                            {pi.numeric_value}
                                            {pi.sub_area === "percentage"
                                              ? "%"
                                              : null}
                                          </p>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </>
                                );
                              }
                            })
                          : null}
                      </Grid>
                      <Grid>
                        <h4
                          style={{
                            fontWeight: "bold",
                            padding: "30px 0px 20px 0px",
                          }}
                        >
                          {context.t("Notes and remark")}
                        </h4>
                        {item &&
                        item.notes_remark !== undefined &&
                        Array.isArray(item.notes_remark) &&
                        item.notes_remark.length > 0
                          ? item.notes_remark.map((notes, i) => {
                              if (notes.area === "progress indicators") {
                                return (
                                  <>
                                    <p> {notes.txt_value} </p>
                                  </>
                                );
                              }
                            })
                          : null}
                      </Grid>
                    </Grid>
                  </Grid>

                  <hr
                    style={{
                      padding: "1px",
                      background: "black",
                      marginTop: "30px",
                    }}
                  ></hr>
                  <Grid style={{ padding: "0px 10px" }}>
                    <h3 style={{ fontWeight: "bold", padding: "10px 0px" }}>
                      {context.t("Priority Workplan Activities")}
                    </h3>
                    <p>
                      {context.t(
                        "The priority activities for your company that you indicate here will appear on your SP Progress Dashboard."
                      )}
                    </p>
                    <h5
                      style={{
                        padding: "5px 0px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {context.t("AREA 1. WORKER VOICE: ANNUAL TARGETS")}
                    </h5>
                    {activitiesdata.map((key_act, index) => {
                      if (
                        key_act.priority_activity.category === "worker_voice"
                      ) {
                        return (
                          <>
                            {key_act.value === true ? (
                              <span>[&#x2713;]</span>
                            ) : (
                              <span> [&#10006;]</span>
                            )}
                            &nbsp;&nbsp;
                            <span>{key_act.priority_activity.description}</span>
                            <br />
                          </>
                        );
                      }
                    })}

                    <h5
                      style={{
                        padding: "5px 0px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {context.t("AREA 2. LABOUR RECRUITMENT")}
                    </h5>
                    {activitiesdata.map((key_act, index) => {
                      if (
                        key_act.priority_activity.category ===
                        "labour_recruitment"
                      ) {
                        return (
                          <>
                            {key_act.value === true ? (
                              <span>[&#x2713;]</span>
                            ) : (
                              <span> [&#10006;]</span>
                            )}
                            &nbsp;&nbsp;
                            <span>{key_act.priority_activity.description}</span>
                            <br />
                          </>
                        );
                      }
                    })}

                    <h5
                      style={{
                        padding: "5px 0px",
                        fontSize: "16px",
                        fontWeight: "600",
                      }}
                    >
                      {context.t("AREA 3. WORKING CONDITIONS")}
                    </h5>
                    {activitiesdata.map((key_act, index) => {
                      if (
                        key_act.priority_activity.category ===
                        "working_conditions"
                      ) {
                        return (
                          <>
                            {key_act.value === true ? (
                              <span>[&#x2713;]</span>
                            ) : (
                              <span> [&#10006;]</span>
                            )}
                            &nbsp;&nbsp;
                            <span>{key_act.priority_activity.description}</span>
                            <br />
                          </>
                        );
                      }
                    })}
                    <hr />
                    <h4
                      style={{
                        fontWeight: "bold",
                        padding: "30px 0px 20px 0px",
                      }}
                    >
                      {context.t("Notes and remarks")}
                    </h4>
                    {item &&
                    item.notes_remark !== undefined &&
                    Array.isArray(item.notes_remark) &&
                    item.notes_remark.length > 0
                      ? item.notes_remark.map((notes, i) => {
                          if (notes.area === "workplan activity") {
                            return (
                              <>
                                <p> {notes.txt_value} </p>
                              </>
                            );
                          }
                        })
                      : null}
                  </Grid>
                </Grid>
              </>
            );
          })
        ) : (
          <LinearProgress />
        )}
      </Grid>
    </>
  );
}

FinalWorkplan.contextTypes = {
  t: PropTypes.func.isRequired,
};
