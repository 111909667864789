import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tooltip from '@material-ui/core/Tooltip';
import ReactTable from "react-table-6";

import Pagination from "components/Pagination/Pagination2.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from "@material-ui/core/FormControl";
import CustomInput from "components/CustomInput/CustomInput";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

import moment from "moment";

import { fetchUsers } from "../../redux/actions/UsersActions";
import axios from "axios";



import Utils from "../../services/utils.js";

const customStyle = {
  ...styles,
  button: {
    padding: 0,
    margin: 0
  },


  preBlock: {
    whiteSpace: "pre-wrap",
    wordBreak: "keep-all",
    scroll: "none",
    width: "100%"
  },
  center: {
    textAlign: "center"
  },
  preNextButton: {
    cursor:"pointer",
    padding: "5px 10px",
    margin: "2px",
    borderRadius: "5px"

},
parentpreNextButton: {
    display: "flex",
    justifyContent: "end",
    marginEnd: "5px",
}

}

const useStyles = makeStyles(customStyle);


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export default function SupplierUserLoginsList(props) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const fetchingPartnerUserLogins = useSelector(state => state.parnterUserLoginsReducer.fetchingPartnerUserLogins)
  const [supplierUserLoginsArray, setSupplierUserLoginsArray] = useState([]);

  const [supplierUserListData, setSupplierUserListData] = useState([]);
  const [pageNo, setPageNo] = useState(1)
  const [partnerUserLogins, setPartnerUserLogins] = useState([])
  const users = useSelector(state => state.usersReducer.items);

  const [filters, setFilters] = useState({
    search: ""
  });

  let config;
  useEffect(() => {
    if (localStorage.getItem("token") != undefined) {
      config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
      }
    }

    axios.get(`${process.env.REACT_APP_API_URL}/supplierlogins/?page=${pageNo}`, config)
      .then((response) => {
        setPartnerUserLogins(response.data)
        setSupplierUserLoginsArray(response.data.results)
      }).catch((err) => {
        console.log(err)
      })
  }, [pageNo])

  const pageChange = (e) => {
    if (e.target.innerHTML == "Previous") {
      if (partnerUserLogins.previous !== null) {
        setPageNo(pageNo - 1)
      }
    }
    else if (e.target.innerHTML == "Next") {
      if (partnerUserLogins.next !== null) {
        setPageNo(pageNo + 1)
      }
    }

  }
  useEffect(() => {
    if (supplierUserLoginsArray.length !== 0 && supplierUserLoginsArray !== undefined && supplierUserLoginsArray !== null) {
      setSupplierUserListData(supplierUserLoginsArray)
    }
  }, [supplierUserLoginsArray]);

  useEffect(() => {
    if (filters.search !== undefined && filters.search.length > 0 && filters.search !== "") {
      let filteredSearchData = Utils.findStringInObjectFields(supplierUserLoginsArray, filters.search, ["id",
        "user_name",
        "partner_name",
        "created_at",
        "loggedout_at"
      ]);
      setSupplierUserListData(filteredSearchData);
    } else {
      setSupplierUserListData(supplierUserListData);
    }

  }, [filters]);

  if (supplierUserLoginsArray === undefined || supplierUserLoginsArray === null || supplierUserLoginsArray.length < 1) {
    return (<CircularProgress />);
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <CardHeader>
          <h4>Supplier Login Tracking (Found: {(supplierUserLoginsArray) ? supplierUserLoginsArray.length : 0})</h4>
        </CardHeader>
        <Card style={{ marginTop: 0 }}>
          <CardBody>
            <GridItem xs={12} sm={12} lg={12}>
              <FormControl fullWidth>
                <CustomInput
                  id={"search_text"}
                  labelText={"Search (Fuzzy Search Across all fields)"}
                  isTextArea={false}
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={filters.search}
                  inputProps={{
                    onChange: (e) => {
                      setFilters({ ...filters, search: (e.target.value === undefined) ? "" : e.target.value })
                    }
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <br />
            </GridItem>
            <GridItem>
              {
                (supplierUserLoginsArray === undefined || supplierUserLoginsArray === null || supplierUserLoginsArray.length < 1)
                  ?
                  (<GridContainer key={Utils.giveMeGuid()}>
                    <Card>
                      <CardBody>
                        <GridContainer>
                          <GridItem>
                            <h4>Supplier Logins:</h4>
                            <div>
                              <p>
                                No Supplier Logins at this time.
                              </p>
                            </div>

                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridContainer>)
                  :
                  <div>
                    <GridContainer>
                      <>
                        <ReactTable key={Utils.giveMeGuid()}
                          PaginationComponent={Pagination}
                          defaultFilterMethod={(filter, row) => Utils.findStringInObjectFields([row._original], filter.value, [filter.id]).length > 0}
                          filterable={true}
                          sortable={true}
                          data={supplierUserListData}
                          columns={[
                            {
                              Header: "User",
                              accessor: "user_name",
                              width: 220,
                              Cell: props => (
                                <HtmlTooltip title={props.value} interactive>
                                  <div className="cell-overflow" >
                                    {Utils.shortenString(props.value, 100)}
                                  </div>
                                </HtmlTooltip>)
                            }, {
                              Header: "Supplier",
                              accessor: "supplier_name",
                              width: 250,
                              Cell: props => (
                                <HtmlTooltip title={(<div className={classes.preBlock}>{props.value}</div>)} interactive>
                                  <div className="cell-overflow" >
                                    {Utils.shortenString(props.value, 100)}
                                  </div>
                                </HtmlTooltip>)
                            }, {
                              Header: "Log-In",
                              accessor: "created_at",
                              width: 220,
                              Cell: props => (
                                <HtmlTooltip title={(props.value) ? props.value : ""} interactive>
                                  <div className="cell-overflow">
                                    {moment(props.value).format('MM/DD/YYYY h:mm a')}
                                  </div>
                                </HtmlTooltip>)
                            }, {
                              Header: "Log-Out",
                              accessor: "loggedout_at",
                              width: 220,
                              Cell: props => (
                                <HtmlTooltip title={(props.value) ? props.value : "_"} interactive>
                                  <div className="cell-overflow">
                                    {(props.value) ? moment(props.value).format('MM/DD/YYYY h:mm a') : "-"}
                                  </div>
                                </HtmlTooltip>)
                            }, {
                              Header: "",
                              width: 20,
                              sortable: false,
                              filterable: false
                            }
                          ]}
                          defaultPageSize={supplierUserLoginsArray.length}
                          showPaginationTop={false}
                          showPaginationBottom={false}
                          loading={fetchingPartnerUserLogins}
                          className="-striped -highlight"
                        />
                      </>
                    </GridContainer>
                    <GridContainer style={customStyle.parentpreNextButton}>

                      <button onClick={pageChange} style={customStyle.preNextButton} disabled={partnerUserLogins.previous == null ? true : false} >Previous</button>

                      <button onClick={pageChange} style={customStyle.preNextButton} disabled={partnerUserLogins.next == null ? true : false}>Next</button>

                    </GridContainer>

                  </div>

              }

            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );


}
