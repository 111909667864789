import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function LanguageChangeDialog(props, context) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.dialogState);
  }, [props]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        {context.t("Are you sure want to change the language ?")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <span>
            {context.t("Are you sure want to change the language to")}{" "}
            {props.fullToChangeLang}.
          </span>
          <br />
          <span>
            {context.t(
              "The application will be refreshed to effect the changed language."
            )}
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.confirmation.bind(this, false)} color="primary">
          {context.t("Cancel")}
        </Button>
        <Button
          onClick={props.confirmation.bind(this, props.tochangeLang)}
          color="primary"
        >
          {context.t("Yes, Sure Please")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

LanguageChangeDialog.contextTypes = {
  t: PropTypes.func.isRequired,
};
