import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// core components
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import Button from "components/CustomButtons/Button.js";
import CloseIcon from "@material-ui/icons/Close";

const customStyles = {
  paper: {
    backgroundColor: "white",
    padding: 20,
    overflow: "auto",
    webkitBoxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
    mozBoxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
    boxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
  },
  modal: {
    display: "block",
    overflowY: "auto !important",
    width: "80%",
    height: "90%",
  },
  spanLabel: {
    fontWeight: 500,
  },
  button: {
    position: "absolute",
    padding: 0,
    margin: 0,
    top: "40px",
    right: "40px",
  },
};

const useStyles = makeStyles(customStyles);

export default function KpiLegalViolationModal(props, context) {
  const classes = useStyles();

  const kpiLegalViolations = useSelector(
    (state) => state.kpiLegalViolationTypesReducer.items
  );
  const kpiLegalViolation = kpiLegalViolations.find((kpi) => {
    return kpi.id === props.value;
  });

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className={classes.modal + " modal"}
      >
        <div className={classes.paper}>
          <h3 style={{ marginTop: 0 }}>
            {context.t("Legal Violation #{id}", { id: props.value })}
          </h3>
          <Button
            simple
            color="danger"
            className={classes.button}
            onClick={() => props.onClose()}
          >
            <CloseIcon />
          </Button>
          {kpiLegalViolation && (
            <div>
              <Divider />
              <h5>{context.t("General:")}</h5>
              <p>
                <span className={classes.spanLabel}>{context.t("Id:")}</span>
                {kpiLegalViolation.id || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>{context.t("Name:")}</span>
                {kpiLegalViolation.name || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Description:")}
                </span>
                {kpiLegalViolation.description || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>{context.t("Law:")}</span>
                {kpiLegalViolation.law || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Article:")}
                </span>
                {kpiLegalViolation.article || "-"}
              </p>
              <p>
                <span className={classes.spanLabel}>
                  {context.t("Country:")}
                </span>
                {kpiLegalViolation.country || "-"}
              </p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

KpiLegalViolationModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
