import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.js";
import ButtonBar from "components/ButtonBar/ButtonBar.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "12px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  inputAdornment: {
    position: "relative",
  },
};

class EmploymentFields extends React.Component {
  constructor(props) {
    super(props);

    if (props.allDataWizardSaveState !== undefined) {
      this.state = Object.assign({}, props.allDataWizardSaveState);
    } else {
      this.state = {
        rating_source_broker: props.globalEditData
          ? props.globalEditData.rating_source_broker
          : undefined,
        rating_source_recruiter: props.globalEditData
          ? props.globalEditData.rating_source_recruiter
          : undefined,
        rating_dest_recruiter: props.globalEditData
          ? props.globalEditData.rating_dest_recruiter
          : undefined,
        rating_dest_employer: props.globalEditData
          ? props.globalEditData.rating_dest_employer
          : undefined,
      };
    }
  }

  sendState = () => {
    return this.state;
  };

  isValidated = () => {
    return true;
  };

  render() {
    const { classes, globalEditData } = this.props;
    return (
      <form>
        <GridItem>
          <h4 className={classes.infoText}>
            {this.context.t(
              "Business Ratings - How Does the client rate the following actors that were involved in their recruitment and employment ?"
            )}
          </h4>
          <h4 style={{ textAlign: "center" }}>
            {this.context.t("(1 very low, 5 very high)")}
          </h4>
        </GridItem>
        <div className="mt-2" />
        <GridItem>
          <ButtonBar
            name={this.context.t("Source Broker Rating")}
            value={this.state.rating_source_broker}
            buttons={[
              { name: this.context.t("1"), value: 1 },
              { name: this.context.t("2"), value: 2 },
              { name: this.context.t("3"), value: 3 },
              { name: this.context.t("4"), value: 4 },
              { name: this.context.t("5"), value: 5 },
            ]}
            onClick={(name, value) => {
              this.setState({ rating_source_broker: value });
            }}
          />
        </GridItem>
        <GridItem>
          <ButtonBar
            name={this.context.t("Source Recruiter Rating")}
            value={this.state.rating_source_recruiter}
            buttons={[
              { name: this.context.t("1"), value: 1 },
              { name: this.context.t("2"), value: 2 },
              { name: this.context.t("3"), value: 3 },
              { name: this.context.t("4"), value: 4 },
              { name: this.context.t("5"), value: 5 },
            ]}
            onClick={(name, value) => {
              this.setState({ rating_source_recruiter: value });
            }}
          />
        </GridItem>
        <GridItem>
          <ButtonBar
            name={this.context.t("Destination Broker Rating")}
            value={this.state.rating_dest_recruiter}
            buttons={[
              { name: this.context.t("1"), value: 1 },
              { name: this.context.t("2"), value: 2 },
              { name: this.context.t("3"), value: 3 },
              { name: this.context.t("4"), value: 4 },
              { name: this.context.t("5"), value: 5 },
            ]}
            onClick={(name, value) => {
              this.setState({ rating_dest_recruiter: value });
            }}
          />
        </GridItem>
        <GridItem>
          <ButtonBar
            name={this.context.t("Destination Employer Rating")}
            value={this.state.rating_dest_employer}
            buttons={[
              { name: this.context.t("1"), value: 1 },
              { name: this.context.t("2"), value: 2 },
              { name: this.context.t("3"), value: 3 },
              { name: this.context.t("4"), value: 4 },
              { name: this.context.t("5"), value: 5 },
            ]}
            onClick={(name, value) => {
              this.setState({ rating_dest_employer: value });
            }}
          />
        </GridItem>
      </form>
    );
  }
}

EmploymentFields.propTypes = {
  classes: PropTypes.object,
};

EmploymentFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default withStyles(style)(EmploymentFields);
