import React from 'react';

// @material-ui/core components
import {
    Select,
    FormControl,
    InputLabel,
    MenuItem
} from '@material-ui/core';

const SupplierStatusDropDownLight = (props) => {

    const statuses = [
        { status: 'Pre Production', value: 'pre_production' },
        { status: 'Active', value: 'active' },
        { status: 'Inactive', value: 'inactive' },
    ]

    const handleSelectStatus = (e) => {
        props.onSelect(e.target.value)
    }

    return (
        <div>
            <FormControl fullWidth inline>
                <InputLabel>Select Status</InputLabel>
                <Select
                    defaultValue=""
                    name="supplier_status"
                    onChange={handleSelectStatus}
                >
                    {statuses.map(option => {
                        return <MenuItem value={option.value} primaryText={option.status}>{option.status}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <hr />
        </div>
    );
};

export default SupplierStatusDropDownLight;
