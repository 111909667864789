import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// core components
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import Button from "components/CustomButtons/Button.js";
import CloseIcon from "@material-ui/icons/Close";
import GridItem from "components/Grid/GridItem.js";

const customStyles = {
  paper: {
    backgroundColor: "white",
    padding: 30,
    webkitBoxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
    mozBoxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
    boxShadow: "5px 6px 10px 0px rgba(0,0,0,0.27)",
  },
  modal: {
    display: "block",
    maxWidth: "70%",
    height: "100%",
    overflowWrap: "break-word",
    overflowY: "auto;",
    overflowX: "none;",
    paddingBottom: "20px",
  },
  spanLabel: {
    fontWeight: 500,
  },
  button: {
    position: "absolute",
    padding: 0,
    margin: 0,
    top: "40px",
    right: "40px",
    scrollbars: "none",
  },
  preBlock: {
    whiteSpace: "pre-wrap",
    wordBreak: "keep-all",
    scroll: "none",
    width: "100%",
  },
};

const useStyles = makeStyles(customStyles);

export default function FieldworkActivityModal(props, context) {
  const classes = useStyles();

  const suppliers = useSelector((state) => state.suppliersReducer.items);
  const recruiters = useSelector((state) => state.recruitersReducer.items);
  const strategicPartners = useSelector(
    (state) => state.strategicPartnerReducer.items
  );
  // const fetchingStrategicPartners = useSelector(
  //   (state) => state.strategicPartnerReducer.fetchingStrategicPartners
  // );

  const fieldworkActivities = useSelector(
    (state) => state.teamActivityReducer.fieldworkActivities
  );


  const getNames = (ids, items) => {
    const names = ids
      .map(id => {
        const item = items.find(i => i.id === id);
        return item?.name;
      })
      .filter(Boolean)
      .join(", ");

    return names
  };

  const fieldworkActivity = fieldworkActivities[props.value];

  return (
    <Modal open={props.open} onClose={props.onClose} className={classes.modal}>
      <div className={classes.paper}>
        <h3 style={{ marginTop: 0 }}>
          {context.t("Fieldwork Activity #{id}", { id: props.value })}
        </h3>
        <Button
          simple
          color="danger"
          className={classes.button}
          onClick={() => props.onClose()}
        >
          <CloseIcon />
        </Button>
        {fieldworkActivity && (
          <div>
            <Divider />
            <h5> {context.t("General Information:")} </h5>
            <p>
              <span className={classes.spanLabel}>{context.t("Type:")}</span>
              {fieldworkActivity.fieldwork_type_name}
            </p>
            <p>
              <span className={classes.spanLabel}>{context.t("Date:")}</span>
              {(fieldworkActivity.fieldwork_date &&
                new Date(fieldworkActivity.fieldwork_date).toDateString()) ||
                "-"}
            </p>
            <p>
              <span className={classes.spanLabel}>
                {context.t("Location:")}
              </span>
              {fieldworkActivity.location || "-"}
            </p>
            <p>
              <span className={classes.spanLabel}>
                {context.t("Workers Reached:")}
              </span>
              {fieldworkActivity.outreach_target}
            </p>
            <p>
              <span className={classes.spanLabel}>
                {context.t("Suppliers:")}
              </span>
              {getNames(fieldworkActivity.suppliers, suppliers)}
            </p>
            <p>
              <span className={classes.spanLabel}>
                {context.t("Recruiters:")}
              </span>
              {getNames(fieldworkActivity.recruiters, recruiters)}
            </p>
            <p>
              <span className={classes.spanLabel}>
                {context.t("Partners:")}
              </span>
              {getNames(fieldworkActivity.strategic_partners, strategicPartners)}
            </p>
            <p>
              <span className={classes.spanLabel}>
                {context.t("Nationality:")}
              </span>
              {fieldworkActivity.nationality_name}
            </p>
            <p>
              <span className={classes.spanLabel}> {context.t("Notes:")}</span>
              <GridItem xs={12} md={12} lg={12}>
                <p
                  className={classes.preBlock}
                  style={{ overflow: "overflow-wrap" }}
                >
                  {fieldworkActivity.notes}
                </p>
              </GridItem>
            </p>
            <p>
              <span className={classes.spanLabel}> {context.t("Links:")} </span>
              {fieldworkActivity.links &&
                fieldworkActivity.links.split(",").map((link) => {
                  return (
                    <div>
                      <a href={link}>{link}</a>
                    </div>
                  );
                })}
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
}

FieldworkActivityModal.contextTypes = {
  t: PropTypes.func.isRequired,
};
