import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";

import { useDispatch, useSelector } from "react-redux";

import Datetime from "react-datetime";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import { fetchSharedFiles } from "../../redux/actions/StrategicPartnerActions";
import { fetchNationalities } from "../../redux/actions/LocaleActions";
import C3Chart from "react-c3js";
import "c3/c3.css";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import { Button as OtherButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SupplierKpisTimelineChart from "../../components/Charts/SupplierKpisTimelineChart";
import SpeedometerStat from "../Dashboard/SpeedometerStat.js";

import {
  fetchSuppliers,
  fetchSupplierKpiUpdates,
  SupplyChainData,
} from "redux/actions/SupplierActions.js";
import {
  fetchSupplyChains,
  fetchStrategicPartners,
} from "redux/actions/StrategicPartnerActions";
import { fetchRecruiters } from "redux/actions/RecruiterActions.js";

import ComponentToPrint from "./ComponentToPrint";
import { useReactToPrint } from "react-to-print";
import Utils from "../../services/utils";

import loginStore from "../../redux/stores/LoginStore";

import { OpenClosedKpisListSummary } from "./SupplyChainDashboardKpi";
import GrievanceMechanismsDashboard from "./GrievanceMechanismsDashboard";

import { fetchKPICategoryList } from "redux/actions/IssueActions.js";

import moment from "moment";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import SuppliersPaginateDropdown from "components/Async/SuppliersPaginateDropdown";
import CallsAndMessagesFromWorkersChart from "./CallsAndMessagesFromWorkersChart";
import TotalWorkerCasesSummaryPanels from "./TotalWorkerCasesSummaryPanels";

const customStyles = {
  ...styles,
  largeStatContainer: {
    paddingTop: "6.3rem",
    paddingBottom: "6.7rem",
    margin: "10px 0 0 0",
  },
  largeStatContainer2: {
    paddingTop: "6.3rem",
    paddingBottom: "7.3rem",
    margin: "10px 0 0 0",
  },
  largeStatNumber: {
    textAlign: "center",
    fontSize: "3.5rem",
    fontWeight: 500,
    minHeight: "2.5rem",
  },
  largeStatName: {
    textAlign: "center",
    fontSize: "1.3rem",
    fontWeight: 400,
  },
  smallStatContainer: {
    paddingTop: "1.8rem",
    paddingBottom: "1rem",
    margin: "0 0 1rem 0",
  },
  smallStatNumber: {
    textAlign: "center",
    fontSize: "2rem",
    fontWeight: 600,
    minHeight: "1.65rem",
  },
  smallStatName: {
    textAlign: "center",
    fontSize: "1.1rem",
    fontWeight: 400,
  },
  outreachTable: {
    padding: "0.7rem 1rem 0.7rem 1rem",
    margin: "10px 0 1rem 0",
  },
  marginTopZero: {
    marginTop: 0,
  },
  marginBottom: {
    marginBottom: "1rem",
  },
  center: {
    textAlign: "center",
  },
  marginAuto: {
    margin: "auto",
  },
  gaugeBox: {
    minHeight: "10rem",
    margin: "auto",
  },

  gaugeTableText: {
    fontSize: "1.2rem",
    fontWeight: 500,
  },
  gaugeContainer: {
    textAlign: "center",
    width: "200px",
    height: "200px",
  },
  button: {
    padding: 0,
    margin: 0,
  },
  chartcontainerholder: {
    minWidth: "850px",
  },
  galleryImg: {
    /* CSS Hack will make it width 100% and height 100% */
    width: "30px",
    height: "18px",
    maxWidth: "30px",
    maxHeight: "20px",
    paddingRight: "5px",
    paddingBottom: "4px",
  },
  galleryContainer: {},
  galleryContainerBigger: {
    width: "100px",
    height: "100px",
  },
  cardCategory: {
    padding: "20px",
  },
};

const useStyles = makeStyles(customStyles);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

/**
 *
 * @param {suppliers, suppliersMap} props
 */
export function SupplyChainWorkforceSummaryPanels(props, context) {
  const classes = useStyles();
  const { suppliersMap, suppliers } = props;
  const sharedFiles = props.sharedFiles;
  const nationalities = props.nationalities;
  const [totalWorkers, setTotalWorkers] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (suppliers && suppliers?.length > 0) {
      let updatedTotalWorkers = [];
      const requests = suppliers.map((supplierId) =>
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/suppliers/${supplierId}/total_workers/`
          )
          .then((response) => {
            if (isMounted) {
              updatedTotalWorkers.push(response.data.Total_workers);
            }
          })
          .catch((error) => {
            console.log(error);
            if (isMounted) {
              updatedTotalWorkers.push(0);
            }

            return new Promise((resolve) => setTimeout(resolve, 1000)).then(
              () =>
                axios.get(
                  `${process.env.REACT_APP_API_URL}/suppliers/${supplierId}/total_workers/`
                )
            );
          })
      );
      Promise.all(requests).then(() => {
        if (isMounted) {
          setTotalWorkers(updatedTotalWorkers);
        }
      });
    } else {
      if (isMounted) {
        setTotalWorkers([0]);
      }
    }
    return () => {
      isMounted = false;
    };
  }, [suppliers]);

  if (!suppliers && !Array.isArray(suppliers) && suppliers.length < 1) {
    return <div>{context.t("Not selected suppliers to chart")}</div>;
  }

  return (
    <div key={Utils.giveMeGuid()}>
      <HtmlTooltip
        title={
          <h6 className={classes.center}>
            <p>
              {context.t(
                "These workforce demographics are based on figures provided by your supplier to Issara Institute. Please note the figures correspond to the suppliers selected at top."
              )}
            </p>
          </h6>
        }
        interactive
      >
        <div className="cell-overflow">
          <h3 className={classes.center}>
            {context.t("Workforce Demographics")}
          </h3>
        </div>
      </HtmlTooltip>
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} lg={3}>
          <Card className={classes.largeStatContainer}>
            <span>
              <div
                className={`${classes.cardTitle} ${classes.largeStatNumber}`}
              >
                {/* {Utils.formatNumber((() => {
              if (suppliers) {
                return eval(totalWorkers.join('+'));
              }
            }
            )())} */}
                {eval(totalWorkers.join("+"))}
              </div>
              <p className={`${classes.cardCategory} ${classes.largeStatName}`}>
                {context.t("Total")}
              </p>
            </span>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={8} lg={9}>
          <GridContainer>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {Utils.formatNumber(
                    (() => {
                      let total = 0;
                      suppliers.forEach((item) => {
                        let totalWorkers =
                          suppliersMap[item] &&
                          suppliersMap[item].total_num_thai_workers !==
                            undefined &&
                          suppliersMap[item].total_num_thai_workers !== null
                            ? suppliersMap[item].total_num_thai_workers
                            : 0;
                        total += totalWorkers;
                      });
                      return total;
                    })()
                  )}
                </div>
                {(() => {
                  let nationality = nationalities.find((nationalityItem) => {
                    return (
                      nationalityItem.name.toLowerCase().indexOf("thai") !== -1
                    );
                  });

                  let sharedFileFlag =
                    nationality !== null && nationality !== undefined
                      ? sharedFiles.find((sharedFileItem) => {
                          return sharedFileItem.id === nationality.imageicon;
                        })
                      : null;

                  return (
                    <span
                      className={`${classes.cardCategory} ${classes.smallStatName}`}
                    >
                      {sharedFileFlag && (
                        <img
                          className={classes.galleryImg}
                          src={sharedFileFlag.file_path}
                        />
                      )}
                      {context.t("Thai")}
                    </span>
                  );
                })()}
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {Utils.formatNumber(
                    (() => {
                      let total = 0;
                      suppliers.forEach((item) => {
                        let totalWorkers =
                          suppliersMap[item] &&
                          suppliersMap[item].total_num_cambodian_workers !==
                            undefined &&
                          suppliersMap[item].total_num_cambodian_workers !==
                            null
                            ? suppliersMap[item].total_num_cambodian_workers
                            : 0;
                        total += totalWorkers;
                      });
                      return total;
                    })()
                  )}
                </div>
                {(() => {
                  let nationality = nationalities.find((nationalityItem) => {
                    return (
                      nationalityItem.name.toLowerCase().indexOf("cambodia") !==
                      -1
                    );
                  });

                  let sharedFileFlag =
                    nationality !== null && nationality !== undefined
                      ? sharedFiles.find((sharedFileItem) => {
                          return sharedFileItem.id === nationality.imageicon;
                        })
                      : null;

                  return (
                    <span
                      className={`${classes.cardCategory} ${classes.smallStatName}`}
                    >
                      {sharedFileFlag && (
                        <img
                          className={classes.galleryImg}
                          src={sharedFileFlag.file_path}
                        />
                      )}
                      {context.t("Cambodia")}
                    </span>
                  );
                })()}
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {Utils.formatNumber(
                    (() => {
                      let total = 0;
                      suppliers.forEach((item) => {
                        let totalWorkers =
                          suppliersMap[item] &&
                          suppliersMap[item].total_num_myanmar_workers !==
                            undefined &&
                          suppliersMap[item].total_num_myanmar_workers !== null
                            ? suppliersMap[item].total_num_myanmar_workers
                            : 0;
                        total += totalWorkers;
                      });
                      return total;
                    })()
                  )}
                </div>
                {(() => {
                  let nationality = nationalities.find((nationalityItem) => {
                    return (
                      nationalityItem.name.toLowerCase().indexOf("burmese") !==
                      -1
                    );
                  });

                  let sharedFileFlag =
                    nationality !== null && nationality !== undefined
                      ? sharedFiles.find((sharedFileItem) => {
                          return sharedFileItem.id === nationality.imageicon;
                        })
                      : null;

                  return (
                    <span
                      className={`${classes.cardCategory} ${classes.smallStatName}`}
                    >
                      {sharedFileFlag && (
                        <img
                          className={classes.galleryImg}
                          src={sharedFileFlag.file_path}
                        />
                      )}
                      {context.t("Myanmar")}
                    </span>
                  );
                })()}
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {Utils.formatNumber(
                    (() => {
                      let total = 0;
                      suppliers.forEach((item) => {
                        let totalWorkers =
                          suppliersMap[item] &&
                          suppliersMap[item].total_num_lao_workers !==
                            undefined &&
                          suppliersMap[item].total_num_lao_workers !== null
                            ? suppliersMap[item].total_num_lao_workers
                            : 0;
                        total += totalWorkers;
                      });
                      return total;
                    })()
                  )}
                </div>
                {(() => {
                  let nationality = nationalities.find((nationalityItem) => {
                    return (
                      nationalityItem.name.toLowerCase().indexOf("lao") !== -1
                    );
                  });

                  let sharedFileFlag =
                    nationality !== null && nationality !== undefined
                      ? sharedFiles.find((sharedFileItem) => {
                          return sharedFileItem.id === nationality.imageicon;
                        })
                      : null;

                  return (
                    <span
                      className={`${classes.cardCategory} ${classes.smallStatName}`}
                    >
                      {sharedFileFlag && (
                        <img
                          className={classes.galleryImg}
                          src={sharedFileFlag.file_path}
                        />
                      )}
                      {context.t("Lao")}
                    </span>
                  );
                })()}
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {Utils.formatNumber(
                    (() => {
                      let total = 0;
                      suppliers.forEach((item) => {
                        let totalWorkers =
                          suppliersMap[item] &&
                          suppliersMap[item].total_num_vietnamese_workers !==
                            undefined &&
                          suppliersMap[item].total_num_vietnamese_workers !==
                            null
                            ? suppliersMap[item].total_num_vietnamese_workers
                            : 0;
                        total += totalWorkers;
                      });
                      return total;
                    })()
                  )}
                </div>
                {(() => {
                  let sharedFileFlag = sharedFiles.find((sharedFileItem) => {
                    return sharedFileItem.id === 31; // ID for shared file Vietnam
                  });

                  return (
                    <span
                      className={`${classes.cardCategory} ${classes.smallStatName}`}
                    >
                      {sharedFileFlag && (
                        <img
                          className={classes.galleryImg}
                          src={sharedFileFlag.file_path}
                        />
                      )}
                      {context.t("Vietnamese")}
                    </span>
                  );
                })()}
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {Utils.formatNumber(
                    (() => {
                      let total = 0;
                      suppliers.forEach((item) => {
                        let totalWorkers =
                          suppliersMap[item] &&
                          suppliersMap[item].total_num_nepalese_workers !==
                            undefined &&
                          suppliersMap[item].total_num_nepalese_workers !== null
                            ? suppliersMap[item].total_num_nepalese_workers
                            : 0;
                        total += totalWorkers;
                      });
                      return total;
                    })()
                  )}
                </div>
                {(() => {
                  let nationality = nationalities.find((nationalityItem) => {
                    return (
                      nationalityItem.name.toLowerCase().indexOf("nep") !== -1
                    );
                  });

                  let sharedFileFlag =
                    nationality !== null && nationality !== undefined
                      ? sharedFiles.find((sharedFileItem) => {
                          return sharedFileItem.id === nationality.imageicon;
                        })
                      : null;

                  return (
                    <span
                      className={`${classes.cardCategory} ${classes.smallStatName}`}
                    >
                      {sharedFileFlag && (
                        <img
                          className={classes.galleryImg}
                          src={sharedFileFlag.file_path}
                        />
                      )}
                      {context.t("Nepalese")}
                    </span>
                  );
                })()}
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {Utils.formatNumber(
                    (() => {
                      let total = 0;
                      suppliers.forEach((item) => {
                        let totalWorkers =
                          suppliersMap[item] &&
                          suppliersMap[item].total_num_bangladeshi_workers !==
                            undefined &&
                          suppliersMap[item].total_num_bangladeshi_workers !==
                            null
                            ? suppliersMap[item].total_num_bangladeshi_workers
                            : 0;
                        total += totalWorkers;
                      });
                      return total;
                    })()
                  )}
                </div>
                {(() => {
                  let nationality = nationalities.find((nationalityItem) => {
                    return (
                      nationalityItem.name.toLowerCase().indexOf("ban") !== -1
                    );
                  });

                  let sharedFileFlag =
                    nationality !== null && nationality !== undefined
                      ? sharedFiles.find((sharedFileItem) => {
                          return sharedFileItem.id === nationality.imageicon;
                        })
                      : null;

                  return (
                    <span
                      className={`${classes.cardCategory} ${classes.smallStatName}`}
                    >
                      {sharedFileFlag && (
                        <img
                          className={classes.galleryImg}
                          src={sharedFileFlag.file_path}
                        />
                      )}
                      {context.t("Bangladeshi")}
                    </span>
                  );
                })()}
              </Card>
            </GridItem>
            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {Utils.formatNumber(
                    (() => {
                      let total = 0;
                      suppliers.forEach((item) => {
                        let totalWorkers =
                          suppliersMap[item] &&
                          suppliersMap[item].total_num_indonesian_workers !==
                            undefined &&
                          suppliersMap[item].total_num_indonesian_workers !==
                            null
                            ? suppliersMap[item].total_num_indonesian_workers
                            : 0;
                        total += totalWorkers;
                      });
                      return total;
                    })()
                  )}
                </div>
                {(() => {
                  let nationality = nationalities.find((nationalityItem) => {
                    return (
                      nationalityItem.name.toLowerCase().indexOf("ind") !== -1
                    );
                  });

                  let sharedFileFlag =
                    nationality !== null && nationality !== undefined
                      ? sharedFiles.find((sharedFileItem) => {
                          return sharedFileItem.id === nationality.imageicon;
                        })
                      : null;

                  return (
                    <span
                      className={`${classes.cardCategory} ${classes.smallStatName}`}
                    >
                      {sharedFileFlag && (
                        <img
                          className={classes.galleryImg}
                          src={sharedFileFlag.file_path}
                        />
                      )}
                      {context.t("Indonesian")}
                    </span>
                  );
                })()}
              </Card>
            </GridItem>

            <GridItem xs={6} sm={4} md={4} lg={4}>
              <Card className={classes.smallStatContainer}>
                <div
                  className={`${classes.cardTitle} ${classes.smallStatNumber}`}
                >
                  {Utils.formatNumber(
                    (() => {
                      let total = 0;
                      suppliers.forEach((item) => {
                        let totalWorkers =
                          suppliersMap[item] &&
                          suppliersMap[item].total_num_malaysian_workers !==
                            undefined &&
                          suppliersMap[item].total_num_malaysian_workers !==
                            null
                            ? suppliersMap[item].total_num_malaysian_workers
                            : 0;
                        total += totalWorkers;
                      });
                      return total;
                    })()
                  )}
                </div>
                {(() => {
                  let nationality = nationalities.find((nationalityItem) => {
                    return (
                      nationalityItem.name.toLowerCase().indexOf("mal") !== -1
                    );
                  });

                  let sharedFileFlag =
                    nationality !== null && nationality !== undefined
                      ? sharedFiles.find((sharedFileItem) => {
                          return sharedFileItem.id === nationality.imageicon;
                        })
                      : null;

                  return (
                    <span
                      className={`${classes.cardCategory} ${classes.smallStatName}`}
                    >
                      {sharedFileFlag && (
                        <img
                          className={classes.galleryImg}
                          src={sharedFileFlag.file_path}
                        />
                      )}
                      {context.t("Malaysian")}
                    </span>
                  );
                })()}
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}

/**
 * <CRCScoreSummaryPanels key={Utils.giveMeGuid()} workerInteractions={workerInteractions} />
 * @param {workerInteractions} props
 * @returns
 */
function CRCScoreSummaryPanels(props) {
  const classes = useStyles();
  const { supplier } = props;
  const [chartData, setChartData] = useState({});
  const [showCRCChart, setShowCRCChart] = useState(false);
  const workerInteractions = props.workerInteractions;
  const [qualityAvg, setQualityAvg] = useState(0);
  const [timelinessAvg, setTimelinessAvg] = useState(0);
  const [opennessAvg, setOpennessAvg] = useState(0);
  const [totalCRCCount, setTotalCRCCount] = useState(0);

  useEffect(() => {
    if (workerInteractions.crcRawScored) {
      if (loginStore.isGlobalPartner() === true) {
        const result = Utils.generateCrCAveragesMap(
          workerInteractions.crcRawScored,
          false
        );
        const crcDataSummary = result.get(supplier);
        if (crcDataSummary) {
          setQualityAvg(crcDataSummary.quality_avg);
          setTimelinessAvg(crcDataSummary.timeliness_avg);
          setOpennessAvg(crcDataSummary.openness_avg);
          setTotalCRCCount(crcDataSummary.supplier_crc_scores.length);
        }
      } else {
        const result = Utils.generateCrCAveragesMap(
          workerInteractions.crcRawScored
        );
        if (result) {
          setChartData(result);
          setShowCRCChart(true);
        }
      }
    }
  }, [workerInteractions]);

  return (
    <div>
      <h3 className={classes.center}> Supplier Performance </h3>
      {/* Disable progress bar*/}
      <GridContainer>
        {showCRCChart &&
        (loginStore.isIssaraManagement() || loginStore.isIssaraStaff()) ? (
          <C3Chart
            data={chartData.data}
            axis={chartData.axis}
            bar={chartData.bar ? chartData.bar : {}}
          />
        ) : null}
      </GridContainer>
      {loginStore.isGlobalPartner() === true ? (
        <GridContainer style={{ paddingLeft: "20%" }}>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card className={classes.gaugeContainer}>
                  <div className={classes.gaugeBox}>
                    <SpeedometerStat
                      value={parseFloat(
                        (qualityAvg / totalCRCCount).toFixed(2)
                      )}
                    />
                  </div>
                  <p className={classes.gaugeTableText}>
                    {" "}
                    Quality of Response{" "}
                  </p>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card className={classes.gaugeContainer}>
                  <div className={classes.gaugeBox}>
                    <SpeedometerStat
                      value={parseFloat(
                        (timelinessAvg / totalCRCCount).toFixed(2)
                      )}
                    />
                  </div>
                  <p className={classes.gaugeTableText}>
                    {" "}
                    Timeliness of Response{" "}
                  </p>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Card className={classes.gaugeContainer}>
                  <div className={classes.gaugeBox}>
                    <SpeedometerStat
                      value={parseFloat(
                        (opennessAvg / totalCRCCount).toFixed(2)
                      )}
                    />
                  </div>
                  <p className={classes.gaugeTableText}> Openness to Reform </p>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      ) : (
        <GridContainer></GridContainer>
      )}
    </div>
  );
}

export default function SupplierKpiDashboard(props, context) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const supplierKPIs = useSelector((state) => {
    return state.suppliersReducer.supplierKPIs;
  });
  const supplierKPIUpdateItems = useSelector(
    (state) => state.suppliersReducer.supplierKPIUpdateItems
  );
  const kpiCategories = useSelector((state) => state.kpisReducer);

  const sharedFiles = useSelector(
    (state) => state.sharedFilesReducer.sharedFiles
  );

  const nationalities = useSelector(
    (state) => state.nationalitiesReducer.items
  );

  const suppliers = useSelector((state) => state.suppliersReducer.items);
  const supplyChains = useSelector((state) => state.supplyChainReducer.items);
  const strategicPartners = useSelector(
    (state) => state.strategicPartnerReducer.strategicPartners
  );
  const suppliersMap = useSelector((state) => state.suppliersReducer.itemsMap);
  const [supplier, setSupplier] = useState(null);

  const [workerInteractions, setWorkerInteractions] = useState({});
  const [totalWorkerInteractions, setTotalWorkerInteractions] = useState({});

  const [totalWorkerCalls, setTotalWorkerCalls] = useState({
    x: ["x"],
    y: ["Worker Calls and Messages By Month"],
  });

  const [partnerObject, setPartnerObject] = useState(null);

  // FILTERS
  const [filters, setFilters] = useState({
    supplier: null,
    supplyChain: null,
    startTime: moment("2018-01-01"),
    endTime: moment(),
    search: "",
  });

  const [modal, setModal] = useState(null);
  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    if (suppliers.length > 0 && filters.supplier) {
      const supplier = suppliers.filter((item) => {
        return item.id == filters.supplier;
      })[0];
      setSupplier(supplier);
    }
  }, [suppliers, filters]);

  const fetchMetricsFilters = (filters) => {
    Promise.all([
      new Promise((resolve) => {
        axios
          .get(`${process.env.REACT_APP_API_URL}/metrics/interactions`, {
            params: {
              start: filters.startTime ? filters.startTime.unix() : "",
              end: filters.endTime ? filters.endTime.unix() : "",
              supplierIds: filters.supplier ? filters.supplier : "",
            },
          })
          .then((response) => {
            const interactions = response.data;

            if (filters.supplier) {
              if (interactions !== workerInteractions) {
                setWorkerInteractions(interactions);
                setShowChart(true);
              }
              setTotalWorkerInteractions(interactions);
            }

            const totalProvinceCallsNew = [];
            const totalNationalityCallsNew = [];

            interactions.nationalityByCallCount.forEach((item) => {
              totalNationalityCallsNew.push([
                item.client_nationality__name,
                item.client_nationality__count,
                item.client_nationality__id,
              ]);
            });

            interactions.districtsByCallCount.forEach((item) => {
              totalProvinceCallsNew.push({
                name: item.district__name,
                call_count: item.district__count,
                lng: item.lng,
                lat: item.lat,
              });
            });

            const totalWorkerCallsNew = {
              x: ["x"],
              y: ["Worker Calls and Messages By Month"],
            };

            interactions.caseCountByYearMonth.forEach((item) => {
              totalWorkerCallsNew.x.push(item.month_year + "-01");
              totalWorkerCallsNew.y.push(item.call_count);
            });

            setTotalWorkerCalls(totalWorkerCallsNew);

            resolve(interactions);
          })
          .catch((err) => {
            console.log(err.message);
            resolve(err.message);
          });
      }),
    ]).then((resolve) => {});
  };

  useEffect(() => {
    dispatch(fetchNationalities());
    dispatch(fetchSharedFiles());
    dispatch(SupplyChainData(false, false, false, false));
    dispatch(fetchRecruiters());
    dispatch(fetchKPICategoryList());

    dispatch(fetchSupplyChains());
    dispatch(fetchStrategicPartners());
    if (suppliers === null || suppliers === undefined || suppliers.length < 1) {
      dispatch(fetchSuppliers());
    }
    dispatch(fetchSupplierKpiUpdates());
  }, []);

  useEffect(() => {
    fetchMetricsFilters(filters);
    let partnerData = Utils.findPartnerAndSupplyChain(
      loginStore.getLoginUser(),
      supplyChains,
      strategicPartners
    );
    setPartnerObject(partnerData);
  }, [filters, supplierKPIUpdateItems]);

  useEffect(() => {
    if (filters.supplier) {
      fetchMetricsFilters(filters);
    }
  }, [filters.supplier]);

  const componentRef = useRef(this);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (
    suppliers === null ||
    suppliers === undefined ||
    suppliers.length === undefined ||
    suppliers.length < 1 ||
    (supplierKPIUpdateItems === null ||
      supplierKPIUpdateItems === undefined ||
      supplierKPIUpdateItems.length === undefined ||
      supplierKPIUpdateItems.length < 1) ||
    (sharedFiles === null ||
      sharedFiles === undefined ||
      sharedFiles.length === undefined ||
      sharedFiles.length < 1) ||
    (nationalities === null ||
      nationalities === undefined ||
      nationalities.length === undefined ||
      nationalities.length < 1) ||
    (kpiCategories === null || kpiCategories === undefined) ||
    (kpiCategories.kpicategoryitems === null ||
      kpiCategories.kpicategoryitems === undefined ||
      kpiCategories.kpicategoryitems.length < 1) ||
    (totalWorkerCalls === null || totalWorkerCalls === undefined) ||
    (suppliersMap === null ||
      suppliersMap === undefined ||
      Object.keys(suppliersMap).length < 1) ||
    (supplierKPIs === null ||
      supplierKPIs === undefined ||
      Object.keys(supplierKPIs).length < 1)
  ) {
    return <CircularProgress />;
  }

  let displayNode = (
    <div>
      {modal}

      <GridContainer className={classes.marginBottom}>
        <GridItem xs={6} sm={6} md={6} lg={6}>
          <SuppliersPaginateDropdown
            value={filters.supplier}
            isMulti={false}
            onSelect={(supplier) => {
              setFilters({
                supplier: supplier,
                supplyChain: null,
                endTime: props.endTime ? props.endTime : moment(),
                startTime: props.startTime
                  ? props.startTime
                  : moment("2018-01-01"),
                search: "",
              });
            }}
          />
        </GridItem>

        <GridItem xs={6} sm={2} lg={2} xl={1}>
          <InputLabel className={classes.label}>
            {context.t("Between")}
          </InputLabel>
          <FormControl fullWidth>
            <Datetime
              value={filters.startTime}
              timeFormat={false}
              inputProps={{ placeholder: context.t("Start") }}
              onChange={(date) =>
                typeof date === "object" &&
                setFilters({ ...filters, startTime: date })
              }
              closeOnSelect={true}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={6} sm={2} lg={2} xl={1}>
          <InputLabel className={classes.label}>{context.t("And")}</InputLabel>
          <FormControl fullWidth>
            <Datetime
              value={filters.endTime}
              timeFormat={false}
              inputProps={{ placeholder: context.t("End") }}
              onChange={(date) =>
                typeof date === "object" &&
                setFilters({ ...filters, endTime: date })
              }
              closeOnSelect={true}
            />
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={2} lg={2} xl={2}>
          <FormControl justify="center">
            <Button
              color="rose"
              onClick={() =>
                setFilters({
                  supplier: null,
                  supplyChain: null,
                  endTime: props.endTime ? props.endTime : moment(),
                  startTime: props.startTime
                    ? props.startTime
                    : moment("2018-01-01"),
                  search: "",
                })
              }
            >
              {context.t("Reset all")}
            </Button>
          </FormControl>
        </GridItem>
      </GridContainer>

      {!filters.supplier ? (
        <div style={{ padding: "40px" }}>
          <Card>
            <CardHeader>
              <h4 className={classes.center}>
                {context.t("* Please Select Supplier")}
              </h4>
            </CardHeader>
          </Card>
        </div>
      ) : (
        <div>
          <GridContainer>
            <GridItem>
              <br />
            </GridItem>
            <GridItem>
              <br />
            </GridItem>
          </GridContainer>

          <SupplyChainWorkforceSummaryPanels
            context={context}
            suppliers={[filters.supplier]}
            suppliersMap={suppliersMap}
            sharedFiles={sharedFiles}
            nationalities={nationalities}
          />

          <GridContainer>
            <GridItem>
              <br />
            </GridItem>
            <GridItem>
              <br />
            </GridItem>
          </GridContainer>

          <CallsAndMessagesFromWorkersChart
            totalWorkerCalls={totalWorkerCalls}
          />

          {supplier?.worker_voice != null && (
            <Card>
              <CardBody>
                <h4>{context.t("Worker Voice")}</h4>
                <span>
                  {supplier?.worker_voice != null
                    ? supplier.worker_voice
                      ? context.t("There is worker voice in this workplace")
                      : context.t("There is no worker voice in this workplace")
                    : ""}
                </span>
              </CardBody>
            </Card>
          )}

          <GridContainer>
            <GridItem>
              <br />
            </GridItem>
            <GridItem>
              <br />
            </GridItem>
          </GridContainer>

          <TotalWorkerCasesSummaryPanels
            totalWorkerInteractions={totalWorkerInteractions}
            sharedFiles={sharedFiles}
            nationalities={nationalities}
          />

          {showChart ? (
            <CRCScoreSummaryPanels
              context={context}
              workerInteractions={workerInteractions}
              supplier={filters.supplier}
            />
          ) : null}

          {supplier && supplier?.reports?.length > 0 && (
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12}>
                    {supplier &&
                      supplier?.reports?.length > 0 &&
                      supplier.reports.map((report, index) => {
                        return (
                          <div key={report.id + index} title={report.name}>
                            <h6>
                              {context.t("Report File ({index})", {
                                index: index + 1,
                              })}
                            </h6>
                            <p>
                              {context.t("File Name: {name}", {
                                name: report.name,
                              })}
                            </p>
                            <Tooltip
                              title={context.t("Click to see the file")}
                              placement="top-start"
                            >
                              <OtherButton
                                href={report.file_path}
                                target="_blank"
                                variant="outlined"
                                style={{ backgroundColor: "#00acc1" }}
                                className={classes.button}
                                startIcon={<VisibilityIcon />}
                              >
                                {context.t("View")}
                              </OtherButton>
                            </Tooltip>
                            {supplier.reports.length > 1 && <hr />}
                          </div>
                        );
                      })}
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          )}

          <GrievanceMechanismsDashboard
            key={Utils.giveMeGuid()}
            selectedSuppliers={[filters.supplier]}
          />

          <SupplierKpisTimelineChart filters={filters} />

          <OpenClosedKpisListSummary
            key={Utils.giveMeGuid()}
            selectedSuppliers={[filters.supplier]}
          />
        </div>
      )}
    </div>
  );

  return (
    <div>
      <div style={{ display: "none" }}>
        <ComponentToPrint
          key={Utils.giveMeGuid()}
          ref={componentRef}
          render={() => {
            return displayNode;
          }}
        />
      </div>
      <Button onClick={handlePrint}>{context.t("Print Report")}</Button>
      {displayNode}
    </div>
  );
}

SupplierKpiDashboard.contextTypes = {
  t: PropTypes.func.isRequired,
};

SupplyChainWorkforceSummaryPanels.contextTypes = {
  t: PropTypes.func.isRequired,
};
