import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import Divider from '@material-ui/core/Divider';
import FormControl from "@material-ui/core/FormControl";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import CloseIcon from '@material-ui/icons/Close';

import Datetime from "react-datetime";

import SuppliersDropdown from "components/ilmdb/SuppliersDropdown.js";
import RecruitersDropdown from "components/ilmdb/RecruitersDropdown.js";
import StrategicPartnersDropdown from "components/ilmdb/StrategicPartnersDropdown.js";
import IssaraStaffDropdown from "components/ilmdb/IssaraStaffDropdown.js";
import ResponseInteractionTypesDropdown from "components/ilmdb/ResponseInteractionTypesDropdown.js";

import { fetchRecruiters } from "../../redux/actions/RecruiterActions";
import { fetchStrategicPartners } from "../../redux/actions/StrategicPartnerActions";

import sweetAlertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";


const customStyles = {
  paper: {
    backgroundColor: 'white',
    padding: 30,
    webkitBoxShadow: '5px 6px 10px 0px rgba(0,0,0,0.27)',
    mozBoxShadow: '5px 6px 10px 0px rgba(0,0,0,0.27)',
    boxShadow: '5px 6px 10px 0px rgba(0,0,0,0.27)',
  },
  modal: {
    display: 'block',
    maxWidth: '70%',
    height: '100%',
    overflowWrap: "break-word",
    overflowY: "auto;",
    overflowX: "none;",
    paddingBottom: "20px"
  },
  spanLabel: {
    fontWeight: 500,
  },
  button: {
    position: 'absolute',
    padding: 0,
    margin: 0,
    top: '40px',
    right: '40px',
    scrollbars: "none"
  },
  preBlock: {
    whiteSpace: "pre-wrap",
    wordBreak: "keep-all",
    scroll: "none",
    width: "100%"
  }

};

const useStyles = makeStyles(customStyles);


export default function GrievanceMechanismsModal(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const businessResponses = useSelector(state => state.businessResponsesReducer.items)
  const grievanceMechanisms = useSelector(state => state.grievanceMechanismsReducer.items)
  const users = useSelector(state => state.usersReducer.items)
  const suppliers = useSelector(state => state.suppliersReducer.items)
  const recruiters = useSelector(state => state.recruitersReducer.items)
  const strategicPartners = useSelector(state => state.strategicPartnerReducer.items)
  const interactionTypes = useSelector(state => state.responseInteractionTypesReducer.items)

  useEffect(() => {
    // Fetch recruiters
    if (recruiters.length === 0) {
      dispatch(fetchRecruiters())
    }
    // Fetch strategicPartners
    if (strategicPartners.length === 0) {
      dispatch(fetchStrategicPartners())
    }
  }, []);

  const getSupplierName = (id) => {
    const filteredSupplier = suppliers.find((supplier) => {
      return supplier.id === id
    })
    return filteredSupplier.name
  }

  const getRecruiterNames = (ids) => {
    let names = []
    const filteredRecruiters = ids.map(id => {
      return recruiters.find((recruiter) => {
        return recruiter.id === id
      })
    })
    names = filteredRecruiters.map(item => {
      return item.name
    })
    return names.length > 0 ? names.join(', ') : '-'
  }

  const getStrategicPartnerNames = (ids) => {
    let names = []
    const filteredStrategicPartners = ids.map(id => {
      return strategicPartners.find((strategicPartner) => {
        return strategicPartner.id === id
      })
    })
    names = filteredStrategicPartners.map(item => {
      return item.name
    })
    return names.length > 0 ? names.join(', ') : '-'
  }

  const getInteractionTypeName = (id) => {
    const interactionType = interactionTypes.find((element) => {
      return element.id === id
    });
    return interactionType ? interactionType.name : '-'
  }

  const getIssaraNames = (ids) => {
    let names = []
    const filteredUsers = ids.map(id => {
      return users.find((user) => {
        return user.id === id
      })
    })
    names = filteredUsers.map(item => {
      return item.first_name
    })
    return names.length > 0 ? names.join(', ') : '-'
  }

  const businessResponse = businessResponses[props.value]
  const grievanceMechanism = grievanceMechanisms[props.value]

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      className={classes.modal}
    >
      <div className={classes.paper}>
        <h3 style={{ marginTop: 0 }}> {`Grievance Mechanisms #${props.value}`} </h3>
        <Button
          simple
          color="danger"
          className={classes.button}
          onClick={() => props.onClose()}
        >
          <CloseIcon />
        </Button>
        <Divider />
        <h5> Detail View: </h5>
        <p> <span className={classes.spanLabel}>Supplier:</span> {grievanceMechanism && getSupplierName(grievanceMechanism.supplier)} </p>
        <p><span className={classes.spanLabel}>Date of Review:</span>
          <GridItem xs={12} md={12} lg={12}>
            <p className={classes.preBlock} style={{ overflow: "overflow-wrap" }}>
              {grievanceMechanism && grievanceMechanism.date_of_review || '-'}
            </p>
          </GridItem>
        </p>
        <p><span className={classes.spanLabel}>Description:</span>
          <GridItem xs={12} md={12} lg={12}>
            <p className={classes.preBlock} style={{ overflow: "overflow-wrap" }}>
              {grievanceMechanism && grievanceMechanism.description || '-'}
            </p>
          </GridItem>
        </p>
        <p><span className={classes.spanLabel}>Assessment:</span>
          <GridItem xs={12} md={12} lg={12}>
            <p className={classes.preBlock} style={{ overflow: "overflow-wrap" }}>
              {grievanceMechanism && grievanceMechanism.assessment || '-'}
            </p>
          </GridItem>
        </p>
      </div>
    </Modal>
  );
}
