import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import "./css/style1.css";
import swal from "@sweetalert/with-react";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import GridContainer from "components/Grid/GridContainer";
import TextField from "@material-ui/core/TextField";
import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { createWorkPlan } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocOne } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocTwo } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocThree } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocFour } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocFive } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocSix } from "redux/actions/SpWorkplanFormAction.js";
import { createWorkPlanDocSeven } from "redux/actions/SpWorkplanFormAction.js";
import { mainGoalsAndPriority } from "redux/actions/SpWorkplanFormAction.js";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { workplanIsloadingSuccess } from "redux/actions/SpWorkplanFormAction";
import FinalWorkplan from "./FinalWorkplan.js";
import loginStore from "redux/stores/LoginStore";
import LinearProgress from "@material-ui/core/LinearProgress";
import HttpService from "../../../services/HttpService";

const BottomBtn = {
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: "20px",
};
const percentBox = {
  marginTop: "-23px",
  marginLeft: "37px",
};
const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: "full",
  },
  targetbox: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  area: {
    marginTop: 30,
  },
  indicatorpara: {
    marginTop: 20,
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-1),
  },
  popover: {
    pointerEvents: "none",
  },
  checkBox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  area: {
    marginTop: 30,
  },
  checkBox: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  area: {
    marginTop: 30,
  },
  sub_indicatorpara: {
    marginTop: 10,
    marginLeft: "1%",
    display: "flex",
    justifyContent: "space-between",
  },
  textArea: {
    width: "100%",
    border: "1px solid black",
    padding: "10px",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}));

let name = "";
let gl_sdate = "";
let gl_wp = "";
let gl_maingoals = "";
let gl_mainpriority = "";
let gl_actionednEscalated = "";
let gl_prioritySupplierCountriesRegions = "";
let gl_undertakenRecruitmentSuppliers = 0;
let gl_workersInSC = 0;
let gl_tieronesuppliers = 0;
let gl_upstreamsuppliers = 0;
let gl_qualityremediation = 1;
let gl_timelinessremediation = 1;
let gl_opennesstoreform = 1;
let gl_workerreportedissuespercent = 0;
let gl_remediationeffortpercent = 0;
let gl_dontShow = false;

export function DatePicker(props) {
  const [selectedDate, setSelectedDate] = React.useState(
    props.savedSuggestDate
      ? props.savedSuggestDate
      : new Date().toLocaleDateString()
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  function convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  let suggest_date = convert(selectedDate);
  React.useEffect(() => {
    gl_sdate = suggest_date;
  });
  // disable past dates
  const yesterday = moment().subtract(1, "day");
  const disablePastDt = (current) => {
    return current.isAfter(yesterday);
  };
  return (
    <Datetime
      timeFormat={false}
      isValidDate={disablePastDt}
      value={selectedDate}
      onChange={handleDateChange}
      inputProps={{
        placeholder: "Click here to open calendar",
        readOnly: true,
      }}
      closeOnSelect={true}
    />
  );
}
// -----------------------SP form-One---------------------------//
export function SPWorkPlaningFormOne(props, context) {
  const dispatch = useDispatch();
  const current_date = new Date().toLocaleDateString();
  const [fpname, setFpName] = React.useState("");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [workplanID, setWorkplanID] = React.useState();
  const [title, setTitle] = React.useState([]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  function fpNameHandler(e) {
    setFpName(e.target.value);
    document.getElementById("outlined-basic").style.border = "none";
    document.getElementById("error1").style.visibility = "hidden";
  }

  const workplanresponse = useSelector((state) => state.workplanReducer.items);
  const workplanedocone = useSelector(
    (state) => state.workplanDocReducer.items
  );
  const workplanedoctwo = useSelector(
    (state) => state.workplanDocTwoReducer.items
  );
  const workplanedocthree = useSelector(
    (state) => state.workplanDocThreeReducer.items
  );
  const workplanedocfour = useSelector(
    (state) => state.workplanDocFourReducer.items
  );
  const workplanedocfive = useSelector(
    (state) => state.workplanDocFiveReducer.items
  );
  const workplanedocsix = useSelector(
    (state) => state.workplanDocSixReducer.items
  );
  const workplanedocseven = useSelector(
    (state) => state.workplanDocSevenReducer.items
  );

  const [spworkplan, setSPWorkplan] = React.useState([]);
  const [strategicPartner, setStrategicPartner] = React.useState();
  const [workplanedoconedata, setWorkplaneDocOneData] = React.useState([]);
  const [workplanedoctwodata, setWorkplaneDoctwoData] = React.useState([]);
  const [workplanedocthreedata, setWorkplaneDocthreeData] = React.useState([]);
  const [workplanedocfourdata, setWorkplaneDocfourData] = React.useState([]);
  const [workplanedocfivedata, setWorkplaneDocFiveData] = React.useState([]);
  const [workplanedocsixdata, setWorkplaneDocsixData] = React.useState([]);
  const [workplanedocsevendata, setWorkplaneDocsevenData] = React.useState([]);
  const [workplaneAllDocs, setWorkplaneAllDocs] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);
  const [fatchdata, setFatchdata] = React.useState([]);
  const [savedSuggestDate, setSavedSuggestDate] = useState(new Date());
  let _user = loginStore.getLoginUser();

  const callApi = () => {
    setStrategicPartner(_user.partner);

    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/`,
      (response) => {
        setSavedSuggestDate(response.suggested_date);
        setFatchdata(response);
        setStrategicPartner(response.strategic_partner);
        setWorkplanID(response.id);
        if (response.id || localStorage.getItem("workplan_id")) {
          localStorage.setItem("strategicPartner", strategicPartner);
        }
        setFpName(response.fp_name);
        setTitle(response.fp_title);
        setWorkplaneAllDocs(response.documents);
        setSPWorkplan(response);
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const workplanresponseLoading = useSelector(
    (state) => state.isLoadingReducer.isLoading
  );

  React.useEffect(() => {
    if (workplanresponseLoading === true) {
      setLoading(true);
    } else {
      setLoading(false);
    }
    if (workplanresponseLoading === true) {
      props.next();
    }
    callApi();
  }, [
    workplanedocone,
    workplanedoctwo,
    workplanedocthree,
    workplanedocfour,
    workplanedocfive,
    workplanedocsix,
    workplanedocseven,
    workplanresponseLoading,
    Loading,
  ]);
  const createWorkPlanAndDoc = async () => {
    callApi();
    if (fpname === "") {
      document.getElementById("error1").style.visibility = "visible";
    } else {
      document.getElementById("outlined-basic").style.border = "none";
      document.getElementById("error1").style.visibility = "hidden";
      dispatch(
        createWorkPlan({
          strategic_partner: _user.partner,
          fp_name: fpname,
          fp_title: "mr",
          suggested_date: gl_sdate,
        })
      );
      await callApi();
    }
  };
  // PDF file upload event
  const setPdfHandlerSCinfo = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocOne({
          workplan: workplanID,
          document_type: "supply_chain",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerPolicyHR = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocTwo({
          workplan: workplanID,
          document_type: "human_rights",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerPolicyMW = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocThree({
          workplan: workplanID,
          document_type: "migrant_worker_policy",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerPolicyER = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocFour({
          workplan: workplanID,
          document_type: "ethical_recruitment",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerEProtocol = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocFive({
          workplan: workplanID,
          document_type: "worker_voice",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerAuditReports = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocSix({
          workplan: workplanID,
          document_type: "audit_reports",
          document: e.target.files[0],
        })
      );
    }
  };
  const setPdfHandlerOtherKey = (e) => {
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      dispatch(
        createWorkPlanDocSeven({
          workplan: workplanID,
          document_type: "others",
          document: e.target.files[0],
        })
      );
    }
  };
  const reviewFileOne = () => {
    if (workplanedocone.length != 0) {
      window.open(workplanedocone.document.file_path);
    } else {
      window.open(workplanedoconedata.document.file_path);
    }
  };
  const reviewFileTwo = () => {
    if (workplanedoctwo.length != 0) {
      window.open(workplanedoctwo.document.file_path);
    } else {
      window.open(workplanedoctwodata.document.file_path);
    }
  };
  const reviewFileThree = () => {
    if (workplanedocthree.length != 0) {
      window.open(workplanedocthree.document.file_path);
    } else {
      window.open(workplanedocthreedata.document.file_path);
    }
  };
  const reviewFileFour = () => {
    if (workplanedocfour.length != 0) {
      window.open(workplanedocfour.document.file_path);
    } else {
      window.open(workplanedocfourdata.document.file_path);
    }
  };
  const reviewFileFive = () => {
    if (workplanedocfive.length != 0) {
      window.open(workplanedocfive.document.file_path);
    } else {
      window.open(workplanedocfivedata.document.file_path);
    }
  };
  const reviewFileSix = () => {
    if (workplanedocsix.length != 0) {
      window.open(workplanedocsix.document.file_path);
    } else {
      window.open(workplanedocsixdata.document.file_path);
    }
  };
  const reviewFileSeven = () => {
    if (workplanedocseven.length != 0) {
      window.open(workplanedocseven.document.file_path);
    } else {
      window.open(workplanedocsevendata.document.file_path);
    }
  };
  const delDocOne = () => {
    swal({
      title: context.t("Are you sure?"),
      text: context.t(
        "Once deleted, you will not be able to recover this file!"
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedoconedata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedoconedata.id}/`,
            (response) => {
              swal({
                text: context.t("Document removed successfully!"),
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedoconedata) {
                delete workplanedoconedata[key];
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };

  const delDocTwo = () => {
    swal({
      title: context.t("Are you sure?"),
      text: context.t(
        "Once deleted, you will not be able to recover this file!"
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedoctwodata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedoctwodata.id}/`,
            (response) => {
              swal({
                text: context.t("Document removed successfully!"),
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedoctwodata) {
                delete workplanedoctwodata[key];
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };

  const delDocThree = () => {
    swal({
      title: context.t("Are you sure?"),
      text: context.t(
        "Once deleted, you will not be able to recover this file!"
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedocthreedata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedocthreedata.id}/`,
            (response) => {
              swal({
                text: context.t("Document removed successfully!"),
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedocthreedata) {
                delete workplanedocthreedata[key];
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };

  const delDocFour = () => {
    swal({
      title: context.t("Are you sure?"),
      text: context.t(
        "Once deleted, you will not be able to recover this file!"
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedocfourdata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedocfourdata.id}/`,
            (response) => {
              swal({
                text: context.t("Document removed successfully!"),
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedocfourdata) {
                delete workplanedocfourdata[key];
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };

  const delDocFive = () => {
    swal({
      title: context.t("Are you sure?"),
      text: context.t(
        "Once deleted, you will not be able to recover this file!"
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedocfivedata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedocfivedata.id}/`,
            (response) => {
              swal({
                text: context.t("Document removed successfully!"),
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedocfivedata) {
                delete workplanedocfivedata[key];
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };

  const delDocSix = () => {
    swal({
      title: context.t("Are you sure?"),
      text: context.t(
        "Once deleted, you will not be able to recover this file!"
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedocsixdata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedocsixdata.id}/`,
            (response) => {
              swal({
                text: context.t("Document removed successfully!"),
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedocsixdata) {
                delete workplanedocsixdata[key];
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };

  const delDocSeven = () => {
    swal({
      title: context.t("Are you sure?"),
      text: context.t(
        "Once deleted, you will not be able to recover this file!"
      ),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (workplanedocsevendata.document) {
          HttpService.delete(
            `${process.env.REACT_APP_API_URL}/sp-workplan-docs/${workplanedocsevendata.id}/`,
            (response) => {
              swal({
                text: context.t("Document removed successfully!"),
                icon: "success",
                buttons: false,
                timer: 2000,
              });
              for (const key in workplanedocsevendata) {
                delete workplanedocsevendata[key];
              }
              callApi();
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
    });
  };

  const [dontShow, setDontShow] = React.useState(gl_dontShow);
  React.useEffect(() => {
    gl_dontShow = dontShow;
    spworkplan.suggested_date = gl_sdate;
  });
  const NextHandler = () => {
    if (gl_dontShow == false) {
      if (
        spworkplan.fp_name !== fpname ||
        spworkplan.suggested_date !== gl_sdate
      ) {
        swal({
          text: context.t("Changes have not been saved."),
          icon: "warning",
          buttons: true,
          dangerMode: true,
          buttons: {
            close: context.t("Cancel"),
            proceed: context.t("Proceed Anyway"),
            save: context.t("Save"),
          },
          closeOnClickOutside: false,
          content: (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setDontShow(e.target.checked);
                  }}
                  color="black"
                />
              }
              label={
                <span style={{ fontSize: "14px" }}>
                  {context.t("Do not show this again.")}
                </span>
              }
            />
          ),
        }).then((willSave) => {
          switch (willSave) {
            case "Proceed":
              props.next();
              break;
            case "save":
              createWorkPlanAndDoc();
              break;
          }
        });
      } else {
        props.next();
      }
    } else {
      props.next();
    }
  };

  React.useEffect(() => {
    name = fpname;
    if (workplaneAllDocs) {
      if (workplaneAllDocs.length !== 0) {
        for (let i = 0; i < workplaneAllDocs.length; i++) {
          if (workplaneAllDocs[i].document_type === "supply_chain") {
            setWorkplaneDocOneData(workplaneAllDocs[i]);
          }
          if (workplaneAllDocs[i].document_type === "human_rights") {
            setWorkplaneDoctwoData(workplaneAllDocs[i]);
          }
          if (workplaneAllDocs[i].document_type === "migrant_worker_policy") {
            setWorkplaneDocthreeData(workplaneAllDocs[i]);
          }
          if (workplaneAllDocs[i].document_type === "ethical_recruitment") {
            setWorkplaneDocfourData(workplaneAllDocs[i]);
          }
          if (workplaneAllDocs[i].document_type === "worker_voice") {
            setWorkplaneDocFiveData(workplaneAllDocs[i]);
          }
          if (workplaneAllDocs[i].document_type === "audit_reports") {
            setWorkplaneDocsixData(workplaneAllDocs[i]);
          }
          if (workplaneAllDocs[i].document_type === "others") {
            setWorkplaneDocsevenData(workplaneAllDocs[i]);
          }
        }
      }
    }
  }, [
    strategicPartner,
    delDocOne,
    delDocTwo,
    delDocThree,
    delDocFour,
    delDocFive,
    delDocSix,
    delDocSeven,
  ]);

  return (
    <Container>
      {fatchdata.length === 0 ? (
        <LinearProgress />
      ) : (
        <div className="workplan-box">
          <h3>{context.t("1 of 5. Intro")}</h3>
          <p>
            {context.t(
              "This annual workplanning form aims to help your company identify and maintain focus on key business priorities with regard to ethical sourcing and ethical recruitment across your supply chain. The target progress indicators that you indicate here will display on your SP Progress Metrics Dashboard. At any time, you can visit the SP Progress Metrics to see your progress to date against each target as well as update this Workplanning Form if priorities change."
            )}
            <br />
            <br />
            {context.t(
              "The SP Workplanning has 4 sections: 1. Intro, 2. Main Goals and Priority Partnership Areas, 3. Progress Indicators, and 4. Priority Workplan Activities."
            )}
          </p>
          <br /> <br />
          {/* Toast alert for removing document */}
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={true}
            icon={true}
          />
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="first-input-box"
            >
              <form noValidate autoComplete="off">
                <TextField
                  id="outlined-basic"
                  label="Name of SP focal point"
                  variant="outlined"
                  value={fpname}
                  onChange={fpNameHandler}
                />
                <p
                  id="error1"
                  style={{
                    visibility: "hidden",
                    color: "red",
                    margin: "0 0 -2px 0",
                  }}
                >
                  {context.t("Please enter FP name")}
                </p>
              </form>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <form id="current-date" noValidate autoComplete="off">
                <TextField
                  disabled
                  id="filled-disabled"
                  label="Today's date"
                  defaultValue={current_date}
                  variant="filled"
                />
              </form>
            </Grid>
          </Grid>
          <Grid container style={{ margin: "0 0 7px 0" }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <span style={{ fontSize: "11px" }} className={classes.container}>
                {context.t("Suggested date for mid-year check-in")}
              </span>
              <DatePicker savedSuggestDate={savedSuggestDate} />
            </Grid>
          </Grid>
          <br /> <br />
          <p>
            {context.t(
              "Please check that all foundational documents and materials that have been shared with Issara. If not, you can upload them here ."
            )}
          </p>
          <Grid style={{ marginBottom: "16px" }}>
            <div>
              <label htmlFor="SC-information">
                <input
                  accept="application/pdf"
                  className={classes.input}
                  id="SC-information"
                  type="file"
                  onChange={setPdfHandlerSCinfo}
                  style={{ display: "none" }}
                />
                <Button
                  variant="contained"
                  component="span"
                  style={{ marginRight: "16px" }}
                >
                  {workplanedoconedata.document
                    ? context.t("Change")
                    : context.t("Upload")}
                </Button>
                <Button
                  onClick={reviewFileOne}
                  variant="contained"
                  style={{ marginRight: "16px" }}
                >
                  {context.t("Review")}
                </Button>
              </label>
              <span>{context.t("Updated supply chain information")}</span>
              <InfoIcon
                className="info-icon"
                aria-owns={open ? "mouse-over-popover" : undefined}
                style={{ marginBottom: "-5px" }}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              />
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography bgcolor="text.secondary">
                  <ul style={{ paddingRight: "15px" }}>
                    {context.t("Helpful information to include:")}
                    <li>{context.t("Supplier name")}</li>
                    <li>{context.t("Supplier address")}</li>
                    <li>{context.t("Product sourced")}</li>
                    <li>
                      {context.t(
                        "Supplier key focal point name, title, and contact information"
                      )}
                    </li>
                    <li>{context.t("Length of sourcing relationship")}</li>
                    <li>
                      {context.t(
                        "Number and breakdown of workers by nationality and gender"
                      )}
                    </li>
                  </ul>
                </Typography>
              </Popover>
              &nbsp;&nbsp;&nbsp;
              <span
                style={{
                  color: "#148696",
                  cursor: "pointer",
                  cursor: "pointer",
                }}
                onClick={reviewFileOne}
              >
                {workplanedoconedata.document
                  ? " // " + workplanedoconedata.document.name
                  : ""}
              </span>
              &nbsp;&nbsp;&nbsp;
              <DeleteForeverIcon
                style={{ marginBottom: "-5px" }}
                onClick={delDocOne}
                className={
                  workplanedoconedata.document
                    ? "trash-btn-show"
                    : "trash-btn-hide"
                }
              />
            </div>
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="company-policy-human-rights">
              <input
                accept="application/pdf"
                className={classes.input}
                id="company-policy-human-rights"
                type="file"
                onChange={setPdfHandlerPolicyHR}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedoctwodata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileTwo}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                {context.t("Review")}
              </Button>
            </label>
            <span>{context.t("Company policy: human rights")}</span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileTwo}
            >
              {workplanedoctwodata.document
                ? " //  " + workplanedoctwodata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocTwo}
              className={
                workplanedoctwodata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="company-policy-migrant-worker">
              <input
                accept="application/pdf"
                className={classes.input}
                id="company-policy-migrant-worker"
                type="file"
                onChange={setPdfHandlerPolicyMW}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedocthreedata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileThree}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                {context.t("Review")}
              </Button>
            </label>
            <span>{context.t("Company policy: migrant worker")}</span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileThree}
            >
              {workplanedocthreedata.document
                ? " // " + workplanedocthreedata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocThree}
              className={
                workplanedocthreedata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="company-policy-ethical-recruitment">
              <input
                accept="application/pdf"
                className={classes.input}
                id="company-policy-ethical-recruitment"
                type="file"
                onChange={setPdfHandlerPolicyER}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedocfourdata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileFour}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                {context.t("Review")}
              </Button>
            </label>
            <span>{context.t("Company policy: ethical recruitment")}</span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileFour}
            >
              {workplanedocfourdata.document
                ? " // " + workplanedocfourdata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocFour}
              className={
                workplanedocfourdata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="escalation-protocol">
              <input
                accept="application/pdf"
                className={classes.input}
                id="escalation-protocol"
                type="file"
                onChange={setPdfHandlerEProtocol}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedocfivedata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileFive}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                {context.t("Review")}
              </Button>
            </label>
            <span>
              {context.t(
                "Escalation protocol for worker-reported issues and worker voice"
              )}
            </span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileFive}
            >
              {workplanedocfivedata.document
                ? " // " + workplanedocfivedata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocFive}
              className={
                workplanedocfivedata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="audit-reports">
              <input
                accept="application/pdf"
                className={classes.input}
                id="audit-reports"
                type="file"
                onChange={setPdfHandlerAuditReports}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedocsixdata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileSix}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                {context.t("Review")}
              </Button>
            </label>
            <span>{context.t("Audit reports for suppliers")}</span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileSix}
            >
              {workplanedocsixdata.document
                ? " // " + workplanedocsixdata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocSix}
              className={
                workplanedocsixdata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid style={{ marginBottom: "16px" }}>
            <label htmlFor="other-key-policy">
              <input
                accept="application/pdf"
                className={classes.input}
                id="other-key-policy"
                type="file"
                onChange={setPdfHandlerOtherKey}
                style={{ display: "none" }}
              />
              <Button
                variant="contained"
                component="span"
                style={{ marginRight: "16px" }}
              >
                {workplanedocsevendata.document
                  ? context.t("Change")
                  : context.t("Upload")}
              </Button>
              <Button
                onClick={reviewFileSeven}
                variant="contained"
                style={{ marginRight: "16px" }}
              >
                {context.t("Review")}
              </Button>
            </label>
            <span>{context.t("Other key policy or background documents")}</span>
            &nbsp;&nbsp;&nbsp;
            <span
              style={{ color: "#148696", cursor: "pointer" }}
              onClick={reviewFileSeven}
            >
              {workplanedocsevendata.document
                ? " // " + workplanedocsevendata.document.name
                : ""}
            </span>
            &nbsp;&nbsp;&nbsp;
            <DeleteForeverIcon
              style={{ marginBottom: "-5px" }}
              onClick={delDocSeven}
              className={
                workplanedocsevendata.document
                  ? "trash-btn-show"
                  : "trash-btn-hide"
              }
            />
          </Grid>
          <Grid component="span" style={BottomBtn}>
            <Button
              onClick={createWorkPlanAndDoc}
              variant="contained"
              style={{ marginRight: "15px", backgroundColor: "#03A9F4" }}
            >
              {context.t("Save")}
            </Button>
            <Button
              onClick={NextHandler}
              variant="contained"
              width="200px"
              style={{ marginRight: "15px" }}
            >
              {context.t("Next")}
            </Button>
          </Grid>
        </div>
      )}
    </Container>
  );
}
// -----------------------SP form-Two---------------------------//
export function SPWorkPlaningFormTwo(props, context) {
  const [ethicalRecruitment, setEthicalRecruitment] = React.useState(
    gl_maingoals
  );
  const [mainpriority, setMainPriority] = React.useState(gl_mainpriority);
  const [actionednEscalated, setActionednEscalated] = React.useState(
    gl_actionednEscalated
  );
  const [
    prioritySupplierCountriesRegions,
    setPrioritySupplierCountriesRegions,
  ] = React.useState(gl_prioritySupplierCountriesRegions);
  const [workplanID, setWorkplanID] = React.useState();
  const [WPData, setWPData] = React.useState([]);
  const workplanresponse = useSelector((state) => state.workplanReducer.items);
  const [mainGoals, setMainGoals] = React.useState([]);
  const isRunned = useRef(false);
  const dispatch = useDispatch();

  const callApi = () => {
    dispatch(workplanIsloadingSuccess());
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/`,
      (response) => {
        setWPData(response);
        setWorkplanID(response.id);
        if (
          response &&
          Array.isArray(response.main_goals) &&
          response.main_goals[0]
        ) {
          setEthicalRecruitment(response.main_goals[0].ethical_recruitment);
          setMainPriority(response.main_goals[0].partnership_areas);
          setActionednEscalated(response.main_goals[0].worker_voice_escalation);
          setPrioritySupplierCountriesRegions(
            response.main_goals[0].priority_suppliers_countries_regions
          );
          setMainGoals(response.main_goals[0]);
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  React.useEffect(() => {
    if (isRunned.current) return;
    callApi();
    isRunned.current = true;
  }, []);

  React.useEffect(() => {
    gl_maingoals = ethicalRecruitment;
    gl_mainpriority = mainpriority;
    gl_actionednEscalated = actionednEscalated;
    gl_prioritySupplierCountriesRegions = prioritySupplierCountriesRegions;
    gl_wp = workplanID;
  });

  function mainGoalHandler(e) {
    setEthicalRecruitment(e.target.value);
    document.getElementById("main-goal").style.border = "1px solid black";
    document.getElementById("ethicalRecruitment").style.visibility = "hidden";
  }

  function mainMainPriorityHandler(e) {
    setMainPriority(e.target.value);
    document.getElementById("main-priority").style.border = "1px solid black";
    document.getElementById("mainpriority").style.visibility = "hidden";
  }

  function actionednEscalatedHandler(e) {
    setActionednEscalated(e.target.value);
    document.getElementById("text-3").style.border = "1px solid black";
    document.getElementById("actionednEscalated").style.visibility = "hidden";
  }

  function prioritySupplierCountriesRegionsHandler(e) {
    setPrioritySupplierCountriesRegions(e.target.value);
    document.getElementById("text-4").style.border = "1px solid black";
    document.getElementById(
      "prioritySupplierCountriesRegions"
    ).style.visibility = "hidden";
  }

  function mainGoalsAndPriorityPartnership() {
    // if ((gl_maingoals == "") && (gl_mainpriority == "") && (gl_actionednEscalated == "")) {
    //   document.getElementById('text-3').style.border = "2px solid red"
    // }
    // else if (gl_maingoals == "") {
    //   document.getElementById('main-goal').style.border = "2px solid red"
    // }
    // else if (gl_mainpriority == "") {
    //   document.getElementById('main-priority').style.border = "2px solid red"
    // }
    // else if (gl_actionednEscalated == "") {
    //   document.getElementById('text-3').style.border = "2px solid red"
    // }

    if (!workplanID || workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    } else {
      const mainGoalPayload = {
        workplan: workplanID,
        ethical_recruitment: ethicalRecruitment,
        partnership_areas: mainpriority,
        worker_voice_escalation: actionednEscalated,
        priority_suppliers_countries_regions: prioritySupplierCountriesRegions,
      };
      dispatch(mainGoalsAndPriority(mainGoalPayload));
      callApi();
    }
  }

  const classes = useStyles();

  const [dontShow, setDontShow] = React.useState(gl_dontShow);
  React.useEffect(() => {
    gl_dontShow = dontShow;
  });

  const NextHandler = () => {
    if (gl_dontShow == false) {
      if (
        mainGoals !== null &&
        mainGoals !== undefined &&
        mainGoals.length !== 0 &&
        (mainGoals.ethical_recruitment !== ethicalRecruitment ||
          mainGoals.partnership_areas !== mainpriority ||
          mainGoals.worker_voice_escalation !== actionednEscalated ||
          mainGoals.priority_suppliers_countries_regions !==
            prioritySupplierCountriesRegions)
      ) {
        swal({
          text: context.t("Changes has not been saved."),
          icon: "warning",
          buttons: true,
          dangerMode: true,
          buttons: {
            close: context.t("Cancel"),
            Proceed: context.t("Proceed Anyway"),
            save: context.t("Save"),
          },
          closeOnClickOutside: false,
          content: (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setDontShow(e.target.checked);
                  }}
                  color="black"
                />
              }
              label={
                <span style={{ fontSize: "14px" }}>
                  {context.t("Do not show this again.")}
                </span>
              }
            />
          ),
        }).then((willSave) => {
          switch (willSave) {
            case "Proceed":
              props.next();
              break;
            case "save":
              mainGoalsAndPriorityPartnership();
              break;
          }
        });
      } else {
        props.next();
      }
    } else {
      props.next();
    }
  };

  return (
    <Container>
      {WPData.length == 0 ? (
        <LinearProgress />
      ) : (
        <div className="workplan-box">
          <Grid>
            <h3>
              {context.t("2 of 5. Main Goals and Priority Partnership Areas")}
            </h3>
            <p style={{ color: "rgba(0, 0, 0, 0.7)", marginTop: "10px" }}>
              {context.t(
                "Summary of the Partner’s main goals in responsible sourcing and ethical recruitment over the next year and the next three years:"
              )}
            </p>
            <TextareaAutosize
              id="main-goal"
              aria-label="minimum height"
              className={classes.textArea}
              minRows={10}
              value={ethicalRecruitment}
              defaultValue={gl_maingoals}
              onChange={mainGoalHandler}
              isTextArea={true}
            />
            <span
              id="ethicalRecruitment"
              style={{ visibility: "hidden", color: "red" }}
            >
              {context.t(
                "What are the company's main goals in responsible sourcing and ethical recruitment over the next 3 years?"
              )}
            </span>
            <p style={{ color: "rgba(07)", marginTop: "10px" }}>
              {context.t(
                "Partner’s main priority areas of partnership with Issara Institute:"
              )}
            </p>
            <TextareaAutosize
              id="main-priority"
              aria-label="minimum height"
              className={classes.textArea}
              minRows={10}
              value={mainpriority}
              defaultValue={gl_mainpriority}
              onChange={mainMainPriorityHandler}
              isTextArea={true}
            />
            <span
              id="mainpriority"
              style={{ visibility: "hidden", color: "red" }}
            >
              {context.t(
                "What are the company's main priority areas of partnership with Issara Institute?"
              )}
            </span>
            <p style={{ color: "rgba(0, 0, 0, 0.7)", marginTop: "10px" }}>
              {context.t(
                "How worker voice via Inclusive Labor Monitoring (ILM) will be actioned and escalated at Partner:"
              )}
            </p>

            <TextareaAutosize
              id="text-3"
              aria-label="minimum height"
              className={classes.textArea}
              minRows={10}
              value={actionednEscalated}
              defaultValue={gl_actionednEscalated}
              onChange={actionednEscalatedHandler}
              isTextArea={true}
            />
            <span
              id="actionednEscalated"
              style={{ visibility: "hidden", color: "red" }}
            >
              {context.t(
                "How worker voice via Inclusive Labor Monitoring (ILM) will be actioned and escalated at Partner:"
              )}
            </span>

            <p style={{ color: "rgba(0, 0, 0, 0.7)", marginTop: "10px" }}>
              {context.t(
                "Priority suppliers, countries, and/or regions for continued focus or expansion:"
              )}
            </p>
            <TextareaAutosize
              id="text-4"
              aria-label="minimum height"
              className={classes.textArea}
              minRows={10}
              value={prioritySupplierCountriesRegions}
              defaultValue={gl_prioritySupplierCountriesRegions}
              onChange={prioritySupplierCountriesRegionsHandler}
              isTextArea={true}
            />
            <span
              id="prioritySupplierCountriesRegions"
              style={{ visibility: "hidden", color: "red" }}
            >
              {context.t(
                "Priority suppliers, countries, and/or regions for continued focus or expansion:"
              )}
            </span>
          </Grid>
          <GridContainer>
            <Grid item xs={6} sm={6} md={8} lg={8}>
              <Button
                onClick={props.back}
                variant="contained"
                style={{ marginLeft: "5px" }}
              >
                {context.t("Back")}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} style={BottomBtn}>
              <Button
                onClick={mainGoalsAndPriorityPartnership}
                variant="contained"
                style={{ marginRight: "15px", backgroundColor: "#03A9F4" }}
              >
                {context.t("Save")}
              </Button>
              <Button
                onClick={NextHandler}
                variant="contained"
                style={{ marginRight: "5px" }}
              >
                {context.t("Next")}
              </Button>
            </Grid>
          </GridContainer>
        </div>
      )}
    </Container>
  );
}

// -----------------------SP form-Three---------------------------//
export function SPWorkPlaningFormThree(props, context) {
  const workplanresponse = useSelector((state) => state.workplanReducer.items);
  const classes = useStyles();
  const [internationalSnD, setinternationalSnD] = React.useState(false);
  const [internationalSnDold, setinternationalSnDold] = React.useState(false);
  const [communicatedToS, setcommunicatedToS] = React.useState(false);
  const [communicatedToSold, setcommunicatedToSold] = React.useState(false);
  const [commercialProcesses, setcommercialProcesses] = React.useState(false);
  const [commercialProcessesold, setcommercialProcessesold] = React.useState(
    false
  );
  const [wcInternationalSnD, setwcInternationalSnD] = React.useState(false);
  const [wcInternationalSnDold, setwcInternationalSnDold] = React.useState(
    false
  );
  const [wcCommunicatedToS, setwcCommunicatedToS] = React.useState(false);
  const [wcCommunicatedToSold, setwcCommunicatedToSold] = React.useState(false);
  const [wcCommercialProcesses, setwcCommercialProcesses] = React.useState(
    false
  );
  const [
    wcCommercialProcessesold,
    setwcCommercialProcessesold,
  ] = React.useState(false);
  const [workplanID, setWorkplanID] = React.useState();
  const [noteandremarks, setNotesAndRemarks] = React.useState("");
  const [noteandremarksClone, setNotesAndRemarksClone] = React.useState("");
  const [tieronesuppliers, setTierOneSuppliers] = React.useState(0);
  const [tieronesuppliersold, setTierOneSuppliersold] = React.useState(0);
  const [tieronesuppliersClone, setTierOneSuppliersClone] = React.useState(0);
  const [upstreamsuppliers, setUpstreamSuppliers] = React.useState(0);
  const [upstreamsuppliersold, setUpstreamSuppliersold] = React.useState(0);
  const [upstreamsuppliersClone, setUpstreamSuppliersClone] = React.useState(0);
  const [suppliersCollaborating, setSuppliersCollaborating] = React.useState(0);
  const [
    suppliersCollaboratingClone,
    setSuppliersCollaboratingClone,
  ] = React.useState(0);
  // sub indicators states
  const [suppliersBase, setSuppliersBase] = React.useState(0);
  const [suppliersBaseClone, setSuppliersBaseClone] = React.useState(0);
  const [suppliersCollaborate, setSuppliersCollaborate] = React.useState(0);
  const [
    suppliersCollaborateClone,
    setSuppliersCollaborateClone,
  ] = React.useState(0);
  const [suppliersUtilizing, setSuppliersUtilizing] = React.useState(0);
  const [suppliersUtilizingClone, setSuppliersUtilizingClone] = React.useState(
    0
  );
  const [
    undertakenRecruitmentSupp,
    setUndertakenRecruitmentSuppliers,
  ] = React.useState(0);
  const [
    undertakenRecruitmentSuppClone,
    setUndertakenRecruitmentSuppliersClone,
  ] = React.useState(0);
  const [workersInSC, setWorkersInSC] = React.useState(0);
  const [workersInSCClone, setWorkersInSCClone] = React.useState(0);
  const [timelinessremediation, setTimelinessRemediation] = React.useState(1);
  const [
    timelinessremediationClone,
    setTimelinessRemediationClone,
  ] = React.useState(1);
  const [qualityremediation, setQualityRemediation] = React.useState(1);
  const [qualityremediationClone, setQualityRemediationClone] = React.useState(
    1
  );
  const [opennesstoreform, setOpennessToreForm] = React.useState(1);
  const [opennesstoreformClone, setOpennessToreFormClone] = React.useState(1);
  const [
    workerreportedissuespercent,
    setWorkerReportedIssuesPercent,
  ] = React.useState(0);
  const [
    workerreportedissuespercentClone,
    setWorkerReportedIssuesPercentClone,
  ] = React.useState(0);
  const [
    remediationeffortpercent,
    setRemediationEffortPercent,
  ] = React.useState(0);
  const [
    remediationeffortpercentClone,
    setRemediationEffortPercentClone,
  ] = React.useState(0);
  const [openSuccessWindow, setOpenSuccessWindow] = React.useState(false);
  const [getdata, setGetdata] = React.useState([]);

  const callApi = () => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/`,
      (response) => {
        setGetdata(response);
        setWorkplanID(response.id);

        if (response && Array.isArray(response.progress_indicator)) {
          for (let xl = 0; xl < response.progress_indicator.length; xl++) {
            if (
              response.progress_indicator[xl].str_value == "Tier 1 suppliers"
            ) {
              setTierOneSuppliers(
                response.progress_indicator[xl].numeric_value
              );
              setTierOneSuppliersold(
                response.progress_indicator[xl].numeric_value
              );
              setTierOneSuppliersClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value == "Upstream suppliers"
            ) {
              setUpstreamSuppliers(
                response.progress_indicator[xl].numeric_value
              );
              setUpstreamSuppliersold(
                response.progress_indicator[xl].numeric_value
              );
              setUpstreamSuppliersClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value ==
              "Collaborating suppliers"
            ) {
              setSuppliersCollaborating(
                response.progress_indicator[xl].numeric_value
              );
              setSuppliersCollaboratingClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            // sub indicators values for 2A, 2B and 2C value
            if (
              response.progress_indicator[xl].str_value == "number of ra mapped"
            ) {
              setSuppliersBase(response.progress_indicator[xl].numeric_value);
              setSuppliersBaseClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value ==
              "number of collaborating suppliers with Issara"
            ) {
              setSuppliersCollaborate(
                response.progress_indicator[xl].numeric_value
              );
              setSuppliersCollaborateClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value ==
              "number suppliers utilizing GD marketplace"
            ) {
              setSuppliersUtilizing(
                response.progress_indicator[xl].numeric_value
              );
              setSuppliersUtilizingClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value ==
              "Undertaken recruitment suppliers"
            ) {
              setUndertakenRecruitmentSuppliers(
                response.progress_indicator[xl].numeric_value
              );
              setUndertakenRecruitmentSuppliersClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value ==
              "Workers in supply chain"
            ) {
              setWorkersInSC(response.progress_indicator[xl].numeric_value);
              setWorkersInSCClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value ==
              "Timeliness remediation"
            ) {
              setTimelinessRemediation(
                response.progress_indicator[xl].numeric_value
              );
              setTimelinessRemediationClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value == "Quality remediation"
            ) {
              setQualityRemediation(
                response.progress_indicator[xl].numeric_value
              );
              setQualityRemediationClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value == "Opennes store form"
            ) {
              setOpennessToreForm(
                response.progress_indicator[xl].numeric_value
              );
              setOpennessToreFormClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value ==
              "Worker reported issues"
            ) {
              setWorkerReportedIssuesPercent(
                response.progress_indicator[xl].numeric_value
              );
              setWorkerReportedIssuesPercentClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value == "Remediation effort"
            ) {
              setRemediationEffortPercent(
                response.progress_indicator[xl].numeric_value
              );
              setRemediationEffortPercentClone(
                response.progress_indicator[xl].numeric_value
              );
            }
            if (
              response.progress_indicator[xl].str_value == "openSuccessWindow"
            ) {
              setOpenSuccessWindow(
                response.progress_indicator[xl].numeric_value
              );
            }
          }
        }

        if (response && Array.isArray(response.key_activities)) {
          for (let xl = 0; xl < response.key_activities.length; xl++) {
            // labour_recruitment area
            if (
              response.key_activities[xl].str_value ==
                "In line with international standards and definitions" &&
              response.key_activities[xl].area === "labour_recruitment"
            ) {
              setinternationalSnD(response.key_activities[xl].value);
              setinternationalSnDold(response.key_activities[xl].value);
            }
            if (
              response.key_activities[xl].str_value ==
                "Integrated into commercial processes" &&
              response.key_activities[xl].area === "labour_recruitment"
            ) {
              setcommercialProcesses(response.key_activities[xl].value);
              setcommercialProcessesold(response.key_activities[xl].value);
            }
            if (
              response.key_activities[xl].str_value ==
                "Clearly communicated to suppliers, including effective date" &&
              response.key_activities[xl].area === "labour_recruitment"
            ) {
              setcommunicatedToS(response.key_activities[xl].value);
              setcommunicatedToSold(response.key_activities[xl].value);
            }
            // working_conditions area
            if (
              response.key_activities[xl].str_value ==
                "In line with international standards and definitions" &&
              response.key_activities[xl].area === "working_conditions"
            ) {
              setwcInternationalSnD(response.key_activities[xl].value);
              setwcInternationalSnDold(response.key_activities[xl].value);
            }
            if (
              response.key_activities[xl].str_value ==
                "Clearly communicated to suppliers" &&
              response.key_activities[xl].area === "working_conditions"
            ) {
              setwcCommunicatedToS(response.key_activities[xl].value);
              setwcCommunicatedToSold(response.key_activities[xl].value);
            }
            if (
              response.key_activities[xl].str_value ==
                "Integrated into commercial processes" &&
              response.key_activities[xl].area === "working_conditions"
            ) {
              setwcCommercialProcesses(response.key_activities[xl].value);
              setwcCommercialProcessesold(response.key_activities[xl].value);
            }
          }
        }

        if (response.notes_remark) {
          for (let xxl = 0; xxl < response.notes_remark.length; xxl++) {
            if (response.notes_remark[xxl].area === "progress indicators") {
              setNotesAndRemarks(response.notes_remark[xxl].txt_value);
              setNotesAndRemarksClone(response.notes_remark[xxl].txt_value);
            }
          }
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  React.useEffect(() => {
    callApi();
  }, []);

  React.useEffect(() => {
    gl_undertakenRecruitmentSuppliers = undertakenRecruitmentSupp;
    gl_workersInSC = workersInSC;
    gl_tieronesuppliers = tieronesuppliers;
    gl_upstreamsuppliers = upstreamsuppliers;
    gl_qualityremediation = qualityremediation;
    gl_timelinessremediation = timelinessremediation;
    gl_opennesstoreform = opennesstoreform;
    gl_workerreportedissuespercent = workerreportedissuespercent;
    gl_remediationeffortpercent = remediationeffortpercent;
  });

  const noteandremarksHandler = (e) => {
    setNotesAndRemarks(e.target.value);
  };
  const tierOneSuppliersHandler = (e) => {
    setTierOneSuppliers(e.target.value);
  };
  const upstreamSuppliersHandler = (e) => {
    setUpstreamSuppliers(e.target.value);
  };

  // sub indicators values
  const suppliersBaseHandler = (e) => {
    setSuppliersBase(e.target.value);
    if (suppliersBase !== 0) {
      document
        .getElementById("supplier_basepercent")
        .classList.remove("indicatorError");
    }
  };
  const suppliersCollaborateHandler = (e) => {
    setSuppliersCollaborate(e.target.value);
  };
  const suppliersUtilizingHandler = (e) => {
    setSuppliersUtilizing(e.target.value);
  };
  const undertakenRecruitmentSuppliersHandler = (e) => {
    setUndertakenRecruitmentSuppliers(e.target.value);
    if (!undertakenRecruitmentSupp == 0) {
      document
        .getElementById("undertakenRecruitmentSupp")
        .classList.remove("indicatorError");
    }
  };
  const workersInSCHandler = (e) => {
    setWorkersInSC(e.target.value);
    if (!workersInSC == 0 && !workersInSC <= 100) {
      document.getElementById("workersInSC").classList.remove("indicatorError");
    }
  };
  const timelinessRemediationHandler = (e) => {
    setTimelinessRemediation(parseInt(e.target.innerText));
  };
  const qualityRemediationHandler = (e) => {
    setQualityRemediation(parseInt(e.target.innerText));
  };
  const opennessToReformHandler = (e) => {
    setOpennessToreForm(parseInt(e.target.innerText));
  };
  const workerReportedIssuespercentHnadler = (e) => {
    setWorkerReportedIssuesPercent(e.target.value);
    if (!gl_workerreportedissuespercent == 0) {
      document
        .getElementById("workerreportedissuespercent")
        .classList.remove("indicatorError");
    }
  };
  const progressIndicatorHandler = () => {
    let api_data = [];
    let progress_indicators_data = [];
    let internationalStandards = {
      str_value: "In line with international standards and definitions",
      value: internationalSnD,
      area: "labour_recruitment",
      workplan: workplanID,
    };
    let communicatedToSupp = {
      str_value: "Clearly communicated to suppliers, including effective date",
      value: communicatedToS,
      area: "labour_recruitment",
      workplan: workplanID,
    };
    let commercialProcess = {
      str_value: "Integrated into commercial processes",
      value: commercialProcesses,
      area: "labour_recruitment",
      workplan: workplanID,
    };
    let wcInternationalSD = {
      str_value: "In line with international standards and definitions",
      value: wcInternationalSnD,
      area: "working_conditions",
      workplan: workplanID,
    };
    let wcCommunicatedToSupp = {
      str_value: "Clearly communicated to suppliers",
      value: wcCommunicatedToS,
      area: "working_conditions",
      workplan: workplanID,
    };
    let wcCommercialProcess = {
      str_value: "Integrated into commercial processes",
      value: wcCommercialProcesses,
      area: "working_conditions",
      workplan: workplanID,
    };
    if (internationalSnD !== internationalSnDold) {
      api_data.push(internationalStandards);
    }
    if (communicatedToS !== communicatedToSold) {
      api_data.push(communicatedToSupp);
    }
    if (commercialProcesses !== commercialProcessesold) {
      api_data.push(commercialProcess);
    }
    if (wcInternationalSnD !== wcInternationalSnDold) {
      api_data.push(wcInternationalSD);
    }
    if (wcCommunicatedToS !== wcCommunicatedToSold) {
      api_data.push(wcCommunicatedToSupp);
    }
    if (wcCommercialProcesses !== wcCommercialProcessesold) {
      api_data.push(wcCommercialProcess);
    }
    if (tieronesuppliers > 100) {
      document
        .getElementById("tieronesuppliers")
        .classList.add("indicatorError");
    }
    if (upstreamsuppliers > 100) {
      document
        .getElementById("upstreamsuppliers")
        .classList.add("indicatorError");
    }
    if (workerreportedissuespercent > 100) {
      document
        .getElementById("workerreportedissuespercent")
        .classList.add("indicatorError");
    }
    if (workersInSC > 100) {
      document.getElementById("workersInSC").classList.add("indicatorError");
    }

    // checkbox validations
    if (workplanID == undefined) {
      setWorkplanID(workplanresponse.id);
    }
    // worker_voice indicators APIs
    let progressIndicatorT1 = {
      str_value: "Tier 1 suppliers",
      numeric_value: tieronesuppliers,
      sub_area: "percentage",
      area: "worker_voice",
      workplan: workplanID,
    };
    let progressIndicatorUpStream = {
      str_value: "Upstream suppliers",
      numeric_value: upstreamsuppliers,
      sub_area: "percentage",
      area: "worker_voice",
      workplan: workplanID,
    };
    if (tieronesuppliers !== tieronesuppliersold) {
      progress_indicators_data.push(progressIndicatorT1);
    }
    if (upstreamsuppliers !== upstreamsuppliersold) {
      progress_indicators_data.push(progressIndicatorUpStream);
    }
    // labour_recruitment indicators APIs
    // Sub indicators payloads (2A, 2B and 2C)
    let indicator_2A = {
      str_value: "number of ra mapped",
      numeric_value: suppliersBase,
      sub_area: "number",
      area: "labour_recruitment",
      workplan: workplanID,
    };
    let indicator_2B = {
      str_value: "number of collaborating suppliers with Issara",
      numeric_value: suppliersCollaborate,
      sub_area: "number",
      area: "labour_recruitment",
      workplan: workplanID,
    };
    let indicator_2C = {
      str_value: "number suppliers utilizing GD marketplace",
      numeric_value: suppliersUtilizing,
      sub_area: "number",
      area: "labour_recruitment",
      workplan: workplanID,
    };
    if (suppliersBase !== suppliersBaseClone) {
      progress_indicators_data.push(indicator_2A);
    }
    if (suppliersCollaborate !== suppliersCollaborateClone) {
      progress_indicators_data.push(indicator_2B);
    }
    if (suppliersUtilizing !== suppliersUtilizingClone) {
      progress_indicators_data.push(indicator_2C);
    }
    // Sub indicators payloads
    let undertakenRecruitmentSup = {
      str_value: "Undertaken recruitment suppliers",
      numeric_value: undertakenRecruitmentSupp,
      sub_area: "number",
      area: "labour_recruitment",
      workplan: workplanID,
    };
    let workers_in_SC = {
      str_value: "Workers in supply chain",
      numeric_value: workersInSC,
      sub_area: "percentage",
      area: "labour_recruitment",
      workplan: workplanID,
    };
    if (undertakenRecruitmentSupp !== undertakenRecruitmentSuppClone) {
      progress_indicators_data.push(undertakenRecruitmentSup);
    }
    if (workersInSC !== workersInSCClone) {
      progress_indicators_data.push(workers_in_SC);
    }
    // WORKING CONDITIONS: indicators APIs
    // Average supplier performance scores api
    let qualityofRemediation = {
      str_value: "Quality remediation",
      numeric_value: qualityremediation,
      sub_area: "number",
      area: "working_conditions",
      workplan: workplanID,
    };
    let timelinessOfRemediation = {
      str_value: "Timeliness remediation",
      numeric_value: timelinessremediation,
      sub_area: "number",
      area: "working_conditions",
      workplan: workplanID,
    };
    let opennessReform = {
      str_value: "Opennes store form",
      numeric_value: opennesstoreform,
      sub_area: "number",
      area: "working_conditions",
      workplan: workplanID,
    };
    let workerreportedissues = {
      str_value: "Worker reported issues",
      numeric_value: workerreportedissuespercent,
      sub_area: "percentage",
      area: "working_conditions",
      workplan: workplanID,
    };
    // let remediationeffort = {
    //   'str_value': "Remediation effort",
    //   'numeric_value': remediationeffortpercent,
    //   'sub_area': 'percentage',
    //   'area': 'working_conditions',
    //   'workplan': workplanID
    // }
    if (qualityremediation !== qualityremediationClone) {
      progress_indicators_data.push(qualityofRemediation);
    }
    if (timelinessremediation !== timelinessremediationClone) {
      progress_indicators_data.push(timelinessOfRemediation);
    }
    if (opennesstoreform !== opennesstoreformClone) {
      progress_indicators_data.push(opennessReform);
    }
    if (workerreportedissuespercent !== workerreportedissuespercentClone) {
      progress_indicators_data.push(workerreportedissues);
    }
    // if (remediationeffortpercent !== remediationeffortpercentClone) {
    //   progress_indicators_data.push(remediationeffort)
    // }
    // API
    // checkbox data
    let noteRemark = {
      txt_value: noteandremarks,
      area: "progress indicators",
      workplan: workplanID,
    };
    let api_data1 = [
      internationalStandards,
      communicatedToSupp,
      commercialProcess,
      wcInternationalSD,
      wcCommunicatedToSupp,
      wcCommercialProcess,
    ];
    let progress_indicators_data1 = [
      progressIndicatorT1,
      progressIndicatorUpStream,
      indicator_2A,
      indicator_2B,
      indicator_2C,
      undertakenRecruitmentSup,
      workers_in_SC,
      qualityofRemediation,
      timelinessOfRemediation,
      opennessReform,
      workerreportedissues,
    ];
    // Conditionally call API for mandatory fields
    if (
      tieronesuppliers <= 100 &&
      upstreamsuppliers <= 100 &&
      workersInSC <= 100 &&
      workerreportedissuespercent <= 100
    ) {
      // && (workerreportedissuespercent != 0 && workerreportedissuespercent < 100) && (remediationeffortpercent != 0 && remediationeffortpercent < 100)) {
      if (progress_indicators_data.length !== 0) {
        for (let xl = 0; xl < progress_indicators_data.length; xl++) {
          HttpService.post(
            `${process.env.REACT_APP_API_URL}/sp-workplan-progress-indicators/`,
            progress_indicators_data[xl],
            (response) => {
              if (xl + 1 == progress_indicators_data.length) {
                swal({
                  text: context.t("Data save sucsessfully"),
                  buttons: false,
                  timer: 2000,
                });
              }
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      } else {
        for (let xl = 0; xl < progress_indicators_data.length; xl++) {
          HttpService.post(
            `${process.env.REACT_APP_API_URL}/sp-workplan-progress-indicators/`,
            progress_indicators_data1[xl],
            (response) => {
              if (xl + 1 == progress_indicators_data.length) {
                swal({
                  text: context.t("Data save sucsessfully"),
                  buttons: false,
                  timer: 2000,
                });
              }
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
      if (api_data.length !== 0) {
        for (let ik = 0; ik < api_data.length; ik++) {
          HttpService.post(
            `${process.env.REACT_APP_API_URL}/sp-workplan-activity/`,
            api_data[ik],
            (response) => {
              if (ik + 1 == api_data.length) {
                callApi();
                swal({
                  text: context.t("Data save sucsessfully"),
                  buttons: false,
                  timer: 2000,
                });
              }
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      } else {
        for (let ik = 0; ik < api_data1.length; ik++) {
          HttpService.post(
            `${process.env.REACT_APP_API_URL}/sp-workplan-activity/`,
            api_data1[ik],
            (response) => {
              if (ik + 1 == api_data.length) {
                callApi();
              }
            },
            (err) => {
              console.log(err.message);
            }
          );
        }
      }
      HttpService.post(
        `${process.env.REACT_APP_API_URL}/sp-workplan-notes_and_remarks/`,
        noteRemark,
        (response) => {
          swal({
            text: context.t("Data save sucsessfully"),
            buttons: false,
            timer: 2000,
          });
        },
        (err) => {
          console.log(err.message);
        }
      );
      callApi();
    } else {
      swal({
        text: "Error!",
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  const [dontShow, setDontShow] = React.useState(gl_dontShow);
  React.useEffect(() => {
    gl_dontShow = dontShow;
  });

  const NextHandler = () => {
    if (gl_dontShow == false) {
      if (
        tieronesuppliersClone != tieronesuppliers ||
        upstreamsuppliersClone != upstreamsuppliers ||
        suppliersCollaboratingClone != suppliersCollaborating ||
        suppliersBaseClone != suppliersBase ||
        suppliersCollaborateClone != suppliersCollaborate ||
        suppliersUtilizingClone != suppliersUtilizing ||
        undertakenRecruitmentSuppClone != undertakenRecruitmentSupp ||
        workersInSCClone != workersInSC ||
        timelinessremediationClone != timelinessremediation ||
        qualityremediationClone != qualityremediation ||
        opennesstoreformClone != opennesstoreform ||
        workerreportedissuespercentClone != workerreportedissuespercent ||
        noteandremarksClone != noteandremarks
      ) {
        swal({
          text: context.t("Changes has not been saved."),
          icon: "warning",
          buttons: true,
          dangerMode: true,
          buttons: {
            close: context.t("Cancel"),
            Proceed: context.t("Proceed Anyway"),
            save: context.t("Save"),
          },
          closeOnClickOutside: false,
          content: (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setDontShow(e.target.checked);
                  }}
                  color="black"
                />
              }
              label={
                <span style={{ fontSize: "14px" }}>
                  {context.t("Do not show this again.")}
                </span>
              }
            />
          ),
        }).then((willSave) => {
          switch (willSave) {
            case "Proceed":
              props.next();
              break;
            case "save":
              progressIndicatorHandler();
              break;
          }
        });
      } else {
        props.next();
      }
    } else {
      props.next();
    }
  };
  return (
    <Container>
      {getdata.length === 0 ? (
        <LinearProgress />
      ) : (
        <div className="workplan-box">
          <h3>{context.t("3 of 5. Progress Indicators")}</h3>
          <p>
            {context.t(
              "The target progress indicators for your company that you indicate here will display on your SP Progress Metrics Dashboard. At any time, you can visit the SP Progress Dashboard to see your targets as well as the progress made to date against each one."
            )}
          </p>

          {/* ================================= end Area 1 =========================================== */}
          <Grid container>
            <Grid className={classes.area}>
              <h5> {context.t("AREA 1. WORKER VOICE: ANNUAL TARGETS")}</h5>
              <Typography style={{ marginBottom: "10px" }}>
                {context.t(
                  "If additional details need to be provided, please use the note box at bottom."
                )}
              </Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container className={classes.targetbox}>
                  <Grid item xs={9}>
                    <p>
                      {context.t(
                        "WV INDICATOR 1. Percentage of tier-1 suppliers (export level) in your supply chain with Issara worker voice channels available to workers"
                      )}
                    </p>
                  </Grid>
                  <Grid item xs={2} className="percent-box">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      id="tieronesuppliers"
                      value={tieronesuppliers}
                      onChange={tierOneSuppliersHandler}
                    />
                    <div style={percentBox}> % </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Grid container className={classes.targetbox}>
                  <Grid item xs={9}>
                    <p>
                      {context.t(
                        "WV INDICATOR 2. Percentage of upstream (tier-2 and beyond) suppliers in your supply chain with Issara worker voice channels available to workers, based on supply chain information provided"
                      )}
                    </p>
                  </Grid>
                  <Grid item xs={2} className="percent-box">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      id="upstreamsuppliers"
                      value={upstreamsuppliers}
                      onChange={upstreamSuppliersHandler}
                    />
                    <div style={percentBox}> % </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* ================================= end Area 1 =========================================== */}

            {/* ================================= starts Area 2 =========================================== */}
            <Grid className={classes.area}>
              <h5>{context.t("AREA 2. LABOUR RECRUITMENT: ANNUAL TARGETS")}</h5>
            </Grid>
            <Grid container>
              <Grid className={classes.indicatorpara}>
                <p>
                  {context.t(
                    "ER INDICATOR 1. Ethical recruitment policy in force is (tick all that are within your goal for this year):"
                  )}
                </p>
              </Grid>
              <FormGroup style={{ marginLeft: "1%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setinternationalSnD(e.target.checked);
                      }}
                      checked={internationalSnD}
                      color="black"
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>
                      {context.t(
                        "In line with international standards and definitions"
                      )}
                    </span>
                  }
                  id="internationalSnD"
                  className={internationalSnD ? "checkbox" : ""}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setcommunicatedToS(e.target.checked);
                      }}
                      checked={communicatedToS}
                      color="black"
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>
                      {context.t(
                        "Clearly communicated to suppliers, including effective date"
                      )}
                    </span>
                  }
                  id="communicatedToS"
                  className={communicatedToS ? "checkbox" : ""}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setcommercialProcesses(e.target.checked);
                      }}
                      checked={commercialProcesses}
                      color="black"
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>
                      {context.t(
                        "Integrated into commercial processes (supplier scorecards, contracts, etc.)"
                      )}
                    </span>
                  }
                  id="commercialProcesses"
                  className={commercialProcesses ? "checkbox" : ""}
                />
              </FormGroup>
              {/* Sub indicators */}
              <Grid container>
                <Grid item xs={10}>
                  <Grid className={classes.indicatorpara}>
                    <p> {context.t("ER INDICATOR 2.")} </p>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className={classes.sub_indicatorpara}>
                <Grid item xs={10}>
                  <Grid>
                    <p>
                      {context.t(
                        "Indicator 2A: Number of recruitment agencies mapped across supplier base."
                      )}
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid className="number-box">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={suppliersBase}
                      onChange={suppliersBaseHandler}
                      id="supplier_basepercent"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.sub_indicatorpara}>
                <Grid item xs={10}>
                  <Grid>
                    <p>
                      {context.t(
                        "Indicator 2B: Number of suppliers collaborating with Issara and their recruitment agencies on pre-departure orientations and strengthened recruitment practices"
                      )}
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid className="number-box">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={suppliersCollaborate}
                      onChange={suppliersCollaborateHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.sub_indicatorpara}>
                <Grid item xs={10}>
                  <Grid>
                    <p>
                      {context.t(
                        "Indicator 2C: Number of suppliers utilizing Golden Dreams Marketplace to reduce risk of worker indebtedness and workers paying fees to brokers and middlemen"
                      )}
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid className="number-box">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={suppliersUtilizing}
                      onChange={suppliersUtilizingHandler}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={10}>
                  <Grid className={classes.indicatorpara}>
                    <p>
                      {context.t(
                        "ER INDICATOR 3. Number of suppliers that have undertaken recruitment surveys by Issara to assess recruitment process-related risks, and recruitment-related fees paid by workers"
                      )}
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid className="number-box">
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={undertakenRecruitmentSupp}
                      onChange={undertakenRecruitmentSuppliersHandler}
                      id="undertakenRecruitmentSupp"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={10}>
                  <Grid className={classes.indicatorpara}>
                    <p>
                      {context.t(
                        "ER INDICATOR 4. Percentage of workers in supply chain reimbursed for recruitment-related fees owed"
                      )}
                    </p>
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  <Grid className={`percent-box ${classes.indicatorpara}`}>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      value={workersInSC}
                      onChange={workersInSCHandler}
                      id="workersInSC"
                    />
                    <Grid style={percentBox}> % </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* ================================= end Area 2 =========================================== */}
            {/* ================================= starts Area 3 =========================================== */}
            <Grid className={classes.area}>
              <h5>{context.t("AREA 3. WORKING CONDITIONS: ANNUAL TARGETS")}</h5>
            </Grid>
            <Grid container>
              <Grid className={classes.indicatorpara}>
                <p>
                  {context.t(
                    "WORK INDICATOR 1. Ethical sourcing and migrant worker policies put into force (tick all that are within your goal for this year)."
                  )}
                </p>
              </Grid>
              <FormGroup style={{ marginLeft: "1%" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => setwcInternationalSnD(e.target.checked)}
                      checked={wcInternationalSnD}
                      color="black"
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>
                      {context.t(
                        "In line with international standards and definitions"
                      )}
                    </span>
                  }
                  id="wcInternationalSnD"
                  className={wcInternationalSnD ? "checkbox" : ""}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setwcCommunicatedToS(e.target.checked);
                      }}
                      checked={wcCommunicatedToS}
                      color="black"
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>
                      {context.t("Clearly communicated to suppliers")}
                    </span>
                  }
                  id="wcCommunicatedToS"
                  className={wcCommunicatedToS ? "checkbox" : ""}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => {
                        setwcCommercialProcesses(e.target.checked);
                      }}
                      checked={wcCommercialProcesses}
                      color="black"
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>
                      {context.t(
                        "Integrated into commercial processes (supplier scorecards, contracts, etc.)"
                      )}
                    </span>
                  }
                  id="wcCommercialProcesses"
                  className={wcCommercialProcesses ? "checkbox" : ""}
                />
              </FormGroup>
              <GridContainer>
                <Grid className={classes.indicatorpara}>
                  <p>
                    {context.t(
                      "WORK INDICATOR 2. Average supplier performance scores (scale of 1-5, where 1 is poor and 5 is excellent):"
                    )}
                  </p>
                </Grid>
                <Grid container style={{ marginLeft: "1%" }}>
                  <Grid item md={3}>
                    <Grid>
                      <p style={{ paddingRight: "10px" }}>
                        {context.t("• Quality of remediation")}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid className="number-box">
                    <Grid item md={4}>
                      <ToggleButtonGroup
                        color="primary"
                        value={String(qualityremediation)}
                        exclusive
                        onChange={qualityRemediationHandler}
                        style={{ height: "25px" }}
                      >
                        <ToggleButton value="1">{context.t("1")}</ToggleButton>
                        <ToggleButton value="2">{context.t("2")}</ToggleButton>
                        <ToggleButton value="3">{context.t("3")}</ToggleButton>
                        <ToggleButton value="4">{context.t("4")}</ToggleButton>
                        <ToggleButton value="5">{context.t("5")}</ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container style={{ marginLeft: "1%" }}>
                  <Grid item md={3}>
                    <Grid>
                      <p style={{ paddingRight: "10px" }}>
                        {context.t("• Timeliness of remediation")}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="number-box">
                      <Grid item md={4}>
                        <ToggleButtonGroup
                          color="primary"
                          value={String(timelinessremediation)}
                          exclusive
                          onChange={timelinessRemediationHandler}
                          style={{ height: "25px" }}
                        >
                          <ToggleButton value="1">
                            {context.t("1")}
                          </ToggleButton>
                          <ToggleButton value="2">
                            {context.t("2")}
                          </ToggleButton>
                          <ToggleButton value="3">
                            {context.t("3")}
                          </ToggleButton>
                          <ToggleButton value="4">
                            {context.t("4")}
                          </ToggleButton>
                          <ToggleButton value="5">
                            {context.t("5")}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container style={{ marginLeft: "1%" }}>
                  <Grid item md={3}>
                    <Grid>
                      <p style={{ paddingRight: "10px" }}>
                        {context.t("• Openness to reform")}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid>
                    <Grid className="number-box">
                      <Grid item md={4}>
                        <ToggleButtonGroup
                          color="primary"
                          value={String(opennesstoreform)}
                          exclusive
                          onChange={opennessToReformHandler}
                          style={{ height: "25px" }}
                        >
                          <ToggleButton value="1">
                            {context.t("1")}
                          </ToggleButton>
                          <ToggleButton value="2">
                            {context.t("2")}
                          </ToggleButton>
                          <ToggleButton value="3">
                            {context.t("3")}
                          </ToggleButton>
                          <ToggleButton value="4">
                            {context.t("4")}
                          </ToggleButton>
                          <ToggleButton value="5">
                            {context.t("5")}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={10}>
                    <Grid className={classes.indicatorpara}>
                      <p>
                        {context.t(
                          "WORK INDICATOR 3. Percentage of worker-reported issues in the supply chain resolved in under 60 days"
                        )}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid className="percent-box">
                      <input
                        type="number"
                        min="0"
                        max="100"
                        value={workerreportedissuespercent}
                        onChange={workerReportedIssuespercentHnadler}
                        id="workerreportedissuespercent"
                      />
                      <div style={percentBox}> % </div>
                    </Grid>
                  </Grid>
                </Grid>
              </GridContainer>
              <Grid className={classes.area}>
                <h5>
                  {context.t(
                    "NOTES & REMARKS ON THE PROGRESS INDICATORS FOR THE ANNUAL WORKPLAN"
                  )}
                </h5>
              </Grid>
              <p>
                {context.t(
                  "Please feel free to add any note in this space, either for yourself or to note for the Issara team."
                )}
              </p>
              <TextareaAutosize
                id="free-note"
                aria-label="minimum height"
                className={classes.textArea}
                minRows={10}
                value={noteandremarks}
                defaultValue={gl_mainpriority}
                formControlProps={{ fullWidth: true }}
                onChange={noteandremarksHandler}
                istextarea={true}
              />
            </Grid>
          </Grid>

          <GridContainer style={{ marginTop: "10px" }}>
            <Grid item xs={6} sm={6} md={8} lg={8}>
              <Button
                onClick={props.back}
                variant="contained"
                style={{ marginLeft: "5px" }}
              >
                {context.t("Back")}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={4} lg={4} style={BottomBtn}>
              <Button
                onClick={progressIndicatorHandler}
                variant="contained"
                width="200px"
                style={{ marginRight: "15px", backgroundColor: "#03A9F4" }}
              >
                {context.t("Save")}
              </Button>
              <Button
                onClick={NextHandler}
                variant="contained"
                width="200px"
                style={{ marginRight: "5px" }}
              >
                {context.t("Next")}
              </Button>
            </Grid>
          </GridContainer>
        </div>
      )}
    </Container>
  );
}

// -----------------------SP form-Four---------------------------//
export function SPWorkPlaningFormFour(props, context) {
  const classes = useStyles();
  const isRunned = useRef(false);
  const [noteandremarks, setNotesAndRemarks] = React.useState("");
  const [noteandremarksClone, setNotesAndRemarksClone] = React.useState("");
  const [workplanID, setWorkplanID] = React.useState();
  const [activitiesData, setactivitiesData] = React.useState([]);

  const callApi = () => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/`,
      (response) => {
        setWorkplanID(response.id);
        if (response.notes_remark) {
          for (let xxl = 0; xxl < response.notes_remark.length; xxl++) {
            if (response.notes_remark[xxl].area === "workplan activity") {
              setNotesAndRemarks(response.notes_remark[xxl].txt_value);
              setNotesAndRemarksClone(response.notes_remark[xxl].txt_value);
            }
          }
        }
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  React.useEffect(() => {
    callApi();
  }, []);

  function submitHandler() {
    let noteRemark = {
      txt_value: noteandremarks,
      area: "workplan activity",
      workplan: workplanID,
    };

    // API
    HttpService.post(
      `${process.env.REACT_APP_API_URL}/sp-workplan-notes_and_remarks/`,
      noteRemark,
      (response) => {
        swal({
          text: context.t("Data save sucsessfully"),
          buttons: false,
          timer: 2000,
        });
      },
      (err) => {
        console.log(err.message);
      }
    );
    callApi();
  }

  const [dontShow, setDontShow] = React.useState(gl_dontShow);
  React.useEffect(() => {
    gl_dontShow = dontShow;
  });

  const NextHandler = () => {
    if (gl_dontShow == false) {
      if (noteandremarksClone !== noteandremarks) {
        swal({
          text: context.t("Changes has not been saved."),
          icon: "warning",
          buttons: true,
          dangerMode: true,
          buttons: {
            close: context.t("Cancel"),
            Proceed: context.t("Proceed Anyway"),
            save: context.t("Save"),
          },
          closeOnClickOutside: false,
          content: (
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setDontShow(e.target.checked);
                  }}
                  color="black"
                />
              }
              label={
                <span style={{ fontSize: "14px" }}>
                  {context.t("Do not show this again.")}
                </span>
              }
            />
          ),
        }).then((willSave) => {
          switch (willSave) {
            case "Proceed":
              props.next();
              break;
            case "save":
              submitHandler();
              break;
          }
        });
      } else {
        props.review();
      }
    } else {
      props.review();
    }
  };

  let _user = loginStore.getLoginUser();
  let priority_activity = null;
  let activityValue = false;
  const updateActivity = () => {
    HttpService.post(
      `${process.env.REACT_APP_API_URL}/activity_progress/`,
      {
        value: activityValue,
        priority_activity: priority_activity,
        workplan: workplanID ? workplanID : gl_wp,
      },
      (response) => {
        getWPActivities();
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  const getWPActivities = () => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/activity_progress/?sp=${_user.partner}`,
      (response) => {
        callApi();
        setactivitiesData(response.results);

        isRunned.current = true;
      },
      (err) => {
        console.log(err.message);
      }
    );
  };

  React.useEffect(() => {
    if (isRunned.current) return;
    getWPActivities();
  }, [_user.partner]);

  let worker_voice_data = [];
  let labour_recruitment_data = [];
  let working_conditions_data = [];

  var newupdateddata = activitiesData.sort((a, b) => {
    if (a.priority_activity.id > b.priority_activity.id) {
      return 1;
    }
    if (a.priority_activity.id < b.priority_activity.id) {
      return -1;
    }
    return 0;
  });

  newupdateddata.forEach((datas) => {
    if (datas.priority_activity.category === "worker_voice") {
      worker_voice_data.push(datas);
    } else if (datas.priority_activity.category === "labour_recruitment") {
      labour_recruitment_data.push(datas);
    } else if (datas.priority_activity.category === "working_conditions") {
      working_conditions_data.push(datas);
    }
  });
  return (
    <Container>
      {activitiesData.length === 0 ? (
        <LinearProgress />
      ) : (
        <div className="workplan-box">
          <h3>
            {context.t(
              "4 of 5. Priority Workplan Activities (please check all that apply)"
            )}
          </h3>
          <p>
            {context.t(
              "The priority activities for your company that you indicate here will appear on your SP Progress Dashboard."
            )}
          </p>

          {/* ================================= end Area 1 =========================================== */}
          <Grid container>
            <Grid className={classes.area}>
              <h5>{context.t("AREA 1. WORKER VOICE: ANNUAL TARGETS")}</h5>
              <Typography style={{ marginBottom: "10px" }}>
                {context.t(
                  "If additional details need to be provided, please use the note box at bottom."
                )}
              </Typography>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p>{context.t("Foundational activities")}</p>
                {worker_voice_data.map((item, index) => {
                  return item.priority_activity.category == "worker_voice" ? (
                    index <= 2 ? (
                      <Grid>
                        <FormGroup className={classes.checkBox}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="black"
                                name="priority_activity"
                                onClick={(e) => {
                                  priority_activity = item.id;
                                  activityValue = e.target.checked;
                                  updateActivity();
                                }}
                                id={item.id}
                                checked={item.value}
                              />
                            }
                            label={item.priority_activity.description}
                          />
                        </FormGroup>
                      </Grid>
                    ) : null
                  ) : null;
                })}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p>{context.t("Supply chain activities")}</p>
                {worker_voice_data.map((item, index) => {
                  return item.priority_activity.category == "worker_voice" ? (
                    index > 2 ? (
                      <Grid>
                        <FormGroup className={classes.checkBox}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="black"
                                name="priority_activity"
                                onClick={(e) => {
                                  priority_activity = item.id;
                                  activityValue = e.target.checked;
                                  updateActivity();
                                }}
                                id={item.id}
                                checked={item.value}
                              />
                            }
                            label={item.priority_activity.description}
                          />
                        </FormGroup>
                      </Grid>
                    ) : null
                  ) : null;
                })}
              </Grid>
            </Grid>
            {/* ================================= end Area 1 =========================================== */}
            <Grid className={classes.area}>
              <h5>{context.t("AREA 2. LABOUR RECRUITMENT")}</h5>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p>{context.t("Foundational activities")}</p>
                {labour_recruitment_data.map((item, index) => {
                  return item.priority_activity.category ==
                    "labour_recruitment" ? (
                    index <= 2 ? (
                      <Grid>
                        <FormGroup className={classes.checkBox}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="black"
                                name="priority_activity"
                                onClick={(e) => {
                                  priority_activity = item.id;
                                  activityValue = e.target.checked;
                                  updateActivity();
                                }}
                                id={item.id}
                                checked={item.value}
                              />
                            }
                            label={item.priority_activity.description}
                          />
                        </FormGroup>
                      </Grid>
                    ) : null
                  ) : null;
                })}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p>{context.t("Supply chain activities")}</p>
                {labour_recruitment_data.map((item, index) => {
                  return item.priority_activity.category ==
                    "labour_recruitment" ? (
                    index > 2 ? (
                      <Grid>
                        <FormGroup className={classes.checkBox}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="black"
                                name="priority_activity"
                                onClick={(e) => {
                                  priority_activity = item.id;
                                  activityValue = e.target.checked;
                                  updateActivity();
                                }}
                                id={item.id}
                                checked={item.value}
                              />
                            }
                            label={item.priority_activity.description}
                          />
                        </FormGroup>
                      </Grid>
                    ) : null
                  ) : null;
                })}
              </Grid>
            </Grid>
            {/* ================================= end Area 2 =========================================== */}
            <Grid className={classes.area}>
              <h5>{context.t("AREA 3. WORKING CONDITIONS")}</h5>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p>{context.t("Foundational activities")}</p>
                {working_conditions_data.map((item, index) => {
                  return item.priority_activity.category ==
                    "working_conditions" ? (
                    index <= 2 ? (
                      <Grid>
                        <FormGroup className={classes.checkBox}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="black"
                                name="priority_activity"
                                onClick={(e) => {
                                  priority_activity = item.id;
                                  activityValue = e.target.checked;
                                  updateActivity();
                                }}
                                id={item.id}
                                checked={item.value}
                              />
                            }
                            label={item.priority_activity.description}
                          />
                        </FormGroup>
                      </Grid>
                    ) : null
                  ) : null;
                })}
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <p>{context.t("Supply chain activities")}</p>
                {working_conditions_data.map((item, index) => {
                  return item.priority_activity.category ==
                    "working_conditions" ? (
                    index > 2 ? (
                      <Grid>
                        <FormGroup className={classes.checkBox}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="black"
                                name="priority_activity"
                                onClick={(e) => {
                                  priority_activity = item.id;
                                  activityValue = e.target.checked;
                                  updateActivity();
                                }}
                                id={item.id}
                                checked={item.value}
                              />
                            }
                            label={item.priority_activity.description}
                          />
                        </FormGroup>
                      </Grid>
                    ) : null
                  ) : null;
                })}
              </Grid>
            </Grid>
            {/* ================================= end Area 3 =========================================== */}
          </Grid>
          <Grid>
            <h3>{context.t("4 of 5. Priority Workplan Activities")}</h3>
            <h5>
              {context.t(
                "NOTES & REMARKS ON THE PRIORITY WORKPLAN ACTIVITIES FOR THE ANNUAL WORKPLAN"
              )}
            </h5>
            <p>
              {context.t(
                "Please feel free to add any note in this space, either for yourself or to note for the Issara team."
              )}
            </p>

            <TextareaAutosize
              id="free-note"
              style={{
                width: "100%",
                border: "1px solid black",
                padding: "10px",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
              }}
              aria-label="minimum height"
              minRows={10}
              value={noteandremarks}
              defaultValue={noteandremarks}
              formControlProps={{ fullWidth: true }}
              onChange={(e) => {
                setNotesAndRemarks(e.target.value);
              }}
              isTextArea={true}
            />
            <GridContainer style={{ marginTop: "10px" }}>
              <Grid item xs={6} sm={6} md={8} lg={8}>
                <Button
                  onClick={props.back}
                  variant="contained"
                  width="200px"
                  style={{ marginLeft: "5px" }}
                >
                  {context.t("Back")}
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} style={BottomBtn}>
                <Button
                  onClick={submitHandler}
                  variant="contained"
                  width="200px"
                  style={{ marginRight: "15px", backgroundColor: "#03A9F4" }}
                >
                  {context.t("Save")}
                </Button>
                <Button
                  onClick={NextHandler}
                  variant="contained"
                  width="200px"
                  style={{ marginRight: "15px" }}
                >
                  {context.t("Review Workplan")}
                </Button>
              </Grid>
            </GridContainer>
          </Grid>
        </div>
      )}
    </Container>
  );
}

export function WorkplanFromReview(props, context) {
  const [workplanID, setWorkplanID] = React.useState();
  React.useEffect(() => {
    HttpService.get(
      `${process.env.REACT_APP_API_URL}/sp_work_plan/`,
      (response) => {
        setWorkplanID(response.id);
      },
      (err) => {
        console.log(err.message);
      }
    );
  }, []);
  return (
    <Container>
      <Grid className="workplan-box">
        <h3>{context.t("5 of 5. Review and Finalize Annual Workplan")}</h3>
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            style={{
              textAlign: "center",
              border: "2px solid black",
              borderRadius: "18px",
              margin: "50px 0px",
              padding: "20px 10px",
            }}
          >
            <h4 id="congratulations-text">
              <b>{context.t("CONGRATULATIONS!")}</b>
            </h4>
            <p style={{ fontWeight: "500" }}>
              {context.t(
                "Great work, you have finalized your Issara Annual Workplan! We will look forward to reviewing your company's progress at the mid-year review."
              )}
            </p>
            <Grid>
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <a
                    href={`${process.env.REACT_APP_API_URL}/work_plan_pdf/${workplanID}`}
                    style={{ marginLeft: "16px" }}
                  >
                    {context.t("Download Workplan PDF")}
                  </a>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Link
                    to="/admin/spprogressdashboard"
                    style={{ marginRight: "16px" }}
                  >
                    {context.t("Go to SP Progress Metrics")}
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}></Grid>
        </Grid>
        <Grid component="span" style={BottomBtn}>
          <Button
            onClick={props.back}
            variant="contained"
            width="200px"
            style={{ marginRight: "15px", backgroundColor: "#03A9F4" }}
          >
            {context.t("Back")}
          </Button>
          <Button
            onClick={props.revise}
            variant="contained"
            width="200px"
            style={{ marginRight: "15px" }}
          >
            {context.t("Revise")}
          </Button>
          <Button
            variant="contained"
            width="200px"
            style={{ marginRight: "15px" }}
          >
            {context.t("FINALIZE & SAVE WORKPLAN")}
          </Button>
        </Grid>
      </Grid>
      <Grid className="workplan-box">
        <FinalWorkplan />
      </Grid>
    </Container>
  );
}

// -----------------------Main SP form---------------------------//
const SPWorkPlaningForm = (props, context) => {
  const [formone, setFormOne] = React.useState(true);
  const [formtwo, setFormTwo] = React.useState(false);
  const [formthree, setFormThree] = React.useState(false);
  const [formfour, setFormFour] = React.useState(false);
  const [reviewWP, setReviewWP] = React.useState(false);

  function showFormOne() {
    setFormOne(true);
    setFormTwo(false);
    setFormThree(false);
    setFormFour(false);
  }
  function showFormTwo() {
    // if ("" === "") {
    //   // document.getElementById('error1').style.visibility = "visible"
    // }
    // else {
    setFormOne(false);
    setFormTwo(true);
    setFormThree(false);
    setFormFour(false);
    // }
  }
  function showFormThree() {
    setFormOne(false);
    setFormTwo(false);
    setFormThree(true);
    setFormFour(false);
  }
  function showFormFour() {
    if (gl_tieronesuppliers > 100) {
      document
        .getElementById("tieronesuppliers")
        .classList.add("indicatorError");
    }
    if (gl_upstreamsuppliers > 100) {
      document
        .getElementById("upstreamsuppliers")
        .classList.add("indicatorError");
    }
    if (gl_workersInSC > 100) {
      document.getElementById("workersInSC").classList.add("indicatorError");
    }
    if (gl_undertakenRecruitmentSuppliers == 0) {
      document
        .getElementById("undertakenRecruitmentSupp")
        .classList.add("indicatorError");
    }
    setFormOne(false);
    setFormTwo(false);
    setFormThree(false);
    setFormFour(true);
  }
  function backForm4() {
    setFormFour(true);
    setReviewWP(false);
  }
  function revise() {
    setFormOne(true);
    setReviewWP(false);
  }
  function backFormThree() {
    setFormTwo(false);
    setFormThree(true);
    setFormFour(false);
  }
  function reviewWorkplan() {
    setFormOne(false);
    setFormTwo(false);
    setFormThree(false);
    setFormFour(false);
    setReviewWP(true);
  }
  return (
    <>
      {formone ? <SPWorkPlaningFormOne next={showFormTwo} /> : null}
      {formtwo ? (
        <SPWorkPlaningFormTwo next={showFormThree} back={showFormOne} />
      ) : null}
      {formthree ? (
        <SPWorkPlaningFormThree next={showFormFour} back={showFormTwo} />
      ) : null}
      {formfour ? (
        <SPWorkPlaningFormFour back={backFormThree} review={reviewWorkplan} />
      ) : null}
      {reviewWP ? (
        <WorkplanFromReview back={backForm4} revise={revise} />
      ) : null}
    </>
  );
};
export default SPWorkPlaningForm;

SPWorkPlaningForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

SPWorkPlaningFormOne.contextTypes = {
  t: PropTypes.func.isRequired,
};

SPWorkPlaningFormTwo.contextTypes = {
  t: PropTypes.func.isRequired,
};

SPWorkPlaningFormThree.contextTypes = {
  t: PropTypes.func.isRequired,
};

SPWorkPlaningFormFour.contextTypes = {
  t: PropTypes.func.isRequired,
};

WorkplanFromReview.contextTypes = {
  t: PropTypes.func.isRequired,
};
